/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TestQuery
// ====================================================

export interface TestQuery_getPlanningCycleSpec_availableCountrySourceCatalogItems {
  __typename: 'CountrySourceCatalogItem';
  catalogId: number;
  depth: number;
  polygonCount: number;
  country: string;
  name: string;
  sourceId: string;
  sourceKey: string;
  sourceUrl: string;
  countryName: string;
}

export interface TestQuery_getPlanningCycleSpec {
  __typename: 'PlanningCycleResponse';
  availableCountrySourceCatalogItems: TestQuery_getPlanningCycleSpec_availableCountrySourceCatalogItems[];
}

export interface TestQuery {
  getPlanningCycleSpec: TestQuery_getPlanningCycleSpec | null;
}

export interface TestQueryVariables {
  planningCycleId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddCustomMeasure
// ====================================================

export interface AddCustomMeasure_addCustomMeasure {
  __typename: 'AddCustomMeasureResponse';
  measureId: number | null;
}

export interface AddCustomMeasure {
  addCustomMeasure: AddCustomMeasure_addCustomMeasure | null;
}

export interface AddCustomMeasureVariables {
  tenantId: number;
  planningCycleId: number;
  measureName: string;
  measureFieldType: string;
  measureFormatType: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddFileMetadata
// ====================================================

export interface AddFileMetadata_addFileMetadata {
  __typename: 'FileMetadataWithAuth';
  fileId: string;
  fileName: string;
  jobId: string | null;
  planningCycleId: number;
  tenantId: number;
  bucket: string;
  key: string;
  fileType: string | null;
  status: string | null;
  message: string | null;
  accessKeyId: string | null;
  secretKey: string | null;
  sessionToken: string | null;
  tqAccessKeyId: string | null;
  tqS3BucketId: string | null;
  tqSecretKey: string | null;
  tqS3FileName: string;
}

export interface AddFileMetadata {
  addFileMetadata: AddFileMetadata_addFileMetadata | null;
}

export interface AddFileMetadataVariables {
  planningCycleId: number;
  fileName: string;
  fileType: string;
  quotaComponentId?: number | null;
  battlecardId?: number | null;
  hierarchyId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addQuotaComponentToBattlecard
// ====================================================

export interface addQuotaComponentToBattlecard_addQuotaComponentToBattlecard {
  __typename: 'AddQuotaComponentToBattlecardResponse';
  battlecardId: number;
  quotaComponentId: number;
}

export interface addQuotaComponentToBattlecard {
  addQuotaComponentToBattlecard: addQuotaComponentToBattlecard_addQuotaComponentToBattlecard | null;
}

export interface addQuotaComponentToBattlecardVariables {
  battlecardId: number;
  quotaComponentId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddTenant
// ====================================================

export interface AddTenant_addTenant {
  __typename: 'Tenant';
  tenantId: number | null;
  tenantName: string | null;
  tenantSlug: string | null;
  tenantGlobalId: string | null;
}

export interface AddTenant {
  addTenant: AddTenant_addTenant | null;
}

export interface AddTenantVariables {
  input: TenantAddInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddTerritoryWorkflowComment
// ====================================================

export interface AddTerritoryWorkflowComment_addTerritoryWorkflowComment {
  __typename: 'AddTerritoryWorkflowCommentOutput';
  success: boolean;
  threadId: number;
  commentId: number;
}

export interface AddTerritoryWorkflowComment {
  addTerritoryWorkflowComment: AddTerritoryWorkflowComment_addTerritoryWorkflowComment | null;
}

export interface AddTerritoryWorkflowCommentVariables {
  deploymentModelId: number;
  battlecardId: number;
  threadId?: number | null;
  content: string;
  version: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddUser
// ====================================================

export interface AddUser {
  addUser: boolean | null;
}

export interface AddUserVariables {
  input: AddUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddUserToTenant
// ====================================================

export interface AddUserToTenant {
  addUserToTenant: number | null;
}

export interface AddUserToTenantVariables {
  input: UserTenantAddInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AllocateTopDown
// ====================================================

export interface AllocateTopDown_allocateTopDown {
  __typename: 'AllocateTopDownOutput';
  success: boolean;
}

export interface AllocateTopDown {
  allocateTopDown: AllocateTopDown_allocateTopDown;
}

export interface AllocateTopDownVariables {
  amount?: number | null;
  battlecardId: number;
  measureId: number;
  quotaComponentId: number;
  measureIdToProrateBy?: number | null;
  territoryGroupId?: number | null;
  clearQuotaAdjustment?: boolean | null;
  hierarchyTarget?: HierarchyTarget[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AllocateTopDownTerritoryGroup
// ====================================================

export interface AllocateTopDownTerritoryGroup_allocateTopDownTerritoryGroup {
  __typename: 'TerritoryMeasureValue';
  battlecardId: number | null;
}

export interface AllocateTopDownTerritoryGroup {
  allocateTopDownTerritoryGroup: (AllocateTopDownTerritoryGroup_allocateTopDownTerritoryGroup | null)[] | null;
}

export interface AllocateTopDownTerritoryGroupVariables {
  battlecardId: number;
  measureId: number;
  quotaComponentId: number;
  territoryGroupId?: number | null;
  amount: number;
  clearQuotaAdjustment?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ApproveWorkflow
// ====================================================

export interface ApproveWorkflow_approveWorkflow {
  __typename: 'ApproveWorkflowResponse';
  success: boolean | null;
}

export interface ApproveWorkflow {
  approveWorkflow: ApproveWorkflow_approveWorkflow | null;
}

export interface ApproveWorkflowVariables {
  battlecardWorkflowId?: number | null;
  territoryGroupWorkflowId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BulkInviteUsers
// ====================================================

export interface BulkInviteUsers_bulkInviteUsers {
  __typename: 'InviteUserOutput';
  emailAddress: string;
  invitationId: string | null;
}

export interface BulkInviteUsers {
  bulkInviteUsers: BulkInviteUsers_bulkInviteUsers[];
}

export interface BulkInviteUsersVariables {
  users: InviteUserInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CancelSymonPipeConfiguration
// ====================================================

export interface CancelSymonPipeConfiguration {
  cancelSymonPipeConfiguration: boolean | null;
}

export interface CancelSymonPipeConfigurationVariables {
  input?: CancelSymonPipeConfigurationInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CancelWorkflow
// ====================================================

export interface CancelWorkflow_cancelWorkflow {
  __typename: 'CancelWorkflowResponse';
  success: boolean | null;
}

export interface CancelWorkflow {
  cancelWorkflow: CancelWorkflow_cancelWorkflow | null;
}

export interface CancelWorkflowVariables {
  deploymentModelId: number;
  planningCycleId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeCurrentUserPasswordMutation
// ====================================================

export interface ChangeCurrentUserPasswordMutation_changeCurrentUserPassword {
  __typename: 'UserPasswordResponse';
  errorMessage: string | null;
  passwordUpdatedAt: any | null;
  wasPasswordChanged: boolean;
}

export interface ChangeCurrentUserPasswordMutation {
  changeCurrentUserPassword: ChangeCurrentUserPasswordMutation_changeCurrentUserPassword | null;
}

export interface ChangeCurrentUserPasswordMutationVariables {
  proposedPassword: string;
  previousPassword: string;
}

// ====================================================
// GraphQL query operation: GetTerritoryRulesForRebalancing
// ====================================================

export interface GetTerritoryRulesForRebalancing_getTerritoryRules_territoryRules {
  __typename: 'TerritoryRules';
  effectiveDate: any | null;
  endDate: any | null;
  measureGap: number | null;
  measureValue: number | null;
  numberOfAccounts: number | null;
  numberOfAccountsGap: number | null;
  ruleId: number | null;
  territoryId: string;
  territoryName: string;
  territoryGroupId: number;
  territoryGroupName: string;
}

export interface GetTerritoryRulesForRebalancing_getTerritoryRules {
  __typename: 'GetTerritoryRulesResponse';
  territoryRules: (GetTerritoryRulesForRebalancing_getTerritoryRules_territoryRules | null)[] | null;
  totalCount: number;
  totalAccountsCount: number;
  totalAccountsMean: number;
  measureValueMean: number;
  measureValueStandardDeviation: number;
}

export interface GetTerritoryRulesForRebalancing {
  getTerritoryRules: GetTerritoryRulesForRebalancing_getTerritoryRules;
}

export interface GetTerritoryRulesForRebalancingVariables {
  input?: GetTerritoryRulesInput | null;
}

export interface GetTerritoryRulesInput {
  battlecardId?: number | null;
  territoryGroupId?: number | null;
  quotaComponentId: number;
  startRow?: number | null;
  endRow?: number | null;
  sorting: GetTerritoryRulesSortModel;
  measureId: number;
  completeDefinition?: boolean | null;
  searchInput?: GetTerritoryRulesSearchInput | null;
  fieldIds?: (number | null)[] | null;
  rangeFieldInput?: GetRangeFieldInput | null;
  source?: SourceInput | null;
  dimensionsAndModifiersLimit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CleanFileProcess
// ====================================================

export interface CleanFileProcess_cleanFileProcess_pipes {
  __typename: 'SymonTransientPipeConfiguration';
  fileId: string;
  pipeUuid: string;
  status: string;
}

export interface CleanFileProcess_cleanFileProcess_fileMetadata {
  __typename: 'FileMetadata';
  fileId: string | null;
  status: string | null;
}

export interface CleanFileProcess_cleanFileProcess {
  __typename: 'FileMetadataWithPipes';
  pipes: (CleanFileProcess_cleanFileProcess_pipes | null)[] | null;
  fileMetadata: CleanFileProcess_cleanFileProcess_fileMetadata;
}

export interface CleanFileProcess {
  cleanFileProcess: CleanFileProcess_cleanFileProcess | null;
}

export interface CleanFileProcessVariables {
  input: CleanFileProcessInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateBattlecard
// ====================================================

export interface CreateBattlecard_createBattlecard {
  __typename: 'Battlecard';
  battlecardId: number;
}

export interface CreateBattlecard {
  createBattlecard: CreateBattlecard_createBattlecard | null;
}

export interface CreateBattlecardVariables {
  battlecardName: string;
  battlecardParentId: number;
  battlecardType: string;
  deploymentModelId: number;
  hierarchies?: (BattlecardHierarchyInput | null)[] | null;
  quotaComponent: (number | null)[];
  teams?: (TeamInput | null)[] | null;
  fileIds?: (string | null)[] | null;
  memberId?: number | null;
  currencyCode?: string | null;
  mapLevel?: BCMapLevelEnum | null;
  quotaDistributionHierarchyIds?: number[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateBlankScenario
// ====================================================

export interface CreateBlankScenario_createDeploymentModel {
  __typename: 'CreateDeploymentModelOutput';
  deploymentModelId: number;
  deploymentModelName: string;
}

export interface CreateBlankScenario {
  createDeploymentModel: CreateBlankScenario_createDeploymentModel | null;
}

export interface CreateBlankScenarioVariables {
  deploymentModelName: string;
  planningCycleId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTerritoryGroup
// ====================================================

export interface CreateTerritoryGroup_createTerritoryGroup {
  __typename: 'TerritoryGroup';
  territoryGroupId: number | null;
}

export interface CreateTerritoryGroup {
  createTerritoryGroup: CreateTerritoryGroup_createTerritoryGroup | null;
}

export interface CreateTerritoryGroupVariables {
  battlecardId: number;
  hierarchyId?: number | null;
  precedence: number;
  territoryGroupName: string;
  territoryGroupParentId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteBattlecard
// ====================================================

export interface DeleteBattlecard {
  deleteBattlecard: number | null;
}

export interface DeleteBattlecardVariables {
  battlecardId: number;
  deploymentModelId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteCustomMeasure
// ====================================================

export interface DeleteCustomMeasure_deleteCustomMeasure {
  __typename: 'DeleteCustomMeasureResponse';
  success: boolean | null;
}

export interface DeleteCustomMeasure {
  deleteCustomMeasure: DeleteCustomMeasure_deleteCustomMeasure | null;
}

export interface DeleteCustomMeasureVariables {
  tenantId: number;
  measureId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteFile
// ====================================================

export interface DeleteFile_deleteFile {
  __typename: 'FileMetadata';
  battlecardId: number | null;
  bucket: string | null;
  fileId: string | null;
  fileName: string | null;
  fileType: string | null;
  jobId: string | null;
  key: string | null;
  message: string | null;
  metaDataCreatedAt: number | null;
  metaDataUpdateAt: number | null;
  planningCycleId: number | null;
  published: boolean | null;
  quotaComponentId: number | null;
  status: string | null;
  tenantId: number | null;
}

export interface DeleteFile {
  deleteFile: DeleteFile_deleteFile | null;
}

export interface DeleteFileVariables {
  fileId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteHierarchyAttribute
// ====================================================

export interface DeleteHierarchyAttribute_deleteHierarchyAttribute {
  __typename: 'GetCustomHierarchy';
  hierarchyId: number | null;
  planningCycleId: number | null;
  name: string | null;
  key: string | null;
  parentKey: string | null;
  path: string | null;
  version: number | null;
  customProperties: any | null;
  children: (number | null)[] | null;
  hasChildren: boolean | null;
}

export interface DeleteHierarchyAttribute {
  deleteHierarchyAttribute: DeleteHierarchyAttribute_deleteHierarchyAttribute | null;
}

export interface DeleteHierarchyAttributeVariables {
  hierarchyId: number;
  planningCycleId: number;
  hierarchyType: string;
  hierarchyAttributeName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteHierarchyById
// ====================================================

export interface DeleteHierarchyById_deleteHierarchyById {
  __typename: 'HierarchyType';
  customProperties: any | null;
  fileId: string | null;
  hierarchyId: number | null;
  key: string | null;
  name: string | null;
  parentKey: string | null;
  path: string | null;
  planningCycleId: number | null;
  version: number | null;
}

export interface DeleteHierarchyById {
  deleteHierarchyById: (DeleteHierarchyById_deleteHierarchyById | null)[] | null;
}

export interface DeleteHierarchyByIdVariables {
  hierarchyIds: (number | null)[];
  hierarchyType: string;
  planningCycleId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteMember
// ====================================================

export interface DeleteMember_deleteMember {
  __typename: 'Member';
  tenantId: number;
  systemRoleName: string;
  memberUpdatedAt: any | null;
  memberId: number;
  memberCreatedAt: any | null;
  jobTitle: string | null;
  lastName: string;
  firstName: string;
  employeeId: string | null;
  emailAddress: string;
  deleted: string | null;
  customProperties: any | null;
}

export interface DeleteMember {
  deleteMember: DeleteMember_deleteMember | null;
}

export interface DeleteMemberVariables {
  memberId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteSheetMeasures
// ====================================================

export interface DeleteSheetMeasures_deleteSheetMeasures {
  __typename: 'DeleteSheetMeasuresResponse';
  success: boolean | null;
}

export interface DeleteSheetMeasures {
  deleteSheetMeasures: DeleteSheetMeasures_deleteSheetMeasures | null;
}

export interface DeleteSheetMeasuresVariables {
  sheetId: number;
  measureIds: (number | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteSymonPipeConfigurations
// ====================================================

export interface DeleteSymonPipeConfigurations {
  deleteSymonPipeConfigurations: boolean | null;
}

export interface DeleteSymonPipeConfigurationsVariables {
  input?: DeleteSymonPipeConfigurationsInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteTerritoryGroup
// ====================================================

export interface DeleteTerritoryGroup_deleteTerritoryGroup {
  __typename: 'TerritoryGroup';
  territoryGroupId: number | null;
}

export interface DeleteTerritoryGroup {
  deleteTerritoryGroup: DeleteTerritoryGroup_deleteTerritoryGroup | null;
}

export interface DeleteTerritoryGroupVariables {
  territoryGroupId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteTerritoryOwners
// ====================================================

export interface DeleteTerritoryOwners_deleteTerritoryOwners {
  __typename: 'DeleteTerritoryOwnersResponse';
  success: boolean;
}

export interface DeleteTerritoryOwners {
  deleteTerritoryOwners: DeleteTerritoryOwners_deleteTerritoryOwners | null;
}

export interface DeleteTerritoryOwnersVariables {
  tenantId: number;
  territoryId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteTerritoryRule
// ====================================================

export interface DeleteTerritoryRule_deleteTerritoryRule {
  __typename: 'DeleteTerritoryRuleInputOutput';
  success: boolean | null;
}

export interface DeleteTerritoryRule {
  deleteTerritoryRule: DeleteTerritoryRule_deleteTerritoryRule | null;
}

export interface DeleteTerritoryRuleVariables {
  ruleIds?: number[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ExchangeTemporaryToken
// ====================================================

export interface ExchangeTemporaryToken_exchangeTemporaryAuthToken {
  __typename: 'ExchangeToken';
  exchangeToken: string;
  expiresAt: number;
}

export interface ExchangeTemporaryToken {
  exchangeTemporaryAuthToken: ExchangeTemporaryToken_exchangeTemporaryAuthToken | null;
}

export interface ExchangeTemporaryTokenVariables {
  input: ExchangeTemporaryAuthTokenInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ExportData
// ====================================================

export interface ExportData_exportData_credentials {
  __typename: 'ExportCredentials';
  accessKey: string | null;
  secretAccessKey: string | null;
}

export interface ExportData_exportData_exportData {
  __typename: 'ExportDataStatus';
  exportId: string | null;
  status: ExportDataStatusEnum | null;
  message: string | null;
}

export interface ExportData_exportData_location {
  __typename: 'ExportLocation';
  bucket: string | null;
  key: string | null;
}

export interface ExportData_exportData {
  __typename: 'ExportDataCredentialsLocation';
  credentials: ExportData_exportData_credentials | null;
  exportData: ExportData_exportData_exportData | null;
  location: ExportData_exportData_location | null;
}

export interface ExportData {
  exportData: ExportData_exportData | null;
}

export interface ExportDataVariables {
  input: ExportDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GenerateAPIKey
// ====================================================

export interface GenerateAPIKey_generateAPIKey {
  __typename: 'APIKeyDef';
  apiKey: string | null;
  status: ApiTokenStatus;
  message: string | null;
}

export interface GenerateAPIKey {
  generateAPIKey: GenerateAPIKey_generateAPIKey | null;
}

export interface GenerateAPIKeyVariables {
  input: GenerateAPIKeyInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: generateImpersonationToken
// ====================================================

export interface generateImpersonationToken_generateImpersonationToken {
  __typename: 'GenerateImpersonationTokenResponse';
  token: string | null;
}

export interface generateImpersonationToken {
  generateImpersonationToken: generateImpersonationToken_generateImpersonationToken | null;
}

export interface generateImpersonationTokenVariables {
  input: GenerateImpersonationTokenInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InitiateWorkflow
// ====================================================

export interface InitiateWorkflow_initiateWorkflow {
  __typename: 'InitiateWorkflowResponse';
  success: boolean | null;
}

export interface InitiateWorkflow {
  initiateWorkflow: InitiateWorkflow_initiateWorkflow | null;
}

export interface InitiateWorkflowVariables {
  deploymentModelId: number;
  planningCycleId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InviteUser
// ====================================================

export interface InviteUser {
  inviteUser: string | null;
}

export interface InviteUserVariables {
  emailAddress: string;
  firstName: string;
  lastName: string;
  tenantId: number;
  systemRoleName?: SystemRoleName | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MarkScenariosOpen
// ====================================================

export interface MarkScenariosOpen_markDeploymentModelsAsOpened {
  __typename: 'MarkDeploymentModelsAsOpenedOutput';
  deploymentModelIds: number[];
}

export interface MarkScenariosOpen {
  markDeploymentModelsAsOpened: MarkScenariosOpen_markDeploymentModelsAsOpened | null;
}

export interface MarkScenariosOpenVariables {
  deploymentModelIds: number[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProrateAllocateTopDownTerritoryGroup
// ====================================================

export interface ProrateAllocateTopDownTerritoryGroup_prorateAllocateTopDownTerritoryGroup {
  __typename: 'TerritoryMeasureValue';
  battlecardId: number | null;
}

export interface ProrateAllocateTopDownTerritoryGroup {
  prorateAllocateTopDownTerritoryGroup:
    | (ProrateAllocateTopDownTerritoryGroup_prorateAllocateTopDownTerritoryGroup | null)[]
    | null;
}

export interface ProrateAllocateTopDownTerritoryGroupVariables {
  allocationMeasureId: number;
  amount: number;
  battlecardId: number;
  clearQuotaAdjustment: boolean;
  measureIdToProrateBy: number;
  quotaComponentId: number;
  territoryGroupId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PublishDeploymentModel
// ====================================================

export interface PublishDeploymentModel_updateDeploymentModel {
  __typename: 'UpdateDeploymentModelOutput';
  deploymentModelId: number;
  isPublished: boolean;
  deploymentModelName: string;
}

export interface PublishDeploymentModel {
  updateDeploymentModel: PublishDeploymentModel_updateDeploymentModel | null;
}

export interface PublishDeploymentModelVariables {
  deploymentModelId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: publishFileFromSymonS3
// ====================================================

export interface publishFileFromSymonS3_publishFileFromSymonS3 {
  __typename: 'SymonPipeConfiguration';
  downloadId: string | null;
  endAt: string | null;
  exportId: string | null;
  exportName: string | null;
  fileFormat: string | null;
  fileType: string | null;
  pipeId: string | null;
  pipeName: string | null;
  planningCycleId: number | null;
  progress: string | null;
  properties: any | null;
  runId: string | null;
  seqPipeExecution: number | null;
  startAt: string | null;
  status: string | null;
  symonPipeConfigurationId: number | null;
  tableNameLabel: string | null;
}

export interface publishFileFromSymonS3 {
  publishFileFromSymonS3: publishFileFromSymonS3_publishFileFromSymonS3 | null;
}

export interface publishFileFromSymonS3Variables {
  input: PublishFileFromSymonS3Input;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: publishFileToDB
// ====================================================

export interface publishFileToDB_publishFileToDB_value {
  __typename: 'Value';
  id: string;
  type: string;
}

export interface publishFileToDB_publishFileToDB {
  __typename: 'FieldName';
  fieldName: string;
  value: publishFileToDB_publishFileToDB_value | null;
}

export interface publishFileToDB {
  publishFileToDB: publishFileToDB_publishFileToDB[];
}

export interface publishFileToDBVariables {
  input: UpsertMappingInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReassignRuleHierarchies
// ====================================================

export interface ReassignRuleHierarchies_reassignRuleHierarchies {
  __typename: 'ReassignRuleHierarchiesOutput';
  success: boolean | null;
}

export interface ReassignRuleHierarchies {
  reassignRuleHierarchies: ReassignRuleHierarchies_reassignRuleHierarchies | null;
}

export interface ReassignRuleHierarchiesVariables {
  input: ReassignRuleHierarchiesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReassignTerritories
// ====================================================

export interface ReassignTerritories_reassignTerritories {
  __typename: 'reassignTerritoriesResponse';
  success: boolean;
}

export interface ReassignTerritories {
  reassignTerritories: ReassignTerritories_reassignTerritories;
}

export interface ReassignTerritoriesVariables {
  input?: reassignTerritoriesInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RebalanceTerritoryRules
// ====================================================

export interface RebalanceTerritoryRules_rebalanceTerritoryRules {
  __typename: 'RebalanceTerritoryOutput';
  success: boolean | null;
}

export interface RebalanceTerritoryRules {
  rebalanceTerritoryRules: RebalanceTerritoryRules_rebalanceTerritoryRules | null;
}

export interface RebalanceTerritoryRulesVariables {
  input: RebalanceTerritoryInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RedirectAccountUnit
// ====================================================

export interface RedirectAccountUnit_redirectAccountUnit {
  __typename: 'AccountRedirectOutput';
  redirectIds: number[];
}

export interface RedirectAccountUnit {
  redirectAccountUnit: RedirectAccountUnit_redirectAccountUnit;
}

export interface RedirectAccountUnitVariables {
  input: AccountRedirectInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RejectWorkflow
// ====================================================

export interface RejectWorkflow_rejectWorkflow {
  __typename: 'RejectWorkflowResponse';
  success: boolean | null;
}

export interface RejectWorkflow {
  rejectWorkflow: RejectWorkflow_rejectWorkflow | null;
}

export interface RejectWorkflowVariables {
  battlecardWorkflowId?: number | null;
  territoryGroupWorkflowId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeQuotaComponentFromBattlecard
// ====================================================

export interface removeQuotaComponentFromBattlecard_removeQuotaComponentFromBattlecard {
  __typename: 'RemoveQuotaComponentFromBattlecardResponse';
  success: boolean;
}

export interface removeQuotaComponentFromBattlecard {
  removeQuotaComponentFromBattlecard: removeQuotaComponentFromBattlecard_removeQuotaComponentFromBattlecard | null;
}

export interface removeQuotaComponentFromBattlecardVariables {
  battlecardId: number;
  quotaComponentId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RenameDeploymentModel
// ====================================================

export interface RenameDeploymentModel_updateDeploymentModel {
  __typename: 'UpdateDeploymentModelOutput';
  deploymentModelId: number;
  deploymentModelName: string;
}

export interface RenameDeploymentModel {
  updateDeploymentModel: RenameDeploymentModel_updateDeploymentModel | null;
}

export interface RenameDeploymentModelVariables {
  deploymentModelId: number;
  deploymentModelName?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RequestAllocateTopDown
// ====================================================

export interface RequestAllocateTopDown_requestAllocateTopDown {
  __typename: 'AllocateTopDownRequestOutput';
  jobId: string;
}

export interface RequestAllocateTopDown {
  requestAllocateTopDown: RequestAllocateTopDown_requestAllocateTopDown;
}

export interface RequestAllocateTopDownVariables {
  input: RequestAllocateTopDownInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RequestDeleteTerritoryRules
// ====================================================

export interface RequestDeleteTerritoryRules_requestDeleteTerritoryRules {
  __typename: 'RequestDeleteTerritoryRulesOutput';
  messageId: string;
  jobKey: string;
}

export interface RequestDeleteTerritoryRules {
  requestDeleteTerritoryRules: RequestDeleteTerritoryRules_requestDeleteTerritoryRules;
}

export interface RequestDeleteTerritoryRulesVariables {
  input: RequestDeleteTerritoryRulesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RequestDeploymentModelClone
// ====================================================

export interface RequestDeploymentModelClone_requestDeploymentModelClone {
  __typename: 'DeploymentModelCloneRequestOutput';
  messageId: string;
}

export interface RequestDeploymentModelClone {
  requestDeploymentModelClone: RequestDeploymentModelClone_requestDeploymentModelClone | null;
}

export interface RequestDeploymentModelCloneVariables {
  deploymentModelId: number;
  clonedDeploymentModelType?: DeploymentModelTypeEnum | null;
  clonedDeploymentModelName?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: requestDeploymentModelDelete
// ====================================================

export interface requestDeploymentModelDelete_requestDeploymentModelDelete {
  __typename: 'DeploymentModelDeleteRequestOutput';
  messageId: string;
  jobKey: string;
}

export interface requestDeploymentModelDelete {
  requestDeploymentModelDelete: requestDeploymentModelDelete_requestDeploymentModelDelete | null;
}

export interface requestDeploymentModelDeleteVariables {
  deploymentModelIds: number[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RequestPlanningCycleClone
// ====================================================

export interface RequestPlanningCycleClone_requestPlanningCycleClone {
  __typename: 'PlanningCycleCloneRequestOutput';
  messageId: string;
}

export interface RequestPlanningCycleClone {
  requestPlanningCycleClone: RequestPlanningCycleClone_requestPlanningCycleClone | null;
}

export interface RequestPlanningCycleCloneVariables {
  planningCycleId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RevokeSystemRole
// ====================================================

export interface RevokeSystemRole_revokeSystemRole {
  __typename: 'Member';
  tenantId: number;
  systemRoleName: string;
  memberUpdatedAt: any | null;
  memberId: number;
  memberCreatedAt: any | null;
  jobTitle: string | null;
  lastName: string;
  firstName: string;
  employeeId: string | null;
  emailAddress: string;
  deleted: string | null;
  customProperties: any | null;
}

export interface RevokeSystemRole {
  revokeSystemRole: RevokeSystemRole_revokeSystemRole | null;
}

export interface RevokeSystemRoleVariables {
  memberId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SaveSymonCredentials
// ====================================================

export interface SaveSymonCredentials_saveSymonCredentials {
  __typename: 'SymonAccountOutput';
  tenantId: number;
  symonAccountName: string | null;
  symonAccountEmailAddress: string | null;
  symonAccountCreatedAt: any | null;
  symonDomain: string | null;
  symonUiClientKey: string | null;
}

export interface SaveSymonCredentials {
  saveSymonCredentials: SaveSymonCredentials_saveSymonCredentials | null;
}

export interface SaveSymonCredentialsVariables {
  input?: SaveSymonCredentialsInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendChat
// ====================================================

export interface SendChat_chat_content {
  __typename: 'AssistantMessage';
  message: string;
  role: ChatRoleEnum | null;
}

export interface SendChat_chat {
  __typename: 'ChatResponse';
  content: SendChat_chat_content | null;
  conversationId: string | null;
}

export interface SendChat {
  chat: SendChat_chat | null;
}

export interface SendChatVariables {
  query: string;
  conversationId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: StartDataPreviewFromSymonPipe
// ====================================================

export interface StartDataPreviewFromSymonPipe_startDataPreviewFromSymonPipe {
  __typename: 'StartSymonPipePreviewData';
  exportId: string;
  pipeId: string;
  pollingTokenId: string;
  progress: number | null;
  status: string;
}

export interface StartDataPreviewFromSymonPipe {
  startDataPreviewFromSymonPipe: StartDataPreviewFromSymonPipe_startDataPreviewFromSymonPipe | null;
}

export interface StartDataPreviewFromSymonPipeVariables {
  exportId: string;
  pipeId: string;
  planningCycleId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: StartFileProcess
// ====================================================

export interface StartFileProcess_startFileProcess {
  __typename: 'FileProcessOutput';
  fileId: string;
}

export interface StartFileProcess {
  startFileProcess: StartFileProcess_startFileProcess | null;
}

export interface StartFileProcessVariables {
  input: FileProcessInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SubmitWorkflow
// ====================================================

export interface SubmitWorkflow_submitWorkflow {
  __typename: 'SubmitWorkflowResponse';
  success: boolean | null;
}

export interface SubmitWorkflow {
  submitWorkflow: SubmitWorkflow_submitWorkflow | null;
}

export interface SubmitWorkflowVariables {
  battlecardWorkflowId?: number | null;
  territoryGroupWorkflowId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ToggleTerritoryWorkflowStatus
// ====================================================

export interface ToggleTerritoryWorkflowStatus_toggleTerritoryWorkflowStatus {
  __typename: 'DeploymentModelDef';
  isTerritoryWorkflowStarted: string | null;
}

export interface ToggleTerritoryWorkflowStatus {
  toggleTerritoryWorkflowStatus: ToggleTerritoryWorkflowStatus_toggleTerritoryWorkflowStatus | null;
}

export interface ToggleTerritoryWorkflowStatusVariables {
  deploymentModelId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnassignRuleHierarchies
// ====================================================

export interface UnassignRuleHierarchies_unassignRuleHierarchies {
  __typename: 'UnassignRuleHierarchiesOutput';
  success: boolean | null;
}

export interface UnassignRuleHierarchies {
  unassignRuleHierarchies: UnassignRuleHierarchies_unassignRuleHierarchies | null;
}

export interface UnassignRuleHierarchiesVariables {
  input: UnassignRuleHierarchiesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateActivityFiles
// ====================================================

export interface UpdateActivityFiles_updateActivityFiles {
  __typename: 'BattlecardActivityFile';
  battlecardId: number;
  fileId: string;
  fileName: string | null;
}

export interface UpdateActivityFiles {
  updateActivityFiles: (UpdateActivityFiles_updateActivityFiles | null)[] | null;
}

export interface UpdateActivityFilesVariables {
  battlecardId: number;
  deploymentModelId: number;
  fileIds: (string | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateBattlecard
// ====================================================

export interface UpdateBattlecard_updateBattlecard {
  __typename: 'Battlecard';
  battlecardId: number;
}

export interface UpdateBattlecard {
  updateBattlecard: UpdateBattlecard_updateBattlecard | null;
}

export interface UpdateBattlecardVariables {
  battlecardId: number;
  deploymentModelId: number;
  battlecardName: string;
  battlecardType: string;
  currencyCode?: string | null;
  battlecardParentId?: number | null;
  mapLevel?: BCMapLevelEnum | null;
  quotaDistributionHierarchyIds?: number[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCurrentUser
// ====================================================

export interface UpdateCurrentUser_updateCurrentUser {
  __typename: 'User';
  firstName: string | null;
  lastName: string | null;
  subjectId: string;
  emailAddress: string;
}

export interface UpdateCurrentUser {
  updateCurrentUser: UpdateCurrentUser_updateCurrentUser | null;
}

export interface UpdateCurrentUserVariables {
  firstName: string;
  lastName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCustomMeasure
// ====================================================

export interface UpdateCustomMeasure_updateCustomMeasure {
  __typename: 'UpdateCustomMeasureResponse';
  success: boolean | null;
}

export interface UpdateCustomMeasure {
  updateCustomMeasure: UpdateCustomMeasure_updateCustomMeasure | null;
}

export interface UpdateCustomMeasureVariables {
  tenantId: number;
  measureId: number;
  measureName: string;
  measureFormatType: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateHierarchyParent
// ====================================================

export interface updateHierarchyParent_updateHierarchyParent_newParentHierarchy {
  __typename: 'HierarchyType';
  children: (number | null)[] | null;
  cloneId: number | null;
  customProperties: any | null;
  fileId: string | null;
  hierarchyId: number | null;
  hierarchyType: string | null;
  key: string | null;
  name: string | null;
  parentKey: string | null;
  path: string | null;
  planningCycleId: number | null;
  version: number | null;
}

export interface updateHierarchyParent_updateHierarchyParent {
  __typename: 'UpdateHierarchyParent';
  newParentHierarchy: updateHierarchyParent_updateHierarchyParent_newParentHierarchy | null;
}

export interface updateHierarchyParent {
  updateHierarchyParent: updateHierarchyParent_updateHierarchyParent | null;
}

export interface updateHierarchyParentVariables {
  hierarchyIds: (number | null)[];
  newParentHierarchyId: number;
  planningCycleId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTerritoryGroup
// ====================================================

export interface UpdateTerritoryGroup_updateTerritoryGroup {
  __typename: 'TerritoryGroup';
  territoryGroupId: number | null;
}

export interface UpdateTerritoryGroup {
  updateTerritoryGroup: UpdateTerritoryGroup_updateTerritoryGroup | null;
}

export interface UpdateTerritoryGroupVariables {
  hierarchyId?: number | null;
  territoryGroupId: number;
  territoryGroupName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertBattlecard
// ====================================================

export interface UpsertBattlecard_upsertBattlecard {
  __typename: 'Battlecard';
  battlecardId: number;
}

export interface UpsertBattlecard {
  upsertBattlecard: UpsertBattlecard_upsertBattlecard | null;
}

export interface UpsertBattlecardVariables {
  input: UpsertBattlecardInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertBattlecardHierarchyTops
// ====================================================

export interface UpsertBattlecardHierarchyTops_upsertBattlecardHierarchyTops {
  __typename: 'BattlecardHierarchyTopConfiguration';
  battlecardId: number;
  hierarchyAlias: string;
  hierarchyName: string | null;
  hierarchyTopId: number;
  hierarchyTopName: string;
  hierarchyType: string;
  precedence: number;
}

export interface UpsertBattlecardHierarchyTops {
  upsertBattlecardHierarchyTops: (UpsertBattlecardHierarchyTops_upsertBattlecardHierarchyTops | null)[] | null;
}

export interface UpsertBattlecardHierarchyTopsVariables {
  battlecardId: number;
  hierarchyUpsertInput: (BattlecardHierarchyUpsert | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertBattlecardOwner
// ====================================================

export interface UpsertBattlecardOwner_upsertBattlecardOwner {
  __typename: 'UpsertBattlecardOwnerResponse';
  success: boolean;
}

export interface UpsertBattlecardOwner {
  upsertBattlecardOwner: UpsertBattlecardOwner_upsertBattlecardOwner | null;
}

export interface UpsertBattlecardOwnerVariables {
  battlecardId: number;
  memberId: number;
  tenantId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertConversionRates
// ====================================================

export interface UpsertConversionRates_upsertConversionRates_localCurrencies {
  __typename: 'LocalCurrency';
  localCurrencyName: string | null;
  localCurrencyCode: string | null;
  currencyHierarchyId: number | null;
  conversionRate: number | null;
  canDelete: boolean | null;
}

export interface UpsertConversionRates_upsertConversionRates {
  __typename: 'ConversionRates';
  canDelete: boolean | null;
  currencyHierarchyId: number | null;
  planningCycleId: number | null;
  reportingCurrencyCode: string | null;
  reportingCurrencyName: string | null;
  localCurrencies: (UpsertConversionRates_upsertConversionRates_localCurrencies | null)[] | null;
}

export interface UpsertConversionRates {
  upsertConversionRates: (UpsertConversionRates_upsertConversionRates | null)[] | null;
}

export interface UpsertConversionRatesVariables {
  upsertConversionRatesInput: (UpsertConversionRatesInput | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertFieldValues
// ====================================================

export interface UpsertFieldValues_upsertFieldValues {
  __typename: 'TerritoryFieldValues';
  battlecardId: number | null;
  fieldId: number | null;
  fieldValue: string | null;
  quotaComponentId: number | null;
  territoryGroupId: number | null;
  territoryOwnerId: number | null;
  territoryId: string | null;
  factorPath: string | null;
  factors: any | null;
  quarter: number | null;
  tmvId: number | null;
  adate: any | null;
  isTQM: boolean | null;
}

export interface UpsertFieldValues {
  upsertFieldValues: UpsertFieldValues_upsertFieldValues[];
}

export interface UpsertFieldValuesVariables {
  input: TerritoryFieldValuesInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertGeoSelection
// ====================================================

export interface UpsertGeoSelection_upsertGeoHierarchySelection {
  __typename: 'UpsertGeoHierarchySelectionResponse';
  planningCycleId: number;
}

export interface UpsertGeoSelection {
  upsertGeoHierarchySelection: UpsertGeoSelection_upsertGeoHierarchySelection | null;
}

export interface UpsertGeoSelectionVariables {
  input: UpsertGeoHierarchySelectionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertCustomHierarchy
// ====================================================

export interface UpsertCustomHierarchy_upsertCustomHierarchy {
  __typename: 'CustomHierarchy';
  customProperties: any | null;
  hierarchyId: number | null;
  key: string | null;
  name: string | null;
  parentKey: string | null;
  path: string | null;
  planningCycleId: number | null;
  effectiveDate: any | null;
  endDate: any | null;
  version: number | null;
}

export interface UpsertCustomHierarchy {
  upsertCustomHierarchy: UpsertCustomHierarchy_upsertCustomHierarchy | null;
}

export interface UpsertCustomHierarchyVariables {
  hierarchyId?: number | null;
  planningCycleId: number;
  rootId?: number | null;
  name: string;
  key: string;
  parentKey?: string | null;
  path?: string | null;
  version: number;
  effectiveDate?: any;
  endDate?: any;
  customProperties?: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertCustomerAccountHierarchy
// ====================================================

export interface UpsertCustomerAccountHierarchy_upsertCustomerAccountHierarchy {
  __typename: 'CustomerAccountHierarchy';
  address1: string | null;
  address2: string | null;
  city: string | null;
  country: string | null;
  customProperties: any | null;
  customerAccountNumber: string | null;
  hierarchyId: number | null;
  industry: string | null;
  key: string | null;
  name: string | null;
  parentKey: string | null;
  path: string | null;
  planningCycleId: number | null;
  zipPostal: string | null;
  stateProvince: string | null;
  effectiveDate: any | null;
  endDate: any | null;
  version: number | null;
}

export interface UpsertCustomerAccountHierarchy {
  upsertCustomerAccountHierarchy: UpsertCustomerAccountHierarchy_upsertCustomerAccountHierarchy | null;
}

export interface UpsertCustomerAccountHierarchyVariables {
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  country?: string | null;
  customProperties?: any | null;
  customerAccountNumber?: string | null;
  hierarchyId?: number | null;
  industry?: string | null;
  key?: string | null;
  name: string;
  path?: string | null;
  parentKey?: string | null;
  planningCycleId: number;
  zipPostal?: string | null;
  stateProvince?: string | null;
  effectiveDate?: any;
  endDate?: any;
  version: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertGeographicTerritoryHierarchy
// ====================================================

export interface UpsertGeographicTerritoryHierarchy_upsertGeographicTerritoryHierarchy {
  __typename: 'GeographicTerritoryHierarchy';
  hierarchyId: number | null;
  key: string | null;
  name: string | null;
  parentKey: string | null;
}

export interface UpsertGeographicTerritoryHierarchy {
  upsertGeographicTerritoryHierarchy: UpsertGeographicTerritoryHierarchy_upsertGeographicTerritoryHierarchy | null;
}

export interface UpsertGeographicTerritoryHierarchyVariables {
  customProperties?: any | null;
  geographicTerritoryId: string;
  hierarchyId?: number | null;
  name: string;
  key: string;
  parentKey?: string | null;
  path?: string | null;
  planningCycleId: number;
  version: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertLookupRow
// ====================================================

export interface UpsertLookupRow_upsertLookupRow_metadata {
  __typename: 'Metadata';
  tableData: any;
  tableSchema: any;
}

export interface UpsertLookupRow_upsertLookupRow {
  __typename: 'Lookup';
  fileId: string | null;
  lookupId: string;
  lookupType: LookupTypeEnum;
  metadata: UpsertLookupRow_upsertLookupRow_metadata;
}

export interface UpsertLookupRow {
  upsertLookupRow: UpsertLookupRow_upsertLookupRow | null;
}

export interface UpsertLookupRowVariables {
  input?: UpsertLookupInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertMeasureValue
// ====================================================

export interface UpsertMeasureValue_upsertMeasureValue {
  __typename: 'TerritoryMeasureValue';
  measureValue: number | null;
}

export interface UpsertMeasureValue {
  upsertMeasureValue: (UpsertMeasureValue_upsertMeasureValue | null)[] | null;
}

export interface UpsertMeasureValueVariables {
  input: TerritoryMeasureValueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertMember
// ====================================================

export interface UpsertMember_upsertMember {
  __typename: 'Member';
  memberId: number;
}

export interface UpsertMember {
  upsertMember: UpsertMember_upsertMember | null;
}

export interface UpsertMemberVariables {
  input: UpsertMemberInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertNewBusinessTarget
// ====================================================

export interface UpsertNewBusinessTarget_upsertNewBusinessTarget {
  __typename: 'UpsertNewBusinessTargetOutput';
  success: boolean;
}

export interface UpsertNewBusinessTarget {
  upsertNewBusinessTarget: UpsertNewBusinessTarget_upsertNewBusinessTarget | null;
}

export interface UpsertNewBusinessTargetVariables {
  battlecardId: number;
  quotaComponentId: number;
  newBusinessTarget: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertPinSet
// ====================================================

export interface UpsertPinSet_upsertPinSet {
  __typename: 'UpsertPinSetResponse';
  pinSetId: number;
}

export interface UpsertPinSet {
  upsertPinSet: UpsertPinSet_upsertPinSet | null;
}

export interface UpsertPinSetVariables {
  input: PinSetInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertPlanningCycle
// ====================================================

export interface UpsertPlanningCycle_upsertPlanningCycle {
  __typename: 'PlanningCycle';
  currencyCode: string | null;
  planningCycleId: number;
  planningCycleSlug: string;
}

export interface UpsertPlanningCycle {
  upsertPlanningCycle: UpsertPlanningCycle_upsertPlanningCycle | null;
}

export interface UpsertPlanningCycleVariables {
  tenantId: number;
  planningCycleId?: number | null;
  planningCycleName?: string | null;
  planningCyclePeriodicity?: string | null;
  planningCycleStartDate?: any | null;
  planningCycleDuration?: number | null;
  planningCycleComment?: string | null;
  planningCycleArchived?: boolean | null;
  currencyCode?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: upsertQuotaComponent
// ====================================================

export interface upsertQuotaComponent_upsertQuotaComponent {
  __typename: 'UpsertQuotaComponentResponse';
  deleted: string;
  deploymentModelId: number;
  quotaComponentComment: string;
  quotaComponentId: number;
  quotaComponentName: string;
}

export interface upsertQuotaComponent {
  upsertQuotaComponent: upsertQuotaComponent_upsertQuotaComponent | null;
}

export interface upsertQuotaComponentVariables {
  deleted?: string | null;
  deploymentModelId: number;
  quotaComponentComment: string;
  quotaComponentId?: number | null;
  quotaComponentName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertRolePermissions
// ====================================================

export interface UpsertRolePermissions_upsertRolePermissions {
  __typename: 'DeploymentModelAction';
  actionName: string;
  deploymentModelId: number;
}

export interface UpsertRolePermissions {
  upsertRolePermissions: (UpsertRolePermissions_upsertRolePermissions | null)[] | null;
}

export interface UpsertRolePermissionsVariables {
  input: UpsertRolePermissionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertSheet
// ====================================================

export interface UpsertSheet_upsertSheet {
  __typename: 'UpsertSheetResponse';
  success: boolean | null;
  sheetIds: (number | null)[] | null;
}

export interface UpsertSheet {
  upsertSheet: UpsertSheet_upsertSheet | null;
}

export interface UpsertSheetVariables {
  input: UpsertSheetInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: upsertSymonPipeConfiguration
// ====================================================

export interface upsertSymonPipeConfiguration_upsertSymonPipeConfiguration {
  __typename: 'SymonPipeConfiguration';
  symonPipeConfigurationId: number | null;
  pipeId: string | null;
  exportId: string | null;
  planningCycleId: number | null;
  tableNameLabel: string | null;
  fileType: string | null;
  fileFormat: string | null;
  runId: string | null;
  downloadId: string | null;
  status: string | null;
  progress: string | null;
  startAt: string | null;
  endAt: string | null;
  seqPipeExecution: number | null;
  properties: any | null;
}

export interface upsertSymonPipeConfiguration {
  upsertSymonPipeConfiguration: upsertSymonPipeConfiguration_upsertSymonPipeConfiguration | null;
}

export interface upsertSymonPipeConfigurationVariables {
  input?: UpsertSymonPipeConfigurationInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertTerritoryGroupOwner
// ====================================================

export interface UpsertTerritoryGroupOwner_upsertTerritoryGroupOwner {
  __typename: 'UpsertTerritoryGroupOwnerResponse';
  success: boolean;
}

export interface UpsertTerritoryGroupOwner {
  upsertTerritoryGroupOwner: UpsertTerritoryGroupOwner_upsertTerritoryGroupOwner | null;
}

export interface UpsertTerritoryGroupOwnerVariables {
  input: TerritoryGroupOwnerInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertTerritoryOwners
// ====================================================

export interface UpsertTerritoryOwners_upsertTerritoryOwners_result {
  __typename: 'OwnerResponse';
  memberId: number;
  ownerId: number;
  ownership: number;
  effectiveEndDate: any | null;
  effectiveStartDate: any | null;
}

export interface UpsertTerritoryOwners_upsertTerritoryOwners {
  __typename: 'UpsertTerritoryOwnersResponse';
  success: boolean;
  result: UpsertTerritoryOwners_upsertTerritoryOwners_result[];
}

export interface UpsertTerritoryOwners {
  upsertTerritoryOwners: UpsertTerritoryOwners_upsertTerritoryOwners | null;
}

export interface UpsertTerritoryOwnersVariables {
  tenantId: number;
  territoryId: number;
  owners: (Owner | null)[];
  quotaComponentId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertComment
// ====================================================

export interface UpsertComment_upsertTerritoryQuotaComment {
  __typename: 'UpsertTerritoryQuotaCommentResponse';
  quotaComponentId: number;
  territoryGroupId: number;
  territoryId: string;
  comment: string;
  factorPath: string;
  ruleId: number | null;
}

export interface UpsertComment {
  upsertTerritoryQuotaComment: UpsertComment_upsertTerritoryQuotaComment | null;
}

export interface UpsertCommentVariables {
  input: UpsertTerritoryQuotaCommentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertTerritoryRule
// ====================================================

export interface UpsertTerritoryRule_upsertTerritoryRule {
  __typename: 'UpsertTerritoryRuleOutput';
}

export interface UpsertTerritoryRule {
  upsertTerritoryRule: UpsertTerritoryRule_upsertTerritoryRule | null;
}

export interface UpsertTerritoryRuleVariables {
  ruleId?: number | null;
  territoryId: string;
  territoryName: string;
  territoryGroupId: number;
  definition?: NewRuleDefinitionResponse | null;
  effectiveDate?: any | null;
  endDate?: any | null;
  color?: string | null;
  inheritsFrom?: (InheritedRule | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertTQMTerritoryRuleEffectiveDates
// ====================================================

export interface UpsertTQMTerritoryRuleEffectiveDates_upsertTerritoryRule {
  __typename: 'UpsertTerritoryRuleOutput';
}

export interface UpsertTQMTerritoryRuleEffectiveDates {
  upsertTerritoryRule: UpsertTQMTerritoryRuleEffectiveDates_upsertTerritoryRule | null;
}

export interface UpsertTQMTerritoryRuleEffectiveDatesVariables {
  ruleId?: number | null;
  territoryId: string;
  territoryName: string;
  territoryGroupId: number;
  quotaComponentId?: number | null;
  definition?: NewRuleDefinitionResponse | null;
  effectiveDate?: any | null;
  endDate?: any | null;
  color?: string | null;
  inheritsFrom?: (InheritedRule | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: WriteFileToDB
// ====================================================

export interface WriteFileToDB_writeFileToDB {
  __typename: 'FileMetadata';
  fileId: string | null;
  status: string | null;
  message: string | null;
}

export interface WriteFileToDB {
  writeFileToDB: WriteFileToDB_writeFileToDB | null;
}

export interface WriteFileToDBVariables {
  input: WriteFileToDBInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: WriteLocationFileToDB
// ====================================================

export interface WriteLocationFileToDB_writeLocationFileToDB {
  __typename: 'FileMetadata';
  fileId: string | null;
  status: string | null;
  message: string | null;
}

export interface WriteLocationFileToDB {
  writeLocationFileToDB: WriteLocationFileToDB_writeLocationFileToDB | null;
}

export interface WriteLocationFileToDBVariables {
  input: WriteLocationFileToDBInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CancelCoinsort
// ====================================================

export interface CancelCoinsort_cancelCoinsort {
  __typename: 'CoinsortStatusOutput';
  actorEmail: string | null;
  assignments: any | null;
  jobAction: string;
  jobKey: string;
  coinsortStatus: string;
  numberOfRules: number | null;
  numberOfRulesProcessed: number | null;
  timeToLive: number | null;
}

export interface CancelCoinsort {
  cancelCoinsort: CancelCoinsort_cancelCoinsort | null;
}

export interface CancelCoinsortVariables {
  battlecardId: number;
  quotaComponentId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckSymonConnectivity
// ====================================================

export interface CheckSymonConnectivity {
  checkSymonConnectivity: boolean | null;
}

export interface CheckSymonConnectivityVariables {
  input?: checkSymonConnectivityInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExportChangeHistory
// ====================================================

export interface ExportChangeHistory_getChangeHistoryExport {
  __typename: 'ChangeHistoryOutput';
  id: string | null;
  jobUUID: string | null;
  objectType: string | null;
  tenantId: string | null;
  actorEmail: string | null;
  status: string | null;
  isActive: number | null;
  description: string | null;
  startDate: number | null;
  progress: number | null;
  downloadUrl: string | null;
}

export interface ExportChangeHistory {
  getChangeHistoryExport: ExportChangeHistory_getChangeHistoryExport | null;
}

export interface ExportChangeHistoryVariables {
  input: ChangeHistoryInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAccountRuleBindings
// ====================================================

export interface GetAccountRuleBindings_getAccountRuleBindings_bindings_hierarchies {
  __typename: 'AccountRuleBindingHierarchyWithType';
  key: string;
  name: string;
  hierarchyId: number;
  type: QuotaDistributionHierarchyTypeEnum;
}

export interface GetAccountRuleBindings_getAccountRuleBindings_bindings_sourceRule {
  __typename: 'TerritoryRuleSummary';
  ruleId: number;
  territoryId: string;
  territoryName: string;
  territoryGroupId: number;
  effectiveDate: any | null;
  endDate: any | null;
}

export interface GetAccountRuleBindings_getAccountRuleBindings_bindings_redirects_targetRule {
  __typename: 'TerritoryRuleSummary';
  ruleId: number;
  territoryId: string;
  territoryName: string;
  territoryGroupId: number;
  effectiveDate: any | null;
  endDate: any | null;
}

export interface GetAccountRuleBindings_getAccountRuleBindings_bindings_redirects_fields {
  __typename: 'AccountRuleBindingRedirectField';
  primaryFieldId: number;
  fieldId: number;
  fieldValue: string;
}

export interface GetAccountRuleBindings_getAccountRuleBindings_bindings_redirects {
  __typename: 'AccountRuleBindingRedirect';
  startDate: any | null;
  endDate: any | null;
  redirectId: number;
  targetRule: GetAccountRuleBindings_getAccountRuleBindings_bindings_redirects_targetRule | null;
  fields: GetAccountRuleBindings_getAccountRuleBindings_bindings_redirects_fields[];
}

export interface GetAccountRuleBindings_getAccountRuleBindings_bindings_measures {
  __typename: 'AccountRuleBindingMeasure';
  measureId: number;
  measureValue: number;
  measureName: string;
}

export interface GetAccountRuleBindings_getAccountRuleBindings_bindings {
  __typename: 'AccountRuleBinding';
  hierarchies: GetAccountRuleBindings_getAccountRuleBindings_bindings_hierarchies[];
  sourceRule: GetAccountRuleBindings_getAccountRuleBindings_bindings_sourceRule;
  redirects: GetAccountRuleBindings_getAccountRuleBindings_bindings_redirects[];
  measures: GetAccountRuleBindings_getAccountRuleBindings_bindings_measures[];
}

export interface GetAccountRuleBindings_getAccountRuleBindings {
  __typename: 'GetAccountRuleBindingsOutput';
  totalCount: number;
  bindings: GetAccountRuleBindings_getAccountRuleBindings_bindings[];
}

export interface GetAccountRuleBindings {
  getAccountRuleBindings: GetAccountRuleBindings_getAccountRuleBindings;
}

export interface GetAccountRuleBindingsVariables {
  input: GetAccountRuleBindingsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAccountRuleBindingsForQuotaDrillIn
// ====================================================

export interface GetAccountRuleBindingsForQuotaDrillIn_getAccountRuleBindings_bindings_hierarchies {
  __typename: 'AccountRuleBindingHierarchyWithType';
  key: string;
  name: string;
  hierarchyId: number;
}

export interface GetAccountRuleBindingsForQuotaDrillIn_getAccountRuleBindings_bindings_measures {
  __typename: 'AccountRuleBindingMeasure';
  measureId: number;
  measureValue: number;
}

export interface GetAccountRuleBindingsForQuotaDrillIn_getAccountRuleBindings_bindings {
  __typename: 'AccountRuleBinding';
  hierarchies: GetAccountRuleBindingsForQuotaDrillIn_getAccountRuleBindings_bindings_hierarchies[];
  measures: GetAccountRuleBindingsForQuotaDrillIn_getAccountRuleBindings_bindings_measures[];
}

export interface GetAccountRuleBindingsForQuotaDrillIn_getAccountRuleBindings_measureTotals {
  __typename: 'AccountRuleBindingMeasureTotals';
  measureTotal: number;
}

export interface GetAccountRuleBindingsForQuotaDrillIn_getAccountRuleBindings {
  __typename: 'GetAccountRuleBindingsOutput';
  totalCount: number;
  bindings: GetAccountRuleBindingsForQuotaDrillIn_getAccountRuleBindings_bindings[];
  measureTotals: GetAccountRuleBindingsForQuotaDrillIn_getAccountRuleBindings_measureTotals[] | null;
}

export interface GetAccountRuleBindingsForQuotaDrillIn {
  getAccountRuleBindings: GetAccountRuleBindingsForQuotaDrillIn_getAccountRuleBindings;
}

export interface GetAccountRuleBindingsForQuotaDrillInVariables {
  input: GetAccountRuleBindingsInput;
}

// ====================================================
// GraphQL query operation: GetAccountRuleBindingsForQuotaMove
// ====================================================

export interface GetAccountRuleBindingsForQuotaMove_getAccountRuleBindings_bindings_measures_measureValueBreakdown {
  __typename: 'MeasureValueBreakdown';
  measureValue: number;
  periodEndDate: any;
  periodStartDate: any;
}

export interface GetAccountRuleBindingsForQuotaMove_getAccountRuleBindings_bindings_measures {
  __typename: 'AccountRuleBindingMeasure';
  measureId: number;
  measureValue: number;
  measureName: string;
  quotaComponentId: number | null;
  quotaComponentName: string | null;
  measureValueBreakdown:
    | GetAccountRuleBindingsForQuotaMove_getAccountRuleBindings_bindings_measures_measureValueBreakdown[]
    | null;
}

export interface GetAccountRuleBindingsForQuotaMove_getAccountRuleBindings_bindings {
  __typename: 'AccountRuleBinding';
  measures: GetAccountRuleBindingsForQuotaMove_getAccountRuleBindings_bindings_measures[];
}

export interface GetAccountRuleBindingsForQuotaMove_getAccountRuleBindings {
  __typename: 'GetAccountRuleBindingsOutput';
  totalCount: number;
  bindings: GetAccountRuleBindingsForQuotaMove_getAccountRuleBindings_bindings[];
}

export interface GetAccountRuleBindingsForQuotaMove {
  getAccountRuleBindings: GetAccountRuleBindingsForQuotaMove_getAccountRuleBindings;
}

export interface GetAccountRuleBindingsForQuotaMoveVariables {
  input: GetAccountRuleBindingsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAccountQuotaRedirectDistribution
// ====================================================

export interface GetAccountQuotaRedirectDistribution_getTerritoryQuota_accountRedirectQuotaDistribution_alignmentEffectiveDate {
  __typename: 'DatePeriod';
  periodEndDate: any;
  periodStartDate: any;
}

export interface GetAccountQuotaRedirectDistribution_getTerritoryQuota_accountRedirectQuotaDistribution_measures_measureValueBreakdown {
  __typename: 'MeasureValueBreakdown';
  measureValue: number;
  periodEndDate: any;
  periodStartDate: any;
}

export interface GetAccountQuotaRedirectDistribution_getTerritoryQuota_accountRedirectQuotaDistribution_measures {
  __typename: 'MeasureBreakdown';
  editable: boolean;
  measureId: number;
  measureName: string;
  measureValue: number;
  measureValueBreakdown: GetAccountQuotaRedirectDistribution_getTerritoryQuota_accountRedirectQuotaDistribution_measures_measureValueBreakdown[];
}

export interface GetAccountQuotaRedirectDistribution_getTerritoryQuota_accountRedirectQuotaDistribution_quotaRedirect_territory {
  __typename: 'TerritoryRuleHierarchyWithInfo';
  ruleId: number | null;
  territoryId: string | null;
  territoryName: string | null;
}

export interface GetAccountQuotaRedirectDistribution_getTerritoryQuota_accountRedirectQuotaDistribution_quotaRedirect {
  __typename: 'QuotaRedirect';
  moveDirection: AccountQuotaRedirectDirection;
  territory: GetAccountQuotaRedirectDistribution_getTerritoryQuota_accountRedirectQuotaDistribution_quotaRedirect_territory;
}

export interface GetAccountQuotaRedirectDistribution_getTerritoryQuota_accountRedirectQuotaDistribution {
  __typename: 'AccountRedirectQuotaDistribution';
  accountKey: string;
  accountName: string;
  adjustmentType: AccountQuotaAdjustmentType;
  alignmentEffectiveDate: GetAccountQuotaRedirectDistribution_getTerritoryQuota_accountRedirectQuotaDistribution_alignmentEffectiveDate[];
  measures: GetAccountQuotaRedirectDistribution_getTerritoryQuota_accountRedirectQuotaDistribution_measures[];
  quotaRedirect: GetAccountQuotaRedirectDistribution_getTerritoryQuota_accountRedirectQuotaDistribution_quotaRedirect | null;
}

export interface MeasureBreakdown {
  editable: boolean;
  measureId: number;
  measureName: string;
  measureValue: number;
  measureValueBreakdown: GetAccountQuotaRedirectDistribution_getTerritoryQuota_accountRedirectQuotaDistribution_measures_measureValueBreakdown[];
}

export interface GetAccountQuotaRedirectDistribution_getTerritoryQuota {
  __typename: 'GetTerritoryQuotaResponse';
  accountRedirectQuotaDistribution:
    | GetAccountQuotaRedirectDistribution_getTerritoryQuota_accountRedirectQuotaDistribution[]
    | null;
  accountRedirectQuotaDistributionCount: number | null;
  accountRedirectQuotaDistributionTotals: MeasureBreakdown;
}

export interface GetAccountQuotaRedirectDistribution {
  getTerritoryQuota: GetAccountQuotaRedirectDistribution_getTerritoryQuota;
}

export interface GetAccountQuotaRedirectDistributionVariables {
  input: GetTerritoryQuotaInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAccountRuleGrid
// ====================================================

export interface GetAccountRuleGrid_getAccountRuleGrid_accountRuleHeaders {
  __typename: 'AccountRuleHeader';
  colId: string;
  dataType: AccountRuleHeaderEnum;
  isFilterable: boolean;
  isSortable: boolean;
  name: string;
}

export interface GetAccountRuleGrid_getAccountRuleGrid_accountRules_dynamicColumns {
  __typename: 'AccountRuleDynamicColumn';
  colId: string;
  value: any | null;
}

export interface GetAccountRuleGrid_getAccountRuleGrid_accountRules_rule {
  __typename: 'AccountRuleGridRowRule';
  ruleId: number;
  territoryName: string;
  territoryId: string;
  territoryGroupName: string;
  territoryGroupId: number;
}

export interface GetAccountRuleGrid_getAccountRuleGrid_accountRules {
  __typename: 'AccountRuleGridRow';
  accountId: number;
  accountKey: string;
  accountName: string;
  dynamicColumns: GetAccountRuleGrid_getAccountRuleGrid_accountRules_dynamicColumns[];
  rule: GetAccountRuleGrid_getAccountRuleGrid_accountRules_rule | null;
}

export interface GetAccountRuleGrid_getAccountRuleGrid {
  __typename: 'AccountRuleGrid';
  accountRuleHeaders: GetAccountRuleGrid_getAccountRuleGrid_accountRuleHeaders[];
  accountRules: GetAccountRuleGrid_getAccountRuleGrid_accountRules[];
  totalCount: number;
}

export interface GetAccountRuleGrid {
  getAccountRuleGrid: GetAccountRuleGrid_getAccountRuleGrid;
}

export interface GetAccountRuleGridVariables {
  input: GetAccountRuleGridInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetActivitiesForFile
// ====================================================

export interface GetActivitiesForFile_getActivitiesForFile {
  __typename: 'TerritoryActivities';
  territoryActivitiesList: (any | null)[] | null;
  territoryActivityCount: number | null;
}

export interface GetActivitiesForFile {
  getActivitiesForFile: GetActivitiesForFile_getActivitiesForFile;
}

export interface GetActivitiesForFileVariables {
  planningCycleId: number;
  fileId: string;
  startRow?: number | null;
  endRow?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetActivitiesForTerritory
// ====================================================

export interface GetActivitiesForTerritory_getActivitiesForTerritory {
  __typename: 'TerritoryActivities';
  territoryActivitiesList: (any | null)[] | null;
  territoryActivityCount: number | null;
}

export interface GetActivitiesForTerritory {
  getActivitiesForTerritory: GetActivitiesForTerritory_getActivitiesForTerritory;
}

export interface GetActivitiesForTerritoryVariables {
  battlecardId?: number | null;
  territoryId: string;
  territoryGroupId?: number | null;
  quotaComponentId: number;
  startRow?: number | null;
  endRow?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetActivitiesForTerritoryGroup
// ====================================================

export interface GetActivitiesForTerritoryGroup_getActivitiesForTerritoryGroup {
  __typename: 'TerritoryActivities';
  territoryActivitiesList: (any | null)[] | null;
  territoryActivityCount: number | null;
}

export interface GetActivitiesForTerritoryGroup {
  getActivitiesForTerritoryGroup: GetActivitiesForTerritoryGroup_getActivitiesForTerritoryGroup;
}

export interface GetActivitiesForTerritoryGroupVariables {
  battlecardId?: number | null;
  territoryGroupId: number;
  quotaComponentId: number;
  startRow?: number | null;
  endRow?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAggregatedActivitiesForTerritoryWithMeasureValue
// ====================================================

export interface GetAggregatedActivitiesForTerritoryWithMeasureValue_getAggregatedActivitiesForTerritoryWithMeasureValue_territoryAggregatedActivitiesTotals {
  __typename: 'MeasureValue';
  measureId: number | null;
  measureName: string | null;
  measureValue: number | null;
}

export interface GetAggregatedActivitiesForTerritoryWithMeasureValue_getAggregatedActivitiesForTerritoryWithMeasureValue_territoryAggregatedActivitiesList_hierarchies {
  __typename: 'HierarchyProperty';
  name: string | null;
  type: string | null;
  val: string | null;
  id: string | null;
  key: string | null;
}

export interface GetAggregatedActivitiesForTerritoryWithMeasureValue_getAggregatedActivitiesForTerritoryWithMeasureValue_territoryAggregatedActivitiesList_measures {
  __typename: 'MeasureValue';
  measureId: number | null;
  measureName: string | null;
  measureValue: number | null;
}

export interface GetAggregatedActivitiesForTerritoryWithMeasureValue_getAggregatedActivitiesForTerritoryWithMeasureValue_territoryAggregatedActivitiesList_rebalancingPermissions_hierarchyIncludedIn {
  __typename: 'HierarchyIncludedIn';
  territoryId: string | null;
  territoryGroupId: string | null;
  ruleId: number | null;
}

export interface GetAggregatedActivitiesForTerritoryWithMeasureValue_getAggregatedActivitiesForTerritoryWithMeasureValue_territoryAggregatedActivitiesList_rebalancingPermissions {
  __typename: 'RebalancingPermissions';
  isValidForRebalance: boolean | null;
  hierarchyIncludedIn:
    | (GetAggregatedActivitiesForTerritoryWithMeasureValue_getAggregatedActivitiesForTerritoryWithMeasureValue_territoryAggregatedActivitiesList_rebalancingPermissions_hierarchyIncludedIn | null)[]
    | null;
}

export interface GetAggregatedActivitiesForTerritoryWithMeasureValue_getAggregatedActivitiesForTerritoryWithMeasureValue_territoryAggregatedActivitiesList {
  __typename: 'TerritoryAggregatedActivitiesList';
  hierarchies:
    | (GetAggregatedActivitiesForTerritoryWithMeasureValue_getAggregatedActivitiesForTerritoryWithMeasureValue_territoryAggregatedActivitiesList_hierarchies | null)[]
    | null;
  measures:
    | (GetAggregatedActivitiesForTerritoryWithMeasureValue_getAggregatedActivitiesForTerritoryWithMeasureValue_territoryAggregatedActivitiesList_measures | null)[]
    | null;
  rebalancingPermissions: GetAggregatedActivitiesForTerritoryWithMeasureValue_getAggregatedActivitiesForTerritoryWithMeasureValue_territoryAggregatedActivitiesList_rebalancingPermissions | null;
}

export interface GetAggregatedActivitiesForTerritoryWithMeasureValue_getAggregatedActivitiesForTerritoryWithMeasureValue {
  __typename: 'TerritoryAggregatedActivitiesWithMeasureValue';
  territoryAggregatedActivitiesCount: number | null;
  territoryAggregatedActivitiesTotals:
    | (GetAggregatedActivitiesForTerritoryWithMeasureValue_getAggregatedActivitiesForTerritoryWithMeasureValue_territoryAggregatedActivitiesTotals | null)[]
    | null;
  territoryAggregatedActivitiesList:
    | (GetAggregatedActivitiesForTerritoryWithMeasureValue_getAggregatedActivitiesForTerritoryWithMeasureValue_territoryAggregatedActivitiesList | null)[]
    | null;
}

export interface GetAggregatedActivitiesForTerritoryWithMeasureValue {
  getAggregatedActivitiesForTerritoryWithMeasureValue: GetAggregatedActivitiesForTerritoryWithMeasureValue_getAggregatedActivitiesForTerritoryWithMeasureValue;
}

export interface GetAggregatedActivitiesForTerritoryWithMeasureValueVariables {
  battlecardId?: number | null;
  territoryGroupId?: number | null;
  territoryId?: string | null;
  quotaComponentId: number;
  hierarchies: (string | null)[];
  measures: (number | null)[];
  startRow: number;
  endRow: number;
  sorting?: SortModel | null;
  filtering?: (GetHierarchyFilterModel | null)[] | null;
  ruleId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllBattleCards
// ====================================================

export interface GetAllBattleCards_getDeploymentModelSpec_battlecards_sheets {
  __typename: 'SheetInfo';
  sheetId: number | null;
  sheetName: string | null;
}

export interface GetAllBattleCards_getDeploymentModelSpec_battlecards_territoryGroupTypes_sheets {
  __typename: 'SheetInfo';
  sheetId: number | null;
  sheetName: string | null;
}

export interface GetAllBattleCards_getDeploymentModelSpec_battlecards_territoryGroupTypes {
  __typename: 'BCTerritoryGroupTypeDef';
  hierarchyAlias: string;
  hierarchyRootName: string;
  hierarchyTopName: string;
  hierarchyTopId: number;
  hierarchyType: string;
  precedence: number;
  sheets: (GetAllBattleCards_getDeploymentModelSpec_battlecards_territoryGroupTypes_sheets | null)[] | null;
  territoryGroupId: number;
  territoryGroupOwnerEmailAddress: string | null;
  territoryGroupOwnerEmployeeId: string | null;
  territoryGroupOwnerFirstName: string | null;
  territoryGroupOwnerId: number | null;
  territoryGroupOwnerLastName: string | null;
  territoryGroupOwnerMemberId: number | null;
  territoryGroupTypeHierarchyId: number;
}

export interface GetAllBattleCards_getDeploymentModelSpec_battlecards {
  __typename: 'BattlecardDef';
  battlecardId: number;
  battlecardName: string | null;
  battlecardType: string | null;
  battlecardParentId: number | null;
  sheets: (GetAllBattleCards_getDeploymentModelSpec_battlecards_sheets | null)[] | null;
  children: (number | null)[] | null;
  territoryGroupTypes: (GetAllBattleCards_getDeploymentModelSpec_battlecards_territoryGroupTypes | null)[] | null;
  territoryGroupsLinked: boolean | null;
}

export interface GetAllBattleCards_getDeploymentModelSpec_quotaComponents {
  __typename: 'QuotaComponentDef';
  quotaComponentName: string | null;
  quotaComponentId: number;
  quotaComponentSlug: string | null;
}

export interface GetAllBattleCards_getDeploymentModelSpec {
  __typename: 'DeploymentModelDef';
  battlecards: (GetAllBattleCards_getDeploymentModelSpec_battlecards | null)[] | null;
  quotaComponents: (GetAllBattleCards_getDeploymentModelSpec_quotaComponents | null)[] | null;
}

export interface GetAllBattleCards {
  getDeploymentModelSpec: GetAllBattleCards_getDeploymentModelSpec | null;
}

export interface GetAllBattleCardsVariables {
  deploymentModelId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllHierarchies
// ====================================================

export interface GetAllHierarchies_getAllHierarchies_hierarchies {
  __typename: 'Hierarchy';
  hierarchyId: number | null;
  key: string | null;
  name: string | null;
  parentKey: string | null;
  path: string | null;
  mappedPath: (string | null)[] | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  country: string | null;
  industry: string | null;
  stateProvince: string | null;
  zipPostal: string | null;
  effectiveDate: string | null;
  endDate: string | null;
  customProperties: any | null;
  hasChildren: boolean | null;
}

export interface GetAllHierarchies_getAllHierarchies {
  __typename: 'GetAllHierarchies';
  hierarchies: (GetAllHierarchies_getAllHierarchies_hierarchies | null)[] | null;
  numOfMembers: number | null;
}

export interface GetAllHierarchies {
  getAllHierarchies: (GetAllHierarchies_getAllHierarchies | null)[] | null;
}

export interface GetAllHierarchiesVariables {
  planningCycleId: number;
  depth?: number | null;
  searchHierarchyInput?: SearchHierarchyInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TerritoryGroups
// ====================================================

export interface TerritoryGroups_getAllTerritoryGroups_allTerritoryGroups_owner {
  __typename: 'TerritoryGroupOwner';
  employeeId: string | null;
  firstName: string;
  lastName: string;
  memberId: number | null;
}

export interface TerritoryGroups_getAllTerritoryGroups_allTerritoryGroups_children {
  __typename: 'GetAllTerritoryGroups';
  hierarchyId: number | null;
  hierarchyTopId: number | null;
  precedence: number | null;
  territoryGroupId: number;
  territoryGroupParentId: number | null;
  territoryGroupName: string | null;
}

export interface TerritoryGroups_getAllTerritoryGroups_allTerritoryGroups {
  __typename: 'GetAllTerritoryGroups';
  hierarchyId: number | null;
  hierarchyTopId: number | null;
  hierarchyType: string | null;
  precedence: number | null;
  territoryGroupId: number;
  territoryGroupParentId: number | null;
  territoryGroupName: string | null;
  activityCount: number | null;
  owner: TerritoryGroups_getAllTerritoryGroups_allTerritoryGroups_owner | null;
  children: (TerritoryGroups_getAllTerritoryGroups_allTerritoryGroups_children | null)[] | null;
}

export interface TerritoryGroups_getAllTerritoryGroups {
  __typename: 'GetAllTerritoryGroupsResponse';
  allTerritoryGroups: (TerritoryGroups_getAllTerritoryGroups_allTerritoryGroups | null)[] | null;
  unassignedActivities: number | null;
}

export interface TerritoryGroups {
  getAllTerritoryGroups: TerritoryGroups_getAllTerritoryGroups | null;
}

export interface TerritoryGroupsVariables {
  battlecardId?: number | null;
  quotaComponentId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllTerritoryGroupsMeasure
// ====================================================

export interface AllTerritoryGroupsMeasure_getAllTerritoryGroupsMeasure_territoryGroups_measures {
  __typename: 'TerritoryGroupMeasure';
  measureId: number | null;
  measureName: string | null;
  measureValue: number | null;
}

export interface AllTerritoryGroupsMeasure_getAllTerritoryGroupsMeasure_territoryGroups_children {
  __typename: 'TerritoryGroup';
  territoryGroupId: number | null;
  territoryGroupParentId: number | null;
  territoryGroupName: string | null;
  hierarchyId: number | null;
  battlecardId: number | null;
  path: string | null;
  precedence: number | null;
}

export interface AllTerritoryGroupsMeasure_getAllTerritoryGroupsMeasure_territoryGroups {
  __typename: 'GetTerritoryGroupsMeasure';
  territoryGroupId: number | null;
  territoryGroupParentId: number | null;
  territoryGroupName: string | null;
  hierarchyId: number | null;
  battlecardId: number | null;
  hierarchyType: string | null;
  path: string | null;
  precedence: number | null;
  measures: (AllTerritoryGroupsMeasure_getAllTerritoryGroupsMeasure_territoryGroups_measures | null)[] | null;
  children: (AllTerritoryGroupsMeasure_getAllTerritoryGroupsMeasure_territoryGroups_children | null)[] | null;
}

export interface AllTerritoryGroupsMeasure_getAllTerritoryGroupsMeasure_battlecard {
  __typename: 'TerritoryGroupBattlecard';
  battlecardId: number | null;
  battlecardName: string | null;
  newBusinessTarget: number | null;
  updatedQuota: number | null;
}

export interface AllTerritoryGroupsMeasure_getAllTerritoryGroupsMeasure {
  __typename: 'PlanTargets';
  territoryGroups: (AllTerritoryGroupsMeasure_getAllTerritoryGroupsMeasure_territoryGroups | null)[] | null;
  battlecard: AllTerritoryGroupsMeasure_getAllTerritoryGroupsMeasure_battlecard | null;
}

export interface AllTerritoryGroupsMeasure {
  getAllTerritoryGroupsMeasure: AllTerritoryGroupsMeasure_getAllTerritoryGroupsMeasure | null;
}

export interface AllTerritoryGroupsMeasureVariables {
  battlecardId: number;
  measureId?: number | null;
  quotaComponentId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetBattleCard
// ====================================================

export interface GetBattleCard_getDeploymentModelSpec_battlecards_territoryRules {
  __typename: 'BCTerritoryRule';
  ruleId: number;
  territoryId: string;
}

export interface GetBattleCard_getDeploymentModelSpec_battlecards_territoryGroups {
  __typename: 'BCTerritoryGroup';
  territoryGroupId: number;
  territoryGroupName: string;
}

export interface GetBattleCard_getDeploymentModelSpec_battlecards_territoryGroupTypes_sheets {
  __typename: 'SheetInfo';
  sheetId: number | null;
  sheetName: string | null;
}

export interface GetBattleCard_getDeploymentModelSpec_battlecards_territoryGroupTypes {
  __typename: 'BCTerritoryGroupTypeDef';
  hierarchyAlias: string;
  hierarchyRootName: string;
  hierarchyTopId: number;
  hierarchyTopName: string;
  hierarchyType: string;
  precedence: number;
  sheets: (GetBattleCard_getDeploymentModelSpec_battlecards_territoryGroupTypes_sheets | null)[] | null;
  territoryGroupId: number;
  territoryGroupOwnerEmailAddress: string | null;
  territoryGroupOwnerEmployeeId: string | null;
  territoryGroupOwnerFirstName: string | null;
  territoryGroupOwnerId: number | null;
  territoryGroupOwnerLastName: string | null;
  territoryGroupOwnerMemberId: number | null;
  territoryGroupTypeHierarchyId: number;
}

export interface GetBattleCard_getDeploymentModelSpec_battlecards_files {
  __typename: 'BCFileConfigurationDef';
  fileId: string | null;
  fileName: string | null;
}

export interface GetBattleCard_getDeploymentModelSpec_battlecards_owner {
  __typename: 'BCOwnerDef';
  battlecardOwnerId: number;
  emailAddress: string | null;
  employeeId: string | null;
  firstName: string | null;
  lastName: string | null;
  memberId: string | null;
}

export interface GetBattleCard_getDeploymentModelSpec_battlecards_quotaDistributionHierarchies {
  __typename: 'QuotaDistributionHierarchy';
  hierarchyRootId: number;
  isAllocationTarget: boolean;
}

export interface GetBattleCard_getDeploymentModelSpec_battlecards {
  __typename: 'BattlecardDef';
  battlecardId: number;
  battlecardName: string | null;
  battlecardParentId: number | null;
  battlecardType: string | null;
  battlecardInfoLevel: BCInfoLevelEnum | null;
  children: (number | null)[] | null;
  conversionRate: number | null;
  currencyHierarchyId: number | null;
  invalidConversionReason: string | null;
  localCurrencyCode: string | null;
  localCurrencyName: string | null;
  mapLevel: BCMapLevelEnum;
  reportingCurrencyCode: string | null;
  rollupConversionRate: number | null;
  territoryRules: GetBattleCard_getDeploymentModelSpec_battlecards_territoryRules[];
  territoryGroups: GetBattleCard_getDeploymentModelSpec_battlecards_territoryGroups[];
  territoryGroupTypes: (GetBattleCard_getDeploymentModelSpec_battlecards_territoryGroupTypes | null)[] | null;
  files: (GetBattleCard_getDeploymentModelSpec_battlecards_files | null)[] | null;
  owner: GetBattleCard_getDeploymentModelSpec_battlecards_owner | null;
  quotaDistributionHierarchies: GetBattleCard_getDeploymentModelSpec_battlecards_quotaDistributionHierarchies[];
}

export interface GetBattleCard_getDeploymentModelSpec {
  __typename: 'DeploymentModelDef';
  battlecards: (GetBattleCard_getDeploymentModelSpec_battlecards | null)[] | null;
}

export interface GetBattleCard {
  getDeploymentModelSpec: GetBattleCard_getDeploymentModelSpec | null;
}

export interface GetBattleCardVariables {
  battlecardId: number;
  deploymentModelId: number;
  isTQM?: boolean | null;
}

export interface GetTerritoryGroupTypes {
  getDeploymentModelSpec: GetBattleCard_getDeploymentModelSpec | null;
}

export interface GetTerritoryGroupTypesVariables {
  input: GetDeploymentModelSpecInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetBattleCardCanvas
// ====================================================

export interface GetBattleCardCanvas_getDeploymentModelSpec_battlecards_owner {
  __typename: 'BCOwnerDef';
  battlecardOwnerId: number;
  emailAddress: string | null;
  employeeId: string | null;
  firstName: string | null;
  lastName: string | null;
  memberId: string | null;
}

export interface GetBattleCardCanvas_getDeploymentModelSpec_battlecards_files {
  __typename: 'BCFileConfigurationDef';
  fileId: string | null;
  fileName: string | null;
}

export interface GetBattleCardCanvas_getDeploymentModelSpec_battlecards_sheets {
  __typename: 'SheetInfo';
  sheetId: number | null;
  sheetName: string | null;
}

export interface GetBattleCardCanvas_getDeploymentModelSpec_battlecards_territoryGroupTypes {
  __typename: 'BCTerritoryGroupTypeDef';
  hierarchyType: string;
  hierarchyAlias: string;
  territoryGroupId: number;
  territoryGroupOwnerMemberId: number | null;
}

export interface GetBattleCardCanvas_getDeploymentModelSpec_battlecards_quotaComponents_measures {
  __typename: 'BCQCMeasure';
  activityMeasureColumnName: string | null;
  editable: string | null;
  endDate: any | null;
  fieldId: string | null;
  fieldName: string | null;
  measureCategory: string | null;
  measureConstraints: string | null;
  measureFieldType: string | null;
  measureFormatType: string | null;
  measureFormula: string | null;
  measureId: number | null;
  measureName: string | null;
  measureSource: string | null;
  measureValue: number | null;
  startDate: any | null;
  visible: string | null;
}

export interface GetBattleCardCanvas_getDeploymentModelSpec_battlecards_quotaComponents {
  __typename: 'BCQuotaComponentDef';
  quotaComponentName: string | null;
  allocatedBusinessTarget: number | null;
  battlecardQuotaComponentId: number | null;
  canDelete: boolean | null;
  measures: (GetBattleCardCanvas_getDeploymentModelSpec_battlecards_quotaComponents_measures | null)[] | null;
  newBusinessTarget: number | null;
  padding: number | null;
  quotaComponentComment: string | null;
  quotaComponentId: number;
  quotaComponentSlug: string | null;
  unallocatedBusinessTarget: number | null;
}

export interface GetBattleCardCanvas_getDeploymentModelSpec_battlecards_quotaDistributionHierarchies {
  __typename: 'QuotaDistributionHierarchy';
  hierarchyRootId: number;
  isAllocationTarget: boolean;
}

export interface GetBattleCardCanvas_getDeploymentModelSpec_battlecards {
  __typename: 'BattlecardDef';
  battlecardId: number;
  battlecardInfoLevel: BCInfoLevelEnum | null;
  battlecardName: string | null;
  battlecardParentId: number | null;
  battlecardType: string | null;
  children: (number | null)[] | null;
  conversionRate: number | null;
  currencyHierarchyId: number | null;
  invalidConversionReason: string | null;
  localCurrencyCode: string | null;
  localCurrencyName: string | null;
  mapLevel: BCMapLevelEnum;
  owner: GetBattleCardCanvas_getDeploymentModelSpec_battlecards_owner | null;
  position: number | null;
  reportingCurrencyCode: string | null;
  rollupConversionRate: number | null;
  files: (GetBattleCardCanvas_getDeploymentModelSpec_battlecards_files | null)[] | null;
  sheets: (GetBattleCardCanvas_getDeploymentModelSpec_battlecards_sheets | null)[] | null;
  territoryGroupsLinked: boolean | null;
  territoryGroupTypes: (GetBattleCardCanvas_getDeploymentModelSpec_battlecards_territoryGroupTypes | null)[] | null;
  quotaComponents: (GetBattleCardCanvas_getDeploymentModelSpec_battlecards_quotaComponents | null)[] | null;
  quotaDistributionHierarchies: GetBattleCardCanvas_getDeploymentModelSpec_battlecards_quotaDistributionHierarchies[];
}

export interface GetBattleCardCanvas_getDeploymentModelSpec_planningCycle {
  __typename: 'PlanningCycleDef';
  planningCycleId: number;
  planningCycleName: string | null;
}

export interface GetBattleCardCanvas_getDeploymentModelSpec {
  __typename: 'DeploymentModelDef';
  battlecards: (GetBattleCardCanvas_getDeploymentModelSpec_battlecards | null)[] | null;
  deploymentModelId: number;
  deploymentModelName: string;
  planningCycle: GetBattleCardCanvas_getDeploymentModelSpec_planningCycle | null;
}

export interface GetBattleCardCanvas {
  getDeploymentModelSpec: GetBattleCardCanvas_getDeploymentModelSpec | null;
}

export interface GetBattleCardCanvasVariables {
  deploymentModelId: number;
  reportingCurrency?: boolean | null;
  isTQM?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetBattlecardsForEmbeddedMap
// ====================================================

export interface GetBattlecardsForEmbeddedMap_getDeploymentModelSpec_battlecards_territoryGroupTypes {
  __typename: 'BCTerritoryGroupTypeDef';
  hierarchyType: string;
  territoryGroupId: number;
  territoryGroupOwnerMemberId: number | null;
}

export interface GetBattlecardsForEmbeddedMap_getDeploymentModelSpec_battlecards_quotaComponents {
  __typename: 'BCQuotaComponentDef';
  quotaComponentId: number;
  quotaComponentName: string | null;
  battlecardQuotaComponentId: number | null;
}

export interface GetBattlecardsForEmbeddedMap_getDeploymentModelSpec_battlecards_files {
  __typename: 'BCFileConfigurationDef';
  fileId: string | null;
  fileName: string | null;
}

export interface GetBattlecardsForEmbeddedMap_getDeploymentModelSpec_battlecards {
  __typename: 'BattlecardDef';
  battlecardId: number;
  battlecardInfoLevel: BCInfoLevelEnum | null;
  battlecardName: string | null;
  battlecardType: string | null;
  localCurrencyCode: string | null;
  territoryGroupTypes:
    | (GetBattlecardsForEmbeddedMap_getDeploymentModelSpec_battlecards_territoryGroupTypes | null)[]
    | null;
  quotaComponents: (GetBattlecardsForEmbeddedMap_getDeploymentModelSpec_battlecards_quotaComponents | null)[] | null;
  files: (GetBattlecardsForEmbeddedMap_getDeploymentModelSpec_battlecards_files | null)[] | null;
}

export interface GetBattlecardsForEmbeddedMap_getDeploymentModelSpec {
  __typename: 'DeploymentModelDef';
  deploymentModelId: number;
  deploymentModelName: string;
  battlecards: (GetBattlecardsForEmbeddedMap_getDeploymentModelSpec_battlecards | null)[] | null;
}

export interface GetBattlecardsForEmbeddedMap {
  getDeploymentModelSpec: GetBattlecardsForEmbeddedMap_getDeploymentModelSpec | null;
}

export interface GetBattlecardsForEmbeddedMapVariables {
  input: GetDeploymentModelSpecInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetChatHistory
// ====================================================

export interface GetChatHistory_getChatHistory_content {
  __typename: 'AssistantMessage';
  message: string;
  role: ChatRoleEnum | null;
}

export interface GetChatHistory_getChatHistory {
  __typename: 'ChatResponse';
  content: GetChatHistory_getChatHistory_content | null;
  conversationId: string | null;
}

export interface GetChatHistory {
  getChatHistory: (GetChatHistory_getChatHistory | null)[] | null;
}

export interface GetChatHistoryVariables {
  conversationId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCoinsortProgress
// ====================================================

export interface GetCoinsortProgress_getCoinsortProgress {
  __typename: 'CoinsortStatusOutput';
  actorEmail: string | null;
  assignments: any | null;
  totalActivities: number | null;
  unassignedActivities: number | null;
  jobAction: string;
  jobKey: string;
  coinsortStatus: string;
  numberOfRules: number | null;
  numberOfRulesProcessed: number | null;
  timeToLive: number | null;
}

export interface GetCoinsortProgress {
  getCoinsortProgress: GetCoinsortProgress_getCoinsortProgress | null;
}

export interface GetCoinsortProgressVariables {
  battlecardId: number;
  quotaComponentId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetContributorWorkflows
// ====================================================

export interface GetContributorWorkflows_getContributorWorkflows_battlecards_territoryGroups {
  __typename: 'TerritoryGroupOwnerWorkflows';
  approverEmail: string | null;
  approverFirstName: string | null;
  approverId: number | null;
  approverLastName: string | null;
  children: (number | null)[] | null;
  ownerEmail: string | null;
  ownerFirstName: string | null;
  ownerLastName: string | null;
  status: string | null;
  territoryGroupId: number | null;
  territoryGroupName: string | null;
  territoryGroupOwnerId: number | null;
  territoryGroupParentId: number | null;
  territoryGroupWorkflowId: number | null;
}

export interface GetContributorWorkflows_getContributorWorkflows_battlecards {
  __typename: 'BattlecardWorkflows';
  approverEmail: string | null;
  approverFirstName: string | null;
  approverId: number | null;
  approverLastName: string | null;
  children: (number | null)[] | null;
  ownerEmail: string | null;
  ownerFirstName: string | null;
  ownerLastName: string | null;
  status: string | null;
  battlecardId: number | null;
  battlecardName: string | null;
  battlecardOwnerId: number | null;
  battlecardParentId: number | null;
  battlecardWorkflowId: number | null;
  territoryGroups: (GetContributorWorkflows_getContributorWorkflows_battlecards_territoryGroups | null)[] | null;
}

export interface GetContributorWorkflows_getContributorWorkflows_territoryGroups {
  __typename: 'TerritoryGroupOwnerWorkflows';
  approverEmail: string | null;
  approverFirstName: string | null;
  approverId: number | null;
  approverLastName: string | null;
  children: (number | null)[] | null;
  ownerEmail: string | null;
  ownerFirstName: string | null;
  ownerLastName: string | null;
  status: string | null;
  territoryGroupId: number | null;
  territoryGroupName: string | null;
  territoryGroupOwnerId: number | null;
  territoryGroupParentId: number | null;
  territoryGroupWorkflowId: number | null;
  battlecardId: number | null;
}

export interface GetContributorWorkflows_getContributorWorkflows {
  __typename: 'GetContributorWorkflowsResponse';
  battlecards: ((GetContributorWorkflows_getContributorWorkflows_battlecards | null)[] | null)[] | null;
  territoryGroups: ((GetContributorWorkflows_getContributorWorkflows_territoryGroups | null)[] | null)[] | null;
}

export interface GetContributorWorkflows {
  getContributorWorkflows: GetContributorWorkflows_getContributorWorkflows | null;
}

export interface GetContributorWorkflowsVariables {
  deploymentModelId: number;
  planningCycleId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetConversionRates
// ====================================================

export interface GetConversionRates_getConversionRates_localCurrencies {
  __typename: 'LocalCurrency';
  localCurrencyName: string | null;
  localCurrencyCode: string | null;
  currencyHierarchyId: number | null;
  conversionRate: number | null;
  canDelete: boolean | null;
}

export interface GetConversionRates_getConversionRates {
  __typename: 'ConversionRates';
  canDelete: boolean | null;
  currencyHierarchyId: number | null;
  planningCycleId: number | null;
  reportingCurrencyCode: string | null;
  reportingCurrencyName: string | null;
  localCurrencies: (GetConversionRates_getConversionRates_localCurrencies | null)[] | null;
}

export interface GetConversionRates {
  getConversionRates: (GetConversionRates_getConversionRates | null)[] | null;
}

export interface GetConversionRatesVariables {
  planningCycleId: number;
  reportingCurrencyCode?: string | null;
  localCurrencyCode?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCustomerAggregatePins
// ====================================================

export interface GetCustomerAggregatePins_getTGTAggregate_pinsCompressed {
  __typename: 'TGTAccountPinsCompressed';
  compressed: string;
  compressedType: TGTAccountPinsCompressedEnum;
}

export interface GetCustomerAggregatePins_getTGTAggregate {
  __typename: 'TGTAggregationOutput';
  numberOfAccounts: number;
  pinsCompressed: GetCustomerAggregatePins_getTGTAggregate_pinsCompressed;
}

export interface GetCustomerAggregatePins {
  getTGTAggregate: GetCustomerAggregatePins_getTGTAggregate | null;
}

export interface GetCustomerAggregatePinsVariables {
  input: TGTAggregationInput;
  includeCount: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDataPreviewFromSymonPipe
// ====================================================

export interface GetDataPreviewFromSymonPipe_getDataPreviewFromSymonPipe_colHeaders {
  __typename: 'SymonDataPreviewColHeaders';
  name: string | null;
  type: string | null;
}

export interface GetDataPreviewFromSymonPipe_getDataPreviewFromSymonPipe {
  __typename: 'GetSymonPipeDataPreview';
  rows: ((string | null)[] | null)[] | null;
  colHeaders: (GetDataPreviewFromSymonPipe_getDataPreviewFromSymonPipe_colHeaders | null)[] | null;
  pollingTokenId: string | null;
  status: string;
}

export interface GetDataPreviewFromSymonPipe {
  getDataPreviewFromSymonPipe: GetDataPreviewFromSymonPipe_getDataPreviewFromSymonPipe | null;
}

export interface GetDataPreviewFromSymonPipeVariables {
  planningCycleId: number;
  pollingTokenId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDataSheets
// ====================================================

export interface GetDataSheets_getDeploymentModelSpec_dataSheets {
  __typename: 'DataSheetDef';
  isTQM: boolean | null;
  sheetId: number | null;
  sheetName: string | null;
  sheetType: string | null;
  sheetCreatedAt: any | null;
  sheetUpdatedAt: any | null;
}

export interface GetDataSheets_getDeploymentModelSpec {
  __typename: 'DeploymentModelDef';
  dataSheets: (GetDataSheets_getDeploymentModelSpec_dataSheets | null)[] | null;
}

export interface GetDataSheets {
  getDeploymentModelSpec: GetDataSheets_getDeploymentModelSpec | null;
}

export interface GetDataSheetsVariables {
  deploymentModelId: number;
  isTQM?: boolean | null;
  battlecardId?: number | null;
  quotaComponentId?: number | null;
  territoryGroupId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDeploymentModelForPcPage
// ====================================================

export interface GetDeploymentModelForPcPage_getDeploymentModelSpec_quotaComponents {
  __typename: 'QuotaComponentDef';
  quotaComponentId: number;
  quotaComponentName: string | null;
  quotaComponentComment: string | null;
  canDelete: boolean | null;
}

export interface GetDeploymentModelForPcPage_getDeploymentModelSpec_dataSheets {
  __typename: 'DataSheetDef';
  isTQM: boolean | null;
  sheetId: number | null;
  sheetName: string | null;
  sheetType: string | null;
  sheetCreatedAt: any | null;
  sheetUpdatedAt: any | null;
}

export interface GetDeploymentModelForPcPage_getDeploymentModelSpec {
  __typename: 'DeploymentModelDef';
  deploymentModelId: number;
  deploymentModelName: string;
  quotaComponents: (GetDeploymentModelForPcPage_getDeploymentModelSpec_quotaComponents | null)[] | null;
  isTerritoryWorkflowStarted: string | null;
  dataSheets: (GetDeploymentModelForPcPage_getDeploymentModelSpec_dataSheets | null)[] | null;
}

export interface GetDeploymentModelForPcPage_getWorkflowInitiationStatus {
  __typename: 'GetWorkflowInitiationStatusResponse';
  workflowInitiated: boolean | null;
}

export interface GetDeploymentModelForPcPage {
  getDeploymentModelSpec: GetDeploymentModelForPcPage_getDeploymentModelSpec | null;
  getWorkflowInitiationStatus: GetDeploymentModelForPcPage_getWorkflowInitiationStatus | null;
}

export interface GetDeploymentModelForPcPageVariables {
  deploymentModelId: number;
  planningCycleId: number;
  isTQM: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDeploymentModelSpec
// ====================================================

export interface GetDeploymentModelSpec_getDeploymentModelSpec_quotaComponents {
  __typename: 'QuotaComponentDef';
  quotaComponentId: number;
  quotaComponentName: string | null;
  quotaComponentComment: string | null;
  canDelete: boolean | null;
}

export interface GetDeploymentModelSpec_getDeploymentModelSpec_dataSheets {
  __typename: 'DataSheetDef';
  isTQM: boolean | null;
  sheetId: number | null;
  sheetName: string | null;
  sheetType: string | null;
  sheetCreatedAt: any | null;
  sheetUpdatedAt: any | null;
}

export interface GetDeploymentModelSpec_getDeploymentModelSpec {
  __typename: 'DeploymentModelDef';
  deploymentModelId: number;
  deploymentModelName: string;
  quotaComponents: (GetDeploymentModelSpec_getDeploymentModelSpec_quotaComponents | null)[] | null;
  isTerritoryWorkflowStarted: string | null;
  dataSheets: (GetDeploymentModelSpec_getDeploymentModelSpec_dataSheets | null)[] | null;
}

export interface GetDeploymentModelSpec {
  getDeploymentModelSpec: GetDeploymentModelSpec_getDeploymentModelSpec | null;
}

export interface GetDeploymentModelSpecVariables {
  deploymentModelId: number;
  battlecardId?: number | null;
  isTQM?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDeploymentModelSummaries
// ====================================================

export interface GetDeploymentModelSummaries_getPlanningCycleSpec_deploymentModelSummaries {
  __typename: 'DeploymentModelSummary';
  deploymentModelId: number;
  deploymentModelName: string;
  deploymentModelType: DeploymentModelTypeEnum;
  isPublished: boolean;
  deploymentModelCreatedAt: any;
  deploymentModelLastOpenedAt: any;
}

export interface GetDeploymentModelSummaries_getPlanningCycleSpec {
  __typename: 'PlanningCycleResponse';
  planningCycleId: number;
  deploymentModelSummaries: GetDeploymentModelSummaries_getPlanningCycleSpec_deploymentModelSummaries[];
}

export interface GetDeploymentModelSummaries {
  getPlanningCycleSpec: GetDeploymentModelSummaries_getPlanningCycleSpec | null;
}

export interface GetDeploymentModelSummariesVariables {
  planningCycleId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetExportDataStatus
// ====================================================

export interface GetExportDataStatus_getExportDataStatus {
  __typename: 'ExportDataStatus';
  exportId: string | null;
  status: ExportDataStatusEnum | null;
  message: string | null;
}

export interface GetExportDataStatus {
  getExportDataStatus: GetExportDataStatus_getExportDataStatus;
}

export interface GetExportDataStatusVariables {
  input: GetExportDataStatusInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFieldTotals
// ====================================================

export interface GetFieldTotals_getTerritoryRules_fieldTotals {
  __typename: 'FieldTotal';
  fieldId: number;
  fieldValue: string | null;
}

export interface GetFieldTotals_getTerritoryRules {
  __typename: 'GetTerritoryRulesResponse';
  fieldTotals: (GetFieldTotals_getTerritoryRules_fieldTotals | null)[] | null;
}

export interface GetFieldTotals {
  getTerritoryRules: GetFieldTotals_getTerritoryRules;
}

export interface GetFieldTotalsVariables {
  quotaComponentId: number;
  measureId: number;
  sorting: GetTerritoryRulesSortModel;
  territoryGroupId?: number | null;
  battlecardId?: number | null;
  startRow?: number | null;
  endRow?: number | null;
  fieldIds: (number | null)[];
  rangeFieldInput?: GetRangeFieldInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFileHeaders
// ====================================================

export interface GetFileHeaders_getFileHeaders {
  __typename: 'FileHeader';
  csvHeader: string | null;
  dbColumn: string | null;
  columnRequired: boolean | null;
  columnType: string | null;
  valueRequired: boolean | null;
  valueType: string | null;
}

export interface GetFileHeaders {
  getFileHeaders: (GetFileHeaders_getFileHeaders | null)[] | null;
}

export interface GetFileHeadersVariables {
  planningCycleId: number;
  fileType: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFileImportErrors
// ====================================================

export interface GetFileImportErrors_getPlanningCycleSpec_tables_tableList_user {
  __typename: 'TableUser';
  emailAddress: string;
}

export interface GetFileImportErrors_getPlanningCycleSpec_tables_tableList {
  __typename: 'TableList';
  published: boolean | null;
  status: string | null;
  tableDataType: string;
  tableId: string;
  tableName: string;
  tableType: string;
  messages: any | null;
  createdAt: any | null;
  user: GetFileImportErrors_getPlanningCycleSpec_tables_tableList_user | null;
}

export interface GetFileImportErrors_getPlanningCycleSpec_tables {
  __typename: 'Table';
  tableList: (GetFileImportErrors_getPlanningCycleSpec_tables_tableList | null)[] | null;
  totalNumOfTables: number;
}

export interface GetFileImportErrors_getPlanningCycleSpec {
  __typename: 'PlanningCycleResponse';
  planningCycleId: number;
  tables: GetFileImportErrors_getPlanningCycleSpec_tables | null;
}

export interface GetFileImportErrors {
  getPlanningCycleSpec: GetFileImportErrors_getPlanningCycleSpec | null;
}

export interface GetFileImportErrorsVariables {
  planningCycleId: number;
  lookupInput?: LookupInput | null;
  tableInput?: TableInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFileList
// ====================================================

export interface GetFileList_getPlanningCycleSpec_tables_tableList {
  __typename: 'TableList';
  published: boolean | null;
  status: string | null;
  tableDataType: string;
  tableId: string;
  tableName: string;
  tableType: string;
}

export interface GetFileList_getPlanningCycleSpec_tables {
  __typename: 'Table';
  tableList: (GetFileList_getPlanningCycleSpec_tables_tableList | null)[] | null;
  totalNumOfTables: number;
}

export interface GetFileList_getPlanningCycleSpec {
  __typename: 'PlanningCycleResponse';
  planningCycleId: number;
  tables: GetFileList_getPlanningCycleSpec_tables | null;
}

export interface GetFileList {
  getPlanningCycleSpec: GetFileList_getPlanningCycleSpec | null;
}

export interface GetFileListVariables {
  planningCycleId: number;
  lookupInput?: LookupInput | null;
  tableInput?: TableInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFileProcessStatus
// ====================================================

export interface GetFileProcessStatus_getFileProcessStatus_pipes_configuration {
  __typename: 'SymonTransientCalcConfig';
  optimizationType: string | null;
  territoryRuleBase: TerritoryRuleBase | null;
}

export interface GetFileProcessStatus_getFileProcessStatus_pipes {
  __typename: 'SymonTransientPipeConfiguration';
  fileId: string;
  pipeUuid: string;
  status: string;
  configuration: GetFileProcessStatus_getFileProcessStatus_pipes_configuration | null;
}

export interface GetFileProcessStatus_getFileProcessStatus_fileMetadata {
  __typename: 'FileMetadata';
  fileId: string | null;
  status: string | null;
}

export interface GetFileProcessStatus_getFileProcessStatus {
  __typename: 'FileMetadataWithPipes';
  pipes: (GetFileProcessStatus_getFileProcessStatus_pipes | null)[] | null;
  fileMetadata: GetFileProcessStatus_getFileProcessStatus_fileMetadata;
}

export interface GetFileProcessStatus {
  getFileProcessStatus: (GetFileProcessStatus_getFileProcessStatus | null)[] | null;
}

export interface GetFileProcessStatusVariables {
  input?: GetFileProcessInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFileUploadProgress
// ====================================================

export interface GetFileUploadProgress_getFileUploadProgress {
  __typename: 'FileMetadata';
  fileId: string | null;
  fileName: string | null;
  fileType: string | null;
  planningCycleId: number | null;
  tenantId: number | null;
  status: string | null;
  message: string | null;
  metaDataCreatedAt: number | null;
  metaDataUpdateAt: number | null;
}

export interface GetFileUploadProgress {
  getFileUploadProgress: (GetFileUploadProgress_getFileUploadProgress | null)[] | null;
}

export interface GetFileUploadProgressVariables {
  fileIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetGeoCatalogAndSelection
// ====================================================

export interface GetGeoCatalogAndSelection_getPlanningCycleSpec_inUseCountrySourceCatalogItems {
  __typename: 'CountrySourceCatalogItem';
  catalogId: number;
  depth: number;
  polygonCount: number;
  country: string;
  name: string;
  sourceId: string;
  sourceKey: string;
  sourceUrl: string;
  countryName: string;
}

export interface GetGeoCatalogAndSelection_getPlanningCycleSpec_availableCountrySourceCatalogItems {
  __typename: 'CountrySourceCatalogItem';
  catalogId: number;
  depth: number;
  polygonCount: number;
  country: string;
  name: string;
  sourceId: string;
  sourceKey: string;
  sourceUrl: string;
  countryName: string;
}

export interface GetGeoCatalogAndSelection_getPlanningCycleSpec {
  __typename: 'PlanningCycleResponse';
  planningCycleId: number;
  inUseCountrySourceCatalogItems: GetGeoCatalogAndSelection_getPlanningCycleSpec_inUseCountrySourceCatalogItems[];
  availableCountrySourceCatalogItems: GetGeoCatalogAndSelection_getPlanningCycleSpec_availableCountrySourceCatalogItems[];
}

export interface GetGeoCatalogAndSelection {
  getPlanningCycleSpec: GetGeoCatalogAndSelection_getPlanningCycleSpec | null;
}

export interface GetGeoCatalogAndSelectionVariables {
  planningCycleId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetHierarchiesByIds
// ====================================================

export interface GetHierarchiesByIds_getHierarchiesByIds {
  __typename: 'HierarchyType';
  children: (number | null)[] | null;
  hierarchyId: number | null;
  name: string | null;
  key: string | null;
  parentKey: string | null;
  path: string | null;
  hierarchyType: string | null;
}

export interface GetHierarchiesByIds {
  getHierarchiesByIds: (GetHierarchiesByIds_getHierarchiesByIds | null)[] | null;
}

export interface GetHierarchiesByIdsVariables {
  planningCycleId: number;
  version: number;
  hierarchyIds: (number | null)[];
  hierarchyType: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetHierarchyAncestryTree
// ====================================================

export interface GetHierarchyAncestryTree_getHierarchyAncestryTree {
  __typename: 'HierarchyType';
  children: (number | null)[] | null;
  name: string | null;
  key: string | null;
  parentKey: string | null;
  path: string | null;
  hierarchyId: number | null;
}

export interface GetHierarchyAncestryTree {
  getHierarchyAncestryTree: (GetHierarchyAncestryTree_getHierarchyAncestryTree | null)[] | null;
}

export interface GetHierarchyAncestryTreeVariables {
  planningCycleId: number;
  version: number;
  hierarchyIds: (number | null)[];
  hierarchyType: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetHierarchyByKey
// ====================================================

export interface GetHierarchyByKey_getHierarchyByKey {
  __typename: 'HierarchyType';
  children: (number | null)[] | null;
  cloneId: number | null;
  customProperties: any | null;
  fileId: string | null;
  hierarchyId: number | null;
  hierarchyType: string | null;
  key: string | null;
  name: string | null;
  parentKey: string | null;
  path: string | null;
  planningCycleId: number | null;
  version: number | null;
  rootId: number | null;
}

export interface GetHierarchyByKey {
  getHierarchyByKey: GetHierarchyByKey_getHierarchyByKey | null;
}

export interface GetHierarchyByKeyVariables {
  hierarchyType: string;
  hierarchyKey: string;
  planningCycleId: number;
  version: number;
  rootId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetHierarchyForField
// ====================================================

export interface GetHierarchyForField_getDeploymentModelSpec_pinnedHierarchies {
  __typename: 'PinnedHierarchyDef';
  hierarchyId: number;
  hierarchyType: string | null;
  name: string | null;
  customProperties: any | null;
}

export interface GetHierarchyForField_getDeploymentModelSpec {
  __typename: 'DeploymentModelDef';
  pinnedHierarchies: (GetHierarchyForField_getDeploymentModelSpec_pinnedHierarchies | null)[] | null;
}

export interface GetHierarchyForField {
  getDeploymentModelSpec: GetHierarchyForField_getDeploymentModelSpec | null;
}

export interface GetHierarchyForFieldVariables {
  deploymentModelId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HierarchyPolygonId
// ====================================================

export interface HierarchyPolygonId_getHierarchyPolygonId {
  __typename: 'GetHierarchyPolygonIdOutput';
  compressedType: GetHierarchyPolygonIdOutputCompressedEnum;
  compressed: string;
  totalCount: number;
}

export interface HierarchyPolygonId_getPlanningCycleSpec_inUseCountrySourceCatalogItems_bounds {
  __typename: 'CountrySourceGeoBounds';
  maxLat: number;
  minLon: number;
  maxLon: number;
  minLat: number;
}

export interface HierarchyPolygonId_getPlanningCycleSpec_inUseCountrySourceCatalogItems {
  __typename: 'CountrySourceCatalogItem';
  depth: number;
  polygonCount: number;
  country: string;
  sourceId: string;
  sourceKey: string;
  sourceUrl: string;
  bounds: HierarchyPolygonId_getPlanningCycleSpec_inUseCountrySourceCatalogItems_bounds;
  minZoom: number;
}

export interface HierarchyPolygonId_getPlanningCycleSpec {
  __typename: 'PlanningCycleResponse';
  planningCycleId: number;
  inUseCountrySourceCatalogItems: HierarchyPolygonId_getPlanningCycleSpec_inUseCountrySourceCatalogItems[];
}

export interface HierarchyPolygonId {
  getHierarchyPolygonId: HierarchyPolygonId_getHierarchyPolygonId | null;
  getPlanningCycleSpec: HierarchyPolygonId_getPlanningCycleSpec | null;
}

export interface HierarchyPolygonIdVariables {
  includeCatalog: boolean;
  planningCycleId: number;
  startRow?: number | null;
  endRow?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetInsightsCardStats
// ====================================================

export interface GetInsightsCardStats_getTGTAggregate {
  __typename: 'TGTAggregationOutput';
  numberOfAccounts: number;
  measureValue: number;
}

export interface GetInsightsCardStats {
  getTGTAggregate: GetInsightsCardStats_getTGTAggregate | null;
}

export interface GetInsightsCardStatsVariables {
  input: TGTAggregationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetInUseCountryLevels
// ====================================================

export interface GetInUseCountryLevels_getPlanningCycleSpec_countryLevels {
  __typename: 'CountryLevel';
  country: string;
  level: MapLevel;
}

export interface GetInUseCountryLevels_getPlanningCycleSpec {
  __typename: 'PlanningCycleResponse';
  planningCycleId: number;
  countryLevels: GetInUseCountryLevels_getPlanningCycleSpec_countryLevels[];
}

export interface GetInUseCountryLevels {
  getPlanningCycleSpec: GetInUseCountryLevels_getPlanningCycleSpec | null;
}

export interface GetInUseCountryLevelsVariables {
  planningCycleId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetIsTerritoryIdAvailable
// ====================================================

export interface GetIsTerritoryIdAvailable_getIsTerritoryIdAvailable {
  __typename: 'GetIsTerritoryIdAvailableResponse';
  foundInBattlecardId: number | null;
  isAvailable: boolean;
}

export interface GetIsTerritoryIdAvailable {
  getIsTerritoryIdAvailable: GetIsTerritoryIdAvailable_getIsTerritoryIdAvailable;
}

export interface GetIsTerritoryIdAvailableVariables {
  territoryGroupId: number;
  territoryId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetJobProgress
// ====================================================

export interface GetJobProgress_getJobProgress_pcCloneJobOutput {
  __typename: 'PCCloneJobOutput';
  pcCloneStatus: string;
  numberOfTables: number;
  numberOfTablesProcessed: number;
}

export interface GetJobProgress_getJobProgress_territoryRuleDeleteJobOutput {
  __typename: 'TerritoryRuleDeleteJobOutput';
  territoryRulesDeleteStatus: string;
  message: string | null;
}

export interface GetJobProgress_getJobProgress_allocateTopDownJobOutput {
  __typename: 'AllocateTopDownJobOutput';
  status: string;
}

export interface GetJobProgress_getJobProgress {
  __typename: 'JobOutput';
  pcCloneJobOutput: GetJobProgress_getJobProgress_pcCloneJobOutput | null;
  territoryRuleDeleteJobOutput: GetJobProgress_getJobProgress_territoryRuleDeleteJobOutput | null;
  allocateTopDownJobOutput: GetJobProgress_getJobProgress_allocateTopDownJobOutput | null;
}

export interface GetJobProgress {
  getJobProgress: GetJobProgress_getJobProgress | null;
}

export interface GetJobProgressVariables {
  input: JobInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetJobs
// ====================================================

export interface GetJobs_getJobs_items_error {
  __typename: 'GetJobsItemError';
  message: string;
  errorType: string;
  errorCode: string | null;
  context: any | null;
}

export interface GetJobs_getJobs_items {
  __typename: 'GetJobsItemOutput';
  id: string;
  taskId: string;
  objectType: string;
  tenantId: string;
  planningCycleId: number | null;
  actorEmail: string;
  impersonatorActorEmail: string | null;
  status: string;
  isActive: boolean;
  description: string;
  progress: number;
  startDate: number;
  endDate: number | null;
  error: GetJobs_getJobs_items_error | null;
  metadata: any | null;
}

export interface GetJobs_getJobs {
  __typename: 'GetJobsOutput';
  items: GetJobs_getJobs_items[];
  lastEvaluatedKey: any | null;
  totalCount: number;
}

export interface GetJobs {
  getJobs: GetJobs_getJobs | null;
}

export interface GetJobsVariables {
  input: GetJobsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLocationGroups
// ====================================================

export interface GetLocationGroups_getPlanningCycleSpec_locationGroups {
  __typename: 'LocationGroup';
  locationGroupId: number;
  locationGroupName: string;
}

export interface GetLocationGroups_getPlanningCycleSpec {
  __typename: 'PlanningCycleResponse';
  planningCycleId: number;
  locationGroups: GetLocationGroups_getPlanningCycleSpec_locationGroups[] | null;
}

export interface GetLocationGroups {
  getPlanningCycleSpec: GetLocationGroups_getPlanningCycleSpec | null;
}

export interface GetLocationGroupsVariables {
  planningCycleId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLookupsForPlanningCycle
// ====================================================

export interface GetLookupsForPlanningCycle_getPlanningCycleSpec_lookups_metadata {
  __typename: 'Metadata';
  tableData: any;
  tableSchema: any;
}

export interface GetLookupsForPlanningCycle_getPlanningCycleSpec_lookups {
  __typename: 'Lookup';
  fileId: string | null;
  lookupId: string;
  lookupType: LookupTypeEnum;
  metadata: GetLookupsForPlanningCycle_getPlanningCycleSpec_lookups_metadata;
}

export interface GetLookupsForPlanningCycle_getPlanningCycleSpec {
  __typename: 'PlanningCycleResponse';
  planningCycleId: number;
  lookups: GetLookupsForPlanningCycle_getPlanningCycleSpec_lookups[];
}

export interface GetLookupsForPlanningCycle {
  getPlanningCycleSpec: GetLookupsForPlanningCycle_getPlanningCycleSpec | null;
}

export interface GetLookupsForPlanningCycleVariables {
  planningCycleId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLookupTable
// ====================================================

export interface GetLookupTable_getPlanningCycleSpec_lookups_metadata {
  __typename: 'Metadata';
  tableData: any;
  tableSchema: any;
}

export interface GetLookupTable_getPlanningCycleSpec_lookups {
  __typename: 'Lookup';
  fileId: string | null;
  lookupId: string;
  lookupType: LookupTypeEnum;
  metadata: GetLookupTable_getPlanningCycleSpec_lookups_metadata;
}

export interface GetLookupTable_getPlanningCycleSpec {
  __typename: 'PlanningCycleResponse';
  planningCycleId: number;
  lookups: GetLookupTable_getPlanningCycleSpec_lookups[];
}

export interface GetLookupTable {
  getPlanningCycleSpec: GetLookupTable_getPlanningCycleSpec | null;
}

export interface GetLookupTableVariables {
  planningCycleId: number;
  lookupInput?: LookupInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMappingFields
// ====================================================

export interface GetMappingFields_getMappingFields {
  __typename: 'MappingField';
  colRequired: boolean;
  columnNameInDB: string;
  dataType: MappingFieldDataTypeEnum;
  displayMappingFieldName: string;
  fieldType: string | null;
  idAssociatedToFieldType: string | null;
  valueRequired: boolean;
}

export interface GetMappingFields {
  getMappingFields: (GetMappingFields_getMappingFields | null)[] | null;
}

export interface GetMappingFieldsVariables {
  planningCycleId: number;
  fileType: FileTypeEnum;
  rootHierarchyId?: number | null;
  battlecardId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMapVisualizationSettings
// ====================================================

export interface GetMapVisualizationSettings_getDeploymentModelSpec_battlecards_mapVisualizationSettings {
  __typename: 'BCMapVisualizationSettings';
  customHierarchyQuantifier: CustomHierarchyQuantifierEnum;
  customHierarchyId: number | null;
  territoryGroupType: VisualizationTerritoryGroupTypeEnum;
}

export interface GetMapVisualizationSettings_getDeploymentModelSpec_battlecards {
  __typename: 'BattlecardDef';
  mapVisualizationSettings: GetMapVisualizationSettings_getDeploymentModelSpec_battlecards_mapVisualizationSettings | null;
}

export interface GetMapVisualizationSettings_getDeploymentModelSpec {
  __typename: 'DeploymentModelDef';
  battlecards: (GetMapVisualizationSettings_getDeploymentModelSpec_battlecards | null)[] | null;
}

export interface GetMapVisualizationSettings_getRootHierarchies {
  __typename: 'GetAllHierarchies';
  rootHierarchyId: number | null;
  rootKey: string | null;
  rootName: string | null;
}

export interface GetMapVisualizationSettings {
  getDeploymentModelSpec: GetMapVisualizationSettings_getDeploymentModelSpec | null;
  getRootHierarchies: (GetMapVisualizationSettings_getRootHierarchies | null)[] | null;
}

export interface GetMapVisualizationSettingsVariables {
  input: GetDeploymentModelSpecInput;
  planningCycleId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMeasures
// ====================================================

export interface GetMeasures_getMeasures {
  __typename: 'MeasureDefinition';
  planningCycleId: number | null;
  measureName: string | null;
  measureCategory: string | null;
  measureFieldType: string | null;
  measureFormatType: string | null;
  measureFormula: string | null;
  measureConstraints: string | null;
  activityMeasureColumnName: string | null;
  measureId: number;
  measureType: string | null;
  isTQM: boolean | null;
}

export interface GetMeasures {
  getMeasures: (GetMeasures_getMeasures | null)[] | null;
}

export interface GetMeasuresVariables {
  planningCycleId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TerritoryMeasures
// ====================================================

export interface TerritoryMeasures_getTerritoryMeasuresGrid_periodicTerritories_measures_measureValue_years_halves_quarters_months {
  __typename: 'PmvMonth';
  month: string;
  date: any;
  value: number;
}

export interface TerritoryMeasures_getTerritoryMeasuresGrid_periodicTerritories_measures_measureValue_years_halves_quarters {
  __typename: 'PmvQuarter';
  quarter: QuarterIdentifier;
  date: any;
  value: number;
  months:
    | TerritoryMeasures_getTerritoryMeasuresGrid_periodicTerritories_measures_measureValue_years_halves_quarters_months[]
    | null;
}

export interface TerritoryMeasures_getTerritoryMeasuresGrid_periodicTerritories_measures_measureValue_years_halves {
  __typename: 'PmvHalf';
  half: HalfIdentifier;
  date: any;
  value: number;
  quarters:
    | TerritoryMeasures_getTerritoryMeasuresGrid_periodicTerritories_measures_measureValue_years_halves_quarters[]
    | null;
}

export interface TerritoryMeasures_getTerritoryMeasuresGrid_periodicTerritories_measures_measureValue_years {
  __typename: 'PmvYear';
  year: number;
  date: any;
  value: number;
  halves: TerritoryMeasures_getTerritoryMeasuresGrid_periodicTerritories_measures_measureValue_years_halves[] | null;
}

export interface TerritoryMeasures_getTerritoryMeasuresGrid_periodicTerritories_measures_measureValue {
  __typename: 'PeriodicMeasureValue';
  measureFieldType: string | null;
  textValue: string | null;
  type: string | null;
  value: number;
  years: TerritoryMeasures_getTerritoryMeasuresGrid_periodicTerritories_measures_measureValue_years[];
}

export interface TerritoryMeasures_getTerritoryMeasuresGrid_periodicTerritories_measures {
  __typename: 'PeriodicMeasure';
  measureId: number;
  measureName: string;
  editable: boolean | null;
  measureValue: TerritoryMeasures_getTerritoryMeasuresGrid_periodicTerritories_measures_measureValue;
}

export interface TerritoryMeasures_getTerritoryMeasuresGrid_periodicTerritories {
  __typename: 'PeriodicTerritoryGridTerritory';
  comment: string;
  measures: (TerritoryMeasures_getTerritoryMeasuresGrid_periodicTerritories_measures | null)[];
  territoryName: string;
  territoryId: string;
  territoryGroupName: string;
  territoryGroupLevelName: string;
  territoryGroupId: number | null;
  ruleId: number;
}

export interface TerritoryMeasures_getTerritoryMeasuresGrid {
  __typename: 'TerritoryGrid';
  periodicTerritories: (TerritoryMeasures_getTerritoryMeasuresGrid_periodicTerritories | null)[] | null;
  totalMeasuresCount: number;
}

export interface TerritoryMeasures {
  getTerritoryMeasuresGrid: TerritoryMeasures_getTerritoryMeasuresGrid | null;
}

export interface TerritoryMeasuresVariables {
  battlecardId?: number | null;
  quotaComponentId: number;
  territoryGroupId?: number | null;
  startRow?: number | null;
  endRow?: number | null;
  filter?: GetTerritoryMeasuresGridFilter | null;
  sheetId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTerritoryQuota
// ====================================================

export interface GetTerritoryQuota_getTerritoryQuota_periodicTerritories_measures_measureValue_years_halves_quarters_months {
  __typename: 'PmvMonth';
  month: string;
  date: any;
  value: number;
}

export interface GetTerritoryQuota_getTerritoryQuota_periodicTerritories_measures_measureValue_years_halves_quarters {
  __typename: 'PmvQuarter';
  quarter: QuarterIdentifier;
  date: any;
  value: number;
  months:
    | GetTerritoryQuota_getTerritoryQuota_periodicTerritories_measures_measureValue_years_halves_quarters_months[]
    | null;
}

export interface GetTerritoryQuota_getTerritoryQuota_periodicTerritories_measures_measureValue_years_halves {
  __typename: 'PmvHalf';
  half: HalfIdentifier;
  date: any;
  value: number;
  quarters:
    | GetTerritoryQuota_getTerritoryQuota_periodicTerritories_measures_measureValue_years_halves_quarters[]
    | null;
}

export interface GetTerritoryQuota_getTerritoryQuota_periodicTerritories_measures_measureValue_years {
  __typename: 'PmvYear';
  year: number;
  date: any;
  value: number;
  halves: GetTerritoryQuota_getTerritoryQuota_periodicTerritories_measures_measureValue_years_halves[] | null;
}

export interface GetTerritoryQuota_getTerritoryQuota_periodicTerritories_measures_measureValue {
  __typename: 'PeriodicMeasureValue';
  measureFieldType: string | null;
  textValue: string | null;
  type: string | null;
  value: number;
  years: GetTerritoryQuota_getTerritoryQuota_periodicTerritories_measures_measureValue_years[];
}

export interface GetTerritoryQuota_getTerritoryQuota_periodicTerritories_measures {
  __typename: 'PeriodicMeasure';
  measureId: number;
  measureName: string;
  editable: boolean | null;
  measureValue: GetTerritoryQuota_getTerritoryQuota_periodicTerritories_measures_measureValue;
}

export interface GetTerritoryQuota_getTerritoryQuota_periodicTerritories {
  __typename: 'PeriodicTerritoryResponse';
  comment: string;
  measures: GetTerritoryQuota_getTerritoryQuota_periodicTerritories_measures[];
  territoryName: string;
  territoryId: string;
  territoryGroupName: string;
  territoryGroupLevelName: string;
  territoryGroupId: number;
  ruleId: number;
}

export interface GetTerritoryQuota_getTerritoryQuota {
  __typename: 'GetTerritoryQuotaResponse';
  periodicTerritories: GetTerritoryQuota_getTerritoryQuota_periodicTerritories[] | null;
  totalTerritoriesCount: number;
  totalMeasuresCount: number;
}

export interface GetTerritoryQuota {
  getTerritoryQuota: GetTerritoryQuota_getTerritoryQuota;
}

export interface GetTerritoryQuotaVariables {
  battlecardId?: number | null;
  territoryGroupId?: number | null;
  quotaComponentId: number;
  startRow?: number | null;
  endRow?: number | null;
  sheetId?: number | null;
  filter?: GetTerritoryQuotaFilter | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMeasuresForMap
// ====================================================

export interface GetMeasuresForMap_getMeasures {
  __typename: 'MeasureDefinition';
  measureName: string | null;
  measureId: number;
}

export interface GetMeasuresForMap {
  getMeasures: (GetMeasuresForMap_getMeasures | null)[] | null;
}

export interface GetMeasuresForMapVariables {
  planningCycleId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMemberCount
// ====================================================

export interface GetMemberCount_getMemberList {
  __typename: 'GetMemberListOutput';
  totalCount: number;
}

export interface GetMemberCount {
  getMemberList: GetMemberCount_getMemberList | null;
}

export interface GetMemberCountVariables {
  endRow: number;
  startRow: number;
  tenantId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMemberList
// ====================================================

export interface GetMemberList_getMemberList_memberList_memberInvitesInfo {
  __typename: 'MemberInviteInfo';
  memberInvitedAs: string;
  memberInvitedAt: any | null;
}

export interface GetMemberList_getMemberList_memberList {
  __typename: 'Member';
  customProperties: any | null;
  deleted: string | null;
  emailAddress: string;
  employeeId: string | null;
  firstName: string;
  jobTitle: string | null;
  memberCreatedAt: any | null;
  lastName: string;
  memberId: number;
  memberUpdatedAt: any | null;
  systemRoleName: string;
  tenantId: number;
  memberInvitesInfo: (GetMemberList_getMemberList_memberList_memberInvitesInfo | null)[] | null;
}

export interface GetMemberList_getMemberList {
  __typename: 'GetMemberListOutput';
  totalCount: number;
  memberList: (GetMemberList_getMemberList_memberList | null)[] | null;
}

export interface GetMemberList {
  getMemberList: GetMemberList_getMemberList | null;
}

export interface GetMemberListVariables {
  endRow: number;
  sorting?: SortModel | null;
  startRow: number;
  tenantId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetNumberOfTerritoriesInTerritoryGroup
// ====================================================

export interface GetNumberOfTerritoriesInTerritoryGroup_getNumberOfTerritoriesInTerritoryGroup {
  __typename: 'NumOfTerritories';
  numOfTerritories: number | null;
}

export interface GetNumberOfTerritoriesInTerritoryGroup {
  getNumberOfTerritoriesInTerritoryGroup: GetNumberOfTerritoriesInTerritoryGroup_getNumberOfTerritoriesInTerritoryGroup | null;
}

export interface GetNumberOfTerritoriesInTerritoryGroupVariables {
  battlecardId: number;
  territoryGroupId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOptionsForCompareForm
// ====================================================

export interface GetOptionsForCompareForm_getDeploymentModelSpec_battlecards_territoryGroupTypes {
  __typename: 'BCTerritoryGroupTypeDef';
  hierarchyType: string;
  territoryGroupId: number;
}

export interface GetOptionsForCompareForm_getDeploymentModelSpec_battlecards_quotaComponents {
  __typename: 'BCQuotaComponentDef';
  quotaComponentId: number;
}

export interface GetOptionsForCompareForm_getDeploymentModelSpec_battlecards {
  __typename: 'BattlecardDef';
  battlecardId: number;
  battlecardInfoLevel: BCInfoLevelEnum | null;
  battlecardName: string | null;
  battlecardType: string | null;
  territoryGroupTypes:
    | (GetOptionsForCompareForm_getDeploymentModelSpec_battlecards_territoryGroupTypes | null)[]
    | null;
  quotaComponents: (GetOptionsForCompareForm_getDeploymentModelSpec_battlecards_quotaComponents | null)[] | null;
}

export interface GetOptionsForCompareForm_getDeploymentModelSpec_quotaComponents {
  __typename: 'QuotaComponentDef';
  quotaComponentId: number;
  quotaComponentName: string | null;
}

export interface GetOptionsForCompareForm_getDeploymentModelSpec {
  __typename: 'DeploymentModelDef';
  deploymentModelId: number;
  battlecards: (GetOptionsForCompareForm_getDeploymentModelSpec_battlecards | null)[] | null;
  quotaComponents: (GetOptionsForCompareForm_getDeploymentModelSpec_quotaComponents | null)[] | null;
}

export interface GetOptionsForCompareForm {
  getDeploymentModelSpec: GetOptionsForCompareForm_getDeploymentModelSpec | null;
}

export interface GetOptionsForCompareFormVariables {
  input: GetDeploymentModelSpecInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPermissions
// ====================================================

export interface GetPermissions_getPlanningCycleSpec_permissions {
  __typename: 'Permission';
  actionName: string;
  deploymentModelId: number;
  roleName: string;
}

export interface GetPermissions_getPlanningCycleSpec {
  __typename: 'PlanningCycleResponse';
  planningCycleId: number;
  permissions: GetPermissions_getPlanningCycleSpec_permissions[] | null;
}

export interface GetPermissions {
  getPlanningCycleSpec: GetPermissions_getPlanningCycleSpec | null;
}

export interface GetPermissionsVariables {
  planningCycleId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPinnedHierarchies
// ====================================================

export interface GetPinnedHierarchies_getDeploymentModelSpec_pinnedHierarchies {
  __typename: 'PinnedHierarchyDef';
  hasChildren: boolean | null;
  hierarchyId: number;
  hierarchyType: string | null;
  key: string | null;
  name: string | null;
  planningCycleId: number | null;
  version: number | null;
  firstLevelMemberCount: number;
}

export interface GetPinnedHierarchies_getDeploymentModelSpec {
  __typename: 'DeploymentModelDef';
  pinnedHierarchies: (GetPinnedHierarchies_getDeploymentModelSpec_pinnedHierarchies | null)[] | null;
}

export interface GetPinnedHierarchies {
  getDeploymentModelSpec: GetPinnedHierarchies_getDeploymentModelSpec | null;
}

export interface GetPinnedHierarchiesVariables {
  deploymentModelId: number;
  battlecardId?: number | null;
  includeFirstLevelMemberCount: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPins
// ====================================================

export interface GetPins_getPins_pins_position {
  __typename: 'LatLon';
  lat: number;
  lon: number;
}

export interface GetPins_getPins_pins {
  __typename: 'Pin';
  name: string | null;
  position: GetPins_getPins_pins_position;
}

export interface GetPins_getPins {
  __typename: 'PinsInPinSet';
  pinSetId: number;
  pins: GetPins_getPins_pins[];
}

export interface GetPins {
  getPins: GetPins_getPins[];
}

export interface GetPinsVariables {
  pinSetIds: number[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPinSets
// ====================================================

export interface GetPinSets_getDeploymentModelSpec_battlecards_pinSets {
  __typename: 'PinSet';
  pinSetId: number;
  pinSetName: string;
  battlecardId: number;
  locationGroupId: number;
  icon: string;
  color: string;
}

export interface GetPinSets_getDeploymentModelSpec_battlecards {
  __typename: 'BattlecardDef';
  battlecardId: number;
  pinSets: GetPinSets_getDeploymentModelSpec_battlecards_pinSets[] | null;
}

export interface GetPinSets_getDeploymentModelSpec {
  __typename: 'DeploymentModelDef';
  deploymentModelId: number;
  battlecards: (GetPinSets_getDeploymentModelSpec_battlecards | null)[] | null;
}

export interface GetPinSets {
  getDeploymentModelSpec: GetPinSets_getDeploymentModelSpec | null;
}

export interface GetPinSetsVariables {
  battlecardId: number;
  deploymentModelId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PlansList
// ====================================================

export interface PlansList_getPlanningCycles {
  __typename: 'PlanningCycle';
  tenantId: number;
  planningCycleId: number;
  planningCycleName: string;
  planningCycleComment: string | null;
  planningCycleSlug: string;
  planningCycleDuration: number;
  planningCyclePeriodicity: string;
  planningCycleStartDate: any;
  planningCycleArchived: boolean;
  currencyCode: string | null;
  cloneId: number | null;
}

export interface PlansList {
  getPlanningCycles: (PlansList_getPlanningCycles | null)[] | null;
}

export interface PlansListVariables {
  tenantId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPostCoinsortProgress
// ====================================================

export interface GetPostCoinsortProgress_getPostCoinsortProgress {
  __typename: 'PostCoinsortStatusOutput';
  id: string;
  actorEmail: string | null;
  jobAction: string;
  status: string;
  aggregationType: string;
  planningCycleId: number;
  aggregatedRowsInserted: number;
  totalActivities: number | null;
  jobNumber: number | null;
  totalJobs: number | null;
}

export interface GetPostCoinsortProgress {
  getPostCoinsortProgress: GetPostCoinsortProgress_getPostCoinsortProgress | null;
}

export interface GetPostCoinsortProgressVariables {
  planningCycleId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetQuotaWorkflowInitiationStatus
// ====================================================

export interface GetQuotaWorkflowInitiationStatus_getWorkflowInitiationStatus {
  __typename: 'GetWorkflowInitiationStatusResponse';
  workflowInitiated: boolean | null;
}

export interface GetQuotaWorkflowInitiationStatus {
  getWorkflowInitiationStatus: GetQuotaWorkflowInitiationStatus_getWorkflowInitiationStatus | null;
}

export interface GetQuotaWorkflowInitiationStatusVariables {
  planningCycleId: number;
  deploymentModelId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRootHierarchies
// ====================================================

export interface GetRootHierarchies_getRootHierarchies_attributes {
  __typename: 'HierarchyAttribute';
  name: string | null;
  type: string | null;
  editable: boolean | null;
}

export interface GetRootHierarchies_getRootHierarchies {
  __typename: 'GetAllHierarchies';
  hierarchyType: string | null;
  rootHierarchyId: number | null;
  rootKey: string | null;
  rootName: string | null;
  numOfMembers: number | null;
  attributes: (GetRootHierarchies_getRootHierarchies_attributes | null)[] | null;
}

export interface GetRootHierarchies {
  getRootHierarchies: (GetRootHierarchies_getRootHierarchies | null)[] | null;
}

export interface GetRootHierarchiesVariables {
  planningCycleId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetS3File
// ====================================================

export interface GetS3File_getS3File {
  __typename: 'GetS3File';
  fileRows: (any | null)[] | null;
  fileType: string;
  headers: (string | null)[];
  totalNumOfRows: number;
}

export interface GetS3File {
  getS3File: GetS3File_getS3File | null;
}

export interface GetS3FileVariables {
  fileId: string;
  startRow?: number | null;
  endRow?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSheetDefinitions
// ====================================================

export interface GetSheetDefinitions_getDeploymentModelSpec_dataSheets_sheetDefinitions_quotaComponents_roles {
  __typename: 'DataSheetRoleDef';
  editable: string | null;
  roleId: number | null;
  roleName: string | null;
  sheetACLId: number | null;
  visible: string | null;
}

export interface GetSheetDefinitions_getDeploymentModelSpec_dataSheets_sheetDefinitions_quotaComponents {
  __typename: 'DataSheetQoutaComponentDef';
  quotaComponentId: number | null;
  quotaComponentName: string | null;
  roles: (GetSheetDefinitions_getDeploymentModelSpec_dataSheets_sheetDefinitions_quotaComponents_roles | null)[] | null;
}

export interface GetSheetDefinitions_getDeploymentModelSpec_dataSheets_sheetDefinitions_years {
  __typename: 'YearPeriodicities';
  year: number;
  periodicities: (string | null)[] | null;
}

export interface GetSheetDefinitions_getDeploymentModelSpec_dataSheets_sheetDefinitions {
  __typename: 'DataSheetDefinitionDef';
  fieldId: string | null;
  fieldName: string | null;
  measureId: number | null;
  measureName: string | null;
  measureCategory: string | null;
  measureSource: string | null;
  measureFieldType: string | null;
  measureFormatType: string | null;
  measureFormula: string | null;
  measureConstraints: string | null;
  startDate: any | null;
  endDate: any | null;
  defaultMeasureId: number | null;
  quotaComponents:
    | (GetSheetDefinitions_getDeploymentModelSpec_dataSheets_sheetDefinitions_quotaComponents | null)[]
    | null;
  sheetDefinitionId: number | null;
  sheetDefinitionType: string | null;
  years: (GetSheetDefinitions_getDeploymentModelSpec_dataSheets_sheetDefinitions_years | null)[] | null;
}

export interface GetSheetDefinitions_getDeploymentModelSpec_dataSheets {
  __typename: 'DataSheetDef';
  isTQM: boolean | null;
  sheetDefinitions: (GetSheetDefinitions_getDeploymentModelSpec_dataSheets_sheetDefinitions | null)[] | null;
}

export interface GetSheetDefinitions_getDeploymentModelSpec {
  __typename: 'DeploymentModelDef';
  dataSheets: (GetSheetDefinitions_getDeploymentModelSpec_dataSheets | null)[] | null;
}

export interface GetSheetDefinitions {
  getDeploymentModelSpec: GetSheetDefinitions_getDeploymentModelSpec | null;
}

export interface GetSheetDefinitionsVariables {
  deploymentModelId: number;
  sheetId?: number | null;
  isTQM?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSheetMeasures
// ====================================================

export interface GetSheetMeasures_getDeploymentModelSpec_sheetMeasures {
  __typename: 'SheetMeasuresDef';
  measureCategory: string | null;
  measureConstraints: string | null;
  measureFieldType: string | null;
  measureFormatType: string | null;
  measureFormula: string | null;
  measureId: number | null;
  measureName: string | null;
  measureSource: string | null;
  sheetDefinitionType: string | null;
  sheetId: number | null;
}

export interface GetSheetMeasures_getDeploymentModelSpec {
  __typename: 'DeploymentModelDef';
  sheetMeasures: (GetSheetMeasures_getDeploymentModelSpec_sheetMeasures | null)[] | null;
}

export interface GetSheetMeasures {
  getDeploymentModelSpec: GetSheetMeasures_getDeploymentModelSpec | null;
}

export interface GetSheetMeasuresVariables {
  deploymentModelId: number;
  quotaComponentId?: number | null;
  battlecardId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSubtreeCustomHierarchies
// ====================================================

export interface GetSubtreeCustomHierarchies_getSubtreeCustomHierarchies_items {
  __typename: 'GetCustomHierarchy';
  hasChildren: boolean | null;
  customProperties: any | null;
  hierarchyId: number | null;
  key: string | null;
  name: string | null;
  parentKey: string | null;
  path: string | null;
  planningCycleId: number | null;
  effectiveDate: any | null;
  endDate: any | null;
  version: number | null;
}

export interface GetSubtreeCustomHierarchies_getSubtreeCustomHierarchies {
  __typename: 'PaginatedCustomHierarchy';
  items: GetSubtreeCustomHierarchies_getSubtreeCustomHierarchies_items[];
  totalCount: number | null;
}

export interface GetSubtreeCustomHierarchies {
  getSubtreeCustomHierarchies: GetSubtreeCustomHierarchies_getSubtreeCustomHierarchies | null;
}

export interface GetSubtreeCustomHierarchiesVariables {
  planningCycleId: number;
  hierarchyId: number;
  depth: number;
  startRow: number;
  endRow: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSubtreeCustomerAccountHierarchies
// ====================================================

export interface GetSubtreeCustomerAccountHierarchies_getSubtreeCustomerAccountHierarchies_items {
  __typename: 'GetCustomerAccountHierarchy';
  address1: string | null;
  address2: string | null;
  hasChildren: boolean | null;
  city: string | null;
  country: string | null;
  customProperties: any | null;
  customerAccountNumber: string | null;
  hierarchyId: number | null;
  industry: string | null;
  key: string | null;
  name: string | null;
  parentKey: string | null;
  path: string | null;
  planningCycleId: number | null;
  zipPostal: string | null;
  stateProvince: string | null;
  effectiveDate: any | null;
  endDate: any | null;
  version: number | null;
}

export interface GetSubtreeCustomerAccountHierarchies_getSubtreeCustomerAccountHierarchies {
  __typename: 'PaginatedCustomerAccountHierarchy';
  items: GetSubtreeCustomerAccountHierarchies_getSubtreeCustomerAccountHierarchies_items[];
  totalCount: number | null;
}

export interface GetSubtreeCustomerAccountHierarchies {
  getSubtreeCustomerAccountHierarchies: GetSubtreeCustomerAccountHierarchies_getSubtreeCustomerAccountHierarchies | null;
}

export interface GetSubtreeCustomerAccountHierarchiesVariables {
  planningCycleId: number;
  hierarchyId: number;
  depth: number;
  startRow: number;
  endRow: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSubtreeGeographicTerritoryHierarchies
// ====================================================

export interface GetSubtreeGeographicTerritoryHierarchies_getSubtreeGeographicTerritoryHierarchies_items {
  __typename: 'GetGeographicTerritoryHierarchy';
  hasChildren: boolean | null;
  customProperties: any | null;
  geographicTerritoryId: string | null;
  hierarchyId: number | null;
  key: string | null;
  name: string | null;
  parentKey: string | null;
  path: string | null;
  planningCycleId: number | null;
  version: number | null;
}

export interface GetSubtreeGeographicTerritoryHierarchies_getSubtreeGeographicTerritoryHierarchies {
  __typename: 'PaginatedGeographicTerritoryHierarchy';
  items: GetSubtreeGeographicTerritoryHierarchies_getSubtreeGeographicTerritoryHierarchies_items[];
  totalCount: number | null;
}

export interface GetSubtreeGeographicTerritoryHierarchies {
  getSubtreeGeographicTerritoryHierarchies: GetSubtreeGeographicTerritoryHierarchies_getSubtreeGeographicTerritoryHierarchies | null;
}

export interface GetSubtreeGeographicTerritoryHierarchiesVariables {
  planningCycleId: number;
  hierarchyId: number;
  depth: number;
  startRow: number;
  endRow: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSymonAccount
// ====================================================

export interface GetSymonAccount_getSymonAccount {
  __typename: 'SymonAccountOutput';
  tenantId: number;
  symonAccountName: string | null;
  symonAccountEmailAddress: string | null;
  symonAccountKey: string | null;
  symonDomain: string | null;
  symonUiClientKey: string | null;
  symonAccountCreatedAt: any | null;
}

export interface GetSymonAccount {
  getSymonAccount: GetSymonAccount_getSymonAccount | null;
}

export interface GetSymonAccountVariables {
  tenantId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSymonPipeConfiguration
// ====================================================

export interface GetSymonPipeConfiguration_getSymonPipeConfiguration_mapping_value {
  __typename: 'Value';
  id: string;
  order: number | null;
  type: string;
}

export interface GetSymonPipeConfiguration_getSymonPipeConfiguration_mapping {
  __typename: 'FieldName';
  fieldName: string;
  value: GetSymonPipeConfiguration_getSymonPipeConfiguration_mapping_value | null;
}

export interface GetSymonPipeConfiguration_getSymonPipeConfiguration {
  __typename: 'SymonPipeConfiguration';
  status: string | null;
  mapping: (GetSymonPipeConfiguration_getSymonPipeConfiguration_mapping | null)[] | null;
  pipeId: string | null;
  pipeName: string | null;
  tableNameLabel: string | null;
  exportId: string | null;
  exportName: string | null;
  fileType: string | null;
}

export interface GetSymonPipeConfiguration {
  getSymonPipeConfiguration: GetSymonPipeConfiguration_getSymonPipeConfiguration | null;
}

export interface GetSymonPipeConfigurationVariables {
  symonPipeConfigurationId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSymonPipeConfigurations
// ====================================================

export interface GetSymonPipeConfigurations_getSymonPipeConfigurations {
  __typename: 'SymonPipeConfiguration';
  exportId: string | null;
  exportName: string | null;
  fileType: string | null;
  pipeId: string | null;
  pipeName: string | null;
  runId: string | null;
  symonPipeConfigurationId: number | null;
  status: string | null;
  endAt: string | null;
  tableNameLabel: string | null;
  hierarchyId: number | null;
}

export interface GetSymonPipeConfigurations {
  getSymonPipeConfigurations: (GetSymonPipeConfigurations_getSymonPipeConfigurations | null)[] | null;
}

export interface GetSymonPipeConfigurationsVariables {
  planningCycleId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSymonPipeExportNodes
// ====================================================

export interface PlanningCycleInfo {
  planningCycleId: number;
  planningCycleName: string;
}

export interface GetSymonPipeExportNodes_getSymonPipeExportNodes {
  __typename: 'GetSymonExportNode';
  exportId: string | null;
  exportNodeName: string | null;
  planningCycleInfo: PlanningCycleInfo | null;
}

export interface GetSymonPipeExportNodes {
  getSymonPipeExportNodes: (GetSymonPipeExportNodes_getSymonPipeExportNodes | null)[] | null;
}

export interface GetSymonPipeExportNodesVariables {
  planningCycleId: number;
  pipeId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSymonPipeNames
// ====================================================

export interface GetSymonPipeNames_getSymonPipeNames {
  __typename: 'SymonPipeOutput';
  pipeID: string | null;
  name: string | null;
  engineMode: string | null;
  rowVersion: number | null;
  createdAt: any | null;
}

export interface GetSymonPipeNames {
  getSymonPipeNames: (GetSymonPipeNames_getSymonPipeNames | null)[] | null;
}

export interface GetSymonPipeNamesVariables {
  tenantId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TDAByHierarchy
// ====================================================

export interface TDAByHierarchy_getTerritoryQuota_quotaByHierarchy_hierarchyQuotaInfo {
  __typename: 'HierarchyQuotaInfo';
  hierarchyId: number;
  hierarchyName: string;
  hierarchyQuota: number;
}

export interface TDAByHierarchy_getTerritoryQuota_quotaByHierarchy {
  __typename: 'QuotaByHierarchy';
  measureId: number;
  measureName: string;
  measureValue: number;
  hierarchyQuotaInfo: TDAByHierarchy_getTerritoryQuota_quotaByHierarchy_hierarchyQuotaInfo[];
}

export interface TDAByHierarchy_getTerritoryQuota {
  __typename: 'GetTerritoryQuotaResponse';
  quotaByHierarchy: TDAByHierarchy_getTerritoryQuota_quotaByHierarchy[] | null;
}

export interface TDAByHierarchy {
  getTerritoryQuota: TDAByHierarchy_getTerritoryQuota;
}

export interface TDAByHierarchyVariables {
  battlecardId: number;
  quotaComponentId: number;
  territoryGroupId?: number | null;
  sheetId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTenantWideInfo
// ====================================================

export interface GetTenantWideInfo_getUserSpec_membershipSpecs_planningCycles_deploymentModels {
  __typename: 'DeploymentModelSpec';
  deploymentModelId: number;
  deploymentModelType: DeploymentModelTypeEnum;
  deploymentModelName: string;
  isPublished: boolean;
}

export interface GetTenantWideInfo_getUserSpec_membershipSpecs_planningCycles {
  __typename: 'PlanningCycleSpec';
  planningCycleId: number;
  deploymentModels: (GetTenantWideInfo_getUserSpec_membershipSpecs_planningCycles_deploymentModels | null)[] | null;
}

export interface GetTenantWideInfo_getUserSpec_membershipSpecs {
  __typename: 'MembershipSpec';
  memberId: number | null;
  tenantId: number | null;
  tenantGlobalId: string;
  tenantName: string;
  tenantSlug: string;
  systemRoleName: string | null;
  planningCycles: (GetTenantWideInfo_getUserSpec_membershipSpecs_planningCycles | null)[] | null;
}

export interface GetTenantWideInfo_getUserSpec {
  __typename: 'MemberSpec';
  subjectId: string;
  emailAddress: string;
  firstName: string | null;
  lastName: string | null;
  passwordUpdatedAt: any | null;
  isSupportUser: boolean | null;
  membershipSpecs: (GetTenantWideInfo_getUserSpec_membershipSpecs | null)[] | null;
}

export interface GetTenantWideInfo_getCurrencies {
  __typename: 'Currency';
  currencyCode: string | null;
  currencyName: string | null;
}

export interface GetTenantWideInfo {
  getUserSpec: GetTenantWideInfo_getUserSpec | null;
  getCurrencies: (GetTenantWideInfo_getCurrencies | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTerritoryGroupChildren
// ====================================================

export interface GetTerritoryGroupChildren_getTerritoryGroupChildren_items_children {
  __typename: 'TerritoryGroup';
  battlecardId: number | null;
  color: string | null;
  battlecardQuotaComponentId: number | null;
  hierarchyId: number | null;
  hierarchyTopId: number | null;
  path: string | null;
  precedence: number | null;
  territoryGroupId: number | null;
  territoryGroupParentId: number | null;
  territoryGroupName: string | null;
}

export interface GetTerritoryGroupChildren_getTerritoryGroupChildren_items {
  __typename: 'GetTerritoryGroup';
  children: (GetTerritoryGroupChildren_getTerritoryGroupChildren_items_children | null)[] | null;
  battlecardId: number | null;
  battlecardQuotaComponentId: number | null;
  color: string | null;
  hierarchyId: number | null;
  hierarchyTopId: number | null;
  hierarchyType: string | null;
  path: string | null;
  territoryGroupId: number | null;
  precedence: number | null;
  territoryGroupName: string | null;
  territoryGroupParentId: number | null;
}

export interface GetTerritoryGroupChildren_getTerritoryGroupChildren {
  __typename: 'PaginatedTerritoryGroup';
  items: (GetTerritoryGroupChildren_getTerritoryGroupChildren_items | null)[] | null;
  totalCount: number | null;
}

export interface GetTerritoryGroupChildren {
  getTerritoryGroupChildren: GetTerritoryGroupChildren_getTerritoryGroupChildren | null;
}

export interface GetTerritoryGroupChildrenVariables {
  battlecardId: number;
  territoryGroupParentId: number;
  startRow: number;
  endRow: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTerritoryGroupsForMap
// ====================================================

export interface GetTerritoryGroupsForMap_getAllTerritoryGroups_allTerritoryGroups_owner {
  __typename: 'TerritoryGroupOwner';
  firstName: string;
  lastName: string;
}

export interface GetTerritoryGroupsForMap_getAllTerritoryGroups_allTerritoryGroups {
  __typename: 'GetAllTerritoryGroups';
  hierarchyTopId: number | null;
  hierarchyType: string | null;
  territoryGroupId: number;
  territoryGroupParentId: number | null;
  territoryGroupName: string | null;
  owner: GetTerritoryGroupsForMap_getAllTerritoryGroups_allTerritoryGroups_owner | null;
}

export interface GetTerritoryGroupsForMap_getAllTerritoryGroups {
  __typename: 'GetAllTerritoryGroupsResponse';
  allTerritoryGroups: (GetTerritoryGroupsForMap_getAllTerritoryGroups_allTerritoryGroups | null)[] | null;
}

export interface GetTerritoryGroupsForMap {
  getAllTerritoryGroups: GetTerritoryGroupsForMap_getAllTerritoryGroups | null;
}

export interface GetTerritoryGroupsForMapVariables {
  battlecardId: number;
  quotaComponentId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TerritoryMeasuresTotals
// ====================================================

export interface TerritoryMeasuresTotals_getTerritoryMeasuresGrid_periodicTotals_measureValue_years_halves_quarters_months {
  __typename: 'PmvMonth';
  month: string;
  date: any;
  value: number;
}

export interface TerritoryMeasuresTotals_getTerritoryMeasuresGrid_periodicTotals_measureValue_years_halves_quarters {
  __typename: 'PmvQuarter';
  quarter: QuarterIdentifier;
  date: any;
  value: number;
  months:
    | TerritoryMeasuresTotals_getTerritoryMeasuresGrid_periodicTotals_measureValue_years_halves_quarters_months[]
    | null;
}

export interface TerritoryMeasuresTotals_getTerritoryMeasuresGrid_periodicTotals_measureValue_years_halves {
  __typename: 'PmvHalf';
  half: HalfIdentifier;
  date: any;
  value: number;
  quarters: TerritoryMeasuresTotals_getTerritoryMeasuresGrid_periodicTotals_measureValue_years_halves_quarters[] | null;
}

export interface TerritoryMeasuresTotals_getTerritoryMeasuresGrid_periodicTotals_measureValue_years {
  __typename: 'PmvYear';
  year: number;
  date: any;
  value: number;
  halves: TerritoryMeasuresTotals_getTerritoryMeasuresGrid_periodicTotals_measureValue_years_halves[] | null;
}

export interface TerritoryMeasuresTotals_getTerritoryMeasuresGrid_periodicTotals_measureValue {
  __typename: 'PeriodicMeasureValue';
  measureFieldType: string | null;
  textValue: string | null;
  type: string | null;
  value: number;
  years: TerritoryMeasuresTotals_getTerritoryMeasuresGrid_periodicTotals_measureValue_years[];
}

export interface TerritoryMeasuresTotals_getTerritoryMeasuresGrid_periodicTotals {
  __typename: 'PeriodicMeasure';
  measureId: number;
  measureName: string;
  editable: boolean | null;
  measureValue: TerritoryMeasuresTotals_getTerritoryMeasuresGrid_periodicTotals_measureValue;
}

export interface TerritoryMeasuresTotals_getTerritoryMeasuresGrid {
  __typename: 'TerritoryGrid';
  periodicTotals: (TerritoryMeasuresTotals_getTerritoryMeasuresGrid_periodicTotals | null)[] | null;
}

export interface TerritoryMeasuresTotals {
  getTerritoryMeasuresGrid: TerritoryMeasuresTotals_getTerritoryMeasuresGrid | null;
}

export interface TerritoryMeasuresTotalsVariables {
  battlecardId?: number | null;
  quotaComponentId: number;
  territoryGroupId?: number | null;
  sheetId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTerritoryQuotaTotals
// ====================================================

export interface GetTerritoryQuotaTotals_getTerritoryQuota_periodicTotals_measureValue_years_halves_quarters_months {
  __typename: 'PmvMonth';
  month: string;
  date: any;
  value: number;
}

export interface GetTerritoryQuotaTotals_getTerritoryQuota_periodicTotals_measureValue_years_halves_quarters {
  __typename: 'PmvQuarter';
  quarter: QuarterIdentifier;
  date: any;
  value: number;
  months: GetTerritoryQuotaTotals_getTerritoryQuota_periodicTotals_measureValue_years_halves_quarters_months[] | null;
}

export interface GetTerritoryQuotaTotals_getTerritoryQuota_periodicTotals_measureValue_years_halves {
  __typename: 'PmvHalf';
  half: HalfIdentifier;
  date: any;
  value: number;
  quarters: GetTerritoryQuotaTotals_getTerritoryQuota_periodicTotals_measureValue_years_halves_quarters[] | null;
}

export interface GetTerritoryQuotaTotals_getTerritoryQuota_periodicTotals_measureValue_years {
  __typename: 'PmvYear';
  year: number;
  date: any;
  value: number;
  halves: GetTerritoryQuotaTotals_getTerritoryQuota_periodicTotals_measureValue_years_halves[] | null;
}

export interface GetTerritoryQuotaTotals_getTerritoryQuota_periodicTotals_measureValue {
  __typename: 'PeriodicMeasureValue';
  measureFieldType: string | null;
  textValue: string | null;
  type: string | null;
  value: number;
  years: GetTerritoryQuotaTotals_getTerritoryQuota_periodicTotals_measureValue_years[];
}

export interface GetTerritoryQuotaTotals_getTerritoryQuota_periodicTotals {
  __typename: 'PeriodicMeasure';
  measureId: number;
  measureName: string;
  editable: boolean | null;
  measureValue: GetTerritoryQuotaTotals_getTerritoryQuota_periodicTotals_measureValue;
}

export interface GetTerritoryQuotaTotals_getTerritoryQuota {
  __typename: 'GetTerritoryQuotaResponse';
  periodicTotals: GetTerritoryQuotaTotals_getTerritoryQuota_periodicTotals[] | null;
}

export interface GetTerritoryQuotaTotals {
  getTerritoryQuota: GetTerritoryQuotaTotals_getTerritoryQuota;
}

export interface GetTerritoryQuotaTotalsVariables {
  battlecardId?: number | null;
  territoryGroupId?: number | null;
  quotaComponentId: number;
  sheetId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTerritoryOptimizationPrerequisites
// ====================================================

export interface GetTerritoryOptimizationPrerequisites_getDeploymentModelSpec_sheetMeasures {
  __typename: 'SheetMeasuresDef';
  measureId: number | null;
  measureName: string | null;
  measureFormatType: string | null;
}

export interface GetTerritoryOptimizationPrerequisites_getDeploymentModelSpec {
  __typename: 'DeploymentModelDef';
  sheetMeasures: (GetTerritoryOptimizationPrerequisites_getDeploymentModelSpec_sheetMeasures | null)[] | null;
}

export interface GetTerritoryOptimizationPrerequisites_getRootHierarchies_attributes {
  __typename: 'HierarchyAttribute';
  name: string | null;
  type: string | null;
  editable: boolean | null;
}

export interface GetTerritoryOptimizationPrerequisites_getRootHierarchies {
  __typename: 'GetAllHierarchies';
  hierarchyType: string | null;
  rootHierarchyId: number | null;
  attributes: (GetTerritoryOptimizationPrerequisites_getRootHierarchies_attributes | null)[] | null;
}

export interface GetTerritoryOptimizationPrerequisites_getNumberOfTerritoriesInTerritoryGroup {
  __typename: 'NumOfTerritories';
  numOfTerritories: number | null;
}

export interface GetTerritoryOptimizationPrerequisites {
  getDeploymentModelSpec: GetTerritoryOptimizationPrerequisites_getDeploymentModelSpec | null;
  getRootHierarchies: (GetTerritoryOptimizationPrerequisites_getRootHierarchies | null)[] | null;
  getNumberOfTerritoriesInTerritoryGroup: GetTerritoryOptimizationPrerequisites_getNumberOfTerritoriesInTerritoryGroup | null;
}

export interface GetTerritoryOptimizationPrerequisitesVariables {
  planningCycleId: number;
  deploymentModelId: number;
  quotaComponentId: number;
  battlecardId: number;
  territoryGroupId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTerritoryQuotaDistribution
// ====================================================

export interface GetTerritoryQuotaDistribution_getTerritoryQuota_periodicQuotaDistribution_measures_measureValue_years_halves_quarters_months {
  __typename: 'PmvMonth';
  month: string;
  date: any;
  value: number;
}

export interface GetTerritoryQuotaDistribution_getTerritoryQuota_periodicQuotaDistribution_measures_measureValue_years_halves_quarters {
  __typename: 'PmvQuarter';
  quarter: QuarterIdentifier;
  date: any;
  value: number;
  months:
    | GetTerritoryQuotaDistribution_getTerritoryQuota_periodicQuotaDistribution_measures_measureValue_years_halves_quarters_months[]
    | null;
}

export interface GetTerritoryQuotaDistribution_getTerritoryQuota_periodicQuotaDistribution_measures_measureValue_years_halves {
  __typename: 'PmvHalf';
  half: HalfIdentifier;
  date: any;
  value: number;
  quarters:
    | GetTerritoryQuotaDistribution_getTerritoryQuota_periodicQuotaDistribution_measures_measureValue_years_halves_quarters[]
    | null;
}

export interface GetTerritoryQuotaDistribution_getTerritoryQuota_periodicQuotaDistribution_measures_measureValue_years {
  __typename: 'PmvYear';
  year: number;
  date: any;
  value: number;
  halves:
    | GetTerritoryQuotaDistribution_getTerritoryQuota_periodicQuotaDistribution_measures_measureValue_years_halves[]
    | null;
}

export interface GetTerritoryQuotaDistribution_getTerritoryQuota_periodicQuotaDistribution_measures_measureValue {
  __typename: 'PeriodicMeasureValue';
  measureFieldType: string | null;
  textValue: string | null;
  type: string | null;
  value: number;
  years: GetTerritoryQuotaDistribution_getTerritoryQuota_periodicQuotaDistribution_measures_measureValue_years[];
}

export interface GetTerritoryQuotaDistribution_getTerritoryQuota_periodicQuotaDistribution_measures {
  __typename: 'PeriodicMeasure';
  measureId: number;
  measureName: string;
  editable: boolean | null;
  measureValue: GetTerritoryQuotaDistribution_getTerritoryQuota_periodicQuotaDistribution_measures_measureValue;
}

export interface GetTerritoryQuotaDistribution_getTerritoryQuota_periodicQuotaDistribution_hierarchies {
  __typename: 'HierarchyDetails';
  key: string;
  name: string;
  hierarchyId: number;
  hierarchyType: string;
  rootHierarchyId: number | null;
  rootKey: string | null;
  rootName: string | null;
}

export interface GetTerritoryQuotaDistribution_getTerritoryQuota_periodicQuotaDistribution {
  __typename: 'PeriodicQuotaGridDistributionResponse';
  ruleId: number;
  measures: GetTerritoryQuotaDistribution_getTerritoryQuota_periodicQuotaDistribution_measures[];
  hierarchies: GetTerritoryQuotaDistribution_getTerritoryQuota_periodicQuotaDistribution_hierarchies[];
}

export interface GetTerritoryQuotaDistribution_getTerritoryQuota {
  __typename: 'GetTerritoryQuotaResponse';
  quotaDistributionByHierarchiesTotalCount: number | null;
  periodicQuotaDistribution: GetTerritoryQuotaDistribution_getTerritoryQuota_periodicQuotaDistribution[] | null;
}

export interface GetTerritoryQuotaDistribution {
  getTerritoryQuota: GetTerritoryQuotaDistribution_getTerritoryQuota;
}

export interface GetTerritoryQuotaDistributionVariables {
  input: GetTerritoryQuotaInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTerritoryRules
// ====================================================

export interface GetTerritoryRules_getTerritoryRules_territoryRules_owners {
  __typename: 'TerritoryRuleOwner';
  ownerId: number | null;
  employeeId: string | null;
  firstName: string;
  lastName: string;
  memberId: number;
  ownership: number;
  effectiveStartDate: any | null;
  effectiveEndDate: any | null;
}

export interface GetTerritoryRules_getTerritoryRules_territoryRules_ruleDefinitionCompressed {
  __typename: 'CompressedRuleDefinitionResponse';
  compressed: string;
  compressedType: CompressedRuleDefinitionResponseEnum;
}

export interface GetTerritoryRules_getTerritoryRules_territoryRules_inheritsFrom {
  __typename: 'TerritoryRuleHierarchyWithInfo';
  ruleId: number | null;
  territoryId: string | null;
  territoryName: string | null;
}

export interface GetTerritoryRules_getTerritoryRules_territoryRules {
  __typename: 'TerritoryRules';
  effectiveDate: any | null;
  endDate: any | null;
  measureGap: number | null;
  measureValue: number | null;
  numberOfAccounts: number | null;
  numberOfAccountsGap: number | null;
  ruleId: number | null;
  territoryId: string;
  territoryName: string;
  territoryGroupId: number;
  territoryGroupName: string;
  owners: GetTerritoryRules_getTerritoryRules_territoryRules_owners[] | null;
  ruleDefinitionCompressed: GetTerritoryRules_getTerritoryRules_territoryRules_ruleDefinitionCompressed | null;
  color: string | null;
  inheritsFrom: (GetTerritoryRules_getTerritoryRules_territoryRules_inheritsFrom | null)[] | null;
}

export interface GetTerritoryRules_getTerritoryRules {
  __typename: 'GetTerritoryRulesResponse';
  territoryRules: (GetTerritoryRules_getTerritoryRules_territoryRules | null)[] | null;
  totalCount: number;
  totalAccountsCount: number;
  totalAccountsMean: number;
  measureValueMean: number;
  measureValueStandardDeviation: number;
}

export interface GetTerritoryRules {
  getTerritoryRules: GetTerritoryRules_getTerritoryRules;
}

export interface GetTerritoryRulesVariables {
  quotaComponentId: number;
  measureId: number;
  sorting: GetTerritoryRulesSortModel;
  searchInput?: GetTerritoryRulesSearchInput | null;
  territoryGroupId?: number | null;
  battlecardId?: number | null;
  startRow?: number | null;
  endRow?: number | null;
  includeDefinition?: boolean | null;
  dimensionsAndModifiersLimit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTerritoryRulesForAccountMove
// ====================================================

export interface GetTerritoryRulesForAccountMove_getTerritoryRules_territoryRules {
  __typename: 'TerritoryRules';
  ruleId: number | null;
  territoryId: string;
  territoryName: string;
  effectiveDate: any | null;
  endDate: any | null;
}

export interface GetTerritoryRulesForAccountMove_getTerritoryRules {
  __typename: 'GetTerritoryRulesResponse';
  territoryRules: (GetTerritoryRulesForAccountMove_getTerritoryRules_territoryRules | null)[] | null;
  totalCount: number;
}

export interface GetTerritoryRulesForAccountMove {
  getTerritoryRules: GetTerritoryRulesForAccountMove_getTerritoryRules;
}

export interface GetTerritoryRulesForAccountMoveVariables {
  quotaComponentId: number;
  measureId: number;
  sorting: GetTerritoryRulesSortModel;
  searchInput?: GetTerritoryRulesSearchInput | null;
  territoryGroupId?: number | null;
  startRow?: number | null;
  endRow?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTerritoryRulesForAccountReassign
// ====================================================

export interface GetTerritoryRulesForAccountReassign_getTerritoryRules_territoryRules {
  __typename: 'TerritoryRules';
  ruleId: number | null;
  territoryId: string;
  territoryName: string;
}

export interface GetTerritoryRulesForAccountReassign_getTerritoryRules {
  __typename: 'GetTerritoryRulesResponse';
  territoryRules: (GetTerritoryRulesForAccountReassign_getTerritoryRules_territoryRules | null)[] | null;
}

export interface GetTerritoryRulesForAccountReassign {
  getTerritoryRules: GetTerritoryRulesForAccountReassign_getTerritoryRules;
}

export interface GetTerritoryRulesForAccountReassignVariables {
  quotaComponentId: number;
  measureId: number;
  sorting: GetTerritoryRulesSortModel;
  territoryGroupId?: number | null;
  startRow?: number | null;
  endRow?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTerritoryRulesForFields
// ====================================================

export interface GetTerritoryRulesForFields_getTerritoryRules_territoryRules_fields_owners {
  __typename: 'OwnerField';
  fieldValue: string;
  memberId: number;
  ownerId: number;
}

export interface GetTerritoryRulesForFields_getTerritoryRules_territoryRules_fields {
  __typename: 'Field';
  fieldId: number;
  fieldValue: string;
  owners: GetTerritoryRulesForFields_getTerritoryRules_territoryRules_fields_owners[] | null;
  rangeId: number | null;
  effectiveStartDate: string | null;
  effectiveEndDate: string | null;
}

export interface GetTerritoryRulesForFields_getTerritoryRules_territoryRules_owners {
  __typename: 'TerritoryRuleOwner';
  firstName: string;
  lastName: string;
  memberId: number;
  ownerId: number | null;
  ownership: number;
  employeeId: string | null;
  effectiveEndDate: any | null;
  effectiveStartDate: any | null;
}

export interface GetTerritoryRulesForFields_getTerritoryRules_territoryRules_territoryRangeFields_breakdownByPeriod {
  __typename: 'BreakdownByPeriod';
  period: string | null;
  value: number | null;
}

export interface GetTerritoryRulesForFields_getTerritoryRules_territoryRules_territoryRangeFields {
  __typename: 'TerritoryRangeField';
  fieldId: number | null;
  fieldName: string | null;
  fieldValue: string | null;
  effectiveStartDate: any | null;
  effectiveEndDate: any | null;
  rangeId: number | null;
  territoryId: string | null;
  breakdownByPeriod:
    | (GetTerritoryRulesForFields_getTerritoryRules_territoryRules_territoryRangeFields_breakdownByPeriod | null)[]
    | null;
}

export interface GetTerritoryRulesForFields_getTerritoryRules_territoryRules {
  __typename: 'TerritoryRules';
  fields: GetTerritoryRulesForFields_getTerritoryRules_territoryRules_fields[] | null;
  owners: GetTerritoryRulesForFields_getTerritoryRules_territoryRules_owners[] | null;
  territoryRangeFields:
    | (GetTerritoryRulesForFields_getTerritoryRules_territoryRules_territoryRangeFields | null)[]
    | null;
  territoryGroupId: number;
  territoryGroupName: string;
  ruleId: number | null;
  territoryId: string;
  territoryName: string;
  effectiveDate: any | null;
  endDate: any | null;
}

export interface GetTerritoryRulesForFields_getTerritoryRules_fieldTotals {
  __typename: 'FieldTotal';
  fieldId: number;
  fieldValue: string | null;
}

export interface GetTerritoryRulesForFields_getTerritoryRules {
  __typename: 'GetTerritoryRulesResponse';
  territoryRules: (GetTerritoryRulesForFields_getTerritoryRules_territoryRules | null)[] | null;
  totalCount: number;
  fieldTotals: (GetTerritoryRulesForFields_getTerritoryRules_fieldTotals | null)[] | null;
}

export interface GetTerritoryRulesForFields {
  getTerritoryRules: GetTerritoryRulesForFields_getTerritoryRules;
}

export interface GetTerritoryRulesForFieldsVariables {
  quotaComponentId: number;
  measureId: number;
  sorting: GetTerritoryRulesSortModel;
  searchInput?: GetTerritoryRulesSearchInput | null;
  territoryGroupId?: number | null;
  battlecardId?: number | null;
  startRow?: number | null;
  endRow?: number | null;
  fieldIds: (number | null)[];
  rangeFieldInput?: GetRangeFieldInput | null;
  includeRedirectExists: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTerritoryRulesForMap
// ====================================================

export interface GetTerritoryRulesForMap_getTerritoryRules_territoryRules_ruleDefinitionSlimCompressed {
  __typename: 'CompressedRuleDefinitionResponse';
  compressed: string;
  compressedType: CompressedRuleDefinitionResponseEnum;
}

export interface GetTerritoryRulesForMap_getTerritoryRules_territoryRules {
  __typename: 'TerritoryRules';
  territoryId: string;
  territoryName: string;
  effectiveDate: any | null;
  endDate: any | null;
  measureValue: number | null;
  numberOfAccounts: number | null;
  ruleId: number | null;
  territoryGroupId: number;
  ruleDefinitionSlimCompressed: GetTerritoryRulesForMap_getTerritoryRules_territoryRules_ruleDefinitionSlimCompressed | null;
  color: string | null;
}

export interface GetTerritoryRulesForMap_getTerritoryRules {
  __typename: 'GetTerritoryRulesResponse';
  territoryRules: (GetTerritoryRulesForMap_getTerritoryRules_territoryRules | null)[] | null;
}

export interface GetTerritoryRulesForMap {
  getTerritoryRules: GetTerritoryRulesForMap_getTerritoryRules;
}

export interface GetTerritoryRulesForMapVariables {
  quotaComponentId: number;
  measureId: number;
  sorting: GetTerritoryRulesSortModel;
  territoryGroupId?: number | null;
  startRow?: number | null;
  endRow?: number | null;
  source?: SourceInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTerritoryRulesForSearch
// ====================================================

export interface GetTerritoryRulesForSearch_getTerritoryRules_territoryRules {
  __typename: 'TerritoryRules';
  effectiveDate: any | null;
  ruleId: number | null;
  territoryId: string;
  territoryName: string;
  territoryGroupId: number;
  territoryGroupName: string;
}

export interface GetTerritoryRulesForSearch_getTerritoryRules {
  __typename: 'GetTerritoryRulesResponse';
  territoryRules: (GetTerritoryRulesForSearch_getTerritoryRules_territoryRules | null)[] | null;
  totalCount: number;
}

export interface GetTerritoryRulesForSearch {
  getTerritoryRules: GetTerritoryRulesForSearch_getTerritoryRules;
}

export interface GetTerritoryRulesForSearchVariables {
  quotaComponentId: number;
  measureId: number;
  sorting: GetTerritoryRulesSortModel;
  searchInput?: GetTerritoryRulesSearchInput | null;
  territoryGroupId?: number | null;
  battlecardId?: number | null;
  startRow?: number | null;
  endRow?: number | null;
  completeDefinition?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTerritoryWorkflowCommentThreads
// ====================================================

export interface GetTerritoryWorkflowCommentThreads_getDeploymentModelSpec_territoryWorkflowCommentThreads_items_topLevelComment {
  __typename: 'TerritoryWorkflowComment';
  commentId: number;
  content: string;
  createdAt: number;
  emailAddress: string;
  firstName: string;
  lastName: string;
  memberId: number;
  version: number;
}

export interface GetTerritoryWorkflowCommentThreads_getDeploymentModelSpec_territoryWorkflowCommentThreads_items_threadTags {
  __typename: 'TerritoryWorkflowCommentThreadTag';
  alias: string;
  name: string | null;
  tagId: number;
  threadTagId: number;
  type: TerritoryWorkflowCommentThreadTagType;
}

export interface GetTerritoryWorkflowCommentThreads_getDeploymentModelSpec_territoryWorkflowCommentThreads_items_threadReplies_items {
  __typename: 'TerritoryWorkflowComment';
  commentId: number;
  content: string;
  createdAt: number;
  emailAddress: string;
  firstName: string;
  lastName: string;
  memberId: number;
  version: number;
}

export interface GetTerritoryWorkflowCommentThreads_getDeploymentModelSpec_territoryWorkflowCommentThreads_items_threadReplies {
  __typename: 'PaginatedTerritoryWorkflowComment';
  items: GetTerritoryWorkflowCommentThreads_getDeploymentModelSpec_territoryWorkflowCommentThreads_items_threadReplies_items[];
  totalCount: number | null;
}

export interface GetTerritoryWorkflowCommentThreads_getDeploymentModelSpec_territoryWorkflowCommentThreads_items {
  __typename: 'TerritoryWorkflowCommentThread';
  topLevelComment: GetTerritoryWorkflowCommentThreads_getDeploymentModelSpec_territoryWorkflowCommentThreads_items_topLevelComment;
  updatedAt: number;
  threadId: number;
  threadTags: GetTerritoryWorkflowCommentThreads_getDeploymentModelSpec_territoryWorkflowCommentThreads_items_threadTags[];
  threadReplies: GetTerritoryWorkflowCommentThreads_getDeploymentModelSpec_territoryWorkflowCommentThreads_items_threadReplies;
}

export interface GetTerritoryWorkflowCommentThreads_getDeploymentModelSpec_territoryWorkflowCommentThreads {
  __typename: 'PaginatedTerritoryWorkflowCommentThread';
  items: GetTerritoryWorkflowCommentThreads_getDeploymentModelSpec_territoryWorkflowCommentThreads_items[];
  totalCount: number | null;
}

export interface GetTerritoryWorkflowCommentThreads_getDeploymentModelSpec {
  __typename: 'DeploymentModelDef';
  territoryWorkflowCommentThreads: GetTerritoryWorkflowCommentThreads_getDeploymentModelSpec_territoryWorkflowCommentThreads;
}

export interface GetTerritoryWorkflowCommentThreads {
  getDeploymentModelSpec: GetTerritoryWorkflowCommentThreads_getDeploymentModelSpec | null;
}

export interface GetTerritoryWorkflowCommentThreadsVariables {
  battlecardId: number;
  deploymentModelId: number;
  startRow: number;
  endRow: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTerritoryWorkflowCommentThreadReplies
// ====================================================

export interface GetTerritoryWorkflowCommentThreadReplies_getDeploymentModelSpec_territoryWorkflowCommentThreads_items_threadReplies_items {
  __typename: 'TerritoryWorkflowComment';
  commentId: number;
  createdAt: number;
  content: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  version: number;
  memberId: number;
}

export interface GetTerritoryWorkflowCommentThreadReplies_getDeploymentModelSpec_territoryWorkflowCommentThreads_items_threadReplies {
  __typename: 'PaginatedTerritoryWorkflowComment';
  items: GetTerritoryWorkflowCommentThreadReplies_getDeploymentModelSpec_territoryWorkflowCommentThreads_items_threadReplies_items[];
  totalCount: number | null;
}

export interface GetTerritoryWorkflowCommentThreadReplies_getDeploymentModelSpec_territoryWorkflowCommentThreads_items {
  __typename: 'TerritoryWorkflowCommentThread';
  threadId: number;
  threadReplies: GetTerritoryWorkflowCommentThreadReplies_getDeploymentModelSpec_territoryWorkflowCommentThreads_items_threadReplies;
}

export interface GetTerritoryWorkflowCommentThreadReplies_getDeploymentModelSpec_territoryWorkflowCommentThreads {
  __typename: 'PaginatedTerritoryWorkflowCommentThread';
  items: GetTerritoryWorkflowCommentThreadReplies_getDeploymentModelSpec_territoryWorkflowCommentThreads_items[];
  totalCount: number | null;
}

export interface GetTerritoryWorkflowCommentThreadReplies_getDeploymentModelSpec {
  __typename: 'DeploymentModelDef';
  territoryWorkflowCommentThreads: GetTerritoryWorkflowCommentThreadReplies_getDeploymentModelSpec_territoryWorkflowCommentThreads;
}

export interface GetTerritoryWorkflowCommentThreadReplies {
  getDeploymentModelSpec: GetTerritoryWorkflowCommentThreadReplies_getDeploymentModelSpec | null;
}

export interface GetTerritoryWorkflowCommentThreadRepliesVariables {
  battlecardId: number;
  deploymentModelId: number;
  startRow: number;
  endRow: number;
  threadId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTerritoryWorkflowStatus
// ====================================================

export interface GetTerritoryWorkflowStatus_getDeploymentModelSpec {
  __typename: 'DeploymentModelDef';
  isTerritoryWorkflowStarted: string | null;
}

export interface GetTerritoryWorkflowStatus {
  getDeploymentModelSpec: GetTerritoryWorkflowStatus_getDeploymentModelSpec | null;
}

export interface GetTerritoryWorkflowStatusVariables {
  deploymentModelId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUnassignedActivities
// ====================================================

export interface GetUnassignedActivities_getUnassignedActivities {
  __typename: 'TerritoryActivities';
  territoryActivitiesList: (any | null)[] | null;
  territoryActivityCount: number | null;
}

export interface GetUnassignedActivities {
  getUnassignedActivities: GetUnassignedActivities_getUnassignedActivities;
}

export interface GetUnassignedActivitiesVariables {
  battlecardId: number;
  quotaComponentId: number;
  startRow?: number | null;
  endRow?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserSpec
// ====================================================

export interface GetUserSpec_getUserSpec_membershipSpecs_planningCycles_deploymentModels {
  __typename: 'DeploymentModelSpec';
  deploymentModelId: number;
  deploymentModelType: DeploymentModelTypeEnum;
  deploymentModelName: string;
  isPublished: boolean;
}

export interface GetUserSpec_getUserSpec_membershipSpecs_planningCycles {
  __typename: 'PlanningCycleSpec';
  planningCycleId: number;
  planningCycleName: string;
  planningCycleStartDate: any;
  planningCycleDuration: number;
  planningCyclePeriodicity: string;
  planningCycleArchived: boolean;
  planningCycleSlug: string | null;
  currencyCode: string | null;
  deploymentModels: (GetUserSpec_getUserSpec_membershipSpecs_planningCycles_deploymentModels | null)[] | null;
}

export interface GetUserSpec_getUserSpec_membershipSpecs {
  __typename: 'MembershipSpec';
  memberId: number | null;
  tenantId: number | null;
  tenantGlobalId: string;
  tenantName: string;
  tenantSlug: string;
  systemRoleName: string | null;
  planningCycles: (GetUserSpec_getUserSpec_membershipSpecs_planningCycles | null)[] | null;
}

export interface GetUserSpec_getUserSpec {
  __typename: 'MemberSpec';
  subjectId: string;
  emailAddress: string;
  firstName: string | null;
  lastName: string | null;
  passwordUpdatedAt: any | null;
  isSupportUser: boolean | null;
  membershipSpecs: (GetUserSpec_getUserSpec_membershipSpecs | null)[] | null;
}

export interface GetUserSpec {
  getUserSpec: GetUserSpec_getUserSpec | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserTenant
// ====================================================

export interface GetUserTenant_getUserSpec_membershipSpecs {
  __typename: 'MembershipSpec';
  memberId: number | null;
  tenantId: number | null;
  tenantGlobalId: string;
  tenantName: string;
  tenantSlug: string;
}

export interface GetUserTenant_getUserSpec {
  __typename: 'MemberSpec';
  subjectId: string;
  emailAddress: string;
  firstName: string | null;
  lastName: string | null;
  passwordUpdatedAt: any | null;
  isSupportUser: boolean | null;
  membershipSpecs: (GetUserTenant_getUserSpec_membershipSpecs | null)[] | null;
}

export interface GetUserTenant {
  getUserSpec: GetUserTenant_getUserSpec | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RecolorTerritory
// ====================================================

export interface RecolorTerritoryInput {
  ruleId: number;
  color: string;
}

export interface RecolorTerritory_recolorTerritory {
  __typename: 'RecolorTerritoryOutput';
}

export interface RecolorTerritory {
  recolorTerritory: RecolorTerritory_recolorTerritory;
}

export interface RecolorTerritoryVariables {
  input: RecolorTerritoryInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWorkflows
// ====================================================

export interface GetWorkflows_getWorkflows_workflows_territoryGroups {
  __typename: 'TerritoryGroupOwnerWorkflows';
  approverEmail: string | null;
  approverFirstName: string | null;
  approverId: number | null;
  approverLastName: string | null;
  children: (number | null)[] | null;
  ownerEmail: string | null;
  ownerFirstName: string | null;
  ownerLastName: string | null;
  status: string | null;
  territoryGroupId: number | null;
  territoryGroupName: string | null;
  territoryGroupOwnerId: number | null;
  territoryGroupParentId: number | null;
  territoryGroupWorkflowId: number | null;
}

export interface GetWorkflows_getWorkflows_workflows {
  __typename: 'BattlecardWorkflows';
  approverEmail: string | null;
  approverId: number | null;
  battlecardId: number | null;
  battlecardName: string | null;
  battlecardOwnerId: number | null;
  battlecardParentId: number | null;
  battlecardWorkflowId: number | null;
  children: (number | null)[] | null;
  ownerEmail: string | null;
  status: string | null;
  ownerLastName: string | null;
  ownerFirstName: string | null;
  approverLastName: string | null;
  approverFirstName: string | null;
  territoryGroups: (GetWorkflows_getWorkflows_workflows_territoryGroups | null)[] | null;
}

export interface GetWorkflows_getWorkflows {
  __typename: 'GetWorkflowsResponse';
  completionRate: number | null;
  workflowInitiated: boolean | null;
  workflows: (GetWorkflows_getWorkflows_workflows | null)[] | null;
}

export interface GetWorkflows {
  getWorkflows: GetWorkflows_getWorkflows | null;
}

export interface GetWorkflowsVariables {
  deploymentModelId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PlanningCycleExistsByName
// ====================================================

export interface PlanningCycleExistsByName_planningCycleExistsByName {
  __typename: 'PlanningCycleExistsOutput';
  exists: boolean;
  slugName: string;
}

export interface PlanningCycleExistsByName {
  planningCycleExistsByName: PlanningCycleExistsByName_planningCycleExistsByName | null;
}

export interface PlanningCycleExistsByNameVariables {
  tenantId: number;
  planningCycleName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RunFullCoinsort
// ====================================================

export interface RunFullCoinsort_runFullCoinsort {
  __typename: 'CoinsortOutput';
  messageId: string;
  jobId: string | null;
}

export interface RunFullCoinsort {
  runFullCoinsort: RunFullCoinsort_runFullCoinsort | null;
}

export interface RunFullCoinsortVariables {
  battlecardId: number;
  quotaComponentId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SearchMember
// ====================================================

export interface SearchMember_searchMember_memberList {
  __typename: 'Member';
  customProperties: any | null;
  deleted: string | null;
  emailAddress: string;
  employeeId: string | null;
  firstName: string;
  jobTitle: string | null;
  memberCreatedAt: any | null;
  lastName: string;
  memberId: number;
  memberUpdatedAt: any | null;
  systemRoleName: string;
  tenantId: number;
}

export interface SearchMember_searchMember {
  __typename: 'GetMemberListOutput';
  totalCount: number;
  memberList: (SearchMember_searchMember_memberList | null)[] | null;
}

export interface SearchMember {
  searchMember: SearchMember_searchMember | null;
}

export interface SearchMemberVariables {
  tenantId: number;
  searchString: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TenantExistsByName
// ====================================================

export interface TenantExistsByName_tenantExistsByName {
  __typename: 'TenantExistsOutput';
  exists: boolean;
  slugName: string;
}

export interface TenantExistsByName {
  tenantExistsByName: TenantExistsByName_tenantExistsByName | null;
}

export interface TenantExistsByNameVariables {
  tenantName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PeriodicMeasuresFragment
// ====================================================

export interface PeriodicMeasuresFragment_measureValue_years_halves_quarters_months {
  __typename: 'PmvMonth';
  month: string;
  date: any;
  value: number;
}

export interface PeriodicMeasuresFragment_measureValue_years_halves_quarters {
  __typename: 'PmvQuarter';
  quarter: QuarterIdentifier;
  date: any;
  value: number;
  months: PeriodicMeasuresFragment_measureValue_years_halves_quarters_months[] | null;
}

export interface PeriodicMeasuresFragment_measureValue_years_halves {
  __typename: 'PmvHalf';
  half: HalfIdentifier;
  date: any;
  value: number;
  quarters: PeriodicMeasuresFragment_measureValue_years_halves_quarters[] | null;
}

export interface PeriodicMeasuresFragment_measureValue_years {
  __typename: 'PmvYear';
  year: number;
  date: any;
  value: number;
  halves: PeriodicMeasuresFragment_measureValue_years_halves[] | null;
}

export interface PeriodicMeasuresFragment_measureValue {
  __typename: 'PeriodicMeasureValue';
  measureFieldType: string | null;
  textValue: string | null;
  type: string | null;
  value: number;
  years: PeriodicMeasuresFragment_measureValue_years[];
}

export interface PeriodicMeasuresFragment {
  __typename: 'PeriodicMeasure';
  measureId: number;
  measureName: string;
  editable: boolean | null;
  measureValue: PeriodicMeasuresFragment_measureValue;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountQuotaAdjustmentType {
  AccountMove = 'AccountMove',
  Original = 'Original'
}

export enum AccountQuotaRedirectDirection {
  from = 'from',
  to = 'to'
}

export enum AccountRuleHeaderEnum {
  NUMBER = 'NUMBER',
  STRING = 'STRING'
}

export enum ApiTokenStatus {
  failed = 'failed',
  succeeded = 'succeeded'
}

export enum BCInfoLevelEnum {
  all = 'all',
  hidden = 'hidden',
  territoryGroupOwner = 'territoryGroupOwner',
  territoryGroupTypeOwner = 'territoryGroupTypeOwner',
  territoryOwner = 'territoryOwner'
}

export enum BCMapLevelEnum {
  stateProvince = 'stateProvince',
  undecided = 'undecided',
  zipPostal = 'zipPostal'
}

export enum ChatRoleEnum {
  ai = 'ai',
  human = 'human'
}

export enum CompressedRuleDefinitionResponseEnum {
  NewRuleDefinitionResponse = 'NewRuleDefinitionResponse'
}

export enum ConstraintOperator {
  isEqualTo = 'isEqualTo',
  isGreaterThanOrEqualTo = 'isGreaterThanOrEqualTo',
  isLessThanOrEqualTo = 'isLessThanOrEqualTo'
}

export enum CustomHierarchyQuantifierEnum {
  ALL = 'ALL',
  NONE = 'NONE',
  SINGULAR = 'SINGULAR'
}

export enum DeploymentModelTypeEnum {
  Manage = 'Manage',
  Plan = 'Plan'
}

export enum ExportDataStatusEnum {
  cancelled = 'cancelled',
  completed = 'completed',
  exporting = 'exporting',
  failed = 'failed'
}

export enum FileProcessType {
  territoryOptimization = 'territoryOptimization'
}

export enum FileTypeEnum {
  Activity = 'Activity',
  CustomHierarchy = 'CustomHierarchy',
  CustomerAccountHierarchy = 'CustomerAccountHierarchy',
  Location = 'Location',
  Participant = 'Participant',
  SellerAssignment = 'SellerAssignment',
  Territory = 'Territory',
  TerritoryOptimization = 'TerritoryOptimization',
  TerritoryQuota = 'TerritoryQuota',
  AccountRedirect = 'AccountRedirect'
}

export enum GetHierarchyPolygonIdOutputCompressedEnum {
  GetHierarchyPolygonIdOutput = 'GetHierarchyPolygonIdOutput'
}

export enum HalfIdentifier {
  H1 = 'H1',
  H2 = 'H2'
}

export enum LookupTypeEnum {
  Custom = 'Custom',
  Ramp = 'Ramp',
  Seasonality = 'Seasonality'
}

export enum MapLevel {
  city = 'city',
  country = 'country',
  district = 'district',
  stateProvince = 'stateProvince',
  zipPostal = 'zipPostal'
}

export enum MappingFieldDataTypeEnum {
  Currency = 'Currency',
  Date = 'Date',
  Number = 'Number',
  Percentage = 'Percentage',
  Text = 'Text'
}

export enum PCJobType {
  DEPLOYMENT_MODEL_CLONE = 'DEPLOYMENT_MODEL_CLONE',
  DEPLOYMENT_MODEL_DELETE = 'DEPLOYMENT_MODEL_DELETE',
  PLANNING_CYCLE_CLONE = 'PLANNING_CYCLE_CLONE',
  START_DATE_CHANGE = 'START_DATE_CHANGE'
}

export enum QuarterIdentifier {
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4'
}

export enum QuotaDistributionHierarchyTypeEnum {
  Custom = 'Custom',
  CustomerAccount = 'CustomerAccount'
}

export enum RebalanceHierarchyInputTypesEnum {
  CustomerAccountHierarchy = 'CustomerAccountHierarchy',
  GeographicTerritoryHierarchy = 'GeographicTerritoryHierarchy'
}

export enum SortDirection {
  asc = 'asc',
  desc = 'desc'
}

export enum SortableGetTerritoryRulesGridCols {
  measureValue = 'measureValue',
  territoryId = 'territoryId',
  territoryName = 'territoryName'
}

export enum SourceTypeEnum {
  territoryOptimization = 'territoryOptimization'
}

export enum SystemRoleName {
  Administrator = 'Administrator',
  Contributor = 'Contributor'
}

export enum TGTAccountPinsCompressedEnum {
  TGTAccountPins = 'TGTAccountPins'
}

export enum TargetRuleComponent {
  Dimension = 'Dimension',
  Modifier = 'Modifier',
  SimpleDimensionOrModifier = 'SimpleDimensionOrModifier',
  SimpleDimensionOrThrow = 'SimpleDimensionOrThrow'
}

export enum TerritoryRuleBase {
  customerAccount = 'customerAccount',
  geographic = 'geographic'
}

export enum TerritoryWorkflowCommentThreadTagType {
  TerritoryGroup = 'TerritoryGroup',
  TerritoryRule = 'TerritoryRule'
}

export enum VisualizationTerritoryGroupTypeEnum {
  CustomerAccountHierarchy = 'CustomerAccountHierarchy',
  GeographicTerritoryHierarchy = 'GeographicTerritoryHierarchy'
}

export interface AccountRedirectInput {
  accountId: number;
  sourceRuleId: number;
  targets: AccountRedirectTarget[];
  redirectIdsToRemove: number[];
}

export interface AccountRedirectReallocationField {
  primaryFieldId: number;
  fieldId: number;
}

export interface AccountRedirectTarget {
  targetRuleId?: number | null;
  startDate: any;
  endDate: any;
  reallocationField?: AccountRedirectReallocationField | null;
}

export interface AddUserInput {
  invitationId: string;
}

export interface AllocateTopDownJobInput {
  battlecardId: number;
  quotaComponentId: number;
}

export interface BCAssociation {
  battlecardId: number;
  territoryGroupId?: number | null;
}

export interface BCMapVisualizationSettingsInput {
  territoryGroupType: VisualizationTerritoryGroupTypeEnum;
  customHierarchyQuantifier: CustomHierarchyQuantifierEnum;
  customHierarchyId?: number | null;
}

export interface BattlecardFactorInput {
  numFactorsDisplay: number;
  factors: (FactorInput | null)[];
}

export interface BattlecardHierarchyInput {
  hierarchyTopId: number;
  hierarchyType: string;
  hierarchyName?: string | null;
  hierarchyAlias?: string | null;
  precedence?: number | null;
  memberId?: number | null;
  groupFactors?: BattlecardFactorInput | null;
}

export interface BattlecardHierarchyUpsert {
  currentHierarchyTopId?: number | null;
  hierarchyInput: BattlecardHierarchyInput;
}

export interface CancelSymonPipeConfigurationInput {
  symonPipeConfigurationId: number;
  runId: string;
}

export interface ChangeHistoryInput {
  planningCycleId: number;
  deploymentModelId?: number | null;
  eventCategories?: (string | null)[] | null;
}

export interface CleanFileProcessInput {
  fileId?: string | null;
  territoryGroupId?: number | null;
  forceDelete?: boolean | null;
}

export interface CommentThreadRepliesInput {
  startRow?: number | null;
  endRow?: number | null;
}

export interface CommentThreadsFilterInput {
  territoryRuleId?: number | null;
  territoryGroupId?: number | null;
}

export interface CommentThreadsInput {
  startRow?: number | null;
  endRow?: number | null;
  threadId?: number | null;
  threadReplies?: CommentThreadRepliesInput | null;
  filter?: CommentThreadsFilterInput | null;
}

export interface CountrySourceKeyInput {
  country: string;
  sourceKey: string;
}

export interface DeleteSymonPipeConfigurationsInput {
  symonPipeConfigurationIds: number[];
}

export interface DeploymentModelActionInput {
  actionName: string;
  deploymentModelId: number;
}

export interface ExchangeTemporaryAuthTokenInput {
  tenantSlug: string;
}

export interface ExportDataInput {
  planningCycleId: number;
  tableName: string;
  spInternalInput?: SPInternalInput | null;
}

export interface FactorInput {
  factorId?: number | null;
  rootHierarchyId: number;
  rootHierarchyType: string;
  precedence: number;
}

export interface FieldNameInput {
  fieldName?: string | null;
  value?: ValueInput | null;
}

export interface FileProcessConfig {
  measureId?: number | null;
  territoryRuleBase?: TerritoryRuleBase | null;
  constraints?: FileProcessConfigConstraint | null;
}

export interface FileProcessConfigConstraint {
  territories?: (FileProcessConstraint | null)[] | null;
  accounts?: (FileProcessConstraint | null)[] | null;
}

export interface FileProcessConstraint {
  metric: string;
  operator: ConstraintOperator;
  value: number;
}

export interface FileProcessInput {
  planningCycleId: number;
  territoryGroupId?: number | null;
  quotaComponentId?: number | null;
  processType: FileProcessType;
  config: FileProcessConfig;
}

export interface GenerateAPIKeyInput {
  emailAddress: string;
}

export interface GenerateImpersonationTokenInput {
  emailAddress: string;
}

export interface GetAccountRuleBindingsInput {
  territoryGroupTypeId?: number | null;
  territoryGroupId?: number | null;
  quotaComponentId?: number | null;
  startRow: number;
  endRow: number;
  filters?: any | null;
}

export interface GetAccountRuleGridInput {
  battlecardId: number;
  territoryGroupTypeId?: number | null;
  quotaComponentId: number;
  startRow: number;
  endRow: number;
  filters?: any | null;
  sorting?: SortModel | null;
}

export interface GetDeploymentModelSpecInput {
  deploymentModelId: number;
  battlecardId?: number | null;
  sheetId?: number | null;
  reportingCurrency?: boolean | null;
  quotaComponentId?: number | null;
  planningCycle?: PlanningCycleFilterInput | null;
  territoryGroupId?: number | null;
  territoryWorkflowCommentThreads?: CommentThreadsInput | null;
  isTQM?: boolean | null;
}

export interface GetExportDataStatusInput {
  exportId: string;
}

export interface GetFileProcessInput {
  fileIds?: (string | null)[] | null;
  territoryGroupIds?: (number | null)[] | null;
}

export interface GetHierarchyFilterModel {
  hierarchyName?: string | null;
  filter?: (string | null)[] | null;
}

export interface GetJobsInput {
  objectTypes?: string[] | null;
  ids?: string[] | null;
  isActive?: boolean | null;
  planningCycleId?: number | null;
  exclusiveStartKey?: any | null;
  limit?: number | null;
}

export interface GetRangeFieldInput {
  deploymentModelId: number;
  ruleId: number;
  rangeFieldName: string;
}

export interface GetTerritoryMeasuresGridFilter {
  territoryId?: string | null;
  ruleId?: number | null;
  factorPath?: string | null;
}

export interface GetTerritoryQuotaFilter {
  ruleId: number;
}

export interface GetTerritoryQuotaInput {
  battlecardId?: number | null;
  territoryGroupId?: number | null;
  quotaComponentId: number;
  startRow?: number | null;
  endRow?: number | null;
  sheetId?: number | null;
  filter?: GetTerritoryQuotaFilter | null;
  filters?: any | null;
  sorting?: SortModel | null;
  ruleId?: number | null;
}

export interface GetTerritoryRulesSearchInput {
  searchString?: string | null;
  filters?: any | null;
  definitionFilters?: any | null;
}

export interface GetTerritoryRulesSortModel {
  colId: SortableGetTerritoryRulesGridCols;
  sort?: SortDirection | null;
}

export interface HierarchyTarget {
  hierarchyId: number;
  targetValue: number;
}

export interface IDataSheetDef {
  sheetId?: number | null;
  sheetName?: string | null;
  sheetType?: string | null;
  battlecardAssociations?: (BCAssociation | null)[] | null;
  quotaComponentAssociations?: (number | null)[] | null;
  sheetDefinitions?: (ISheetDefinitionDef | null)[] | null;
}

export interface ISheetDefinitionDef {
  sheetDefinitionId?: number | null;
  sheetDefinitionType?: string | null;
  measureId?: number | null;
  measureCategory?: string | null;
  measureName: string;
  measureSource?: string | null;
  measureFieldType?: string | null;
  measureFormatType?: string | null;
  measureFormula?: string | null;
  measureConstraints?: string | null;
  fieldId?: string | null;
  fieldName?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  quotaComponents?: (ISheetQuotaComponentDef | null)[] | null;
}

export interface ISheetQuotaComponentDef {
  quotaComponentId?: number | null;
  roles?: (ISheetRoleDef | null)[] | null;
}

export interface ISheetRoleDef {
  sheetACLId?: number | null;
  roleId?: number | null;
  roleName?: string | null;
  editable?: string | null;
  visible?: string | null;
}

export interface InheritedRule {
  ruleId?: number | null;
  territoryId?: string | null;
  territoryName?: string | null;
}

export interface InviteUserInput {
  tenantId?: number | null;
  emailAddress: string;
  firstName: string;
  lastName: string;
  systemRoleName?: SystemRoleName | null;
}

export interface JobInput {
  jobKey?: string | null;
  pcCloneJobInput?: PCCloneJobInput | null;
  territoryRuleDeleteJobInput?: TerritoryRuleDeleteJobInput | null;
  allocateTopDownJobInput?: AllocateTopDownJobInput | null;
}

export interface LocalCurrencyInput {
  localCurrencyCode: string;
  conversionRate: number;
}

export interface LookupFilter {
  lookupTypes?: LookupTypeEnum[] | null;
}

export interface LookupInput {
  lookupId?: string | null;
  lookupType?: LookupTypeEnum[] | null;
}

export interface MeasureFilterInput {
  measureFieldType?: string | null;
  measureSource?: string | null;
}

export interface NewRuleDefinitionResponse {
  base?: RuleIncExcGroup | null;
  modifiers?: RuleIncExcGroup | null;
}

export interface Owner {
  ownerId?: number | null;
  memberId: number;
  ownership: number;
  effectiveStartDate?: any | null;
  effectiveEndDate?: any | null;
}

export interface PCCloneJobInput {
  jobType: PCJobType;
  planningCycleId: number;
  deploymentModelId?: number | null;
}

export interface PinSetInput {
  pinSetId?: number | null;
  battlecardId: number;
  locationGroupId: number;
  pinSetName: string;
  icon: string;
  color: string;
}

export interface PlanningCycleFilterInput {
  measureFilter?: MeasureFilterInput | null;
}

export interface PublishFileFromSymonS3Input {
  planningCycleId: number;
  pipeId: string;
  exportId: string;
}

export interface QuotaDistributionHierarchyInput {
  hierarchyRootId: number;
  isAllocationTarget: boolean;
}

export interface RangeFieldInput {
  rangeId?: number | null;
  effectiveStartDate?: any | null;
  effectiveEndDate?: any | null;
  sheetId: number;
}

export interface ReassignRuleHierarchiesInput {
  scopingTerritoryGroupId: number;
  targetRuleId: number;
  sourceHierarchyIds: number[];
  targetRuleComponent?: TargetRuleComponent | null;
  ruleIdsFilter?: number[] | null;
}

export interface RebalanceTerritoryInput {
  sourceRuleId: number;
  targetRuleId: number;
  hierarchyType: RebalanceHierarchyInputTypesEnum;
  hierarchies: number[];
}

export interface RequestAllocateTopDownInput {
  battlecardId: number;
  territoryGroupId?: number | null;
  measureId: number;
  quotaComponentId: number;
  amount?: number | null;
  clearQuotaAdjustment?: boolean | null;
  measureIdToProrateBy?: number | null;
  hierarchyTarget?: HierarchyTarget[] | null;
  preserveSeasonality?: boolean | null;
}

export interface RequestDeleteTerritoryIncExcGroup {
  inclusions: number[];
  exclusions: number[];
}

export interface RequestDeleteTerritoryRulesInput {
  deploymentModelId: number;
  battlecardId?: number | null;
  territoryGroupId?: number | null;
  ruleIds: RequestDeleteTerritoryIncExcGroup;
}

export interface RuleContains {
  hierarchyId: number;
  key?: string | null;
  name?: string | null;
  inheritedFrom?: number | null;
}

export interface RuleIncExc {
  id?: string | null;
  hierarchyType: string;
  rootHierarchyName?: string | null;
  rootHierarchyId?: number | null;
  rootHierarchyType?: string | null;
  contains: RuleContains[];
  containsTotal?: number;
}

export interface RuleIncExcGroup {
  inclusions?: (RuleIncExc | null)[] | null;
  exclusions?: (RuleIncExc | null)[] | null;
}

export interface SPInternalInput {
  territoryQuotaTemplateInput?: TerritoryQuotaTemplateInput;
  territoryGroupId?: number;
}

export interface SaveSymonCredentialsInput {
  tenantId: number;
  symonDomain?: string | null;
  symonUiClientKey?: string | null;
}

export interface SearchHierarchyInput {
  searchString: string;
  hierarchyType: string;
  limit?: number | null;
  rootHierarchyId?: number | null;
  getTree?: boolean | null;
  battlecardId?: string | null;
  includeDetails?: boolean | null;
  startRow?: number | null;
  endRow?: number | null;
}

export interface SortModel {
  sortModel?: (SortModelItem | null)[] | null;
}

export interface SortModelItem {
  colId?: string | null;
  sort?: SortDirection | null;
}

export interface SourceInput {
  sourceType: SourceTypeEnum;
  optimizationPipeUuid?: string | null;
}

export interface TGTAggregationInput {
  battlecardId: number;
  quotaComponentId: number;
  territoryGroupId: number;
  geographyIds?: number[] | null;
  measureId: number;
  startRow?: number | null;
  endRow?: number | null;
}

export interface TableInput {
  startRow?: number | null;
  endRow?: number | null;
  uploadFilter?: UploadFilter | null;
  lookupFilter?: LookupFilter | null;
}

export interface TeamInput {
  teamId: number;
}

export interface TenantAddInput {
  invitationId: number;
  tenantName: string;
  auth0OrgId?: string | null;
}

export interface TerritoryFieldValuesInput {
  tmvId?: number | null;
  territoryId?: string | null;
  territoryGroupId?: number | null;
  territoryOwnerId?: number | null;
  battlecardId?: number | null;
  fieldId?: number | null;
  quotaComponentId?: number | null;
  factors?: any | null;
  factorPath?: string | null;
  adate?: any | null;
  ruleId?: number | null;
  fieldValue?: string | null;
  isTQM?: boolean | null;
  hierarchyId?: number | null;
  rangeField?: RangeFieldInput | null;
}

export interface TerritoryGroupOwnerInput {
  tenantId: number;
  territoryGroupId: number;
  memberId: number;
}

export interface TerritoryMeasureValueInput {
  tmvId?: number | null;
  territoryId?: string | null;
  territoryGroupId?: number | null;
  battlecardId?: number | null;
  measureId?: number | null;
  quotaComponentId?: number | null;
  measureValue?: number | null;
  factors?: any | null;
  factorPath?: string | null;
  adate?: any | null;
  ruleId?: number | null;
  customerAccountHierarchyId?: number | null;
  customHierarchyId_1?: number | null;
  customHierarchyId_2?: number | null;
}

export interface TerritoryOptimizationPublishInput {
  pipeUuid: string;
}

export interface TerritoryQuotaTemplateInput {
  battlecardId: number;
  quotaComponentId: number;
}

export interface TerritoryRuleDeleteJobInput {
  jobKey: string;
}

export interface UnassignRuleHierarchiesInput {
  scopingTerritoryGroupId: number;
  sourceHierarchyIds: number[];
  targetRuleComponent?: TargetRuleComponent | null;
  ruleIdsFilter?: number[] | null;
}

export interface UploadFilter {
  fileTypes?: FileTypeEnum[] | null;
  statuses?: string[] | null;
}

export interface UpsertBattlecardInput {
  deploymentModelId: number;
  battlecardId?: number | null;
  battlecardName?: string | null;
  battlecardType?: string | null;
  battlecardParentId?: number | null;
  currencyCode?: string | null;
  newBusinessTarget?: number | null;
  padding?: number | null;
  quotaComponent?: (number | null)[] | null;
  fileIds?: (string | null)[] | null;
  teams?: (TeamInput | null)[] | null;
  hierarchies?: (BattlecardHierarchyInput | null)[] | null;
  memberId?: number | null;
  reportingCurrency?: boolean | null;
  mapLevel?: BCMapLevelEnum | null;
  quotaDistributionHierarchies?: QuotaDistributionHierarchyInput[] | null;
  mapVisualizationSettings?: BCMapVisualizationSettingsInput | null;
}

export interface UpsertConversionRatesInput {
  planningCycleId: number;
  reportingCurrencyCode: string;
  localCurrencies?: (LocalCurrencyInput | null)[] | null;
}

export interface UpsertGeoHierarchySelectionInput {
  planningCycleId: number;
  selectionBySourceKey?: CountrySourceKeyInput[] | null;
}

export interface UpsertLookupInput {
  lookupId: string;
  rowJson: any;
}

export interface UpsertMappingInput {
  fileId: string;
  fieldNames?: FieldNameInput[] | null;
  planningCycleId?: number | null;
  fileType?: FileTypeEnum | null;
  rootHierarchyId?: number | null;
  territoryOptimization?: TerritoryOptimizationPublishInput | null;
  battlecardId?: number | null;
}

export interface UpsertMemberInput {
  emailAddress: string;
  firstName: string;
  lastName: string;
  employeeId?: string | null;
  jobTitle?: string | null;
}

export interface UpsertRolePermissionInput {
  roleName: string;
  planningCycleId: number;
  actions: DeploymentModelActionInput[];
}

export interface UpsertSheetInput {
  deploymentModelId?: number | null;
  dataSheets: (IDataSheetDef | null)[];
}

export interface UpsertSymonPipeConfigurationInput {
  symonPipeConfigurationId?: number | null;
  pipeId: string;
  pipeName: string;
  exportId: string;
  exportName: string;
  planningCycleId: number;
  tableNameLabel: string;
  fileType: FileTypeEnum;
  properties?: (FieldNameInput | null)[] | null;
  hierarchyId?: number | null;
}

export interface UpsertTerritoryQuotaCommentInput {
  quotaComponentId: number;
  territoryGroupId: number;
  territoryId: string;
  factorPath: string;
  comment: string;
  ruleId?: number | null;
}

export interface UserTenantAddInput {
  invitationId: string;
}

export interface ValueInput {
  id?: string | null;
  type?: string | null;
  order?: number | null;
}

export interface WriteFileToDBInput {
  fileId: string;
  isFileUploaded: boolean;
}

export interface WriteLocationFileToDBInput {
  locationGroupName: string;
  fileId: string;
  isFileUploaded: boolean;
}

export interface checkSymonConnectivityInput {
  symonDomain: string;
  symonUiClientKey: string;
}

export interface reassignTerritoriesInput {
  territoryGroupId?: number | null;
  territoryRuleIdList?: (number | null)[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
