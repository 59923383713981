/* allow anonymous default export */
/* eslint-disable import/no-anonymous-default-export */

/* disallow template strings in messages file */
/* eslint quotes: ["error", "single", { "avoidEscape": true }] */

/* messages from this file should not be imported directly */
export default {
  VARICENT_SP: 'Varicent Sales Planning',
  SALES_PLANNING: 'Sales Planning',
  PLANNING_CYCLES: 'Planning cycles',
  TEXT_FILTERS: 'Text filters',
  NEW_CYCLE: 'New cycle',
  DATA: 'Data',
  CREATED_WITH_DATE: 'Created: {date}',
  DELETE: 'Delete',
  NEW: 'New',
  RUN: 'Run',
  NEW_PLANNING_CYCLE: 'New planning cycle',
  PLANNING_CYCLE: 'Planning cycle',
  DONT_SAVE: "Don't save",
  SAVE: 'Save',
  CANCEL: 'Cancel',
  VISUALIZE: 'Visualize',
  VISUALIZATION_SETTINGS_DIALOG_EXPLANATION: 'Choose the types of territory rules you want to see on the map.',
  VISUALIZATION_SETTINGS_DIALOG_WARNING: 'Some actions are unavailable when viewing complex rules.',
  BACK: 'Back',
  COMPLETE: 'Complete',
  UNTITLED: 'Untitled',
  START_DATE_REQUIRED_MARK: 'Start date*',
  START_DATE_MONTH_REQUIRED_MARK: 'Start date (month) *',
  DATE_FORMAT_PLACEHOLDER: 'MM/DD/YYYY',
  INTERVAL_CYCLE_REQUIRED_MARK: 'Interval cycle *',
  PERIODICITY: 'Periodicity',
  PLAN_DURATION: 'Plan duration',
  CREATE: 'Create',
  CREATE_USER_PROFILE: 'Create user profile',
  SOMETHING_WENT_WRONG: 'Something went wrong',
  BULK_INVITE_ERROR_MESSAGE: 'We were not able to send any invites. Please try again',
  INVITE_EXCEEDS_BATCH_LIMIT: 'Cannot invite more than 100 users at a time.',
  ENTER_UNIQUE_EMAIL: 'Please enter a unique email.',
  USER_CREATION_FAILED: 'User creation failed',
  USER_CREATION_FAILED_MESSAGE:
    "Something went wrong and we weren't able to create the user profile. Please try again.",
  MAP_CUSTOM_HIERARCHY_FILTER_CALLOUT_MESSAGE: "Map edits can only be made when filtering by 'is equal to'",
  CHOSEN_CUSTOM_HIERARCHY: '{hierarchy} hierarchy',
  RULE_VIZ_SETTINGS_DIALOG_TITLE: 'Rule visualization settings',
  RULE_VIZ_SETTINGS_DIALOG_BODY_DESCRIPTION: 'Select what type of rules to visualize',
  RULE_VIZ_SETTINGS_DIALOG_RULE_TYPE_LABEL: '{ruleType}-based rules only',
  RULE_VIZ_SETTINGS_DIALOG_BODY_HEADER:
    'Select a territory group type for the corresponding rule type you would like to visualize.',
  USER_PROFILE_ADDED: 'User profile added',
  USER_PROFILE_CREATION_SUCCESS: 'We added {name} to your users.',
  ACCESS_GRANTED_TITLE: '{systemRoleName} access granted',
  INVITATION_SENT: 'An invitation has been sent.',
  INVITATIONS_SENT: 'Invitations have been sent to {count} users.',
  ENTER_EMPLOYEE_ID: 'Please enter an employee ID.',
  ENTER_FIRST_NAME: 'Please enter a first name.',
  ENTER_LAST_NAME: 'Please enter a last name.',
  ENTER_EMAIL: 'Please enter an email.',
  ROLE_REQUIRED: 'Role *',
  CONTRIBUTOR_INVITE_CALLOUT: 'This user will be granted contributor access. An invitation will be sent.',
  ADMIN_INVITE_CALLOUT: 'This user will be granted administrator access. An invitation will be sent.',
  AN_ERROR_OCCURRED: 'An error occurred.',
  PLANNING_CYCLE_ERROR_EMPTY: 'Enter a title for your planning cycle.',
  PLANNING_CYCLE_ERROR: 'Unable to retrieve your planning cycles from the server.',
  PLANNING_CYCLE_COPY_PROGRESS_DESCRIPTION:
    "We're copying your {name} planning cycle. Please don't make changes to your planning cycle until this process completes",
  PLANNING_CYCLE_PROGRESS_HEADING: 'Planning cycle copy started',
  PLANNING_CYCLE_COPIED: 'Planning cycle copied',
  PLANNING_CYCLE_COPIED_DESCRIPTION: 'We copied your {pcName} planning cycle.',
  PLANNING_CYCLE_COPIED_FAILED: 'Planning cycle copy has failed',
  PLANNING_CYCLE_START_DATE_PROGRESS_HEADING: 'Planning cycle saving in progress',
  PLANNING_CYCLE_START_DATE_PROGRESS_DESCRIPTION:
    "We're saving changes to your {name} planning cycle. Please don't make changes to your planning cycle until this process completes",
  CREATE_NEW_CYCLE_ERROR: 'Unable to create a new planning cycle.',
  CREATE_NEW_CYCLE_SUCCESS: 'Created your new planning cycle.',
  NUM_OF_MONTHS: 'Number of months',
  BATTLECARD_CANVAS_ERROR: 'Unable to retrieve your battle cards from the server.',
  BATTLECARD_ERROR: 'Unable to retrieve your battle card from the server.',
  CREATE_NEW_BATTLECARD_ERROR: 'Unable to create a new battle card.',
  CREATE_NEW_BATTLECARD_SUCCESS: 'Created your new battle card.',
  UPDATE_BATTLECARD_SUCCESS: 'Updated your battle card.',
  UPDATE_BATTLECARD_ERROR: 'Unable to update your battle card.',
  UPDATE_BATTLECARD_OWNER_ERROR: 'Unable to update your battle card owner.',
  UPDATE_ACTIVITY_FILES_ERROR: 'Unable to update your battle card activity files.',
  UPDATE_QUOTA_COMPONENT_ERROR: 'Unable to update battle card quota component.',
  REMOVE_QUOTA_COMPONENT_ERROR: "You must select quota component(s) assigned to this battle card's parent.",
  DISALLOW_REMOVE_QUOTA_COMPONENT:
    'You must remove the quota components referenced in the primary or roll-up child battle card(s) first before it can be removed from the parent battle card.',
  UPDATE_HIERARCHY_TOP_ERROR: 'Unable to update hierarchy top.',
  UPDATE_NEW_BUSINESS_TARGET_SUCCESS: 'Updated new business target.',
  UPDATE_NEW_BUSINESS_TARGET_ERROR: 'Unable to update new business target in your battle card.',
  BATTLECARD_MEASURES_ERROR: 'Unable to retrieve measures for battle card.',
  UNABLE_TO_RETRIEVE_HIERARCHIES: 'Unable to retrieve your hierarchies from the server.',
  PIN_TYPE_ACCOUNT: 'Account pins',
  PIN_TYPE_CUSTOM: 'Other pins',
  CLUSTERS: 'Clusters',
  HEAT_AND_PINS: 'Heat & pins',
  HIDE_ACCOUNTS: 'Hide accounts',
  DEPLOYMENT_MODEL_ERROR: 'Unable to retrieve your deployment model from the server.',
  PINS_ACTIVATED_TOAST_TITLE: 'Pins activated',
  PINS_ACTIVATED_TOAST_BODY: 'Cluster pins were activated. You can now interact with your pins in this selection mode.',
  CUSTOMER_PINS_ERROR: 'Unable to retrieve customer pins.',
  MAP_PINS_ERROR: 'Unable to retrieve map pins.',
  ACCOUNT: 'Account',
  ACCOUNT_WITH_COUNT: '{totalCount} account',
  ACCOUNTS: 'Accounts',
  ACCOUNTS_WITH_COUNT: '{totalCount} accounts',
  ACCOUNT_MOVE_DESCRIPTION: "We'll move your {accountName} account to {destinationText} on {startDate}.",
  ACCOUNT_MOVE_DESCRIPTION_2:
    "We'll move your {accountName} account from {sourceTerritoryId} to {targetText} on {startDate}.",
  ACCOUNT_MOVE_DIALOG_TITLE: 'Schedule move for {accountName}',
  ACCOUNT_MOVE_DIALOG_CONFIRM: 'Schedule move',
  ACCOUNT_MOVE_DIALOG_BODY:
    'Review your selected territory and make changes as required. Next, add a start date for when this move will take effect.',
  SCHEDULE_ACCOUNT_MOVE: 'Schedule account move',
  ACCOUNT_MOVE_SCHEDULED: 'Account move scheduled',
  ACCOUNT_EFFECTIVE_DATE_UPDATED: 'Account effective dates updated',
  UPDATED_EFFECTED_DATES: 'We updated effective dates for your {accountName} scheduled account moves.',
  ORIGINAL_TERRITORY: 'Original territory',
  PREVIOUS_TERRITORY: 'Previous territory',
  GAP_DETECTED: 'Gap detected',
  SCHEDULED_MOVE: 'Scheduled move',
  SCHEDULED_MOVES_WITH_COUNT: '{totalCount} scheduled moves',
  ONE_SCHEDULED_MOVE: '1 scheduled move',
  ACCOUNT_NAME: 'Account name',
  UNASSIGNED_TERRITORY: 'Unassigned territory',
  DELETE_REDIRECT: 'Delete scheduled account move?',
  DELETE_REDIRECT_DIALOGUE_BODY:
    'Deleting this scheduled account move for {accountName} will create a gap and leave your account unassigned. If you choose to delete, select an option to cover the gap:',
  DELETE_REDIRECT_DIALOGUE_SOLO_DELETE_BODY:
    'If you choose to delete, the account will remain in its original territory.',
  DELETE_REDIRECT_START_RADIO: 'Adjust the start date of the next scheduled account move:',
  DELETE_REDIRECT_END_RADIO: 'Adjust the end date of the previous account move:',
  DELETE_REDIRECT_ORIGINAL_RADIO: 'Move the account back to the original territory:',
  DELETE_REDIRECT_TOAST_TITLE: 'Scheduled account move deleted',
  DELETE_REDIRECT_TOAST_BODY_ORIGINAL:
    "We deleted your scheduled account move for {accountName}. We'll move this account back to the original territory on {startDate}.",
  DELETE_REDIRECT_TOAST_BODY_END: "We'll keep this account in {prevTerritoryName} until {endDate}.",
  DELETE_REDIRECT_TOAST_BODY_START: "We'll move this account to {nextTerritoryName} on {startDate}.",
  DELETE_REDIRECT_ERROR: 'Unable to delete account move.',
  CREATE_REDIRECT_ERROR: 'Unable to move account.',
  UPDATE_REDIRECT_ERROR: 'Unable to update account move.',
  EFFECTIVE_DATE: 'Effective date',
  ADD_TERRITORY: 'Add a territory',
  ADD_THIS_TERRITORY: 'Add this territory',
  EMPTY_PANEL_TEXT: 'Add a territory from the list on the left',
  ALLOCATE_TOP_DOWN: 'Allocate (top-down)',
  QUOTA_TARGET_TOP_DOWN: 'Quota target (top-down)',
  TERRITORY: 'Territory',
  TERRITORY_WITH_COUNT: '{totalCount} territory',
  TERRITORIES: 'Territories',
  TERRITORIES_WITH_COUNT: '{totalCount} territories',
  GEOGRAPHIES_MAP: 'Geographies map',
  CUSTOMER_ACCOUNTS_MAP: 'Customer accounts map',
  CURRENT_TERRITORY: 'Current territory',
  TERRITORY_TOGGLE_TOOLTIP: 'This lets you select your existing territories.',
  ACCOUNT_TOGGLE_TOOLTIP: 'This lets you select your existing accounts.',
  PLAN: 'Plan',
  MANAGE: 'Manage',
  EXISTING: 'Existing',
  TOP_DOWN_TARGET: 'Top down target',
  QUOTA_ADJUSTMENTS: 'Quota adjustments',
  QUOTA: 'Quota',
  ADD_NUMBER: 'Add number',
  BASED_ON: 'Based on',
  ALLOCATE_QUOTAS_BASED_ON: 'Allocate quotas based on',
  PRESERVE_QUOTA_ADJUSTMENTS: 'Preserve quota adjustments',
  PRESERVE_SET_SEASONALITY: 'Preserve set seasonality',
  TERRITORY_DEFINE_REFINE: 'Territory definition',
  CREATE_NEW_HIERARCHY_ERROR: 'Unable to create a new hierarchy.',
  CREATE_NEW_HIERARCHY_SUCCESS: 'Created your new hierarchy.',
  EDIT_TERRITORY_GROUP: 'Edit territory group',
  DELETE_TERRITORY_GROUP: 'Delete territory group',
  ADD_TERRITORY_GROUP: 'Add territory group',
  TERRITORY_GROUPS_ERROR: 'Unable to retrieve territory groups from the server.',
  TERRITORY_GROUP_ERROR: 'Unable to retrieve territory group information from the server.',
  COUNT_WITH_NUMBER: 'Count {number}',
  COUNT_WITH_FRACTION: 'Count {numerator} / {denominator}',
  AVERAGE: 'Average',
  AVERAGE_WITH_NUMBER: 'Average {number}',
  STATUS: 'Status',
  VIEW: 'View',
  VIEW_WITH_ELLIPSIS: 'View...',
  HIERARCHY_ERROR: 'Unable to retrieve hierarchy data.',
  HIERARCHY_MOVE_ERROR: 'Unable to move hierarchy.',
  HIERARCHY_MOVE_SUCCESS: 'Hierarchy moved successfully.',
  TOP_DOWN_ALLOCATION_ERROR: 'Unable to perform allocation.',
  CREATE_TERRITORY: 'Create territory',
  REASSIGN_GEOGRAPHIC_REGION: 'Reassign geography region',
  CREATE_NEW_TERRITORY_GROUP_SUCCESS: 'Created your new territory group.',
  CREATE_NEW_TERRITORY_GROUP_ERROR: 'Unable to create a new territory group.',
  NEW_BUSINESS_TARGET: 'New business target',
  BUSINESS_TARGET: 'Business target',
  PLANNED_TERRITORY_QUOTA: 'Planned territory quota',
  UPDATED_QUOTA: 'Updated quota',
  UNDERALLOCATED_BY: 'Underallocated by {amount}',
  OVERALLOCATED_BY: 'Overallocated by {amount}',
  SELECT_BATTLE_CARD_TO_VIEW_TERRITORIES:
    'Select a primary battle card, territory group type, or territory group to view territories',
  EMPTY_GRID: 'No data at the moment',
  NEW_BUSINESS_TARGET_MEASURE_PLACEHOLDER: 'Set target',
  BATTLECARD_MEASURE_PLACEHOLDER: 'Set value',
  COINSORT_PROCESS_SUCCESS: 'Sorting has successfully run.',
  ACTIVITIES_SORTED_INTO: '{activityCount} activities have been sorted into {territoryCount} territories.',
  ACTIVITIES_UNASSIGNED: '{activityCount} activities are unassigned.',
  COINSORT_PROCESS_FAILURE: 'Unable to run coinsort.',
  COINSORT_FETCH_ERROR: 'Unable to fetch coinsort progress.',
  COINSORT_CANCEL_ERROR: 'Unable to cancel coinsort process.',
  COINSORT_CHECK_ERROR: 'Unable to determine if coinsort can be run.',
  COINSORT_RUN_ERROR_TITLE: 'Coin sort failed',
  COINSORT_CANCEL_ERROR_DESC: "Coin sort isn't running, so there is nothing to cancel.",
  COINSORT_GENERIC_ERROR_DESC:
    "We couldn't run coin sort because we ran into some problems. Please refresh the page and try again.",
  COINSORT_RUN_ERROR_DESC:
    "We can't run coin sort right now because there is already a coin sort instance running. Please wait for the current instance to complete and then try again.",
  COINSORT_MISSING_TERRITORY_GROUP_ERROR_DESC:
    "You haven't defined any territory groups yet, so we can't sort your sales activities. Create some territory groups and try again.",
  COINSORT_ACCESS_DENIED_ERROR_DESC:
    "Sorry, you don't have permission to run coin sort. Reach out to your Varicent Sales Planning administrator for assistance.",
  COINSORT_MISSING_ACTIVITY_ERROR_DESC:
    "You haven't added any sales activity data for this battle card, so there's nothing for us to sort. Add activity data and try again.",
  COINSORT_MISSING_TERRITORY_RULE_ERROR_DESC:
    "You don't have any territory rules yet, so we can't sort your sales activities. Create some territory rules and try again.",
  DELETE_NEW_TERRITORY_GROUP_SUCCESS: 'Deleted your territory group.',
  DELETE_NEW_TERRITORY_GROUP_ERROR: 'Unable to delete your territory group.',
  CONFIRM_DELETE: 'Are you sure you want to delete?',
  DELETE_GROUP_WARNING: 'Deleting will remove this territory group.',
  DELETE_GROUP_WITH_TERRITORIES_WARNING_BODY:
    'This territory group has territories. Please move the territories to another territory group before deletion.',
  DELETE_GROUP_WITH_TERRITORIES_WARNING_HEADER: 'Failed to delete territory group',
  LOGOUT: 'Logout',
  TERRITORY_REQUIRED_MARK: 'Territory *',
  TERRITORY_ID: 'Territory ID',
  TERRITORY_ID_WITH_ID: 'Territory ID {territoryID}',
  TERRITORY_NAME: 'Territory name',
  TERRITORY_NAME_REQUIRED_MARK: 'Territory name *',
  TERRITORY_NAME_WITH_COLON: 'Territory name:',
  TERRITORY_NAME_WITH_VALUE: 'Territory name {value}',
  TERRITORY_GROUP_REQUIRED: 'Territory group is required',
  BALANCED: 'Balanced',
  BATTLE_CARD: 'Battle card',
  TERRITORY_GROUP_REQUIRED_MARK: 'Territory group *',
  ENTER_TERRITORY_ID_FOR_RULE: 'Enter territory ID',
  ENTER_TERRITORY_NAME_FOR_RULE: 'Enter territory name',
  CARD_TYPE_TEAM_FUNCTION: 'Card type / team function:',
  DEFINE_TERRITORY_GROUP_TYPES: 'Define territory group types',
  DEFINE_TERRITORY_GROUP_TYPES_DESCRIPTION:
    'Select one or more territory group types, based on your uploaded hierarchies, to define how you want to organize your territories.',
  HIERARCHIES: 'Hierarchies',
  REFER_TO_AS: 'Refer to as',
  TERRITORY_GROUP_TYPE: 'Territory group type',
  PRIMARY_TEAM: 'Primary team',
  PRIMARY_TEAM_WITH_CONSOLIDATE: 'Primary team (consolidate)',
  DIRECT_OVERLAY: 'Overlay (no consolidation)',
  ROLL_UP: 'Roll-up (consolidate)',
  COMMAND_CENTER: 'Command center',
  ADMIN: 'Admin',
  AVAILABLE_WITH_COUNT: '{count} available',
  ACCOUNT_OVERRIDES: 'account overrides',
  DEFAULT: 'Default',
  DEFAULT_WITH_BRACKETS: '(Default)',
  GEOGRAPHY_RULES_ONLY: 'Geography rules only',
  COMPLEX_RULES: 'Complex rules',
  CUSTOM_HIERARCHY_RULES: 'custom hierarchy rules',
  DYNAMIC_HIERARCHY_PLACEHOLDER: '[hierarchy]',
  VISUALIZATION_SETTINGS: 'Rule visualization',
  AND: 'AND',
  OR: 'OR',
  GEOGRAPHY: 'Geography',
  NUMBER_OF_ACCOUNTS: 'Number of accounts',
  TOTAL_NUMBER_OF_ACCOUNTS: 'Total number of accounts',
  NUMBER_OF_OVERRIDE_ACCOUNTS: 'Number of override accounts',
  INCLUDE_OVERRIDE_ACCOUNTS: 'Include override accounts',
  ADD_HIERARCHY: 'Add hierarchy',
  USER_MANAGEMENT: 'User management',
  USERS: 'Users',
  COPIED_TO_CLIPBOARD: 'Copied to clipboard',
  COPY_TO_CLIPBOARD: 'Copy to clipboard',
  GENERATE_API_KEY: 'Generate API key',
  GENERATE_API_KEY_ERROR_MESSAGE: 'Unable to generate API key',
  GENERATE_KEY: 'Generate key',
  GENERATE_API_KEY_DESCRIPTION_1: 'Use this API key to connect your pipe account with your Sales Planning account.',
  GENERATE_API_KEY_DESCRIPTION_2:
    'Sales Planning does not store this API key. Once generated, copy and store it in a safe place. Generating a new key will invalidate the old key.',
  API_KEY_GENERATED: 'API key generated',
  API_KEY_COPIED: 'API key has been copied to clipboard.',
  API_KEY_GENERATED_DESC_1: 'Sales Planning does not store this API key. Copy and store this API key in a safe place.',
  API_KEY_GENERATED_DESC_2:
    'Once you click Done, this key cannot be retrieved. Generating a new key will invalidate the old key.',
  EMAIL_PLACEHOLDER: 'johnsmith@company.com',
  PASSWORD: 'Password',
  PASSWORD_CHANGE_SUCCESS: 'Password changed successfully',
  PASSWORD_CHANGE_ERROR: 'Unable to update your password. Please try again later',
  INVALID_INVITATION_ERROR: 'Invalid invitation link.',
  ADD_USER_TO_TENANT_SUCCESS: 'You have been successfully added to your organization',
  ADD_USER_TO_TENANT_ERROR: 'Unable to add you to your organization. Please contact your admin for a new invitation.',
  GLOBAL_MEASURES: 'Global measures',
  PLAN_TARGETS: 'Plan targets',
  EXPANDED_COMPLETION_RATE: 'Completion rate:',
  QUOTA_APPROVAL_WORKFLOW: 'Quota approval workflow',
  QUOTA_WORKFLOW: 'Quota workflow',
  INITIATE_WORKFLOW_TEXT: 'Initiate workflow',
  INITIATE_WORKFLOW_ERROR: 'Sorry! Failed to initiate workflow.',
  CANCEL_WORKFLOW_TEXT: 'Cancel workflow',
  CANCEL_WORKFLOW_ERROR: 'Sorry! Failed to cancel workflow.',
  TERRITORY_GROUP: 'Territory group',
  TERRIORY_GROUP_LEVEL: 'Territory group level',
  AUTH_CHECK_FAILURE: 'Failed to authenticate. Please login again',
  MEASURE_VALUE_TYPE_ERROR: 'Value must be a number.',
  MEASURE_VALUE_TYPE_ERROR_DATE_REQUIRED: 'Value must be a date.',
  QUOTA_COMPONENTS_ONE_OR_MANY_WITH_COLON: 'Quota component(s):',
  QUOTA_BREAKDOWN_BY: 'Quota breakdown by',
  QUOTA_BREAKDOWN_VALIDATION_ERROR: 'Please only select up to 2 hierarchies.',
  QUOTA_BREAKDOWN_BY_TOOLTIP:
    'This allows you to view a break down of your territory quotas based on the selected hierarchies and adjust them accordingly.',
  QUOTA_BREAKDOWN_BY_PLACEHOLDER_TEXT: 'Select up to 2 hierarchies',
  HIERARCHY_BASED_TARGET_SETTING: 'Hierarchy-based target setting',
  HIERARCHY_BASED_TARGET_SETTING_TOOLTIP:
    'Select a hierarchy to use to set specific quota targets. You can only select a hierarchy with {maxMemberCount} or less members',
  ADD_NEW_QC: 'Add new quota component',
  ACTIVITY_FILE: 'Activity file(s)',
  ACTIVITY_FILE_WITH_COLON: 'Activity file(s):',
  SELECT: 'Select',
  REMOVE: 'Remove',
  REVOKE: 'Revoke',
  INVITE: 'Invite',
  COMMENT: 'Comment',
  COMMENT_ERROR: 'Unable to update the comment.',
  DATE: 'Date',
  EMAIL: 'Email',
  NUMERIC: 'Numeric',
  QUOTA_ALLOCATION: 'Quota allocation:',
  TERRITORY_GROUPS: 'Territory groups',
  UPDATE: 'Update',
  ADD: 'Add',
  ACTION: 'Action',
  ACTIONS: 'Actions',
  ACTION_MENU_TOOLTIP: 'Shows a list of available actions based on your geographic region or territory selections.',
  ADD_TO_SELECTION: 'Add-to selection',
  REMOVE_FROM_SELECTION: 'Remove-from selection',
  UPDATE_TERRITORY_GROUP_SUCCESS: 'Updated your territory group.',
  UPDATE_TERRITORY_GROUP_ERROR: 'Unable to update your territory group.',
  NO_HIERARCHIES: 'No hierarchies',
  USER_MANAGEMENT_DROPDOWN_TOOLTIP: 'Select users with the same role type to send invitations.',
  USER_MANAGEMENT_LOAD_USERS: 'Load users from file',
  USER_MANAGEMENT_ADMIN_INVITE: 'Invite as administrator',
  USER_MANAGEMENT_ADMINS_INVITE: 'Invite as administrators',
  USER_MANAGEMENT_RESEND_ADMIN_INVITE: 'Resend admin invitation',
  USER_MANAGEMENT_CONTRIBUTOR_INVITE: 'Invite as contributor',
  USER_SELECTION_FRACTION: '{selectionCount} / {totalCount} selected',
  USER_MANAGEMENT_CONTRIBUTORS_INVITE_BODY:
    'Are you sure you want to send invitations to {selectionCount} users? These users will be granted contributor access.',
  USER_MANAGEMENT_ADMINS_INVITE_BODY:
    'Are you sure you want to send invitations to {selectionCount} users? These users will be granted administrators access.',
  USER_MANAGEMENT_CONTRIBUTORS_INVITE: 'Invite as contributors',
  USER_MANAGEMENT_RESEND_CONTRIBUTOR_INVITE: 'Resend contributor invitation',
  USER_MANAGEMENT_MEMBER_REVOKED: 'Contributor access revoked for member.',
  USER_MANAGEMENT_ADMIN_REVOKED: "The member's role has been updated to a contributor.",
  USER_MANAGEMENT_MEMBER_REVOKED_ERROR: 'There was a problem revoking the role.',
  USER_MANAGEMENT_MEMBER_DELETED: 'Member has been deleted.',
  USER_MANAGEMENT_MEMBER_DELETED_ERROR: 'There was a problem deleting the member.',
  USER_MANAGEMENT_ADMIN_ACCESS: 'Admin access granted. An email has been sent to the member.',
  USER_MANAGEMENT_CONTRIBUTOR_ACCESS: 'Contributor access granted. An email has been sent to the member.',
  USER_MANAGEMENT_ACCESS_ERROR: 'There was a problem sending the invitation.',
  USER_MANAGEMENT_TABLE_EMPLOYEE_ID: 'Employee ID',
  USER_MANAGEMENT_TABLE_JOB_TITLE: 'Job title',
  USER_MANAGEMENT_TABLE_ROLE_NAME: 'Role name',
  USER_MANAGEMENT_REVOKE_ADMIN: 'Revoke admin access',
  USER_MANAGEMENT_REVOKE_CONTRIBUTOR: 'Revoke contributor access',
  USER_MANAGEMENT_DIALOG_REVOKE_HEADLINE: 'Revoke contributor access?',
  USER_MANAGEMENT_DIALOG_REVOKE_BODY:
    "The user's profile and login credentials will remain in the system. However, they will not have access to this organization.",
  USER_MANAGEMENT_DIALOG_REVOKE_ADMIN_HEADLINE: 'Revoke admin access?',
  USER_MANAGEMENT_DIALOG_REVOKE_ADMIN_BODY:
    "The user's profile will remain in the system. Their role will be updated as a contributor with no admin access.",
  USER_MANAGEMENT_DIALOG_REMOVE_HEADLINE: 'Remove participant profile',
  USER_MANAGEMENT_DIALOG_REMOVE_BODY:
    'Completing this action will immediately remove this user profile from the system. If this profile has been assigned to a territory, group or quota, an error state may occur.',
  USER_MANAGEMENT_DIALOG_INVITE_HEADLINE: 'Invite member as contributor',
  USER_MANAGEMENT_DIALOG_INVITE_BODY:
    'This will send an invitation email to the member to be a contributor. Do you want to complete this action?',
  USER_MANAGEMENT_DIALOG_ADMIN_INVITE_HEADLINE: 'Invite member as admin',
  USER_MANAGEMENT_DIALOG_ADMIN_INVITE_BODY:
    'This will send an invitation email to the member to be an admin. Do you want to complete this action?',
  ROLE_PERMISSIONS_TABLE_VIEW_HEADER_TOOLTIP:
    'Enabling view permissions will give this role view only access to the planning cycle.',
  ROLE_PERMISSIONS_TABLE_EDIT_HEADER_TOOLTIP:
    'Enabling edit permissions will give this role edit access to this planning cycle. For Quota tab in Plan, ensure quota workflow is enabled.',
  ROLE_PERMISSIONS_TABLE_VIEW_CHECKBOX_TOOLTIP: 'View access can only be granted at the highest level',
  ROLE_PERMISSIONS_TABLE_EDIT_CHECKBOX_TOOLTIP: 'Selecting edit permissions will also turn on view permissions.',
  TERRITORY_SHEET_GRID_EDIT_UNALLOWED_TOOLTIP: 'You do not have permission to edit this.',
  EXPAND_ALL: 'Expand all',
  COLLAPSE_ALL: 'Collapse all',
  ROLE_PERMISSIONS_TABLE_QUOTA_SHEET_INFO:
    'Quota sheet permissions can be modified in the data tab in the Command center',
  ROLES: 'Roles',
  ROLE_PERMISSIONS_UPSERT_ERROR: 'We could not save your permissions. Please try again',
  ROLE_PERMISSIONS_UPSERT_SUCCESS: 'We have successfully updated permissions for {role} role',
  ROLE_PERMISSIONS_GET_ERROR: 'We could not load your permissions.',
  CHANGE_REQUESTS: 'Change requests',
  REVIEW_PENDING_CHANGE_REQUESTS: 'Review pending change requests..',
  SUBMIT_CHANGE_REQUEST: 'Submit change request',
  VIEW_CHANGE_REQUEST_LOG: 'View change request log',
  REQUEST_ID: 'Request ID',
  REQUESTED_BY: 'Requested by',
  DATE_REQUESTED: 'Date requested',
  DATE_REVIEWED: 'Date reviewed',
  APPROVE_CHANGE_REQUEST_DIALOG_BODY:
    'Are you ready to approve this change request? Approved changes will show in the data tray.',
  REJECT_CHANGE_REQUEST_DIALOG_BODY:
    'Are you sure you want to reject this change request? Rejected changes will be discarded.',
  ADD_A_COMMENT: 'Add a comment',
  APPROVE_CHANGE_REQUEST: 'Approve change request',
  REJECT_CHANGE_REQUEST: 'Reject change request',
  CONTRIBUTOR_ROLES: 'Contributor roles',
  ADMINISTRATOR_ACCESS_DESCRIPTION: 'This user role has access to all functionalities',
  SEND_INVITATION: 'Send invitation',
  RESEND_INVITATION: 'Resend invitation',
  ADD_DESCRIPTION_PLACEHOLDER: 'Add description...',
  EDIT_PLANNING_CYCLE: 'Edit planning cycle',
  EDIT_PLANNING_CYCLE_SUCCESS_TITLE: 'Planning cycle changes saved',
  EDIT_PLANNING_CYCLE_FAILURE_TITLE: 'Planning cycle update failed',
  EDIT_NEW_CYCLE_SUCCESS: 'Updated your planning cycle.',
  EDIT_NEW_CYCLE_ERROR: 'Unable to update planning cycle.',
  EDIT: 'Edit',
  EDIT_WITH_VALUE: 'Edit {value}',
  LOADING: 'Loading...',
  STARTING: 'Starting...',
  STOPPING: 'Stopping...',
  DELETE_BATTLECARD_SUCCESS: 'Deleted your battle card.',
  DELETE_BATTLECARD_GENERIC_ERROR: 'Unable to delete your battle card.',
  DELETE_BATTLECARD_TERRITORY_RULE_ERROR:
    "We can't delete this battle card right now. Please delete all territories from the battle card and try again.",
  NO_RESULTS: 'No results',
  TERRITORY_OWNER: 'Territory owner:',
  OWNERSHIP_VALUE_RANGE_ERROR: 'Value must be 1-100.',
  PERCENTAGE_MEASURE_VALUE_RANGE_ERROR: 'Value must be 0-100.',
  MEASURES: 'Measures',
  BUILT_IN_MEASURES: 'Built-in measures',
  CUSTOM_MEASURES: 'Custom measures',
  DESCRIPTION: 'Description',
  NAME: 'Name',
  NAME_WITH_COLON: 'Name:',
  TYPE: 'Type',
  TYPE_REQUIRED: '* Type:',
  ATTRIBUTE_NAME: 'Attribute name',
  ADD_CUSTOM_ATTRIBUTE: 'Add custom attribute',
  ATTRIBUTE_NAME_MESSAGE: 'Please enter an attribute name',
  FORMAT: 'Format',
  FORMAT_REQUIRED: '* Format:',
  FORMULA: 'Formula',
  DATE_RANGE: 'Date range',
  ADD_MEASURE: 'Add measure',
  TERRITORY_OWNER_ERROR: 'Please select a territory owner.',
  ASSIGNMENT_BUILDER_ADD_SUCCESS: 'Assignment has been added.',
  ASSIGNMENT_BUILDER_ADD_FAILURE: 'Unable to add assignment.',
  ASSIGNMENT_BUILDER_UPDATE_SUCCESS: 'Assignment has been updated.',
  ASSIGNMENT_BUILDER_UPDATE_FAILURE: 'Unable to update assignment.',
  TERRITORY_GROUP_TYPE_INCLUDE_MESSAGE: 'There must be at least one territory group type.',
  UPDATE_USER_SUCCESS: 'User profile updated successfully.',
  UPDATE_USER_ERROR: 'There was an error updating the user profile.',
  ADD_TERRITORIES_TOOLTIP: 'Add territories to be able to perform top-down allocation to this territory group.',
  ADD_A_CARD: 'Add a card',
  FIVE_HUNDRED: "Oops! There's an issue with our server. Please try again later!",
  FOUR_O_ONE: "Sorry! You don't have authorization to view the page.",
  FOUR_O_FOUR: "Oops! The page you're looking for has been removed, or the link is broken.",
  INTERNAL_ERROR: "Oops! There's an issue with our app. Please try again later!",
  UPSERT_TERRITORY_GROUP_OWNER_ERROR: 'Unable to update territory group owner.',
  OWNER: 'Owner',
  OWNER_WITH_COLON: 'Owner:',
  CREATE_ORGANIZATION_TITLE: 'Create your organization',
  NAME_YOUR_ORGANIZATION: 'Name your organization',
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  FIRST_NAME_PLACEHOLDER: 'John',
  LAST_NAME_PLACEHOLDER: 'Smith',
  ORGANIZATION_NAME_PLACEHOLDER: '8+ characters',
  CREATE_ORGANIZATION: 'Create organization',
  TERMS_OF_SERVICE: 'Terms of service',
  PRIVACY_POLICY: 'Privacy policy',
  INVALID_EMAIL: 'Invalid email',
  ORGANIZATION_NAME_LENGTH_ERROR: 'Organization name should be at least 2 characters.',
  ENTER_YOUR_ORGANIZATION_NAME: 'Enter your organization name',
  BY_CLICKING: 'By clicking {actionText} you agree to our',
  AND_OUR: 'and our',
  QUOTA_COMPONENT: 'Quota component',
  QUOTA_COMPONENT_WITH_COLON: 'Quota component:',
  QUOTA_COMPONENT_WITH_VALUE: '{value} quota component',
  QUOTA_COMPONENTS: 'Quota components',
  QUOTA_COMPONENTS_WITH_VALUE: '{value} quota components',
  QUOTA_COMPONENTS_UPDATE_SUCCESS: 'Quota component has been updated.',
  QUOTA_COMPONENTS_DELETED_SUCCESS: 'Quota component has been deleted.',
  QUOTA_COMPONENTS_UPDATE_ERROR: 'Unable to update the quota component.',
  NO_QUOTA_COMPONENTS: 'No quota components',
  DELETE_QUOTA_COMPONENT: 'Delete quota component',
  CONFIRM_DELETE_QUOTA_COMPONENT: 'Are you sure you want to delete quota component {name}?',
  NO_ACTIVITY_FILES: 'No activity files',
  NO_DATA: 'No data',
  NO_FILE_SELECTED: 'No file selected',
  INSIGHTS: 'Insights',
  SELECTION_INSIGHTS: 'Selection insights',
  CLEAR_SELECTION: 'Clear selection',
  CLEAR_ALL: 'Clear all',
  CLEAR: 'Clear',
  CHOOSE_FILE_FOR_UPLOAD: 'Choose a CSV file for upload (* indicates a required header)',
  CHOOSE_A_FILE: 'Choose a file',
  FILE_UPLOAD_VALIDATION_ERROR:
    'The file you selected, "{file}", does not have a .csv file extension. Please select a different file or add the .csv file extension to your file\'s name.',
  BEGIN_UPLOAD_ERROR: 'Could not begin uploading your selected file.',
  ADD_USERS: 'Add users',
  PROCESSING_MESSAGE_PENDING: 'File is being processed.',
  PROCESSING_MESSAGE_COMPLETED: 'File was processed successfully.',
  PROCESSING_MESSAGE_ERROR: 'File was not processed successfully.',
  PROCESSING_STATUS_PENDING: 'Pending',
  PROCESSING_STATUS_ERROR: 'Error',
  RETRIEVE_PROCESSING_STATUS_ERROR: 'Could not retrieve file processing status.',
  GET_FILE_HEADERS_ERROR: 'Could not retrieve file headers.',
  NOTIFY_SERVER_ERROR: 'Could not notify server about upload status.',
  CHECK_REQUIRED_FILE_HEADERS: 'Please check that the required column headers are present in your file.',
  BEGIN_FILE_UPLOAD: 'Begin file upload',
  FILE_PROCESSING_SUCCESS: 'File processing success',
  FILE_PROCESSING_ERROR: 'File processing error',
  FILE_NAME_VALIDATION_ERROR: "We can't import this file because the file name contains unsupported characters",
  FILE_COLUMN_VALIDATION_ERROR:
    "We can't import this file because the file column header contains unsupported characters",
  FILE_NAME: 'File name',
  SUMMARY: 'Summary',
  IMPORT_DATA: 'Import data',
  SEARCH: 'Search',
  SUBMIT: 'Submit',
  READONLY: 'Read only',
  REJECT: 'Reject',
  APPROVE: 'Approve',
  ADD_TERRITORY_ROW: 'Add territory row',
  LOAD_TERRITORIES: 'Load territories',
  ADD_TERRITORIES: 'Add territories',
  ADD_CUSTOM_MEASURE_SUCCESS: 'Measure has been added.',
  ADD_CUSTOM_MEASURE_FAILURE: 'Unable to add measure.',
  UPDATE_CUSTOM_MEASURE_SUCCESS: 'Measure has been updated.',
  UPDATE_CUSTOM_MEASURE_FAILURE: 'Unable to update measure.',
  UPDATE_MEASURE: 'Update measure',
  DELETE_CUSTOM_MEASURE_SUCCESS: 'Measure has been deleted.',
  DELETE_CUSTOM_MEASURE_FAILURE: 'Unable to delete measure.',
  MEASURE_NAME_REQUIRED: 'Please provide a column name.',
  MEASURE_TYPE_REQUIRED: 'Please select a source.',
  MEASURE_FORMAT_REQUIRED: 'Please select a format.',
  MEASURE_SOURCE_REQUIRED: 'Please select a data table type.',
  FIELD_ID_REQUIRED: 'Please select a data table.',
  FIELD_NAME_REQUIRED: 'Please select a column.',
  CREATE_NEW_ORGANIZATION_SUCCESS: 'Created your new organization.',
  CREATE_NEW_ORGANIZATION_ERROR: 'Unable to create a new organization.',
  TERRITORY_DEFINITIONS: 'Territory definitions',
  STALE_DATA_MESSAGE: 'You are viewing stale data. Run sort to update to your latest territories.',
  ASSIGNED_TOOLTIP: 'Include assigned geographic regions',
  ASSIGNED_TOOLTIP_ACCOUNTS: 'Include assigned accounts',
  ALL_TOOLTIP: 'Include all geographic regions',
  ALL_TOOLTIP_ACCOUNTS: 'Include all accounts',
  UNASSIGNED_TOOLTIP: 'Include unassigned geographic regions',
  UNASSIGNED_TOOLTIP_ACCOUNTS: 'Include unassigned accounts',
  ASSIGNED: 'Assigned',
  UNASSIGNED: 'Unassigned',
  UNASSIGNED_PARENTHESES: '(Not assigned)',
  UNASSIGNED_LOWERCASE: 'unassigned',
  WILL_START: '{territoryName} will start',
  WILL_END: '{territoryName} will end',
  UNABLE_TO_PROCESS_CONTRIBUTOR: 'Unable to process your request.',
  EDIT_TERRITORY: 'Edit territory',
  QUOTA_COMPONENT_VALIDATION_ERROR: 'Please select at least one quota component.',
  EDIT_HIERARCHY: 'Edit hierarchy',
  EDIT_HIERARCHY_ERROR: 'Unable to edit your hierarchy.',
  EDIT_HIERARCHY_SUCCESS: 'Hierarchy has been edited',
  ENTER_HIERARCHY_NAME: 'Enter a name for your hierarchy',
  HIERARCHY_NAME: 'Hierarchy name',
  UNSAVED_CHANGES: 'Are you sure you want to leave? You have some unsaved changes.',
  BATTLE_CARD_TITLE_VALIDATION_ERROR: 'Please provide a name for your battle card.',
  LEVEL_WITH_VALUE: 'Level {value}',
  TERRITORY_GROUP_LEVEL_WITH_VALUE: 'Territory group (level {value})',
  GROUP_BY_WITHOUT_COLON: 'Group by',
  COLOR_BY_TERRITORY_GROUP: 'Color by territory groups',
  COLOR_BY_TERRITORY_GROUP_VIEW_ONLY: '(View only)',
  COLOR_BY_TERRITORY_GROUP_TOAST_TITLE: 'Color by territory groups enabled',
  COLOR_BY_TERRITORY_GROUP_TOAST_MESSAGE: 'You cannot perform map actions or view account pins when in this mode',
  COLOR_BY_TERRITORY_GROUP_PILL_MESSAGE: 'Color by territory groups ({level})',
  RECOLOR_DISABLED_TOOLTIP: 'You cannot change colors when color by territory groups is enabled',
  SORT_BY: 'Sort by',
  MULTIPLE_ACCOUNTS_TITLE: 'Multiple accounts',
  MULTIPLE_ACCOUNTS_BODY:
    'This geographic region contains multiple accounts. Lasso this cluster and view the accounts breakdown in your insights card.',
  MULTIPLE_ACCOUNTS_BODY_2:
    'This cluster contains multiple accounts. Lasso this cluster and view the accounts breakdown in your insights card.',
  UNASSIGN: 'Unassign',
  REASSIGN: 'Reassign',
  GET_ACTIVITIES_FOR_FILE_ERROR: 'Unable to retrieve activities for file.',
  GET_ACTIVITIES_FOR_TERRITORY_ERROR: 'Unable to retrieve activities for territory.',
  GET_ACTIVITIES_FOR_TERRITORY_GROUP_ERROR: 'Unable to retrieve activities for territory group.',
  GET_UNASSIGNED_ACTIVITIES_ERROR: 'Unable to retrieve unassigned activities.',
  WORKFLOW_APPROVE_SUCCESS: '{actionWorkflowName} workflow has been approved!',
  WORKFLOW_APPROVE_FAILURE: 'Unable to approve workflow {actionWorkflowName}',
  WORKFLOW_REJECT_SUCCESS: '{actionWorkflowName} workflow has been rejected!',
  WORKFLOW_REJECT_FAILURE: 'Unable to reject workflow {actionWorkflowName}',
  APPROVE_TERRITORY_GROUP_DIALOG_MESSAGE:
    '{approver} is responsible for approving this territory group and may be in the process of modifying quotas. Are you sure you want to approve?',
  REJECT_TERRITORY_GROUP_DIALOG_MESSAGE:
    '{approver} is responsible for rejecting this territory group and may be in the process of modifying quotas. Are you sure you want to reject?',
  APPROVE_BATTLE_CARD_DIALOG_MESSAGE:
    '{approver} is responsible for approving this battle card and may be in the process of modifying quotas. Are you sure you want to approve?',
  REJECT_BATTLE_CARD_DIALOG_MESSAGE:
    '{approver} is responsible for rejecting this battle card and may be in the process of modifying quotas. Are you sure you want to reject?',
  APPROVE_DIALOG_TITLE: 'You are approving on behalf of another user',
  REJECT_DIALOG_TITLE: 'You are rejecting on behalf of another user',
  AVAILABLE_TERRITORY_ID: '"{territoryId}" is available in territory group "{territoryGroup}".',
  UNAVAILABLE_TERRITORY_ID: '"{territoryId}" is not available because it is already taken.',
  AVAILABLE_WITH_PLANNING_CYCLE_ID: 'is available with planning cycle ID',
  UNAVAILABLE_WITH_PLANNING_CYCLE_ID:
    '"{name}" is not available because planning cycle ID "{slugName}" is already taken.',
  YOUR_ORGANIZATION_URL_WILL_BE: 'Your organization URL will be',
  PLANNING_CYCLE_NAME: 'Planning cycle name',
  NEXT: 'Next',
  SIGN_IN: 'Sign in',
  ARCHIVE: 'Archive',
  ARCHIVE_WITH_TITLE: 'Archive {title}',
  PLANNING_CYCLE_ARCHIVE_MESSAGE_1: 'You are about to archive this planning cycle:',
  PLANNING_CYCLE_ARCHIVE_MESSAGE_2: 'It will be stored in the archive for 30 days.',
  PLANNING_CYCLE_ARCHIVE_SUCCESS: '{title} is now archived.',
  PLANNING_CYCLE_ARCHIVE_FAILURE: 'Unable to archive {title}',
  VIEW_ALL_ACTIVITIES: 'View all activities',
  ADD_A_CHILD: 'Add a child',
  KEY: 'Key',
  ADDRESS1: 'Address line 1',
  ADDRESS2: 'Address line 2',
  CITY: 'City',
  COUNTRY: 'Country',
  INDUSTRY: 'Industry',
  ZIP_OR_POSTAL: 'Zip or postal code',
  STATE_OR_PROVINCE: 'State or province',
  GEOGRAPHIC_REGION: 'Geographic region',
  GEO_REGION: 'Geo region',
  SELECTED_GEOGRAPHIC_REGIONS: 'Selected geographic regions',
  SELECTED_TERRITORIES: 'Selected territories',
  GEOGRAPHIC_REGION_TOGGLE_TOOLTIP:
    'This lets you select individual boundaries based on your chosen geographic level. For example, countries, states, or zip codes.',
  SAVE_CHANGES: 'Save changes',
  CONTACT_ADMIN_EMAIL: 'Contact your administrator to change your email address.',
  REQUIRED_FIELD: 'Required field',
  UPDATE_HIERARCHY_ERROR: 'Unable to update {rootName} "{name}".',
  ADDED_HIERARCHY: 'Added {rootName} "{name}".',
  DETAILS: 'Details',
  DELETE_SUCCESS_WITH_NAMES_QUOTED: '{nameOne} "{nameTwo}" deleted successfully.',
  HIERARCHY_DELETE_ERROR_WITH_NAMES: 'Unable to delete {rootName} "{name}".',
  HIERARCHY_EMPTY: 'Hierarchy empty',
  CONFIRM_DELETE_HIERARCHY: 'Are you sure you want to delete {name}?',
  OPTIONS: 'Options',
  DELETE_TERRITORY_GROUP_CONFIRM: 'Are you sure you would like to delete this territory?',
  DELETE_TERRITORY_SUCCESS: '{territoryId}: {territoryName} territory has been deleted.',
  DELETE_TERRITORY_ERROR: 'Unable to delete territory {territoryId}: {territoryName}.',
  DELETE_TERRITORY_IN_USE_BY_OVERLAY_ERROR_MESSAGE:
    "We couldn't delete this territory because it is used by an overlay battle card. Please remove references to this territory from the overlay battle card and try again.",
  DELETE_TERRITORY_IN_USE_BY_OVERLAY_ERROR_MESSAGE_GENERIC:
    'We failed to delete selected territories because some rules are used by an overlay battle card. Please remove references to selected territories from the overlay battle card and try again.',
  DELETE_TERRITORY_TEXT: 'Delete territory',
  ISOLATE_TERRITORIES: 'Isolate territories',
  ISOLATE_SELECTED_TERRITORIES: 'Isolate selected territories',
  DELETE_TERRITORY_TEXT_WITH_ID_AND_NAME: 'Delete territory {territoryId}: {territoryName}',
  DELETE_MULTIPLE_TERRITORIES_TEXT: 'Delete territories',
  TERRITORY_DELETE_DIALOG_BODY: 'Did you want to delete the {count} selected territory?',
  TERRITORIES_DELETE_DIALOG_BODY: 'Did you want to delete the {count} selected territories?',
  CURRENCIES: 'Currencies',
  CURRENCIES_WITH_COUNT: '{count} currencies',
  CURRENCY: 'Currency',
  CURRENCY_WITH_COUNT: '{count} currency',
  REPORTING_CURRENCY: 'Reporting currency',
  REPORTING_CURRENCY_WITH_VALUE: 'Reporting currency ({value})',
  LOCAL_CURRENCY: 'Local currency',
  LOCAL_CURRENCY_WITH_COLON: 'Local currency:',
  REPORTING_AND_LOCAL_CURRENCIES: 'Reporting and local currencies',
  AVAILABLE_CURRENCIES: 'Available currencies',
  REPORTING_CURRENCY_DEFAULT: 'Reporting currency (default)',
  REPORTING_CURRENCY_DESIGNATED: 'Reporting currency (designated)',
  CONSTANT_CURRENCY_EXCHANGE_RATE: 'Constant currency exchange rate',
  ADD_CURRENCY: 'Add currency',
  VALUES_SHOWN_IN_REPORTING_CURRENCY: 'Values shown in reporting currency ({value})',
  VALUES_SHOWN_IN_LOCAL_CURRENCY: 'Values shown in local currency ({value})',
  REPORTING_CURRENCY_IS: 'Reporting currency is {value}',
  LOCAL_CURRENCY_IS: 'Local currency is {value}',
  EMPTY_HIERARCHY_MESSAGE: 'Select an item in the hierarchy to view its details.',
  ALL: 'All',
  SELECT_CURRENCY: 'Select a currency',
  SELECT_CURRENCY_ERROR_MESSAGE: 'Please select a currency.',
  SELECT_CONVERSION_ERROR_MESSAGE: 'Please enter a conversion.',
  CURRENCY_IN_USE: 'Currency in use',
  DELETE_CURRENCY: 'Delete currency',
  ADD_TERRITORY_GROUP_TYPE: 'Add territory group type',
  KEY_EXISTS_ERROR: '{value} is not available because it is already taken.',
  IS_AVAILABLE: 'is available.',
  NO_GRID_TERRITORIES: 'No territories to show.',
  NO_GRID_TERRITORIES_ADDED: "You haven't added any territories yet.",
  NO_TERRITORY: 'No territory',
  EQUALS_SIGN: '=',
  INVERT_EXCHANGE_RATES: 'Invert exchange rates',
  CURRENCY_PAGE_SUCCESS: 'Currencies successfully updated',
  CURRENCY_PAGE_ERROR: 'Unable to update currencies.',
  DELETE_REPORTING_CURRENCY: 'Delete reporting currency',
  CONFIRM_DELETE_REPORTING_CURRENCY:
    'Are you sure you want to delete this reporting currency? All local currencies associated with this reporting currency will also be deleted.',
  DELETE_LOCAL_CURRENCY: 'Delete local currency',
  CONFIRM_DELETE_LOCAL_CURRENCY: 'Are you sure you want to delete this local currency?',
  PRODUCT_DOCUMENTATION: 'Product documentation',
  GET_CONVERSION_RATES_COUNT_ERROR: 'Unable to retrieve the number of currencies in use.',
  UPLOAD_COLUMN_HEADERS_MESSAGE_PREFIX: 'Please use the following column headers in your upload: {value}',
  UPDATE_MEASURE_VALUE_ERROR: 'Unable to update the measure value.',
  FILE_UPLOAD_HEADER_ERROR_SINGLE: 'The following column header is missing: {value}',
  FILE_UPLOAD_HEADER_ERROR_PLURAL: 'The following column headers are missing: {value}',
  FILE_UPLOAD_HEADER_ERROR_UNKNOWN: 'An error occurred while validating this file.',
  SET_CONVERSION_RATE: 'Set conversion rate',
  DELETE_HIERARCHY_WITH_NAME: 'Delete {name} hierarchy?',
  DELETE_HIERARCHY_MEMBER_WITH_VALUE: 'Delete {value} hierarchy member?',
  DELETE_HIERARCHY_MEMBERS_WITH_VALUE: 'Delete {value} hierarchy members?',
  DELETE_HIERARCHY_SUCCESS: 'Hierarchy {name} deleted successfully.',
  DELETE_HIERARCHY_ERROR: 'Unable to delete hierarchy {name}.',
  MIXED_CLUSTER_TOOLTIP: 'This cluster contains accounts from multiple territories',
  MIXED_CLUSTER_WITH_UNASSIGNED_TOOLTIP:
    'This cluster contains accounts from multiple territories or unassigned accounts',
  OVERASSIGNED_CLUSTER_TOOLTIP: 'This cluster contains accounts that implicitly belong to 2 or more territories',
  UNASSIGNED_CLUSTER_TOOLTIP: 'This cluster contains accounts from unassigned accounts',
  ASSIGNED_CLUSTER_TOOLTIP: 'This cluster contains accounts from {territoryId} ({territoryName})',
  TERRITORIES_AND_COUNT: 'Territories ({count})',
  TERRITORY_ID_AND_NAME: '{territoryId} ({territoryName})',
  TERRITORY_NAME_AND_ID: '{territoryName} ({territoryId})',
  PRIOR_YEAR_ACTUAL: 'Prior year actual: ',
  PRIOR_YEAR_SALES: 'Prior year sales',
  TOTAL: 'Total',
  TOTAL_WITH_LABEL: '{label} total',
  TOTAL_WITH_VALUE: 'Total {value}',
  TOTAL_WITH_FRACTION: 'Total {numerator} / {denominator}',
  CANCEL_WORKFLOW_CONFIRMATION: 'Are you sure you want to cancel the workflow?',
  YES: 'Yes',
  NO: 'No',
  LATER: 'Later',
  REPARENTING: 'Reparenting...',
  NOT_APPLICABLE_ABBREVIATION: 'N/A',
  PIPE_CONFIGURATION_DELETE_PROMPT:
    'This will permanently delete your "{pipeName}, {exportName}" pipe configuration. This action cannot be undone. Confirm?',
  CONTRIBUTOR_QUOTA_COMPONENT_SUBMIT_WARNING: 'Before you submit you must review {value}',
  CONTRIBUTOR_HIERARCHY_SUBMIT_WARNING: 'Before you submit you must approve all child territories.',
  SHOW_CHANGES: 'Show changes',
  SHOW_IMPACTED_ROWS_ONLY: 'Show impacted rows only',
  REVIEW_CHANGES: 'Review changes',
  SHEETS: 'Sheets',
  TABLES: 'Tables',
  UNPUBLISHED: 'Unpublished',
  PUBLISHED: 'Published',
  PUBLISHING_IN_PROGRESS: 'Publishing in progress',
  IMPORT: 'Import',
  DATE_CREATED: 'Date created',
  DATE_MODIFIED: 'Date modified',
  SELLERS: 'Sellers',
  SELLER: 'Seller',
  SELECT_SELLER: 'Select seller',
  SELECT_TERRITORY_GROUP: 'Select territory group',
  MOVE: 'Move',
  MOVE_ACCOUNTS: 'Move account(s)',
  MOVE_ACCOUNTS_DIALOG_BODY: 'Choose a territory to move the selected account(s) to:',
  OVERRIDE_ACCOUNTS_TITLE: 'Override accounts?',
  OVERRIDE_REASSIGN_BODY_1: 'Moving the selected accounts will add override rules to your territory.',
  OVERRIDE_REASSIGN_BODY_2:
    "If you don't want to add overrides, cancel this move and update your base territory rule in the rule editor.",
  DONT_SHOW_THIS_AGAIN: "Don't show this again",
  HYPHEN: '-',
  EXPAND: 'Expand',
  COLLAPSE: 'Collapse',
  ZOOM_IN: 'Zoom in',
  ZOOM_OUT: 'Zoom out',
  CLOSE: 'Close',
  LAST_MODIFIED: 'Last modified: {value}',
  LAST_OPENED: 'Last opened',
  BATTLE_CARD_REFERENCED: 'Battle card referenced',
  SELECT_BATTLE_CARD_REFERENCE: 'Select the battle cards and levels that reference this sheet',
  BATTLE_CARD_NAME: 'Battle card name',
  SHEETS_REFERENCED: 'Sheets referenced',
  REFERENCE_THIS_SHEET: 'Reference this sheet',
  PUBLISH: 'Publish',
  PUBLISHED_TOOLTIP: 'Published scenario: {name}',
  UNPUBLISHED_TOOLTIP: 'Unpublished scenario: {name}',
  SCENARIO_PUBLISHED: 'Scenario published',
  SCENARIO_PUBLISHED_MESSAGE: 'We published your {name} scenario.',
  SCENARIO_PUBLISHED_FAILURE: 'Failed to publish scenario',
  SCENARIO_DELETE_PROMPT:
    'This will permanently delete your {name} scenario. This action cannot be undone. Are you sure you want to delete?',
  SCENARIO_PUBLISHED_ERROR: '{name} is currently published',
  SCENARIO_PUBLISHED_ERROR_DESCRIPTION:
    'Your {name} scenario cannot be deleted because it is currently published. Publish another scenario and try again.',
  SCENARIO_DELETED: 'Scenario deleted',
  SCENARIO_DELETED_FAILURE: 'Delete scenario failed',
  SCENARIO_DELETED_FAILURE_MESSAGE: "We couldn't delete your {name} scenario. Please refresh the page and try again.",
  PERMISSIONS: 'Permissions',
  APPLY_PER_USER_ROLE: 'Apply per user role',
  USER: 'User',
  QUOTA_SHEET_COLUMNS: 'Quota sheet columns',
  TERRITORY_SHEET_COLUMNS: 'Territory sheet columns',
  SELLER_SHEET_COLUMNS: 'Seller sheet columns',
  COLUMNS_DESCRIPTION: 'Manage built-in fields and add custom fields',
  CREATE_FIELD: 'Create field',
  EDIT_FIELD: 'Edit field',
  SHOW_TOTALS: 'Show totals',
  REPARENT_TO_ROOT: 'Reparent to root',
  ADD_HIERARCHY_UNDER_ROOT: 'Add hierarchy under root',
  PIN_SET: 'Pin set',
  PIN_TOOLTIP: 'View and add pins',
  ADD_PIN_SET: 'Add pin set',
  PIN_SET_NAME: 'Pin set name',
  PIN_SET_ICON: 'Pin set icon',
  PIN_SET_COLOR: 'Pin set color',
  COLOR_OPTION: 'Color option {value}',
  PIN_NAME_PLACEHOLDER: 'Enter pin name',
  PIN_SET_DATA_PLACEHOLDER: 'Select a data source for your pin set',
  CREATE_PIN_SET_SUCCESS: 'We created your pin set.',
  CREATE_PIN_SET_ERROR: "Sorry, we couldn't create your pin set.",
  LOCATION_GROUP_UNIQUENESS_ERROR: 'This data table name already exists',
  MISSING_REQUIRED_LOCATION_GROUP_NAME: 'Please enter a data table name',
  MISSING_REQUIRED_PIN_SET_NAME: 'Please enter a pin set name',
  PIN_SET_NAME_TOO_LONG: "You've exceeded the max character limit ({maxCharacters})",
  MISSING_REQUIRED_LOCATION_GROUP: 'Please select a data source',
  MISSING_REQUIRED_ICON: 'Please select an icon',
  MISSING_REQUIRED_COLOR: 'Please select a color',
  DATA_SOURCE_DUPLICATE_USAGE:
    'A pin set on the battle card is already using this data source. Please select a different data source.',
  PIN_SET_NAME_UNIQUENESS_ERROR: 'This pin set name already exists.',
  LOCATION_FILE_TYPE: 'Map pins',
  EXISTING_PIN_SET_TEXT: 'Use existing pin set data',
  NEW_PIN_SET_TEXT: 'Import new pin set data',
  PREVIEW: 'Preview',
  ADD_USER_ROLE: '+ Add',
  QUOTA_COMPONENTS_REFERENCED: 'Quota components referenced',
  QUOTA_COMPONENTS_REFERENCED_CHECKBOX_SELECTION_WARNING: 'Please reference at least one quota component',
  COLUMN_NAME: 'Column name',
  SOURCE: 'Source',
  DATA_TABLE_TYPE_WITH_COLON: 'Data table type:',
  DATA_TABLE_NAME: 'Data table name*',
  DATA_TABLE_PLACEHOLDER: 'Ex. USA City Halls 2022',
  DATA_TABLE: 'Data table',
  COLUMN: 'Column',
  GROUP_BY: 'Group by:',
  APPLIED_FILTERS: 'Applied filters:',
  RESET: 'Reset',
  DELETE_FIELD_FAILED: 'Unable to delete field',
  CREATE_FIELD_SUCCESS: 'Field created successfully',
  UPDATE_FIELD_SUCCESS: 'Field updated successfully',
  DELETE_FIELD_SUCCESS: 'Field deleted successfully',
  UPDATE_QUOTA_SHEET_SUCCESS: 'Quota sheet updated successfully',
  UPDATE_QUOTA_SHEET_FAILED: 'Unable to update quota sheet',
  COLUMN_REORDER_FAILED: 'Unable to reorder column fields',
  COLUMN_REORDER_SUCCESS: 'Column fields reordered successfully',
  DISABLED_CHECKBOX_TOOLTIP: 'This property is not enabled for this field type.',
  DISABLED_PLANNING_CYCLE_FIELD_TOOLTIP: "This field isn't editable after creating the planning cycle.",
  VISIBLE: 'Visible',
  EDITABLE: 'Editable',
  CANCEL_LASSO: 'Cancel lasso',
  LASSO_TOOLTIP: 'Lasso tool',
  LASSO_DROPDOWN_TOOLTIP: 'Filter your lasso selection to include assigned or unassigned geographic regions.',
  LASSO_DROPDOWN_TOOLTIP_ACCOUNTS: 'Filter your lasso selection to include assigned or unassigned accounts.',
  SELECT_TOOLTIP: 'Selection tool',
  UNDO: 'Undo',
  MAP_UNDO_BODY:
    'You are about to undo your most recent change. You will not be able to redo after the undo is complete. Are you sure you want to continue?',
  MAP_UNDO_TOAST_ERROR_TITLE: 'Unable to undo',
  MAP_UNDO_TOAST_ERROR_BODY: 'Something went wrong. We were unable to undo your rule change.',
  MAP_UNDO_TOAST_SUCCESS: 'Undo completed successfully.',
  LAYERS: 'Layers',
  MODERATE_DEVIATION: 'moderate',
  SIGNIFICANT_DEVIATION: 'significant',
  MINOR_DEVIATION: 'minor',
  ABOVE_AVERAGE:
    'Gap score: {gapScore} — this value is above average, placing it in the {percentile} percentile, indicating a {deviationType} deviation.',
  BELOW_AVERAGE:
    'Gap score: {gapScore} — this value is below average, placing it in the {percentile} percentile, indicating a {deviationType} deviation.',
  ON_AVERAGE:
    'Gap score: {gapScore} — this territory is on average with all territories using the same metric, showing no significant deviation.',
  GAP: 'Gap',
  CREATE_NEW_TERRITORY_ERROR: 'Unable to create territory.',
  DUPLICATE_OVERRIDE_INCLUSION_KEY: 'The hierarchy inclusion key already exists in another override rule',
  CREATE_NEW_TERRITORY_SUCCESS: 'New territory created.',
  CREATE_NEW_TERRITORY_DIALOG_TITLE: 'Create new territory',
  EFFECTIVE_DATING: 'Effective dating',
  EXCEL: 'Excel',
  USER_PROFILE: 'Profile',
  PROFILE_PAGE_PERSONAL_INFORMATION: 'Personal information',
  PROFILE_PICTURE: 'Profile picture',
  PROFILE_PICTURE_SUBTITLE: 'Add a photo to personalize your account',
  USE_LASSO_TOOL: 'Use the Lasso tool ',
  DRAW_NEW_TERRITORY: ' to draw a new territory',
  EMPTY_MAP_GRID_TITLE: 'Unable to load previews',
  EMPTY_MAP_TGT_GRID_MESSAGE:
    'You may not have territories within your geographic territory group type. Please contact your administrator.',
  EMPTY_MAP_TGT_GRID_MESSAGE_2:
    'You may not have territories within your account territory group type. Please contact your administrator.',
  EMPTY_MAP_TG_GRID_MESSAGE:
    'You may not have territories within your territory group. Please contact your administrator.',
  PASSWORD_LAST_CHANGED: 'Last changed: {date}',
  PASSWORD_STRENGTH: 'Password strength: ',
  PASSWORD_STRENGTH_INSTRUCTION:
    "Use at least 8 characters. Don't use a password from another site, or something too obvious like your pet's name.",
  PASSWORD_INSTRUCTION: "Choose a strong password and don't reuse it for other accounts.",
  CHANGE_PASSWORD: 'Change password',
  CONFIRM_EXISTING_PASSWORD_LABEL: 'Re-enter password',
  NEW_PASSWORD_LABEL: 'New password',
  CONFIRM_NEW_PASSWORD_LABEL: 'Confirm new password',
  SET_BALANCING_METRIC: 'This is a balancing metric',
  MAKE_THIS_DEFAULT: 'Make this the default',
  BALANCING_MODAL_DELETE_HEADER: 'Remove this territory?',
  REBALANCING_COINSORT_HEADER: 'Run coinsort?',
  REBALANCING_COINSORT_BODY:
    'Did you want to run coin sort now? The territories will be sorted with the new rule updates.',
  BALANCING_MODAL_DELETE_BODY: 'Removing this territory will also cancel your unsaved changes.',
  BALANCING_MODAL_BATTLECARD_BODY: 'Closing this battle card will also cancel your unsaved changes.',
  REBALANCING_SUCCESS: 'Territories have successfully been rebalanced.',
  UPLOADING: 'Uploading...',
  UPLOAD: 'Upload',
  DONE: 'Done',
  DATA_FILE_DRAG_DROP_TITLE: 'Drop file or click to upload',
  DATA_FILE_DRAG_DROP_TEXT: 'Upload a text file from your local device. Accepted format is: .csv',
  FILE_PUBLISH_ERROR: 'Unable to publish your file',
  FILE_HAS_BEEN_PUBLISH_SUCCESS: '{name} has been published successfully',
  FILE_HAS_BEEN_PUBLISH_ERROR: '{name} has failed to publish',
  SYSTEM_TIMESTAMP: 'System timestamp',
  DATA_FILE_UPLOAD_SEQUENCE_ERROR: 'Sorry! Failed to upload file: {message}',
  REMOVE_REBALANCING_TERRITORY: 'Remove this territory',
  REMOVE_TERRITORY: 'Remove territory',
  ADDITIONAL_REBALANCING_TERRITORY_ERROR: 'Sorry! You can only select two territories at a time.',
  TERRITORY_RULE: 'Territory rule',
  UPDATE_TERRITORY_SUCCESS: 'The territory has been successfully updated.',
  UPDATE_TERRITORY_ERROR: 'Unable to update territory.',
  MENU: 'Menu',
  HELP: 'Help',
  WARNING: 'Warning',
  OPEN: 'Open',
  BUILT_IN_MEASURES_CANNOT_EDIT: 'Built-in measures cannot be edited.',
  BEFORE_BALANCING_METRIC_DEFAULT:
    'This metric will be used by default. For example, when rebalancing territories and you have multiple balancing metrics, a feature that uses one balancing metric at a time will use this metric by default.',
  DEFAULT_BALANCING_METRIC_WARNING_MESSAGE: 'Please select another balancing metric if you wish to change the default.',
  AUTOMATCH: 'Automatch',
  GET_S3_FILE_ERROR: 'Unable to retrieve the file content',
  SELECT_A_HIERARCHY: 'Select a hierarchy',
  SELECT_TERRITORY: 'Select territory',
  EDIT_HIERARCHY_NONE: 'Select a hierarchy to edit',
  IMPORT_FROM_CSV: 'Import from .csv file',
  IMPORT_FROM_SYMON: 'Import from pipe',
  MAP_DATA_ERROR: 'There was an error loading map data',
  CANNOT_REBALANCE_CUSTOMER:
    'You cannot move a customer that belongs to multiple territories or has unassigned activities across any quota component.',
  CANNOT_REBALANCE_GEOGRAPHY:
    'You cannot move a geography that belongs to multiple territories or has unassigned activities across any quota component.',
  CANNOT_REBALANCE_MISSING_GEO_HIERARCHY:
    'Unable to move geography because a hierarchy is missing. Please check that the uploaded hierarchies match those present in activity files.',
  TERRITORY_GRID_ERROR: 'The territories cannot be loaded in the grid. Click refresh to load the territories again.',
  ENTER_TERRITORY_GROUP_NAME: 'Enter a name for your territory group',
  GLOBAL_ERROR_BOUNDARY_TROUBLE: "We're having trouble juggling all that data. ",
  GLOBAL_ERROR_BOUNDARY_REFRESH: "Please refresh the page, we'll take you back home.",
  SYMON_AI_SOURCE: 'Pipe source',
  NO_RESULTS_FOUND: 'No results found',
  GET_SYMON_PIPENAMES_ERROR: 'Unable to retrieve pipenames.',
  GET_SYMON_EXPORT_NODES_ERROR: 'Unable to retrieve export nodes.',
  MOVE_TERRITORY: 'Move territory',
  MOVE_TERRITORY_DESCRIPTION: 'Choose the territory group to move the selected territory to:',
  MOVE_TERRITORY_ERROR: 'Unable to move territories.',
  MOVE_TERRITORY_SUCCESS_NAMED:
    '{territoryId} territory has been successfully moved into the {territoryGroup} territory group',
  MOVE_TERRITORY_SUCCESS_MULTIPLE:
    'Multiple territories have been successfully moved into the {territoryGroup} territory group',
  MOVE_TERRITORY_DISABLED:
    "You can't move the selected territories because they belong to different territory group types.",
  CARD_TYPE: 'Card type: ',
  BATTLECARD_TYPE_OVERLAY: 'Overlay',
  BATTLECARD_TYPE_OVERLAY_ICON: 'Overlay battle card icon',
  BATTLECARD_TYPE_PRIMARY: 'Primary',
  BATTLECARD_TYPE_PRIMARY_ICON: 'Primary battle card icon',
  BATTLECARD_TYPE_ROLL_UP: 'Roll-up',
  CREATE_CHILD_UNDER: 'Create child under {value}',
  ROOT: 'root',
  INTEGRATIONS: 'Integrations',
  SYMON: 'Symon',
  VARICENT_ELT: 'Varicent ELT',
  VIEW_SOURCES: 'View sources',
  MANAGE_TABLES: 'Manage tables',
  BUILD_AND_VISUALIZE: 'Build and visualize',
  DASHBOARDS: 'Dashboards',
  CONFIRM: 'Confirm',
  SYMON_CREDENTIALS_SAVE_SUCCESS: 'Pipe credentials successfully saved',
  SYMON_CREDENTIALS_DELETE_SUCCESS: 'Pipe credentials successfully deleted',
  SYMON_CREDENTIALS_SAVE_FAILED: 'Pipe credentials failed to save',
  SYMON_CREDENTIALS_BEING_VALIDATED: 'Your API credentials are being validated.',
  SYMON_CREDENTIALS_VALID: "We've validated your API key and the pipe connection is ready.",
  SYMON_CREDENTIALS_INVALID: 'Unable to verify, please ensure your credentials are correct.',
  VALIDATE_API_KEY: 'Validate API key',
  DELETE_API_KEY: 'Delete API key',
  SYMON_DOMAIN: 'Pipe domain',
  SAVED_SYMON_DOMAIN_INVALID_TITLE: 'Your saved pipe domain is invalid.',
  SAVED_SYMON_DOMAIN_INVALID_DESCRIPTION: 'Please update your key to one from the domain: ',
  SYSTEM_API_KEY: 'System API key',
  CONFIGURE_FOR_PLANNING: 'Configure for planning',
  GET_SYMON_ACCOUNT_ERROR: 'Unable to retrieve pipe account.',
  SYMON_DOMAIN_REQUIRED: 'Pipe domain is required.',
  SYSTEM_API_KEY_REQUIRED: 'System API key is required',
  SYMON_API_URL_INVALID: 'Invalid pipe URL',
  SYMON_EXPORT_NODE_REQUIRED: 'Pipe export node is required.',
  SYMON_PIPE_NAME_REQUIRED: 'Pipe source is required',
  DESTINATION_TABLE_REQUIRED: 'Destination table name is required',
  NEW_TERRITORY: 'New territory',
  FORWARD_SLASH: '/',
  INPUT_TEXT: 'Input text',
  TABLE_CONTROLLER_PUBLISHED_STATUS_FAILED_DEFAULT_ERROR:
    'Your file could not be published. Please check that your imported file has fields that are matched to the appropriate columns.',
  ACTION_REQUIRED_REVIEW_ERROR: 'Action required: review error',
  ACTION_REQUIRED_MATCH_FIELDS: 'Action required: match fields',
  MATCH_FIELDS_TITLE: 'Match fields',
  MATCH_FIELDS_TEXT_RUN_THIS_CONFIGURATION:
    'Select the required columns to match to your data fields. You must match all required columns to your data fields before you can run this configuration.',
  MATCH_FIELDS_TEXT_PUBLISH_THIS_FILE:
    'Select the required columns to match to your data fields. You must match all required columns to your data fields before you can publish this file.',
  MEMBER_SELECTED_WITH_COUNT: '{count} member selected',
  MEMBERS_SELECTED_WITH_COUNT: '{count} members selected',
  DELETE_MEMBER_WITH_VALUE: 'Delete {count} member',
  DELETE_MEMBERS_WITH_VALUE: 'Delete {count} members',
  DESELECT_ALL: 'Deselect all',
  DELETE_HIERARCHIES_ERROR: 'Unable to delete your hierarchies.',
  CONFIGURATIONS: 'Configurations',
  SYMON_AI_EXPORT_NODE: 'Export node',
  CREATE_CONFIGURATION: 'Create configuration',
  RUN_CONFIGURATION_AFTER_CREATING: 'Run this configuration after creating',
  NO_SYMON_PIPES: 'No pipes',
  UPDATED_HIERARCHY: 'Updated {rootName} "{name}".',
  CHECK_FOR_CHANGES: 'Check for changes',
  FILE_TYPE: 'File type',
  FILE_REQUIREMENT_TEXT: '{fileType} files require the following columns to import:',
  DESTINATION: 'Destination',
  DESTINATION_TABLE: 'Destination table',
  NO_SYMON_EXPORT_NODE_ERROR_MESSAGE:
    'There are no export nodes available for this source. Please select a different pipe source to create the configuration.',
  NO_SYMON_AI_SOURCE_ERROR_MESSAGE: 'To select a pipe source, you must first setup a pipe in the platform.',
  TEXT: 'Text',
  ACTIVITY: 'Activity',
  DATA_MAPPING_PANEL_TEXT:
    "Your data isn't in the system yet. This table is only a preview. To import your data and create a new table, click Run.",
  DATA_MAPPING_FIELDS_ERROR: 'Please check that you have matched all fields to the appropriate columns.',
  CONFIGURATION_CREATION_SUCCESS: 'Configuration successfully created',
  CONFIGURATION_CREATION_FAIL: 'Failed to create configuration',
  UNDER_BY: 'Under by {value}',
  OVER_BY: 'Over by {value}',
  RUN_THIS_CONFIGURATION: 'Run this configuration',
  SYMON_PIPE_CONFIGURATIONS_ERROR: 'Unable to retrieve your pipe configurations.',
  GETTING_PREVIEW_DATA: 'Getting configuration preview data from pipe',
  UPDATE_SYMON_PIPE_CONFIGURATION_SUCCESS: 'Configuration successfully updated.',
  UPDATE_SYMON_PIPE_CONFIGURATION_ERROR: 'Unable to update your pipe configuration.',
  DELETE_SYMON_PIPE_CONFIGURATION_SUCCESS: 'Configuration successfully deleted.',
  DELETE_SYMON_PIPE_CONFIGURATION_ERROR: 'Configuration failed to be deleted.',
  CONFIGURATION: 'Configuration',
  MEMBERS_REPARENTED_SUCCESS: '{count} members has been reparented successfully',
  SYMON_FILE_IMPORT_IN_PROGRESS: 'Importing data file',
  SYMON_FILE_IMPORT_IN_PROGRESS_MESSAGE: 'We are importing your data file to {tableName}',
  SYMON_FILE_IMPORT_ERROR: 'Failed to import data file',
  SYMON_FILE_IMPORT_ERROR_MESSAGE:
    "We couldn't import your data file to {tableName}. You can view the error in the Import errors tab.",
  NOT_STARTED: 'Not started',
  COLUMNS_NOT_MATCHED: 'Columns not matched',
  RUNNING: 'Running',
  COMPLETED: 'Completed',
  FAILED: 'Failed',
  FILE_SUCCESSFULLY_PUBLISHED: 'File successfully published',
  FILE_SUCCESSFULLY_PUBLISHED_MESSAGE:
    'Your file has been published to {tableName}. You can view your data file in the Manage tables tab.',
  GO_TO_MANAGE_TABLES: 'Go to Manage tables',
  GO_TO_IMPORT_ERRORS: 'Go to Import errors',
  MAKE_A_COPY: 'Make a copy',
  PLANNING_CYCLE_CLONE_FAILURE: 'Unable to clone planning cycle',
  PLANNING_CYCLE_CLONE_REQUEST_SUCCESS: 'Successfully requested planning cycle clone',
  SELECT_AN_EXISTING_CONFIGURATION: 'Select an existing configuration',
  IMPORT_CONFIG_MESSAGE:
    "We'll import your data file into the destination table. You will be able to view it on the Data page.",
  CREATE_NEW_CONFIGURATION: 'Create new configuration',
  RUN_EXISTING_CONFIGURATION: 'Run existing configuration',
  FILE_PREVIEW: 'File preview',
  REVIEW_IMPORT: 'Review import',
  COMMENTS: 'Comments',
  ALL_GROUPS: 'All groups',
  ALL_TERRITORIES: 'All territories',
  NO_COMMENTS_TO_SHOW: 'No comments to show',
  ADD_COMMENTS_MESSAGE: 'Add comments to provide feedback about territory planning',
  ADD_COMMENTS: 'Add comments',
  NEW_COMMENTS_PLACEHOLDER: 'Use @mentions to tag a territory ID or group',
  REPLY: 'Reply',
  MAP_SELECTION_STATS_ERROR: 'Unable to get stats for selection',
  ENTER_TABLE_NAME: 'Enter table name',
  CHOOSE_THE_SOURCE_AND_EXPORT_NODE: 'Choose the source and export node to pull your data file from.',
  SYMON_AI_SOURCE_AND_EXPORT_NODE: 'Pipe source and export node',
  SYMON_REVIEW_DIALOG_CONFIGURATION_TEXT: "We'll save this configuration for you on the Configurations page.",
  SYMON_REVIEW_DIALOG_EXISTING_CONFIGURATION_TEXT:
    "We'll import your existing configuration again. This will create a new version of your {name} data table.",
  TERRITORY_RULES: 'Territory rules',
  MAP: 'View map',
  GRID: 'View grid',
  URL_ERROR_MESSAGE_GENERIC: 'Please enter a valid URL',
  INCLUSIONS: 'Inclusions',
  EXCLUSIONS: 'Exclusions',
  VIEW_TERRITORIES: 'View territories',
  VIEW_QUOTAS: 'View quotas',
  NO_ACTIVITIES_TOOLTIP: "You haven't added any activity data for this battle card yet.",
  COINSORT_COMPLETE_TOOLTIP: 'Run coin sort to get the most accurate stats',
  COINSORT_RUNNING_TOOLTIP: "We're sorting your activities into territories. Check back soon.",
  HIDDEN: 'Hidden',
  NON_MAPPABLE_TOOLTIP: 'This territory contains complex rules and is not able to render on your map',
  CREATE_TERRITORY_TOOLTIP_GEO: 'Select unassigned boundaries to create a new territory.',
  CREATE_TERRITORY_TOOLTIP_ACCOUNT: 'Select unassigned accounts to create a new territory.',
  IMPORT_QUOTAS: 'Import quotas',
  REQUIRED: 'Required *',
  OPTIONAL: 'Optional',
  ADD_RULE: 'Add rule',
  ADD_OVERRIDE: 'Add override',
  OVERRIDE_RULE_TOOLTIP_ENABLED:
    'Overrides will be moved to this territory and removed from their current territory rules.',
  BASE_RULE: 'Base rule',
  OVERRIDE: 'Override',
  HIERARCHY_MEMBER_DELETE_SUCCESS: 'Hierarchy member deleted successfully.',
  YOUR_FILE_IS_MISSING: 'Your file is missing at least {count} required column(s).',
  DOWNLOAD_QUOTA_FILE_TEXT: 'Download template quota file',
  DOWNLOAD_SELLER_ASSIGNMENT_FILE_TEXT: 'Download template seller assignment file',
  DOWNLOAD_ACTIVITY_FILE_TEXT: 'Download template activity file',
  DOWNLOAD_LOCATION_TEMPLATE_CSV: 'Download template CSV file',
  CONFLICTING_RULES: '{count} conflicting rules',
  CONFLICTING_RULE: 'Conflicting rule',
  LOCKED: 'Locked',
  CONFLICTING_RULES_GRID_TOOLTIP: 'This territory contains rules that conflict with another territory',
  REMOVE_CONFLICT_BUTTON: 'Remove conflicts',
  REMOVE_CONFLICT_ACTION_TOOLTIP: 'Remove conflict by deleting geographic region from selected territory',
  REMOVE_CONFLICT_ACTION_TOOLTIP_2: 'Remove conflict by deleting account from selected territory',
  REMOVE_CONFLICT_DIALOG_TITLE: 'Remove territory rule conflicts?',
  REMOVE_CONFLICT_DIALOG_TEXT: 'You are about to remove the following territory rule conflicts from {territory}:',
  NO_TERRITORY_RULES:
    "The following territories don't contain territory rules, so there's nothing to show on the map: {territories}",
  HIDE_GRID: 'Hide grid',
  SHOW_GRID: 'Show grid',
  CHANGE_COLOR: 'Change color',
  UPLOAD_HIERARCHY: 'Upload hierarchy',
  UPLOAD_HIERARCHY_NONE: 'Select a hierarchy to upload to',
  UPLOAD_TO: 'Upload to {name}',
  SET_SEASONALITY: 'Set seasonality',
  APPLY: 'Apply',
  FORMULA_EDITOR_TOOLTIP_MESSAGE:
    'Select operators and data sources to build your formula, or type it into this text box.',
  CALCULATED_FIELD_EXAMPLE: 'Example: (Income-Expenses)*0.4',
  OPERATORS: 'Operators',
  CANNOT_VIEW_TERRITORY_GROUP: 'You can only view territory groups that you own',
  START_WORKFLOW: 'Start workflow',
  STOP_WORKFLOW: 'Stop workflow',
  START_TERRITORY_WORKFLOW_HEADER: 'Start territory workflow?',
  START_TERRITORY_WORKFLOW_BODY:
    'Did you mean to start this territory workflow? This will notify all contributors that the workflow has started. Contributors will be able to view and leave comments on the territories that they own.',
  STOP_TERRITORY_WORKFLOW_HEADER: 'Stop territory workflow?',
  STOP_TERRITORY_WORKFLOW_BODY:
    'Did you mean to stop this territory workflow? This will notify all contributors that the workflow has stopped. Contributors will no longer be able to access the planning cycle.',
  RETRIEVE_TERRITORY_WORKFLOW_ERROR:
    "We're having trouble loading your workflow options. To start or stop your workflow, please refresh the page.",
  START_TERRITORY_WORKFLOW_ERROR:
    "We're having trouble starting this workflow right now. Please refresh the page and try again.",
  STOP_TERRITORY_WORKFLOW_ERROR:
    "We're having trouble stopping this workflow right now. Please refresh the page and try again.",
  ENTER_COMMENT: 'Please enter comment',
  COMMENT_PANEL_LOADING_ERROR: "Sorry, we're having trouble loading these comments right now. Try refreshing the page.",
  POST_COMMENT_ERROR: "Sorry, we're having trouble posting this comment. Please try again.",
  SELLER_ASSIGNMENT: 'Seller assignment',
  ADD_MORE: 'Add more',
  GET_QUOTA_WORKFLOW_STATUS_ERROR: 'Unable to retrieve quota workflow status from the server.',
  SEASONALITY_INSTRUCTION:
    'Enter custom seasonality weights, or choose an existing seasonality to start. The total allocated value must equal 100%.',
  SET_PC_DURATION_PERIODICITY_WARNING: 'Plan duration and periodicity warning',
  SET_PC_DURATION_PERIODICITY_WARNING_DETAIL_UPDATED:
    "Please review your plan duration and periodicity interval cycle. You won't be able to change these after you've created your planning cycle.",
  IMPORT_SELLER_ASSIGNMENTS: 'Import seller assignments',
  MULTIPLE: 'Multiple',
  FORMULA_EDITOR_VALUE_EXPECTED_ERROR_BEFORE: 'Add a data source or value before the {operator}',
  FORMULA_EDITOR_VALUE_EXPECTED_ERROR_BEFORE_FULL:
    'Add a data source or value before the {operator} ({text}) operator.',
  FORMULA_EDITOR_VALUE_EXPECTED_ERROR_AFTER_FULL: 'Add a data source or value after the {operator} ({text}) operator.',
  FORMULA_EDITOR_VALUE_EXPECTED_ERROR_AFTER: 'Add a data source or value after the {operator}',
  MISSING_CLOSED_PARENTHESIS_ERROR:
    'This formula is missing a closed parenthesis. Make sure to match all open and closing parentheses.',
  INVALID_DATA_SOURCE_ERROR:
    'This formula contains an unrecognized data source. Check your data sources for correct spelling.',
  UNEXPECTED_OPERAND_NO_OPERATOR_ERROR:
    "Unexpected operand '{{value}'}. Data sources and values must be separated by an operator.",
  OWNERSHIP: 'Ownership',
  ADD_SELLER: 'Add seller',
  EXPORT_TO_CSV: 'Export to .csv file',
  EXPORTING_DATA: 'Exporting data...',
  EXPORTING_DATA_FAILED: 'Failed to export data',
  TERRITORY_WORKFLOW_NOT_STARTED: "Your administrator hasn't started a territory planning workflow.",
  TERRITORY_TAB_NO_PERMISSIONS: "You don't have view permissions for this tab.",
  QUOTA_WORKFLOW_NOT_STARTED: "Your administrator hasn't started a quota planning workflow.",
  DELETE_SELLER_ASSIGNMENT: 'Delete assignment?',
  DELETE_SELLER_ASSIGNMENT_CONFIRMATION_MESSAGE:
    "Do you mean to delete this seller assignment? Deleting can't be undone",
  EDIT_ASSIGNMENT: 'Edit assignment',
  FORMULA_REQUIRED: 'Please provide a valid formula.',
  DELETE_SELLER_ASSIGNMENT_SUCCESS: 'Delete seller assignment successfully.',
  DELETE_SELLER_ASSIGNMENT_ERROR: 'Unable to delete seller assignment.',
  DELETE_SELLER_FROM_MULTIPLE_TERRITORIES_ERROR: 'Not all sellers were successfully deleted.',
  REMOVE_SELLER_TITLE: 'Seller removed',
  REMOVE_SELLERS_TITLE: 'Sellers removed',
  REMOVE_SELLER_ERROR_TITLE: 'Failed to remove sellers',
  PERCENTAGE: 'Percentage',
  KEEP: 'Keep',
  ADD_GEO_HIERARCHY_TITLE: 'Add geographies hierarchy data',
  ADD_GEO_HIERARCHY_DESC:
    'Select from a list of supported countries to add geographies hierarchy data to your planning cycle.',
  GEO_GRID_DESC:
    'Next, select the geographic level you want to use to plan your territories, for example, country, state, or zip.',
  NO_IN_USE_GEO_DESC: 'You need to add geographies hierarchy data before you can create territories on the map.',
  ADD_GEO_HIERARCHY_DROP_DOWN_LABEL: 'Select countries',
  EXTERNAL_DOCS_LINK: 'Click here to learn more about geographic levels',
  RAMP: 'Ramp',
  NO_RAMP: 'No ramp',
  RENEW: 'Renew',
  GEO_HIERARCHY_UPDATED_SUCCESS: 'Geographies hierarchies updated',
  GEO_HIERARCHY_UPDATED_ERROR: 'There was an error updating geographies hierarchies',
  GEO_HIERARCHY_GET_ERROR: 'There was an error getting your geographies hierarchies',
  PLAN_TERRITORIES: 'Plan territories',
  PLAN_QUOTAS: 'Plan quotas',
  LOOKUP: 'Lookup',
  SCHEDULE_NAME: 'Schedule name',
  ENTER_NAME: 'Enter name',
  MONTH: 'Month {value}',
  SEASONALITY_SUM_MESSAGE: 'Your monthly seasonality schedule must total to 100%.',
  RAMP_VALUE_MESSAGE:
    'A month in your ramp schedule exceeds 100%. Please adjust your ramp schedule so that every month is between 0% and 100%.',
  PERCENTAGE_VALUE_MESSAGE: 'Only numeric values greater or equal than 0 are accepted.',
  NO_END_DATE: 'No end date',
  NO_START_DATE: 'No start date',
  SCHEDULE_NAME_EXIST_ERROR: 'Schedule name already exists.',
  SCHEDULE_NAME_EMPTY_ERROR: 'Please enter a schedule name.',
  YOUR_MONTH_SEASONALITY_PLEASE_ADJUST:
    'Your monthly seasonality schedule total is {value}%. Please adjust your schedule so that the sum of all months equals 100%.',
  ENTER_AMOUNT: 'Enter amount',
  RAMP_SCHEDULES: 'Ramp schedules',
  SEASONALITY_SCHEDULES: 'Seasonality schedules',
  VIEW_RAMP_DATA: 'View ramp data',
  VIEW_SEASONALITY_DATA: 'View seasonality data',
  GET_LOOKUP_TABLE_SCHDULES_ERROR: 'Unable to retrieve schedules',
  UPSERT_LOOKUP_ROW_ERROR: 'Unable to add the new schedule',
  SYMON_ERROR_MESSAGE_1: "We couldn't load this page.",
  SYMON_ERROR_MESSAGE_2: 'Please refresh the page to try again.',
  REFRESH: 'Refresh',
  SYMON_IMPORT_THIRD_PARTY_HEADER_OVERRIDE: 'Data connectors ({num})',
  RAMP_TABLE_MESSAGE: 'Your monthly ramp schedule must be between 0 and 100.',
  SELLERS_ADDED: 'Sellers added',
  FAILED_TO_ADD_SELLER: 'Failed to add sellers',
  FAILED_TO_ADD_SELLER_MESSAGE: "We couldn't add sellers to your territory. Please refresh the page and try again.",
  SYMON_UPLOAD_START_MESSAGE: "We're uploading your file.",
  SYMON_IMPORT_START_MESSAGE: "We've started your import. You can access it from the View sources tab.",
  SYMON_UPLOAD_ERROR: 'We were unable to upload your file.',
  SYMON_CONNECTION_UPDATED_TITLE: 'Connection updated',
  SYMON_CONNECTION_UPDATED_BODY: 'Your {connectionType} has been validated',
  SYMON_CONNECTION_FAILURE_TITLE: 'Invalid credentials',
  SYMON_CONNECTION_FAILURE_BODY:
    'Your {connectionType} connection could not be validated. Review its credentials and try again.',
  IMPORT_ERRORS: 'Import errors',
  VIEW_DETAILS: 'View details',
  COPY_SUMMARY: 'Copy summary',
  ROLE_PERMISSIONS: 'Role permissions',
  ERROR_SUMMARY_COPIED_TO_CLIPBOARD: 'Error summary has been copied to clipboard',
  PLEASE_SELECT_A_START_DATE: 'Please select a start date.',
  PLEASE_SELECT_AN_END_DATE: 'Please select an end date.',
  SEASONALITY_TOOLTIP_MESSAGE:
    'This is where you apply your seasonality schedule. Use these schedules to divide quota across your planning cycle based on seasonal trends.',
  PLANNED_QUOTA_TOOLTIP_MESSAGE:
    'This is the final planned quota value from your active planning cycle. It includes your allocated quota target (top-down or imported) plus any quota adjustments.',
  ASSIGNMENT_PERCENTAGE_TOOLTIP_MESSAGE:
    'This is the percent of the territory quota assigned to sellers during their effective dates.',
  RAMP_TOOLTIP_MESSAGE:
    'This is where you apply your ramp schedule. Use these schedules to onboard new sellers and ramp them up to full productivity.',
  HISTORICAL_MEASURE_DATE_RANGE_TOOLTIP_MESSAGE:
    "Select a start and end date to only include activity data within this date range. If you don't select a date, we'll default to the planning cycle start and end date.",
  MAP_DISABLED_TOOLTIP_MESSAGE:
    'To use the map, add the geographies hierarchy to your battle card as a territory group type',
  MAP_DISABLED_TOOLTIP_MESSAGE_2:
    'To use the map, add the geographies or customer accounts hierarchy to your battle card as a territory group',
  BULK_DELETE_DISABLED_TOOLTIP: "Delete function disabled while 'Select all' is active and filters are applied.",
  NEW_FIELD: 'New field',
  EXISTING_FIELD: 'Existing field',
  ADD_EXISTING_FIELD: 'Add existing field',
  SELECT_MEASURE_NAME_REQUIRED: 'Please select a column name.',
  SELECT_COLUMN_NAME: 'Select a column name',
  NO_EXISTING_MEASURES: 'No valid existing measures',
  REMOVE_SELLER_ASSIGNMENT: 'Remove seller assignment',
  ACTIVATE: 'Activate',
  ACTIVATE_WITH_NAME: 'Activate {name}?',
  ACTIVATION_STARTED: 'Activation started',
  ACTIVATE_PLANNING_CYCLE: 'Activate planning cycle',
  SELECT_A_TERRITORY: 'Select a territory',
  ACTIVATE_PLANNING_CYCLE_CONFIRMATION:
    "Are you ready to activate your {name} planning cycle? This will create a new manage version of your planning cycle, based on your final plan version. Once you activate your plan version, it's still available, but should be used for reference only.",
  ACTIVATE_PLANNING_CYCLE_MANAGE_VERSION:
    'Your manage version allows for continuous planning throughout your planning cycle. Use it to adapt to market shifts or employee turnover, and keep your plan version preserved for reference.',
  ACTIVATE_PLANNING_CYCLE_BEFORE_MESSAGE:
    'Before you activate this planning cycle, make sure all required stakeholders have approved.',
  ACTIVATE_PLANNING_CYCLE_WARNING: "You can only activate your planning cycle once and activating can't be undone.",
  ACTIVATE_SCENARIO_CONFIRMATION:
    "This will create a new manage version of your published scenario. Once you activate your published scenario, it's still available, but should be used for reference only.",
  ACTIVATE_SCENARIO_MANAGE_VERSION:
    'Your manage version allows for continuous planning throughout your planning cycle. Use it to adapt to market shifts or employee turnover, and keep your published scenario preserved for reference.',
  ACTIVATE_SCENARIO_BEFORE_MESSAGE:
    'Before you activate this scenario, make sure all required stakeholders have approved.',
  ACTIVATE_SCENARIO_WARNING:
    "You can only activate the published scenario for your planning cycle once and activating can't be undone.",
  SCENARIO_ACTIVATION_STARTED_MESSAGE:
    "We're activating your {name} scenario. This may take a few minutes. Please don't make changes to your planning cycle until this process completes.",
  ACTIVATION_STARTED_MESSAGE:
    "We're activating the manage version of your {name} planning cycle. This may take a few minutes. Please don't make changes to your planning cycle until this process completes.",
  CREATE_QUOTA_SHEET: 'Create quota sheet',
  QUOTA_SHEET_CREATED: 'Quota sheet successfully created',
  CREATE_QUOTA_SHEET_ERROR: 'Unable to create a quota sheet.',
  QUOTA_BLANK_NAME_ERROR: 'Quota sheet name cannot be blank.',
  EDIT_SHEET_NAME: 'Edit sheet name',
  SHEET_RENAME_SUCCESS: 'Sheet renamed successfully',
  REVISED_TERRITORY_QUOTA: 'Revised territory quota',
  ASSIGNED_SELLER_QUOTA: 'Assigned seller quota',
  CHANGE_HISTORY_EXPORT_STARTED: 'Export started',
  CHANGE_HISTORY_EXPORT_ERROR: 'Export failed',
  CHANGE_HISTORY_EXPORT_SUCCESS: 'Audit trail exported',
  EXPORT_CHANGE_HISTORY: 'Export audit trail',
  EXPORT_CHANGE_HISTORY_DISABLED: "We're exporting your audit log. Please wait while this process completes.",
  CHANGE_HISTORY_TOAST: "We're exporting your audit trail for your {planningCycleName} planning cycle.",
  CHANGE_HISTORY_EXPORT_SUCCESS_DESCRIPTION:
    'We exported your audit trail for your {planningCycleName} planning cycle.',
  ACTIVATION_FAILED: 'Activation failed',
  SCENARIO_ACTIVATION_FAILED_MESSAGE:
    "We couldn't activate your {name} scenario. Please refresh the page and try again.",
  ACTIVATION_FAILED_MESSAGE: "We couldn't activate your {name} planning cycle. Please refresh the page and try again.",
  ACTIVATE_PLANNING_CYCLE_COMPLETED: 'Activation complete',
  SCENARIO_ACTIVATION_COMPLETED_MESSAGE: 'We activated your {name} scenario. You are now managing your planning cycle.',
  ACTIVATION_COMPLETED_MESSAGE: 'We activated your {name} planning cycle. You are now managing your planning cycle.',
  EQUALS: 'Equals',
  NOT_EQUAL: 'Not equal',
  LESS_THAN: 'Less than',
  LESS_THAN_OR_EQUALS: 'Less than or equal',
  GREATER_THAN: 'Greater than',
  GREATER_THAN_OR_EQUAL: 'Greater than or equal',
  CONTAINS: 'Contains',
  NOT_CONTAINS: 'Not contains',
  STARTS_WITH: 'Starts with',
  ENDS_WITH: 'Ends with',
  COPY_WITH_NAME: 'Copy {name}?',
  COPY_PC_MESSAGE:
    'This planning cycle is active and a manage version exists. When you copy this planning cycle, we copy the data from your original plan version. This means that any changes made in the manage version are not copied. Your new planning cycle will only include a plan version, until you choose to activate it. Do you want to continue?',
  DELETE_HIERARCHY_CONFIRMATION_MESSAGE:
    "Did you mean to delete this hierarchy? This will delete the hierarchy and all its members. This will also break any territory rules that reference the hierarchy. Deleting can't be undone.",
  DELETE_HIERARCHY_MEMBER_CONFIRMATION_MESSAGE:
    "Did you mean to delete this hierarchy member? This will delete the hierarchy member and all its children. This will also break any territory rules that reference the hierarchy member. Deleting can't be undone.",
  DELETE_HIERARCHY_MEMBERS_CONFIRMATION_MESSAGE:
    "Did you mean to delete these hierarchy members? This will delete the hierarchy members and all their children. This will also break any territory rules that reference any of these hierarchy members. Deleting can't be undone.",
  DELETE_HIERARCHY_CUSTOM_ATTRIBUTE: 'This will delete attribute values for all members.',
  QUOTA_GRID_ERROR: 'We could not generate grid data. Please run coin sort first for the battle card and try again.',
  ADD_QUOTA_ADJUSTMENT: 'Add quota adjustment',
  EXCLUDE_COLUMN: 'Exclude column',
  DELETE_MULTIPLE_TERRITORIES_DIALOG_TITLE: 'Delete territories?',
  DELETE_BC_TERRITORIES_CONFIRM:
    "Did you mean to delete {count} territories in this battle card? Deleting can't be undone.",
  DELETE_BC_TERRITORY_CONFIRM:
    "Did you mean to delete {count} territory in this battle card? Deleting can't be undone.",
  DELETE_ALL_BATTLECARD_TERRITORIES_CONFIRMATION_MESSAGE:
    "Did you mean to delete all territories in this battle card? Deleting can't be undone.",
  DELETE_BC_TERRITORIES_WITH_EXCEPTION:
    "Did you mean to delete all territories in this battle card except {count} territories that are unselected? Deleting can't be undone.",
  DELETE_BC_TERRITORY_WITH_EXCEPTION:
    "Did you mean to delete all territories in this battle card except {count} territory that is unselected? Deleting can't be undone.",
  DELETE_TG_TERRITORIES_CONFIRM:
    "Did you mean to delete {count} territories in this territory group? Deleting can't be undone.",
  DELETE_TG_TERRITORY_CONFIRM:
    "Did you mean to delete {count} territory in this territory group? Deleting can't be undone.",
  DELETE_ALL_TERRITORY_GROUP_TERRITORIES_CONFIRMATION_MESSAGE:
    "Did you mean to delete all territories in this territory group? Deleting can't be undone.",
  DELETE_TG_TERRITORIES_WITH_EXCEPTION:
    "Did you mean to delete all territories in this territory group except {count} territories that are unselected? Deleting can't be undone.",
  DELETE_TG_TERRITORY_WITH_EXCEPTION:
    "Did you mean to delete all territories in this territory group except {count} territory that is unselected? Deleting can't be undone.",
  HOW_IT_WORKS: 'How it works:',
  START_OPTIMIZATION: 'Start optimization',
  CONTINUE_OPTIMIZATION: 'Continue optimization',
  EXPORT_CURRENT_TERRITORY_RULES: 'Export current territory rules',
  CONSTRAINTS: 'Constraints',
  PARAMETERS_AND_TERRITORY_RULES: 'Parameters and territory rules',
  OPTIMIZE_RESULTS: 'Optimize results',
  OPTIMIZE_TERRITORIES: 'Optimize territories',
  FAIL_OPTIMIZE_TERRITORIES: 'Failed to optimize territories',
  OPTIMIZATION_SETUP: 'Optimization setup',
  OPTIMIZE_TERRITORIES_FOR_TG: 'Optimize territories for {territoryGroup}',
  TERRITORY_OPTIMIZATION: 'Territory optimization',
  TERRITORY_OPTIMIZATION_TITLE: 'Optimization — the ultimate solution to enhance your sales plan!',
  TERRITORY_OPTIMIZATION_DESCRIPTION_STEP1:
    'You have the option to set parameters and constraints to run the optimization tool against.',
  TERRITORY_OPTIMIZATION_DESCRIPTION_STEP2:
    'Then, you can select from three different scenarios of territories that best aligns with your business objectives.',
  TERRITORY_OPTIMIZATION_DESCRIPTION_WARNING:
    'After publishing the optimization results, your current territory rules will be permanently modified.',
  TERRITORY_OPTIMIZATION_DESCRIPTION_EXPORT:
    'You have the option to revert the changes by exporting your current territory rules.',
  FAIL_BULK_DELETE_TERRITORY_TITLE: 'Failed to delete territories',
  REMOVE_RULE_HAS_OVERLAY_DEPENDENCY:
    'Some rules are in use by an overlay. Please remove from the overlay and try again.',
  REMOVE_RULE_HAS_ONGOING_TERRITORY_OPTIMIZATION:
    'The rule group is currently being optimized. Please try again after territory optimization has completed.',
  FAIL_BULK_DELETE_TERRITORIES: 'We failed to delete {count} territories.',
  FAIL_BULK_DELETE_TERRITORY: 'We failed to delete {count} territory.',
  FAIL_BULK_DELETE_TERRITORY_GENERIC: 'We failed to delete selected territories.',
  START_BULK_DELETE_TERRITORY_TITLE: 'Territories are being deleted',
  START_BULK_DELETE_TERRITORY_GENERIC: "We're deleting selected territories.",
  TERRITORY_DEFINITION_RADIO_LABEL: 'Do you want your territories to be defined by accounts or geographies?',
  RUN_TIME_SELECT_LABEL: 'Select a run time based on your desired level of precision.',
  METRIC_OPTIMIZATION_SELECT_LABEL: 'Select an attribute or metric to optimize your territories against.',
  NUMBER_OF_TERRITORIES: 'Number of territories',
  GEOGRAPHIC_BASED_RULES: 'Geographic-based rules',
  CONSTRAINTS_PAGE_DESCRIPTION: 'You can set constraints on your accounts and the resulting territories.',
  IF_ALL_COMBINATOR_DESCRIPTION_TEXT_ACCOUNTS: 'Do not move accounts if all of the following conditions are true',
  IF_ALL_COMBINATOR_DESCRIPTION_TEXT_TERRITORIES:
    'Optimize territories so that all of the following conditions are true',
  CURRENT_STATE: 'Current state',
  CURRENT_SCENARIO: 'Current scenario',
  MOST_BALANCED: 'Most balanced',
  MOST_CONTIGUOUS: 'Most contiguous',
  OPTIMIZE: 'Optimize',
  DISRUPTION: 'Disruption',
  TERRITORY_MAP: 'Territory map',
  BATTLE_CARD_1: 'Battle card 1:',
  BATTLE_CARD_2: 'Battle card 2:',
  SCENARIO_PLANNING_BC_PICKER_LABEL: 'Battle card:',
  WITH: 'With',
  COMPARE: 'Compare',
  COMPARE_SELECTION: '{scenarioName} / {quotaComponentName} / ',
  SCENARIO_NAME: 'Scenario name',
  SEE_ALL_SCENARIOS: 'See all scenarios',
  SCENARIO_RENAMED: 'Scenario renamed',
  SCENARIO_RENAMED_MESSAGE: 'We renamed your scenario to {name}.',
  SCENARIO_NAME_EXISTS: 'The scenario name "{name}" already exists.',
  SCENARIO_RENAMED_FAILURE: 'Failed to rename scenario',
  PUBLISH_SCENARIO_CONFIRMATION: 'Publish scenario: {scenarioName}?',
  PUBLISH_SCENARIO: 'Publish scenario',
  RENAME_SCENARIO: 'Rename scenario',
  CHARACTER_COUNT_READOUT: '{currentLength}/{maxLength} characters',
  CREATE_SCENARIO: 'Create scenario',
  DELETING_SCENARIO: 'Deleting scenario',
  SCENARIO_LIMIT_REACHED: 'Scenario limit reached',
  SCENARIO_LIMIT_DIALOG_BODY:
    "You've reached the limit of {limit} scenarios for your {name} planning cycle. Delete existing scenarios to free up space.",
  SCENARIO_COPY_FAILED: 'Failed to copy scenario',
  SCENARIO_CREATE_FAILED: 'Failed to create scenario',
  SCENARIO_CREATED: 'Scenario created',
  SCENARIO_CREATED_MESSAGE: 'We created your {name} scenario.',
  COPY_SCENARIO: 'Copy scenario',
  COPY_SCENARIO_NAME: '{name} (copy)',
  SCENARIO_COPY_STARTED: 'Scenario copy started',
  SCENARIO_COPY_MESSAGE:
    "We're copying your {name} scenario. Please don't make changes to your scenario until this process completes.",
  SCENARIO_COPIED: 'Scenario copied',
  SCENARIO_COPIED_MESSAGE: 'We copied your {name} scenario.',
  SCENARIO_DELETED_MESSAGE: 'We deleted your {name} scenario.',
  DELETION_STARTED_MESSAGE: "We're deleting your {name} scenario.",
  BLANK_SCENARIO: 'Blank scenario',
  NO_RECENT_SCENARIOS: 'There are no other recently opened scenarios.',
  NAME_REQUIRED: 'Name *',
  OPEN_RECENT: 'Open recent',
  MANAGE_SCENARIOS: 'Manage scenarios',
  ACTIVATE_SCENARIO: 'Activate scenario',
  SCENARIO_WITH_COLON: 'Scenario:',
  COMPARE_MODE: 'Compare mode',
  RENAME: 'Rename',
  COMPARE_MODE_ACROSS_DM_DIALOG_BODY:
    'View, edit, and compare maps of two battle cards from any scenario within your planning cycle.',
  COMPARE_MODE_WITHIN_DM_DIALOG_BODY: 'View, edit, and compare maps of two battle cards from your planning cycle.',
  COMPARE_MODE_TOOLTIP_TITLE: "You've entered Compare mode",
  COMPARE_MODE_TOOLTIP_BODY: 'Changes made in Compare mode will be applied to your battle card(s).',
  COMPARE_MODE_ERROR_MISSING_REQUIRED:
    'Please select a scenario, quota component, and battle card for each map you want to compare.',
  COMPARE_MODE_ERROR_IDENTICAL_BC: "You can't compare maps of the same battle card from the same scenario.",
  ACCOUNT_MOVES: 'Account moves',
  OPTIMIZATION_METRICS: 'Optimization metrics',
  OPTIMIZING_TERRITORIES: "We're optimizing your territories.",
  OPTIMIZING_TERRITORIES_DESCRIPTION:
    "This process may take several minutes. You'll be notified when optimization is complete.",
  OPTIMIZATION_FAILED: 'Optimization failed',
  OPTIMIZATION_FAILED_DESCRIPTION:
    "We couldn't optimize your territories based on your selected parameters and constraints. Please update your parameters or constraints and try again.",
  OPTIMIZATION_SUCCESS: 'Your territories are optimized.',
  OPTIMIZED_TERRITORIES: 'We optimized your territories for your {territoryGroup} territory group type.',
  BACK_TO_CONSTRAINTS: 'Back to constraints',
  VIEW_RESULTS: 'View results',
  OPTIMIZATION_RESULTS: 'Optimization results for {territoryGroup}',
  MIN_NUMBER_OF_ACCOUNTS: 'Min. number of accounts',
  MAX_NUMBER_OF_ACCOUNTS: 'Max. number of accounts',
  DISRUPTION_ACCOUNTS_REASSIGNED: 'Disruption (accounts reassigned)',
  CURRENT_STATE_DESCRIPTION: 'Your original configuration of territories and accounts',
  MOST_BALANCED_DESCRIPTION: 'An equal distribution of the selected attribute across territories',
  MOST_CONTIGUOUS_DESCRIPTION: 'Geographically contiguous territories that share a common border',
  UNSELECT: 'Unselect',
  OPTIMIZATION_RESULTS_PAGE_HEADER_DESCRIPTION:
    'Select a scenario to publish to your territory rules. You can preview a scenario to view a breakdown of the territories and optimization metrics.',
  PUBLISH_CHANGES_TO: 'Publish changes to {value}',
  PUBLISH_OPTIMIZATION_DESCRIPTION:
    'You are about to publish changes to your territory rules which will sort your accounts based on the optimization run.',
  PUBLISH_OPTIMIZATION_DESCRIPTION_EMPHASIS: 'Publishing will permanently modify your current territory rules.',
  PUBLISH_OPTIMIZATION_DESCRIPTION_WARNING:
    'You can revert the changes by exporting your current territory rules. Are you sure you want to continue?',
  EXIT_OPTIMIZATION: 'Exit optimization',
  PUBLISHED_OPTIMIZATION_TITLE: "You've optimized your {territoryGroup} territory group!",
  VIEW_OPTIMIZATION_SUMMARY_TEXT: "While we update your territory rules, here's a summary of your new territories.",
  SCORE_BREAKDOWN: 'Score breakdown',
  OPTIMIZATION_PUBLISH_SUBTITLE:
    'Your territories are now balanced based on your selected metric, {selectedOptimizationMetric}.',
  OPTIMIZATION_PUBLISH_SUBTITLE_GENERAL: 'Your territories are now balanced based on your selected metric.',
  TERRITORY_QUOTA: 'Territory quota',
  SELLER_QUOTA: 'Seller quota',
  ACCOUNT_QUOTA: 'Account quota',
  TERRITORY_BALANCING: 'Territory balancing',
  ACCOUNT_LIST: 'Account list',
  ASSIGNED_SELLERS: 'We assigned {count} sellers to {territory}',
  LOADING_SHEETS_DATA: 'Loading sheets data...',
  BOOK_OF_BUSINESS: 'Book of business',
  YEARLY: 'Yearly',
  SEMI_ANNUAL: 'Semi-annual',
  QUARTERLY: 'Quarterly',
  MONTHLY: 'Monthly',
  SORTING_COINSORT: 'Sorting territories ({currentNumberOfRulesProcessed} of {currentNumberOfRules})...',
  INPUT: 'Input',
  HISTORICAL: 'Historical',
  LOADED: 'Loaded',
  ALLOCATION: 'Allocation',
  CALCULATED: 'Calculated',
  TERRITORY_GROUP_TYPE_OWNER: 'Territory group type owner',
  TERRITORY_GROUP_OWNER: 'Territory group owner',
  TERRITORY_GROUP_AND_GROUP_TYPE_OWNER: 'Territory group and territory group type owner',
  BATTLE_CARD_OWNER: 'Battle card owner',
  ADMINISTRATOR: 'Administrator',
  CONTRIBUTOR: 'Contributor',
  NONE: 'None',
  NAME_IS_A_REQUIRED_FIELD: 'Name is a required field',
  IN_PROGRESS: 'In progress',
  APPROVED: 'Approved',
  SUBMITTED: 'Submitted',
  REJECTED: 'Rejected',
  APPROVER: 'Approver',
  ACCOUNT_KEY: 'Account key',
  MEMBERS_DELETE_SUCCESS_WITH_COUNT: '{count} members were deleted successfully.',
  MEMBERS_WITH_COUNT: '{count} members',
  SEND: 'Send',
  DELETE_VALUE: 'Delete {value}',
  DELETE_SELLER_FROM_TERRITORY_SUCCESS_MESSAGE: 'We removed {sellerCount} seller from {terCount} territory.',
  DELETE_SELLER_FROM_TERRITORIES_SUCCESS_MESSAGE: 'We removed {sellerCount} seller from {terCount} territories.',
  DELETE_SELLERS_FROM_TERRITORY_SUCCESS_MESSAGE: 'We removed {sellerCount} sellers from {terCount} territory.',
  DELETE_SELLERS_FROM_TERRITORIES_SUCCESS_MESSAGE: 'We removed {sellerCount} sellers from {terCount} territories.',
  ITEMS_PLUS_OTHERS: '{itemsList} (+{otherCount} others)',
  REMOVE_CONFLICT: 'We have successfully removed {count} conflict from {ruleId}',
  REMOVE_CONFLICTS: 'We have successfully removed {count} conflicts from {ruleId}',
  UPDATE_PC_SUCCESS: 'We saved changes to your {planningCycleName} planning cycle.',
  UPDATE_PC_FAILURE: "We couldn't save your updates to your {planningCycleName} planning cycle. Please try again.",
  TERRITORIES_DELETED_TOAST_TITLE: 'Territories deleted',
  TERRITORIES_DELETED_TOAST_MESSAGE: 'We deleted {count} territories.',
  TERRITORIES_DELETED_TOAST_MESSAGE_GENERIC: 'We deleted all selected territories.',
  ACCOUNT_FILTER: 'Account filter',
  FILTERS: 'Filters',
  CLEAR_FILTERS: 'Clear filters',
  SHOW_SELECTED_TERRITORY_ACCOUNTS: 'Show accounts for selected territory',
  SHOW_ALL_ACCOUNTS: 'Show all accounts',
  BASE_OVERRIDE_ACCOUNTS: 'Base & override accounts',
  OVERRIDE_ACCOUNTS_ONLY: 'Override accounts only',
  FILTER_ACCOUNTS_TERRITORY_HEADER: 'Filter accounts by territory',
  FILTER_ACCOUNTS_TYPE_HEADER: 'Filter accounts by type',
  EMBEDDED_MAP_FALLBACK_HEADER: 'Oops! Something went wrong.',
  EMBEDDED_MAP_FALLBACK_BODY_ERROR: 'Please try again later.',
  EMBEDDED_MAP_FALLBACK_BODY_NO_ACCESS: 'Looks like you do not have access. Please contact your administrator.',
  CONSTRAINT_FIELD_ERROR: 'Please fill the constraint value',
  PLEASE_SELECT_A_METRIC: 'Please select a metric',
  ACCOUNT_ADDITIONS: 'Account additions',
  ACCOUNT_REMOVALS: 'Account removals',
  EXISTING_ACCOUNTS: 'Existing accounts',
  ZIP_CODE: 'Zip code',
  LETTER_GRADE: 'Letter grade',
  SIZE: 'Size',
  ACCOUNT_ID: 'Account ID',
  TARGET_SETTING: 'Target setting',
  TARGET_SETTING_DESCRIPTION: 'Select a custom hierarchy to set quota targets for individual hierarchy members',
  PC_START_DATE_POST_COINSORT_FAILURE: 'Unable to update PC start date.',
  PC_START_DATE_POST_COINSORT_FAILURE_MESSAGE:
    'Unable to update {planningCycleName} start date. Please try again after reviewing your territories and running a successful coinsort.',
  FAILED_TO_LASSO_PINS: 'Failed to lasso pins. Please try again.',
  EXIT_OPTIMIZATION_CONFIRMATION: "Your changes won't be saved. Are you sure you want to exit optimization?",
  EXIT: 'Exit',
  UNABLE_TO_RETRIEVE_OPTIMIZATION_STATUS: 'Unable to retrieve optimization status',
  CANNOT_START_OPTIMIZATION_USER_MESSAGE: 'Cannot start optimization as there is already one started by another user.',
  RESTART_OPTIMIZATION: 'Restart optimization',
  RESTART_OPTIMIZATION_DESCRIPTION:
    'Your current optimization result will be discarded. Are you sure you want to restart optimization?',
  MAINTENANCE_MODE_TEXT_1: "Sorry! We're working on something!",
  MAINTENANCE_MODE_TEXT_2:
    "To make sure we continue to deliver a top-notch user experience to you, we're performing some planned maintenance work on our system right now. You're not able to access the Sales Planning product at this time. Please try again later.",
  UNLOCK_TERRITORY: 'Unlock territory',
  LOCK_TERRITORY: 'Lock territory',
  UNLOCK_TERRITORY_GROUP: 'Unlock territory group',
  LOCK_TERRITORY_GROUP: 'Lock territory group',
  LOCK_ALL_TERRITORIES: 'Lock all territories',
  UNLOCK_ALL_TERRITORIES: 'Unlock all territories',
  SHOW_TERRITORY: 'Show territory',
  HIDE_TERRITORY: 'Hide territory',
  SHOW_TERRITORY_GROUP: 'Show territory group',
  HIDE_TERRITORY_GROUP: 'Hide territory group',
  SHOW_ALL_TERRITORIES: 'Show all territories',
  HIDE_ALL_TERRITORIES: 'Hide all territories',
  QUOTA_BREAKDOWN_WARNING:
    "If you update territory rules, activity files or hierarchies selected for quota breakdown, you'll need to \nre-run coinsort and top down allocation for the latest data to reflect.",
  TENANT_ACCESS_ERROR: "You don't have access to this tenant",
  TENANTS_LIST_HEADER: "Tenants I'm currently in",
  EMPTY_TENANT_LIST_MESSAGE: 'Please contact your tenant administrator for an invitation.',
  UNASSIGN_GEO_TITLE: 'Unassign from territory',
  UNASSIGN_GEO_BODY:
    'Are you sure you want to unassign {hierarchyCount} geographic region(s) from {ruleNamesTruncated}?',
  UNASSIGN_GEO_SUCCESS_TOAST: 'Geographic region(s) were successfully unassigned.',
  UNASSIGN_GEO_ERROR_TOAST: 'We were unable to unassign the geographic region(s).',
  UNASSIGN_ACCOUNT_TITLE: 'Unassign override(s)',
  UNASSIGN_ACCOUNTS: 'Unassign account(s)',
  UNASSIGN_ACCOUNT_BODY: 'Are you sure you want to unassign {hierarchyCount} account(s) from {ruleNamesTruncated}?',
  UNASSIGN_OVERRIDE_SUCCESS_TOAST: 'Override(s) were successfully unassigned.',
  UNASSIGN_OVERRIDE_ERROR_TOAST: 'We were unable to unassign the override(s).',
  UNASSIGN_ACCOUNT_SUCCESS_TOAST: 'Account(s) were successfully unassigned.',
  UNASSIGN_ACCOUNT_ERROR_TOAST: 'We were unable to unassign the account(s).',
  UNASSIGN_OVERRIDE_TOOLTIP: 'This will let you unassign selected override accounts.',
  UNASSIGN_ACCOUNT_TOOLTIP: 'This will let you unassign selected accounts.',
  REASSIGN_TITLE: 'Reassign to existing territory',
  MAP_ACTION_MENU_REASSIGN_GEO: 'Reassign geographic region(s)',
  REASSIGN_GEO_BODY: 'Please select a territory where you want to reassign the {count} geographic region(s)',
  REASSIGN_GEO_SUCCESS_TOAST: 'Geographic region(s) were successfully reassigned.',
  REASSIGN_GEO_ERROR_TOAST: 'We were unable to reassign the geographic region(s).',
  MAP_ACTION_MENU_REASSIGN_ACCOUNT: 'Reassign account(s)',
  REASSIGN_ACCOUNT_BODY: 'Please select a territory where you want to reassign {count} account(s)',
  REASSIGN_ACCOUNT_SUCCESS_TOAST: 'Account(s) were successfully reassigned.',
  REASSIGN_ACCOUNT_ERROR_TOAST: 'We were unable to reassign account(s).',
  COMPLEX_RULES_STATUS: 'Complex rules {status}',
  ON: 'ON',
  OFF: 'OFF',
  FUNCTIONALITY: 'Functionality',
  MAPS: 'Maps',
  HIERARCHY_WITH_NAME: '{rootHierarchyName} Hierarchy',
  HIERARCHY: 'Hierarchy',
  CUSTOM: 'Custom',
  DATA_SOURCE: 'Data source',
  FORMAT_CODE: 'Format code',
  INSERT_CALCULATION: 'Insert calculation',
  WELCOME_BACK: 'Welcome back!',
  CHOOSE_A_TENANT: 'Choose a tenant to use Sales Planning.',
  SELECT_TENANT: 'Select tenant',
  IMPERSONATE_USER: 'Impersonate user',
  USER_EMAIL_TO_IMPERSONATE: 'User email to impersonate',
  IMPERSONATE: 'Impersonate',
  EXIT_IMPERSONATION: 'Exit impersonation',
  FAILED_TO_GENERATE_IMPERSONATION_TOKEN: 'Failed to generate impersonation token. Please try again.',
  CUSTOM_HIERARCHY_FILTER_TITLE: 'Custom hierarchy filter',
  CUSTOM_HIERARCHY_FILTER_DYNAMIC_TITLE: '{hierarchyName} filter',
  CUSTOM_HIERARCHY_FILTER_KIND_LABEL: 'Filter kind',
  CUSTOM_HIERARCHY_FILTER_IDS_LABEL: 'Select hierarchies',
  CUSTOM_HIERARCHY_FILTER_IDS_EMPTY_PLACEHOLDER: 'No hierarchies in use',
  NO_CUSTOM_HIERARCHIES_TOOLTIP: 'There are no custom hierarchies to filter',
  COLLECTION_FILTER_CONTAINS_ANY: 'Contains any',
  COLLECTION_FILTER_NOT_CONTAINS_ANY: 'Does not contain',
  COLLECTION_FILTER_EQUALS_TO: 'Is equal to',
  COLLECTION_FILTER_NOT_EQUALS_TO: 'Is not equal to',
  NO_EDIT_PERMISSION: "You don't have permission to edit this",
  TOO_MANY_SELLERS: 'You can only add {maxSellers} sellers to a territory. \nDelete a seller before adding a new one.',
  REMOVE_GEO_HIER_HAS_DEPENDENCY: 'Cannot remove geographies as they are in use by territories: {territoryIds}',
  START_FILE_PROCESS_API_KEY_NOT_FOUND:
    'API key not found for user {emailAddress}. User must generate an API key from the profile menu',
  QUOTA_FILE_UPLOAD_NOTES: 'Some notes on using the generated template:',
  QUOTA_FILE_UPLOAD_WARNING_1: 'Before downloading, you must first run coin sort.',
  QUOTA_FILE_UPLOAD_WARNING_2:
    'This template is for the selected quota component only. You must import quotas for each quota component on the battle card.',
  QUOTA_FILE_UPLOAD_WARNING_3:
    'Changing the template will cause data integrity errors. Only update the Value column with quota targets.',
  ACCOUNT_TOOLTIP_MULTIPLE_TERRITORIES_MESSAGE: 'This account belongs to {geoCount} territories',
  REMOVE_DEFAULT_BALANCING_METRIC: 'Cannot delete this measure as it requires one default balancing measure.',
  REMOVE_SHEET_MEASURE_FORMULA_HAS_DEPENDENCY: 'Cannot delete this measure as it is used in {measureName}.',
  OTHER_MEASURES: 'other measures',
  IMPORT_ACTIVITY_FILE_CALLOUT_MESSAGE: "We assume all sales values use your planning cycle's reporting currency.",
  MEASURE_NAME_VALIDATION_MESSAGE: 'Field names can not contain the following characters: .,\'"{}',
  ACCOUNT_RULE_GRID_ERROR: 'We were unable to load the account list.',
  ALLOCATE_QUOTAS: 'Allocate quotas',
  ALLOCATING_TOP_DOWN: 'Allocating top down',
  ALLOCATING_TOP_DOWN_LOADING_MESSAGE: 'Please wait while we allocate your targets for your {jobName}',
  SINGLE_ACCOUNT_TERRITORY: 'Single account territory',
  SINGLE_ACCOUNT_TERRITORY_DISABLE_SAVE_TOOLTIP_TEXT:
    'Cannot assign more than one account to a single account territory',
  COPILOT: 'Copilot',
  ASSISTANT_HEADER_TITLE: 'Sales Planning Copilot',
  ASSISTANT_YOU: 'You',
  ASSISTANT_HEADER_CLEAR: 'Clear chat',
  ASSISTANT_HEADER_HIDE: 'Hide',
  ASSISTANT_INPUT_PLACEHOLDER: 'What can I help you with?',
  ASSISTANT_EMPTY_TITLE: 'How can I help you today?',
  ASSISTANT_EMPTY_SUBTITLE: 'Ask me anything about Sales Planning',
  ASSISTANT_PROMPT_GENERAL: 'General questions',
  ASSISTANT_PROMPT_GENERAL_EXAMPLE: 'e.g. Where is an account, seller, territory aligned?',
  ASSISTANT_PROMPT_GENERAL_INPUT: 'Where is [Account] aligned?',
  ASSISTANT_PROMPT_INSIGHTS: 'Insights & analysis',
  ASSISTANT_PROMPT_INSIGHTS_EXAMPLE: 'e.g. Compare key metrics between 2 maps',
  ASSISTANT_PROMPT_INSIGHTS_INPUT: 'Compare key metrics between [Battle card name] and [Battle card name] maps',
  ASSISTANT_PROMPT_ACTIONS_EXAMPLE: 'e.g. Move an account to another territory',
  ASSISTANT_PROMPT_ACTIONS_INPUT: 'Move [Account] to [Territory]',
  ASSISTANT_PROMPT_HISTORICAL: 'Historical search',
  ASSISTANT_PROMPT_HISTORICAL_EXAMPLE: 'e.g. Who was the last user to make changes?',
  ASSISTANT_BASE_ERROR: 'An unexpected error with the assistant occurred',
  ASSISTANT_ERROR_TITLE: 'Assistant error',
  FILE_UPLOAD_INVALID_FIRST_ROW_ERROR:
    'Please check if the first data row in the uploaded file starts with "#". If so, you can avoid this error by moving the first column to a different position. If not, please contact support.',
  ASSISTANT_PROMPT_HISTORICAL_INPUT: 'Who was the last user to make changes to [Territory]',
  STATUS_MENU_COINSORT_IN_PROGRESS: 'Running coin sort for {battleCardName}',
  STATUS_MENU_COINSORT_COMPLETED: 'Coin sort ran for {battleCardName}',
  STATUS_MENU_COINSORT_FAILED: 'Failed to run coin sort for {battleCardName}',
  COINSORT_COMPLETED_TOAST_MESSAGE: 'We ran coin sort for your {battleCardName} battle card.',
  COINSORT_FAILED_TOAST_MESSAGE:
    "We couldn't run coin sort for the {quotaComponentName} quota component on your {battleCardName} battle card.",
  ALL_DONE: 'All done!',
  ALL_TASKS_COMPLETED: "You don't have any tasks in progress.",
  TASKS_IN_PROGRESS: '{numOfTasks} tasks in progress',
  COIN_SORTING_COMPLETED: 'Coin sorting completed',
  COIN_SORTING_FAILED: 'Coin sorting failed',
  VIEW_STATUS: 'View status',
  COIN_SORTING_IN_PROGRESS: 'Coin sorting in progress',
  RUN_COIN_SORT_DISABLED_MESSAGE:
    "We can't run coin sort for the {quotaComponentName} quota component on your {battleCardName} battle card because there's already a coin sort in progress.",
  RUN_COIN_SORT: 'Run coin sort',
  STARTED_BY: 'Started by: {userName}',
  DISABLE_ADD_QUOTA_ADJUSTMENT_TOOLTIP: 'Add planned quota prior to adding territory quota adjustment',
  STARTED: 'Started:',
  JUST_NOW: 'just now',
  A_MINUTE_AGO: 'a minute ago',
  ONE_HOUR_AGO: '1 hour ago',
  ONE_DAY_AGO: '1 day ago',
  DAYS_AGO: '{numDays} days ago',
  HOURS_AGO: '{numHours} hours ago',
  MINUTES_AGO: '{numMinutes} minutes ago',
  ADJUSTMENT_EFFECTIVE_DATE_WARNING_TOOLTIP:
    "The effective dates for quota adjustments fall outside the territory's effective dates. Update the quota adjustment effective dates to align with the territory's effective dates.",
  ASCENDING: 'Ascending',
  DESCENDING: 'Descending',
  START_DATE_REQUIRED_MARK_QUOTA_MOVE: 'Effective start date *',
  ACCOUNT_MOVE_WITH_QUOTA_DIALOG_BODY_1: 'Add a destination territory, start date and quota allocation for ',
  ACCOUNT_MOVE_WITH_QUOTA_DIALOG_BODY_2: 'This will be applied across all quota components',
  TERRITORY_REQUIRED_MARK_V2: 'Destination territory *',
  QUOTA_REALLOCATION_MARK: 'Quota reallocation method *',
  MOVE_PRORATED_QUOTA: 'Move pro-rated quota',
  DONT_MOVE_QUOTA: "Don't move quota",
  MINIMIZE: 'Minimize',
  MAXIMIZE: 'Maximize',
  SEASONALITY_DISABLE_TOOLTIP_TEXT:
    'Seasonality cannot be modified as quotas are set at the hierarchy level and not at territory level.',
  TERRITORY_EFFECTIVE_DATE_DISABLE_TOOLTIP_TEXT:
    'Effective date for this territory cannot be modified as there are account redirects associated with this territory.',
  MOVE_ACCOUNT_DIALOG_DISABLE_TOOLTIP_TEXT_FOR_UNASSISGNED:
    'Quota reallocation method is unavailable if accounts are unassigned.',
  MOVE_ACCOUNT_DIALOG__TEXT_FOR_ORIGINAL:
    'Moving an account back to its original territory will move the associated quota for that time period',
  MOVE_ACCOUNT_DIALOG_DISABLE_TOOLTIP_TEXT_FOR_NO_ACCOUNT_VALUE:
    'Quota reallocation method is unavailable if quota components have zero or no account quota value.',
  MOVE_ACCOUNT_DIALOG_DISABLE_TOOLTIP_TEXT_FOR_NO_OVERLAP_REDIRECT_DATE:
    'Quota reallocation method is unavailable if the redirect period does not overlap with the effective dates of the territory rules in the desired destination.',
  CANCELLED: 'Canceled',
  CANCEL_SYMON_PIPE_CONFIGURATION_SUCCESS: 'Import cancelled',
  CANCEL_SYMON_PIPE_CONFIGURATION_SUCCESS_MESSAGE: 'We canceled your import to your {destinationTableName} table.',
  CANCEL_SYMON_PIPE_CONFIGURATION_FAILURE: 'Failed to cancel import',
  CANCEL_SYMON_PIPE_CONFIGURATION_FAILURE_MESSAGE:
    "We couldn't cancel your import to your {destinationTableName} table.",
  CANCEL_IN_PROGRESS: 'Cancel in progress',
  CANCELLING_IMPORT: 'Cancelling import',
  CANCELLING_IMPORT_MESSAGE: "We're cancelling your import to your {destinationTableName} table.",
  TERRITORY_EFFECTIVE_DATE_FOR_TERRITORY_QUOTA_DRILL_IN: 'Territory effective date: {effectiveDate} - {endDate}',
  ADJUSTMENT_TYPE: 'Adjustment type',
  ACCOUNT_MOVED_FROM: 'Account moved from',
  ACCOUNT_MOVED_TO: 'Account moved to',
  ALIGNMENT_EFFECTIVE_DATE: 'Alignment effective date',
  OK: 'Ok',
  FAILED_TO_UPSERT_FIELD_VALUE_ERROR: 'Something went wrong, please try again',
  MISSING_EFFECTIVE_DATING_FIELD_ERROR:
    'Failed to adjust territory quotas. Please make sure to enter an adjustment amount and an effective date range for the adjustment.',
  DELETE_QUOTA_COMPONENT_DISABLED_TEXT:
    'Quota component referenced in a battle card or solely applied to a quota sheet cannot be deleted',
  ORIGINAL: 'Original',
  ACCOUNT_MOVE: 'Account move',
  BC_NAME_ALREADY_EXISTS: 'The battle card name is already in use',
  END_DATE: 'End date',
  CONFIGURATION_EXPORT_NODE_IN_USE_MESSAGE:
    'This export node is used by a configuration in your {planningCycleName} planning cycle. Delete the configuration to use this export node.',
  SCENARIO: 'Scenario',
  HIGHWAYS: 'Highways',
  UPLOAD_ACCOUNT_ALIGNMENT: 'Upload account territory alignment',
  ACCOUNT_TERRITORY_ALIGNMENT: 'Account territory alignment',
  UPLOAD_ACCOUNT_ALIGNMENT_COLUMNS_OPTIONAL:
    'Account territory alignment files may contain the following columns for import:',
  UPLOAD_ACCOUNT_ALIGNMENT_INFO:
    'These effective dates are for the period during which the account aligns to the territory. Please note if the EffectiveStartDate and EffectiveEndDate are left empty, EffectiveStartDate and EffectiveEndDate will default to the account effective date.'
};
