import React from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';
import { TreeNodeInfo } from '@blueprintjs/core';
import { MultiPolygon, Polygon } from '@turf/helpers';
import { MapLayerMouseEvent } from 'react-map-gl';

import { SearchableSelectMenuItem } from 'components/models';

import {
  BCInfoLevelEnum,
  DeploymentModelTypeEnum,
  FileTypeEnum,
  GetAccountRuleBindings_getAccountRuleBindings_bindings_redirects,
  GetAccountRuleBindings_getAccountRuleBindings_bindings_sourceRule,
  GetAccountRuleBindings_getAccountRuleBindings_bindings_redirects_fields,
  GetBattleCardCanvas_getDeploymentModelSpec_battlecards,
  GetBattlecardsForEmbeddedMap_getDeploymentModelSpec_battlecards,
  GetBattlecardsForEmbeddedMap_getDeploymentModelSpec_battlecards_territoryGroupTypes,
  GetGeoCatalogAndSelection_getPlanningCycleSpec_availableCountrySourceCatalogItems,
  GetTerritoryRulesForFieldsVariables,
  LookupTypeEnum,
  NewRuleDefinitionResponse,
  PCJobType,
  GetTerritoryQuota_getTerritoryQuota,
  GetTerritoryQuota_getTerritoryQuota_periodicTerritories,
  GetTerritoryQuota_getTerritoryQuota_periodicTerritories_measures,
  UpsertTerritoryRuleVariables,
  HierarchyPolygonId,
  CompressedRuleDefinitionResponseEnum,
  GetTerritoryRulesForMap_getTerritoryRules_territoryRules,
  UpsertBattlecardHierarchyTops_upsertBattlecardHierarchyTops,
  HierarchyTarget,
  ChatRoleEnum,
  GetAccountRuleBindingsForQuotaMove_getAccountRuleBindings_bindings_measures_measureValueBreakdown
} from 'app/graphql/generated/apolloTypes';

import { Compressed } from 'utils/helpers/CompressionUtil';
import enUS from 'utils/messages/en-US';

export interface AccountMoveVariables {
  accountName: string;
  accountId: number;
  sourceRuleId: number;
  targetRuleId: number;
  targetTerritoryId: string;
  sourceTerritoryId: string;
  sourceRule: GetAccountRuleBindings_getAccountRuleBindings_bindings_sourceRule;
  redirects: GetAccountRuleBindings_getAccountRuleBindings_bindings_redirects[];
  territoryGroupTypeId?: number;
  accountQuotaMeasureId?: number;
  territoryQuotaAdjustmentMeasureId?: number;
  effectiveDate: string;
  endDate: string;
}

export type PrimaryTerritoryField = 'territoryId' | 'territoryName';

export const enum ActivityDrillInColumnNames {
  INDUSTRY = 'Industry',
  PRODUCT = 'Product',
  CUSTOMER_ACCOUNT = 'Customer account',
  GEOGRAPHIC_ID = 'Geographic id',
  SALES = 'Sales',
  PRIOR_YEAR_SALES = 'Prior year sales',
  TRAILING_TWELVE_MONTHS = 'Trailing 12 months',
  UNITS_SOLD = 'Units sold',
  DATE = 'Date'
}

export type MapHoverEvent = {
  features?: MapLayerMouseEvent['features'];
  lngLat: { lng: number; lat: number };
};

type ValueOf<T> = T[keyof T];

type Primitive = number | string | boolean | bigint | symbol | null | undefined;
type DeepPartialMap<T> = {
  [key in keyof T]?: DeepPartial<T[key]>;
};
export type DeepPartial<T> = T extends Primitive ? T : DeepPartialMap<T>;

export interface AllPlanTargetsData {
  territoryGroups: [TerritoryGroupWithMeasuresApiCall];
  battlecard: BattleCardTarget;
}

export const enum AqgTerritoryKind {
  MOVE_AWAY = 'move-away',
  MOVE_BACK = 'move-back',
  EXPANDED_SOURCE = 'expanded-source',
  ROOT_SOURCE = 'root-source',
  UNASSIGNED = 'unassigned'
}

export const enum BalancingPanelColumnNames {
  BALANCING_METRIC = 'Balancing Metric'
}

export const enum BalancingPanelColumnFields {
  BALANCING_HIERARCHY_NAME = 'balancingHierarchyName',
  BALANCING_METRIC = 'balancingMetric'
}

export enum ChangeRequestsGridFieldNames {
  REQUEST_ID = 'requestId',
  REQUESTED_BY = 'requestedBy',
  DATE_REQUESTED = 'dateRequested',
  DATE_REVIEWED = 'dateReviewed',
  TERRITORY_NAMES = 'territoryNames',
  STATUS = 'status',
  COMMENTS = 'comments',
  APPROVE_REJECT = 'approveRejectField'
}

export interface BaseContext {
  resetValues: () => void;
}

export interface BattleCardData {
  battlecardId: string;
  battlecardInfoLevel: BCInfoLevelEnum | null;
  battlecardName: string;
  battlecardParentId: null | number;
  battlecardType: BattlecardType;
  conversionRate: number;
  localCurrencyCode: string;
  reportingCurrencyCode: string;
  isLocalCurrencyMode: boolean;
  invalidConversionReason: string;
  deploymentModelId: number;
  deploymentModelName: string;
  planningCycleId: number;
  planningCycleName: string;
  isRootCard: boolean;
  globalMeasures: Record<number, BattleCardMeasure[]>;
  quotaComponents: QuotaComponent[];
}

export interface BattleCardDefinition {
  data: BattleCardData;
  children: BattleCardDefinition[];
}

export interface BattleCardInfo extends GetBattleCardCanvas_getDeploymentModelSpec_battlecards {
  battlecardType: BattlecardType; // override string type from gql
  battleCardParentType?: BattlecardType;
}

// TODO TQP-1420 Need to create a base Measure type that is shared between components and extend it with specific fields for the UI
export interface BattleCardMeasure {
  measureId?: number;
  measureName: string;
  measureValue: number | '';
  isEditable: boolean;
  measureFormatType: MeasureFormatType | string;
}

export interface BattleCardTarget {
  battlecardId: string;
  battlecardName: string;
  newBusinessTarget: number;
  updatedQuota: number;
}

export interface BattlecardWorkflowResponse {
  workflows: BattlecardWorkflow[];
  completionRate: number;
  workflowInitiated: boolean;
}

export enum BattlecardType {
  Rollup = 'Roll-up',
  PrimaryTeam = 'Primary Team',
  DirectOverlay = 'Direct Overlay',
  IndirectOverlay = 'Indirect Overlay'
}

export interface CardCopy {
  sections: CardCopySection[];
}
export interface CardCopySection {
  name: string;
  header: string;
}

export enum CategoryName {
  CUSTOMER_ACCOUNTS = 'Customer Accounts',
  INDUSTRIES = 'Industries',
  GEOGRAPHIC_TERRITORIES = 'Geographies',
  UNASSIGNED = 'Unassigned'
}

export enum JobStatus {
  IN_PROGRESS = 'in-progress',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  FAILED = 'failed',
  SKIPPED = 'skipped',
  PENDING = 'pending'
}

export enum CombinatorType {
  AND = 'AND',
  OR = 'OR'
}

export enum AssistantDialogState {
  CLOSE = 'close',
  OPEN = 'open'
}

export enum AssistantStreamResponseKeys {
  MESSAGE_TYPE = 'messageType',
  DATA = 'data: ',
  CONVERSATION_ID = 'conversationId:',
  TEXT = 'text'
}

export enum AssistantStreamResponseMessageTypes {
  CONTENT = 'content',
  SYSTEM = 'system',
  ERROR = 'error'
}

export interface AssistantMessageDetails {
  content: {
    message: string;
    role: ChatRoleEnum;
  };
  conversationId?: string;
  inputMessageKey: string;
}

export interface AssistantParsedStreamData {
  content?: string;
  conversationId?: string;
  usedTools?: string[];
  streamError?: string;
}

export enum CommandCenterMenuItem {
  USER_MANAGEMENT = 'user-management',
  USER_ROLES = 'user-roles',
  ROLE_ASSIGNMENTS = 'role-assignments',
  ACTIVITY_FILES = 'activity-files',
  MEASURES = 'measures',
  CUSTOMER_ACCOUNT = 'customer-account',
  CURRENCIES = 'currencies',
  QUOTA_COMPONENTS = 'quota-components',
  DATA = 'data',
  INTEGRATION = 'integration',
  EXPORT = 'export',
  DASHBOARDS = 'dashboards'
}

export const enum CommandCenterMenuSection {
  ADMIN = 'admin',
  PLANNING_CYCLE = 'planning_cycle',
  HIERARCHIES = 'hierarchies'
}

export enum CommandCenterDrawerState {
  CLOSE = 'close',
  OPEN = 'open',
  HALF = 'half',
  EXPAND = 'expand'
}

export interface ConfigurationGridData {
  endAt: string;
  exportId: string;
  exportName: string;
  fileType: FileType;
  hierarchyId: number;
  pipeId: string;
  pipeName: string;
  runId: string;
  status: SymonPipeConfigurationStatus;
  symonPipeConfigurationId: number;
}

export interface ContributorTreeData {
  territoryGroupIdsPath: string[];
  user: string;
  email: string;
  status: WorkflowStatus;
  approver: string;
  parentTerritoryGroupId: string;
  territoryGroupWorkflowId: number;
  territoryGroupId: string;
  territoryGroupName: string;
  type: WorkflowType;
  rootParentType: WorkflowType; // since it is possible that we have same workflow under territory group and battlecard, this verifies what the root parent workflow type is,
  approverEmail: string;
}

export enum DataSheetDrawerState {
  CLOSE = 'close',
  OPEN = 'open'
}

export enum DataSheetDrawerFormType {
  NEW,
  EDIT,
  EXISTING
}

export enum CommandCenterMenuState {
  FULL = 'full',
  ICON_ONLY = 'icon_only'
}

export const enum ConfigurationActionType {
  CHECK_FOR_CHANGES = 'check_for_changes',
  RUN = 'run',
  DELETE = 'delete',
  CREATE = 'create',
  CANCEL = 'cancel'
}

export enum ConfigurationDialogType {
  CREATE = 'create',
  IMPORT = 'import',
  RUN = 'run'
}

export interface ContributorWorkflow {
  battlecards: [BattlecardWorkflow[]];
  territoryGroups: [TerritoryGroupsWorkflow[]];
}

interface WorkflowItem {
  approverId: number;
  approverEmail: string;
  approverFirstName: string;
  approverLastName: string;
  ownerEmail: string;
  ownerFirstName: string;
  ownerLastName: string;
  children: number[];
  status: WorkflowStatus;
}

export interface BattlecardWorkflow extends WorkflowItem {
  battlecardId: number;
  battlecardName: string;
  battlecardOwnerId: number;
  battlecardParentId: number;
  battlecardWorkflowId: number;
  territoryGroups: TerritoryGroupsWorkflow[];
}

export interface TerritoryGroupsWorkflow extends WorkflowItem {
  territoryGroupId: number;
  territoryGroupName: string;
  territoryGroupOwnerId: number;
  territoryGroupParentId: number;
  territoryGroupWorkflowId: number;
  battlecardId: number;
}

export interface ConversionRates {
  canDelete: boolean;
  currencyHierarchyId: number;
  planningCycleId: number;
  reportingCurrencyCode: string;
  reportingCurrencyName: string;
  localCurrencies: {
    canDelete: boolean;
    conversionRate: number;
    currencyHierarchyId: number;
    localCurrencyCode: string;
    localCurrencyName: string;
  }[];
}

export interface Currency {
  currencyCode: string;
  currencyName: string;
}

export enum CurrencyCode {
  AED = 'AED',
  AFN = 'AFN',
  ALL = 'ALL',
  AMD = 'AMD',
  ANG = 'ANG',
  AOA = 'AOA',
  ARS = 'ARS',
  AUD = 'AUD',
  AWG = 'AWG',
  AZN = 'AZN',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BGN = 'BGN',
  BHD = 'BHD',
  BIF = 'BIF',
  BMD = 'BMD',
  BND = 'BND',
  BOB = 'BOB',
  BRL = 'BRL',
  BSD = 'BSD',
  BTN = 'BTN',
  BWP = 'BWP',
  BYN = 'BYN',
  BZD = 'BZD',
  CAD = 'CAD',
  CDF = 'CDF',
  CHF = 'CHF',
  CLP = 'CLP',
  CNY = 'CNY',
  COP = 'COP',
  CRC = 'CRC',
  CUC = 'CUC',
  CUP = 'CUP',
  CVE = 'CVE',
  CZK = 'CZK',
  DJF = 'DJF',
  DKK = 'DKK',
  DOP = 'DOP',
  DZD = 'DZD',
  EGP = 'EGP',
  ERN = 'ERN',
  ETB = 'ETB',
  EUR = 'EUR',
  FJD = 'FJD',
  FKP = 'FKP',
  GBP = 'GBP',
  GEL = 'GEL',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GNF = 'GNF',
  GTQ = 'GTQ',
  GYD = 'GYD',
  HKD = 'HKD',
  HNL = 'HNL',
  HRK = 'HRK',
  HTG = 'HTG',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  INR = 'INR',
  IQD = 'IQD',
  IRR = 'IRR',
  ISK = 'ISK',
  JMD = 'JMD',
  JOD = 'JOD',
  JPY = 'JPY',
  KES = 'KES',
  KGS = 'KGS',
  KHR = 'KHR',
  KMF = 'KMF',
  KPW = 'KPW',
  KRW = 'KRW',
  KWD = 'KWD',
  KYD = 'KYD',
  KZT = 'KZT',
  LAK = 'LAK',
  LBP = 'LBP',
  LKR = 'LKR',
  LRD = 'LRD',
  LSL = 'LSL',
  LYD = 'LYD',
  MAD = 'MAD',
  MDL = 'MDL',
  MGA = 'MGA',
  MKD = 'MKD',
  MMK = 'MMK',
  MNT = 'MNT',
  MOP = 'MOP',
  MRU = 'MRU',
  MUR = 'MUR',
  MVR = 'MVR',
  MWK = 'MWK',
  MXN = 'MXN',
  MYR = 'MYR',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIO = 'NIO',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  OMR = 'OMR',
  PAB = 'PAB',
  PEN = 'PEN',
  PGK = 'PGK',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  PYG = 'PYG',
  QAR = 'QAR',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  SBD = 'SBD',
  SCR = 'SCR',
  SDG = 'SDG',
  SEK = 'SEK',
  SGD = 'SGD',
  SHP = 'SHP',
  SLL = 'SLL',
  SOS = 'SOS',
  SRD = 'SRD',
  SSP = 'SSP',
  STN = 'STN',
  SVC = 'SVC',
  SYP = 'SYP',
  SZL = 'SZL',
  THB = 'THB',
  TJS = 'TJS',
  TMT = 'TMT',
  TND = 'TND',
  TOP = 'TOP',
  TRY = 'TRY',
  TTD = 'TTD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UGX = 'UGX',
  USD = 'USD',
  UYU = 'UYU',
  UZS = 'UZS',
  VES = 'VES',
  VND = 'VND',
  VUV = 'VUV',
  WST = 'WST',
  XAF = 'XAF',
  XCD = 'XCD',
  XOF = 'XOF',
  XPF = 'XPF',
  YER = 'YER',
  ZAR = 'ZAR',
  ZMW = 'ZMW',
  ZWL = 'ZWL'
}

export type CurrencyPageFormValues = Record<number, ReportingCurrencyFormValues>;

export interface CurrencyPageSubmission {
  reportingCurrencyCode: string;
  planningCycleId: string;
  localCurrencies: {
    conversionRate: number;
    localCurrencyCode: string;
  }[];
}

export enum DataMappingType {
  DATA_TABLE = 'Data table',
  CONFIGURATION = 'Configuration'
}

export enum DataPanelTabIds {
  SHEETS = 'sheets',
  TABLES = 'tables',
  CONFIGURATIONS = 'configurations',
  IMPORT_ERRORS = 'import-errors'
}

export enum UserManagementTabNames {
  USERS = 'users',
  ROLE_PERMISSIONS = 'rolePermissions'
}

export enum DataPanelViews {
  DATA_OVERVIEW,
  SHEET_DETAIL,
  TABLE_DETAIL,
  CONFIGURATION_DETAIL,
  LOOKUP_TABLE_DETAIL,
  ERROR_DETAIL
}

export interface DataTrayContent {
  tabId: TabIds;
  tabName: string;
  quotaSheetId?: string;
  gridComponent: React.ReactNode;
  actionButtons?: React.ReactNode;
}

export enum PlanningCycleModal {
  MOVE_TERRITORY,
  IMPORT_QUOTAS,
  IMPORT_SELLER_ASSIGNMENTS,
  STD_GEO_HIERARCHY,
  BULK_DELETE_TERRITORY,
  IMPERSONATE_USER,
  MAP_VIZ_SETTINGS
}

export enum DataType {
  TEXT = 'Text',
  NUMBER = 'Number',
  DATE = 'Date',
  CURRENCY = 'Currency',
  PERCENTAGE = 'Percentage'
}

export interface DeleteRedirectVariables {
  accountName: string;
  accountId: number;
  sourceRuleId: number;
  redirects: GetAccountRuleBindings_getAccountRuleBindings_bindings_redirects[];
  redirectId: number;
}

export enum DeleteOptions {
  START = 'start',
  END = 'end',
  ORIGINAL = 'original'
}

export type DeploymentModelSpec = {
  deploymentModelId: number;
  deploymentModelType: DeploymentModelTypeEnum;
  deploymentModelName: string;
  isPublished?: boolean;
};

export interface DeploymentModel {
  deploymentModelId: number;
  deploymentModelName: string;
  quotaComponents: QuotaComponent[];
  isTerritoryWorkflowStarted: string;
  dataSheets: DataSheet[];
}

// NB parent id is a number but id is a string
export interface ExpandedTerritoryGroupDefineAndRefineApiCall {
  hierarchyId: number;
  hierarchyTopId: number;
  hierarchyType: string;
  precedence: number;
  territoryGroupId: string;
  territoryGroupParentId: null | number;
  territoryGroupName: string;
  activityCount: number;
  owner: {
    employeeId: string;
    firstName: string;
    lastName: string;
  };
  children?: ExpandedTerritoryGroupDefineAndRefineApiCall[];
}

export interface ExpandedTerritoryGroupDefineAndRefinePillData extends TerritoryGroup {
  hierarchyId: number;
  hierarchyTopId: number;
  hierarchyType?: string;
  precedence: number;
  includeItem: boolean;
  owner: {
    employeeId: string;
    firstName: string;
    lastName: string;
    memberId: number;
  };
  children?: ExpandedTerritoryGroupDefineAndRefinePillData[];
  activityCount?: number;
}

export enum FileStatus {
  PENDING = 'pending',
  IN_PROGRESS = 'in-progress',
  PENDING_VALIDATION = 'pending-validation',
  CHECKING_PROGRESS = 'checking-progress',
  VALIDATING = 'validating',
  COMPLETED = 'completed',
  FAILED = 'failed',
  IN_PROGRESS_GET_FILE = 'In-Progress'
}

export enum FileType {
  CUSTOM_HIERARCHY = 'CustomHierarchy',
  GEOGRAPHIC_TERRITORY_HIERARCHY = 'GeographicTerritoryHierarchy',
  CUSTOMER_ACCOUNT_HIERARCHY = 'CustomerAccountHierarchy',
  ACTIVITY = 'Activity',
  PARTICIPANT = 'Participant',
  TERRITORY_RULE = 'TerritoryRule',
  TERRITORY_QUOTA = 'TerritoryQuota',
  LOCATION = 'Location',
  LOOKUP = 'Lookup',
  ACCOUNT_REDIRECT = 'AccountRedirect'
}
export interface FileUploadResult {
  tableId: string | null;
  tableName: string | null;
  planningCycleId: number | null;
  tenantId: number | null;
  tableDataType: LookupTypeEnum | FileTypeEnum | string | null;
  status: string | null;
  message: string | null;
  published: boolean;
  createdAt?: number;
  messages?: string;
  user?: {
    emailAddress: string;
  };
}

export enum FileSource {
  SYMON = 'symon',
  UPLOAD = 'upload'
}

export interface FilterType {
  field: string;
  operator: OperatorType;
  id: number | string;
  fieldName: string;
  fieldId: number;
}

export interface FieldIdsDetails {
  ruleId?: number;
  fieldId: number;
  fieldName: string;
  id?: string;
  key?: string;
}

export enum FieldType {
  MEASURE = 'Measure',
  COLUMN_ID = 'ColumnId',
  GEOGRAPHIC_TERRITORY_HIERARCHY = 'GeographicTerritoryHierarchy',
  CUSTOMER_ACCOUNT_HIERARCHY = 'CustomerAccountHierarchy',
  CUSTOM_HIERARCHY = 'CustomHierarchy'
}

export enum ManageWorkflowStatus {
  ACTION_REQUIRED = 'Action required',
  APPROVED = 'Approved'
}

export enum ActionDialogEnum {
  'approve',
  'reject'
}

export interface FormattedCurrencyInputLocaleConfig {
  currencySymbol: string;
  groupSeparator: string;
  decimalSeparator: string;
  prefix: string;
  suffix: string;
}

export interface FormattedCurrencyInputIntlConfig {
  locale: string;
  currency?: string;
}

export enum SheetsDetailReferencedGridType {
  BATTLE_CARD = 'Battle card',
  QUOTA_COMPONENT = 'Quota component'
}

export interface GetMeasures {
  measureId: number;
  planningCycleId: number;
  measureName: string;
  measureType: string;
  measureCategory: string;
  measureFieldType: string;
  measureFormatType: MeasureFormatType;
  measureFormula: string;
  measureConstraints: string;
  activityMeasureColumnName: string;
}

export enum GridFields {
  RULE_ID = 'ruleId',
  TERRITORY_ID = 'territoryId',
  TERRITORY_NAME = 'territoryName',
  GEOGRAPHY_NAME = 'geographyName',
  LEAF_TERRITORY_NAME = 'leafTerritoryName',
  LEAF_TERRITORY_ID = 'leafTerritoryId',
  TERRITORY_GROUP = 'territoryGroupName',
  TERRITORY_GROUP_ID = 'territoryGroupId',
  TERRITORY_RULE = 'ruleDefinition',
  TERRITORY_GROUP_TYPE = 'territoryGroupLevelName',
  COMMENT = 'comment',
  ACTIVITY_COUNT = 'activityCount',
  MEASURE_VALUE = 'measureValue',
  NUMBER_OF_ACCOUNTS = 'numberOfAccounts',
  SELLER = 'seller',
  OWNERS = 'owners',
  TERRITORY_COLOR = 'territoryColor',
  TERRITORY_GROUP_COLOR = 'territoryGroupColor',
  OWNERSHIP = 'Ownership',
  EFFECTIVE_DATE = 'effectiveDate',
  GEO_NAME = 'country',
  GEO_LEVEL = 'level',
  ACCOUNT_NAME = 'accountName',
  CUSTOM_HIERARCHY_ID = 'customHierarchyId',
  CUSTOM_HIERARCHY_KEY = 'customHierarchyKey',
  CUSTOM_HIERARCHY_NAME = 'customHierarchyName',
  TERRITORY = 'territory',
  TERRITORY_QUOTA = 'territoryQuota',
  ACCOUNT_QUOTA = 'accountQuota',
  STATUS = 'status',
  ACCOUNT_ID = 'accountId',
  ACCOUNT_KEY = 'accountKey',
  SEASONALITY = 'seasonality',
  RAMP = 'ramp',
  EMPLOYEE_ID = 'employeeId',
  USER = 'user',
  EMAIL_ADDRESS = 'emailAddress',
  JOB_TITLE = 'jobTitle',
  SYSTEM_ROLE_NAME = 'systemRoleName',
  MEMBER_UPDATED_AT = 'memberUpdatedAt',
  ACTION = 'action',
  DELETE = 'delete',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  IS_LOCKED = 'isLocked',
  IS_IGNORED = 'isIgnored',
  RULE = 'rule',
  TERRITORY_ID_AND_NAME = 'territoryIdAndName',
  ADJUSTMENT_TYPE = 'adjustmentType',
  ACCOUNT_MOVED_FROM = 'accountMovedFrom',
  ACCOUNT_MOVED_TO = 'accountMovedTo',
  ALIGNMENT_EFFECTIVE_DATE = 'alignmentEffectiveDate',
  QUOTA = 'quota'
}

export enum GridHeaders {
  TERRITORY_ID = 'Territory ID',
  TERRITORY_NAME = 'Territory Name',
  TERRITORY_GROUP = 'Territory group',
  TERRITORY_GROUP_TYPE = 'Territory Group Type',
  TERRITORY_RULE = 'Territory Rule',
  TERRITORY_RULE_ID = 'Territory Rule ID',
  TERRITORY_QUOTA = 'Territory quota',
  TERRITORY_EFFECTIVE_DATES = 'Territory effective dates',
  TERRITORY = 'Territory',
  CURRENT_TERRITORY = 'Current territory',
  ALLOCATE_TD = 'Allocated (Top-Down)',
  ALLOCATION = 'Allocation %',
  COMMENTS = 'Comments',
  PRIOR_YEAR_ACTUAL = 'Prior year actual',
  BOOK_OF_BUSINESS = 'Book of Business',
  NUMBER_OF_ACCOUNTS = 'Number of Accounts',
  SELLER = 'Seller',
  OWNERSHIP = 'Ownership',
  EFFECTIVE_DATE = 'Effective Date',
  GEO_NAME = 'Name',
  GEO_LEVEL = 'Geographic level',
  GEO_REGION = 'Geographic region',
  GEO_NUMBER_REGIONS = 'Region count',
  REMOVE_GEO = 'Remove',
  ACCOUNT_KEY = 'Account key',
  ACCOUNT_ID = 'Account ID',
  ACCOUNTS = 'Accounts',
  ACCOUNT_NAME = 'Account name',
  ADJUSTMENT_TYPE = 'Adjustment type',
  ACCOUNT_MOVED_FROM = 'Account moved from',
  ACCOUNT_MOVED_TO = 'Account moved to',
  ALIGNMENT_EFFECTIVE_DATE = 'Alignment effective date',
  QUOTA = 'Quota',
  ACCOUNT_QUOTA = 'Account quota',
  SEASONALITY = 'Seasonality',
  RAMP = 'Ramp',
  CUSTOMER_ACCOUNTS = 'Customer Accounts',
  GEOGRAPHIES = 'Geographies',
  TYPE = 'Type',
  STATUS = 'Status',
  ASSIGNMENT_PERCENTAGE = 'Assignment %',
  SELLER_EFFECTIVE_DATES = 'Seller effective dates',
  SELLERS = 'Sellers',
  DATE_TIME = 'Date / Time',
  USERNAME = 'User Name',
  CHANGE_TYPE = 'Change Type',
  CHANGED_VALUE = 'Changed Value',
  SOURCE = 'Source',
  VALUES = 'Values',
  PIPE = 'Pipe',
  EXPORT_NODE = 'Export node',
  LAST_RUN = 'Last run',

  QUOTA_ENTRY = 'Quota entry',
  DISRUPTION = 'Disruption',
  PARENT_QUOTA_ADJUSTMENTS = 'Parent Quota Adjustment',
  PARENT_ADJ_EFFECTIVE_DATE = 'Parent Adjustment Effective Date',
  QUOTA_COMPONENT = 'Quota component',
  FULL_ACCOUNT_QUOTA = 'Full acount quota',
  REALLOCATING = 'Reallocating'
}

export enum UserInformationCardFields {
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  EMAIL = 'EMAIL'
}

export type GridTerritory = GetTerritoryQuota_getTerritoryQuota_periodicTerritories;

export interface Hierarchy {
  hierarchyId: number;
  key?: string;
  name: string;
  parentKey: string;
  path?: string;
  children?: number[];
}

export interface HierarchyQuerySpec {
  getRootHierarchies: HierarchySpec[];
}

export interface HierarchySpec {
  rootKey: string;
  rootName: string;
  rootHierarchyId: number;
  hierarchyType: string | null;
  numOfMembers: number;
}

export interface HierarchyTreeNode {
  title?: string;
  id: number;
  hierarchyId?: number;
  label: string;
  key?: string;
  parentKey?: string | null;
  path?: string;
  hierarchyType?: HierarchyType;
  icon?: React.ReactElement;
  secondaryLabel?: React.ReactElement;
  isSelected?: boolean;
  customProperties?: string;
  address1?: string;
  address2?: string;
  city?: string;
  country?: string;
  industry?: string;
  zipPostal?: string;
  stateProvince?: string;
  effectiveDate?: string;
  endDate?: string;
  customerAccountNumber?: string;
  geographicTerritoryId?: string;
  childNodes?: HierarchyTreeNode[] | null;
  children?: (HierarchyTreeNode | number)[];
  isExpanded?: boolean;
  hasChildren?: boolean;
  hierarchyKey?: string;
  switcherIcon?: React.ReactElement;
  expanded?: boolean;
}

export interface HierarchyTreeData {
  hierarchyId: number;
  name: string;
  key: string;
  parentKey: string | null;
  path: string;
  children: number[];
  hasChildren: boolean;
  customProperties?: string;
  address1?: string;
  address2?: string;
  city?: string;
  country?: string;
  industry?: string;
  zipPostal?: string;
  stateProvince?: string;
  effectiveDate?: string;
  endDate?: string;
  customerAccountNumber?: string;
  geographicTerritoryId?: string;
  childNodes?: HierarchyTreeData[] | null;
}

export interface HierarchyAncestryTree {
  children: Array<number>;
  key: string;
  name: string;
  parentKey: string;
  path: string;
}

export interface NamedTgt {
  name: string;
  id: number;
}

export interface HierarchyTreeNodeIcon {
  collapsableNodeIcon: React.ReactElement;
  leafNodeIcon: React.ReactElement;
  switchIcon?: React.ReactElement;
}

export enum HierarchyType {
  CustomHierarchy = 'CustomHierarchy',
  CustomerAccountHierarchy = 'CustomerAccountHierarchy',
  GeographicTerritoryHierarchy = 'GeographicTerritoryHierarchy',
  OverlayHierarchy = 'OverlayHierarchy'
}

export type HierarchyRoot = {
  rootHierarchyId: number;
  rootKey: string;
  rootName: string;
};

export interface HierarchyTopWithMemberId extends UpsertBattlecardHierarchyTops_upsertBattlecardHierarchyTops {
  memberId: number;
}

export interface HierarchyWithTypeAndFirstLevelMemberCount {
  key: string;
  value: string;
  type: HierarchyType;
  firstLevelMemberCount: number;
}

export enum RootHierarchyField {
  CustomHierarchyField = 'CustomHierarchy.hierarchyId',
  CustomerAccountHierarchyField = 'CustomerAccountHierarchy.hierarchyId',
  GeographicTerritoryHierarchyField = 'GeographicTerritoryHierarchy.hierarchyId',
  OverlayHierarchyField = 'TerritoryHierarchy.hierarchyId'
}

export const enum HierarchyTypeName {
  CUSTOMER_ACCOUNTS = 'Customer Accounts',
  INDUSTRIES = 'Industries',
  GEOGRAPHIC_TERRITORIES = 'Geographic Territories',
  OVERLAY_TERRITORIES = 'Territories'
}

export const enum IconButtonCellRendererType {
  EDIT_ASSIGNMENT = 'edit-assignment',
  EDIT_TERRITORY = 'edit-territory',
  PREVIEW = 'preview',
  ADD = 'add',
  SAVE = 'save',
  CANCEL = 'cancel',
  NON_MAPPABLE = 'non-mappable',
  CONFLICT = 'conflict'
}

export const enum InsightCardStat {
  TERRITORIES = 'territories',
  GEOGRAPHIC_REGIONS = 'geographic-regions',
  TOTAL_ACCOUNTS = 'total-accounts',
  OVERRIDE_ACCOUNTS = 'override-accounts',
  MEASURE_VALUES = 'measure-values'
}

export const enum TextFilterModelType {
  equals = 'equals',
  notEqual = 'notEqual',
  contains = 'contains',
  notContains = 'notContains',
  startsWith = 'startsWith',
  endsWith = 'endsWith'
}

export enum TablesTab {
  ALL = 'all',
  ACTIVITY = 'activity',
  ACTIVITY_PUBLISHED = 'activity_published',
  ACTIVITY_UNPUBLISHED = 'activity_unpublished',
  LOOKUP = 'lookup'
}

export enum TerritoryOptimizationPreviewTab {
  TERRITORY_MAP = 'territory-map'
}

export enum TableType {
  ACTIVITY = 'Activity'
}

export interface TerritoryOptimizationJob {
  fileId: string;
  status?: string;
  pipes?: {
    pipeUuid: string;
    optimizationType: string;
    territoryRuleBase: string;
  }[];
}

export interface TreeNodeExtended extends TreeNodeInfo {
  isChecked?: boolean;
  parentID?: number;
  parentKey?: number;
  type?: string;
  hierarchyId?: number;
  hierarchyType?: HierarchyType;
  rootHierarchyId?: number;
  hierarchyTopKey?: string;
  leafNode?: boolean;
  level?: number;
  hasChildren?: boolean;
  ancestorData?: TreeNodeExtended[];
  path?: string;
  name?: string;
  children?: HierarchyTreeNode[];
  switcherIcon?: React.ReactElement;
  expanded?: boolean;
  title?: string;
}

export interface LocalCurrency {
  canDelete: boolean;
  conversionRate: number;
  currencyHierarchyId: number;
  localCurrencyCode: string;
  localCurrencyName: string;
  localCurrencyIndex?: number;
}
export interface LocalCurrencyFormValues {
  conversion?: number;
  baseConversion?: number;
  currency?: SearchableSelectMenuItem;
}

export interface TerritoryGroupLevelDescription {
  levelIndex: number;
  groupsOnLevel: TerritoryGroupForMap[];
}

export enum MeasureType {
  ALLOCATE_TD = 'Allocated (Top-Down)',
  UPDATED_QUOTA = 'Updated Quota',
  QUOTA_ADJUSTMENT = 'Quota Adjustment',
  ALLOCATION = 'Allocation %',
  PRIOR_YEAR_ACTUAL = 'Prior Year Actual',
  PRIOR_YEAR_TARGET = 'Prior Year Target',
  TRAILING_TWELVE_MONTHS = 'Trailing 12 Months',
  GROWTH = 'Growth',
  SALES = 'Sales',
  UNITS_SOLD = 'Units Sold',
  ACCOUNT_QUOTA = 'Account Quota',
  RAMP = 'Ramp',
  SEASONALITY = 'Seasonality',
  TERRITORY_QUOTA_ADJUSTMENT = 'Territory Quota Adjustment'
}

export enum MeasureFieldType {
  INPUT = 'Input',
  ALLOCATION = 'Allocation',
  CALCULATED = 'Calculated',
  WEIGHTED = 'Weighted',
  HISTORICAL = 'Historical',
  LOADED = 'Loaded',
  FORMULA = 'Formula'
}

export enum MeasureFormatType {
  CURRENCY = 'Currency',
  PERCENTAGE = '%',
  NUMERIC = 'Numeric',
  DATE = 'Date',
  TEXT = 'Text',
  DATA = 'Data'
}

export enum MeasureSource {
  HIERARCHY = 'Hierarchy',
  ACTIVITY = 'Activity',
  TERRITORY = 'Territory',
  LOOKUP = 'Lookup',
  CUSTOM = 'Custom',
  DEFAULT = 'Default'
}

export enum MeasureFormatNumberType {
  CURRENCY = 'currency',
  PERCENT = 'percent',
  UNIT = 'unit',
  NUMERIC = 'numeric'
}

export interface MemberInviteInfo {
  memberInvitedAs: string;
  memberInvitedAt: string;
}

export interface Member {
  memberId: number;
  firstName: string;
  lastName: string;
  employeeId?: string;
  tenantId: number;
  emailAddress: string;
  jobTitle: string;
  deleted: string;
  memberCreatedAt: string;
  memberUpdatedAt: string;
  systemRoleName: string;
  customProperties: string;
  memberInvitesInfo?: MemberInviteInfo[];
}

export interface MemberData {
  user: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  jobTitle: string;
  systemRoleName: string;
  memberUpdatedAt: string;
  employeeId: string;
  memberId: number;
  tenantId: number;
  delete: string;
  memberInvitesInfo?: MemberInviteInfo[];
}

export interface MemberList {
  memberList: Member[];
  totalCount: number;
}

export type MembershipSpec = {
  memberId: number;
  tenantId: number;
  tenantName: string;
  tenantGlobalId: string;
  tenantSlug: string;
  systemRoleName: UserRoleType;
  planningCycles: PlanningCycleSpec[];
};

type MapCompareSelection = {
  battlecardId: number;
  quotaComponentId: number;
  deploymentModelId: number;
  geoTgtId: number;
};

export type MapCompareState = {
  primary: MapCompareSelection;
  secondary: MapCompareSelection;
  selectionToRestore: MapCompareSelection;
};

export enum Month {
  JANUARY = 'January',
  FEBRUARY = 'February',
  MARCH = 'March',
  APRIL = 'April',
  MAY = 'May',
  JUNE = 'June',
  JULY = 'July',
  AUGUST = 'August',
  SEPTEMBER = 'September',
  OCTOBER = 'October',
  NOVEMBER = 'November',
  DECEMBER = 'December'
}

export enum NumberInputFormatStyle {
  PERCENTAGE,
  CURRENCY,
  NUMBER
}

export enum OperatorType {
  EQUAL = '=',
  NOT_EQUAL = '!='
}
export interface Owner {
  ownerId?: number;
  employeeId?: string;
  firstName: string;
  lastName: string;
  memberId?: number;
  ownership?: number;
  effectiveStartDate?: string;
  effectiveEndDate?: string;
}

export const PERIODICITY_ANNUAL_VALUE_FIELD_ID = 'Annual total';

export enum PeriodicityType {
  NONE,
  MONTHLY,
  QUARTERLY,
  SEMI_ANNUALLY,
  YEARLY
}

export enum PeriodicityLabels {
  YEARLY = 'Yearly',
  SEMI_ANNUAL = 'Semi-annual',
  QUARTERLY = 'Quarterly',
  MONTHLY = 'Monthly'
}

export enum PillLevel {
  ROOT = 'root',
  CATEGORY = 'category',
  TERRITORY = 'territory'
}

export enum PinSetSource {
  NEW = 'new',
  EXISTING = 'existing'
}

export enum PinIcon {
  STAR = 'star',
  SQUARE = 'square',
  USER = 'user',
  FLAG = 'flag',
  LOCATION = 'location',
  PIN = 'pin',
  FAVORITE = 'favorite'
}

export type PlanningCycleSpec = {
  planningCycleId: number;
  deploymentModels: DeploymentModelSpec[];
};

export enum PlanMeasureType {
  BUILTIN = 'built-in',
  CUSTOM = 'custom'
}

export enum PlanTargetsMeasureAllowed {
  EVENSPLIT = 'Even Split',
  PRIORYEAR = 'Prior Year Actual'
}

export interface PlanTargetsMeasure {
  planningCycleId: number;
  measureName: string;
  measureCategory: string;
  measureFieldType: string;
  measureFormatType: string;
  measureFormula: string;
  measureConstraints: string;
  activityMeasureColumnName: string;
  measureId: number;
  measureType: PlanMeasureType;
}

export enum PendingSelectedBattleCardId {
  NONE = 'None'
}

export enum ProcessingStatus {
  PENDING = 'Pending',
  COMPLETED = 'Completed',
  FAILED = 'Failed',
  IN_PROGRESS = 'In-progress'
}

export enum DataTableFileStatusType {
  DEFAULT = 'default',
  DATE = 'date',
  STATUS = 'status',
  PROCESSING_STATUS = 'processing status'
}

export enum DataTableType {
  ACTIVITY = 'Activity File'
}

export enum QuotaApprovalStatus {
  INITIATE = 'initiate',
  CANCEL = 'cancel',
  NONE = 'none'
}

export interface QuotaApprovals {
  totalApprovalsRequired: number;
  numberOfApproved: number;
  workflows: WorkflowTreeNode[];
  completionRate?: number;
  rootBCOwnerEmail?: string | null;
}
export interface QuotaComponent {
  quotaComponentId: number;
  quotaComponentName: string;
  quotaComponentComment: string;
  canDelete: boolean;
}

export enum QuotaGridColumnName {
  ALLOCATED_TD = 'Allocated (Top-Down)',
  QUOTA_ADJUSTMENT = 'Quota Adjustment',
  ALLOCATION = 'Allocation %',
  UPDATED_QUOTA = 'Updated Quota',
  TERRITORY_ID = 'Territory ID',
  TERRITORY_NAME = 'Territory Name',
  PRIOR_YEAR_TARGET = 'Prior Year Target',
  PRIOR_YEAR_ACTUAL = 'Prior Year Actual',
  TRAILING_MONTHS = 'Trailing 12 Months',
  SALES = 'Sales',
  UNITS_SOLD = 'Units Sold',
  TERRITORY_GROUP = 'Territory Group',
  TERRITORY_GROUP_TYPE = 'Territory Group Type',
  COMMENTS = 'Comments',
  ACCOUNT_QUOTA = 'Account Quota',
  SEASONALITY = 'Seasonality',
  ADJUSTMENT = 'Adjustment %'
}

export interface SelectedPlanningCycle {
  id?: number;
  slug?: string;
  periodicity?: PeriodicityType;
  planningCycleDuration?: number;
  planningCycleStartDate?: string;
  planningCycleName?: string;
}

export interface SelectedQuotaDrillInTerritory {
  territoryName: string;
  territoryId: string;
  ruleId: number;
  territoryGroupId: number;
  effectiveDate?: string;
  endDate?: string;
  accountQuotaTotal?: number;
  accountQuotaMonthlyBreakdownTotals?: Record<string, string>;
}

export interface SelectedAccountQuotaDrillInCell {
  accountId: number;
  measureId: number;
}

export interface SelectedCell {
  rowIndex: number;
  value: string | number;
  column: string;
}

export interface SelectedQACell extends SelectedCell {
  date: string;
  column: QuotaGridColumnName;
  measureId: string;
  territoryGroupId: number;
  ruleId: string;
  factorPath?: number | null;
}

export interface SelectedTDACell extends SelectedCell {
  ruleId: string;
}

export interface BalancingPanelRow {
  balancingHierarchyName: string;
  id: string;
  balancingMetric: number;
  progress: number;
  isAddition?: boolean;
  isRemoved?: boolean;
  isValidForRebalance?: boolean;
}

export enum SourcePanel {
  LEFT = 'left',
  RIGHT = 'right'
}

export enum UserAccountType {
  new = 'new',
  existing = 'existing'
}

export enum RegistrationType {
  onboard = 'onboard'
}

export enum Auth0ScreenTypes {
  signup = 'signup',
  login = 'login'
}

export interface ReportingCurrency {
  canDelete: boolean;
  currencyHierarchyId: number;
  localCurrencies: LocalCurrency[];
  currency?: SearchableSelectMenuItem;
}

export interface ReportingCurrencyFormValues extends LocalCurrencyFormValues {
  localCurrencies: LocalCurrencyFormValues[];
}

export enum CustomHeader {
  BATTLE_CARD_ID = 'X-SP-BATTLECARD-ID',
  PLANNING_CYCLE_ID = 'X-SP-PLANNING-CYCLE-ID',
  DEPLOYMENT_MODEL_ID = 'X-SP-DEPLOYMENT-MODEL-ID'
}

export enum TenantHeader {
  TENANT_GLOBAL_ID = 'X-SP-TENANT-GLOBAL-ID',
  TENANT_SLUG = 'X-SP-TENANT-SLUG',
  IMPERSONATION_TOKEN = 'X-SP-IMPERSONATION-TOKEN'
}

export type Headers = Record<string, string>;

export interface RuleType {
  filters: FilterType[];
  ruleGroups?: RuleType[];
  combinator: string;
  id?: string;
}

export const enum SectionName {
  TERRITORY_DEFINE = 'territoryDefine',
  WORKFLOW = 'workflow',
  PLAN_TARGETS = 'planTargets',
  GLOBAL_MEASURES = 'globalMeasures'
}

export interface BattleCardSection {
  name: string;
  header: string;
}

export interface DataSheet {
  sheetId: number;
  sheetName: string;
  sheetType: string;
  sheetCreatedAt: string;
  sheetUpdatedAt: string;
}

export enum SheetType {
  QUOTA_SHEET = 'Quota Sheet',
  TERRITORY_SHEET = 'Territory Sheet',
  SELLER_SHEET = 'Seller Sheet',
  ALLOCATION_FACTOR_SHEET = 'Allocation Factor Sheet',
  SELLER_QUOTA = 'Seller Quota',
  CUSTOM_SHEET = 'Custom Sheet',
  QUOTA_DISTRIBUTION_SHEET = 'Quota Distribution Sheet',
  ACCOUNT_QUOTA_DISTRIBUTION_SHEET = 'Account Quota Distribution Sheet'
}

export enum DefaultSheetName {
  QUOTA_SHEET = 'Quota sheet',
  QUOTA_DISTRIBUTION_SHEET = 'Quota Distribution sheet',
  TERRITORY_QUOTA_SHEET = 'Territory sheet',
  SELLER_QUOTA_SHEET = 'Seller sheet',
  ACCOUNT_QUOTA_DISTRIBUTION_SHEET = 'Account Quota Distribution Sheet'
}

export enum SelectedState {
  SELECTED = 'selected', // all children are selected
  UNSELECTED = 'unselected', // all children are unselected
  INTERMEDIATE = 'intermediate' // some children are selected
}

export interface DataMappingSelectMenuItem extends SearchableSelectMenuItem {
  properties?: React.ReactNode | Record<string, unknown>;
  autoMatched?: boolean;
}

export enum SymonPipeConfigurationStatus {
  NOT_STARTED = 'not-started',
  RUNNING = 'running',
  COMPLETED = 'completed',
  FAILED = 'failed',
  CANCELLED = 'cancelled'
}

export enum TabIds {
  QUOTA = 'allocate-quota',
  PLAN_TERRITORY = 'plan-territory',
  TERRITORY_BALANCING = 'territory-balancing',
  ACCOUNT_LIST = 'account-list',
  PLAN_SELLER = 'plan-seller',
  MANAGE_SELLER = 'manage-seller',
  MANAGE_TERRITORY = 'manage-territory',
  ACCOUNT_QUOTA = 'account-quota',
  LOADING_SHEETS_DATA = 'Loading sheets data...'
}

export interface Toast {
  message: string;
  title: string;
}

export interface InclusionExclusion {
  id?: string;
  rootHierarchyId: number;
  rootHierarchyName: string;
  hierarchyType: RootHierarchyField;
  contains: {
    hierarchyId: number;
    name: string;
  }[];
  containsTotal?: number;
}

export interface RuleBeingEdited extends FlattenedFilter {
  rulePartType: RulePartType;
}

export interface TerritoryRuleHierarchyWithInfo {
  ruleId: number | null;
  territoryId: string;
  territoryName: string;
  __typename: 'TerritoryRuleHierarchyWithInfo';
}
export interface TerritoryGridRow {
  [GridFields.TERRITORY_ID]: string;
  [GridFields.TERRITORY_NAME]: string;
  [GridFields.MEASURE_VALUE]: number;
  [GridFields.NUMBER_OF_ACCOUNTS]: number;
  [GridFields.TERRITORY_RULE]: NewRuleDefinitionResponse;
  index: number;
  measureGap: number;
  numberOfAccountsGap: number;
  mean: number;
  standardDeviation: number;
  ruleId: number;
  territoryGroupId: number;
  territoryGroupName: string;
  color: string;
  inheritsFrom?: TerritoryRuleHierarchyWithInfo[];
}

export interface SellerRow {
  territoryId: string;
  [GridFields.TERRITORY_NAME]: string;
  index: number;
  ruleId: number;
  territoryGroupId: number;
  territoryGroupName: string;
  owners: Owner[];
}

export const enum TerritoryGroupDialogModes {
  CREATE_GROUP = 'create',
  EDIT_GROUP = 'edit',
  DELETE_GROUP = 'delete'
}

export interface TerritoryGroup {
  name: string;
  territoryGroupId: string;
  territoryGroupParentId: null | string;
  battlecardId: string;
  quotaComponentId: number;
}

export interface TerritoryGroupMeasure {
  measureId: string;
  measureName: MeasureType | string;
  measureValue: number;
  measureDate?: string;
  measureQuarter?: string;
  measureHalf?: string;
  measureYear?: string;
}

export type PeriodicGroupMeasure = GetTerritoryQuota_getTerritoryQuota_periodicTerritories_measures;

// NB parent id is a number but id is a string
export interface TerritoryGroupWithMeasuresApiCall {
  territoryGroupId: string;
  territoryGroupParentId: null | number;
  territoryGroupName: string;
  hierarchyId: number;
  battlecardId: string;
  path: string;
  precedence: number;
  hierarchyType?: string;
  measures?: [TerritoryGroupMeasure];
  children?: [TerritoryGroupWithMeasuresApiCall];
}

export interface TerritoryGroupWithMeasuresNodeData extends TerritoryGroup {
  precedence: number;
  measures: TerritoryGroupMeasure[];
  measureId: string;
  children: TerritoryGroupWithMeasuresNodeData[];
}

export type TerritoryMeasuresGrid = GetTerritoryQuota_getTerritoryQuota;

export enum UpsertQuotaComponentDeleted {
  TRUE = 'Y',
  FALSE = 'N'
}

// TODO TQP-1381 make planningCycleDescription required again once API returns comment field
// TODO TQP-8467 replace deploymentModelId with deploymentModels to support muliple deployment models
export type UserPlanningCycle = {
  planningCycleId: number;
  planningCycleName: string;
  planningCycleStartDate: Date;
  planningCycleDescription?: string;
  planningCyclePeriodicity: string;
  planningCycleDuration: number;
  planningCycleSlug?: string;
  currencyCode: string;
  deploymentModels?: DeploymentModelSpec[];
  planningCycleArchived: boolean;
};

export type UserQuerySpec = {
  getUserSpec: UserSpec;
};

export enum UserRoleType {
  ADMIN = 'Administrator',
  CONTRIBUTOR = 'Contributor',
  TERRITORY_GROUP_TYPE_OWNER = 'Territory Group Type Owner',
  TERRITORY_GROUP_OWNER = 'Territory Group Owner',
  BATTLE_CARD_OWNER = 'Battlecard Owner',
  NONE = 'None'
}

export type UserSpec = {
  subjectId: string;
  firstName: string;
  lastName: string;
  passwordUpdatedAt: Date;
  membershipSpecs: MembershipSpec[];
  emailAddress: string;
};

export enum WorkflowStatus {
  IN_PROGRESS = 'In progress',
  APPROVED = 'Approved',
  NOT_STARTED = 'Not started',
  SUBMITTED = 'Submitted',
  REJECTED = 'Rejected'
}
export interface WorkflowTreeNode {
  workflowHierarchy: string[];
  user: string;
  email: string;
  status: string;
  workflowId: number;
  type: WorkflowType;
  approver: string;
}

export enum WorkflowType {
  BATTLECARD,
  TERRITORY_GROUP
}

export interface MappingFields {
  key: string;
  value: string;
  properties: {
    colRequired: boolean;
    valueRequired: boolean;
    dataType: DataType;
    fieldType: string;
  };
}

export enum SheetDefinitionType {
  BALANCING_METRIC = 'BalancingMetric',
  DEFAULT_BALANCING_METRIC = 'DefaultBalancingMetric',
  NOT_BALANCING_METRIC = 'N/A'
}

export enum MapCountries {
  CA = 'CA',
  US = 'US',
  GB = 'GB',
  DE = 'DE'
}

export enum MapWidth {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE'
}

export interface HierarchyPolygons {
  hierarchyItemCount: number;
  hierarchyId: number[];
  name: string[];
  polygonId: string[];
  sourceKey: string[];
  country: string[];
  center: Array<[number, number]>;
}

export interface PolygonDetails {
  geoId: number;
  name: string;
  polygonId: string;
  country: string;
  featureStateIds: FeatureStateIds;
  center: [number, number];
}

export type MapboxExpression =
  | Array<boolean | string | number | MapboxExpression>
  | ['literal', Record<string, number | string | boolean>];

export interface MapDimensions {
  height: number;
  width: number;
}

export enum CombinedRuleId {
  MIXED = 'mixed',
  UNASSIGNED = 'unassigned'
}

export type CustomerTooltip = {
  x: number;
  y: number;
} & CustomerTooltipProperties;

export type CustomerTooltipProperties = Partial<{
  accountName: string | null;
  measureValue: number | null;
  isModifierAccount: boolean | null;
  territoryName: string | null;
  territoryId: string | null;
  ruleId: number | null;
  combinedRuleId: string | null;
  isGeoOverassigned: boolean | null;
  geoOverassignmentCount: number | null;
  isClusterContainsOverassigned: boolean | null;
  isClusterContainsUnassigned: boolean | null;
}>;

export type PolygonTooltip = {
  x: number;
  y: number;
  polygonId: string;
  polygonName: string;
} & PolygonTooltipProperties;

export type PolygonTooltipProperties = {
  territoryId?: string | null;
  territoryName?: string | null;
  groupName?: string | null;
  groupOwner?: string | null;
  isPolygonOverAssigned: boolean;
  isOverAssignedRule: boolean;
  overAssignmentCount: number;
  ruleId: number | null;
};

export enum MapActionMenuEnum {
  Context = 'Context',
  Action = 'Action'
}

export type MapContextMenuInfo =
  | { type: MapActionMenuEnum.Action }
  | { type: MapActionMenuEnum.Context; x: number; y: number };

export interface LassoPolygon {
  geometry: Polygon;
  type: string;
}

export enum TerritoryOptimizationStep {
  START = 'start',
  PARAMETERS = 'parameters',
  CONSTRAINTS = 'constraints',
  OPTIMIZE_TERRITORIES = 'optimize-territories',
  OPTIMIZE_RESULTS = 'optimize-results',
  PREVIEW = 'preview',
  PUBLISHED = 'published'
}

export enum TerritoryOptimizationStatus {
  UNSTARTED = 'unstarted',
  OPTIMIZING = 'optimizing',
  FAILED = 'failed',
  OPTIMIZED = 'optimized'
}

export type StructuredCloneableFeature = {
  id: string;
  geometry: Polygon | MultiPolygon;
};

export enum MapSelectionTarget {
  polygons = 'polygons',
  territories = 'territories',
  accounts = 'accounts'
}
export enum MapSelectionTool {
  togglePointer = 'togglePointer',
  addLasso = 'addLasso',
  removeLasso = 'removeLasso'
}
export enum MapSelectionRestriction {
  assigned = 'assigned',
  unassigned = 'unassigned',
  all = 'all'
}

export enum AccountTypeFilterOptions {
  BASE_AND_OVERRIDES = 'baseAndOverrides',
  OVERRIDES = 'overrides'
}

export enum AccountTerritoryFilterOptions {
  ALL_TERRITORIES = 'allTerritories',
  SELECTED_TERRITORIES = 'selectedTerritories'
}

export interface AccountFilter {
  territory: AccountTerritoryFilterOptions;
  override: AccountTypeFilterOptions;
}

export type FilterInput = Record<
  string,
  {
    filterType: string;
    type: string;
    filter: string | number;
    filterTo: string | number | null;
  }
>;

export interface MapSelectionOptions {
  tool: MapSelectionTool;
  target: MapSelectionTarget;
  restriction: MapSelectionRestriction;
}

export enum TerritoryColors {
  COBALT_5 = '#597EF7',
  COBALT_3 = '#ADC6FF',
  TURQUOISE_4 = '#5CDBD3',
  LIME_8 = '#5B8C00',
  LIME_4 = '#D3F261',
  SEPIA_5 = '#FFC53D',
  SEPIA_3 = '#FFE58F',
  RED_5 = '#FF4D4F',
  RED_3 = '#FFA39E',
  BLUE_4 = '#69C0FF',
  INDIGO_6 = '#722ED1',
  INDIGO_5 = '#B37FEB',
  ORANGE_6 = '#FA8C16',
  ORANGE_5 = '#FFC069',
  TURQUOISE_7 = '#08979C',
  ROSE_4 = '#FF85C0'
}

export const TerritoryColorList = [...Object.values(TerritoryColors)];
export const ScrambledTerritoryColorList = [
  TerritoryColors.COBALT_5,
  TerritoryColors.RED_5,
  TerritoryColors.INDIGO_6,
  TerritoryColors.ORANGE_6,
  TerritoryColors.LIME_4,
  TerritoryColors.ROSE_4,
  TerritoryColors.TURQUOISE_4,
  TerritoryColors.SEPIA_5,
  TerritoryColors.COBALT_3,
  TerritoryColors.RED_3,
  TerritoryColors.TURQUOISE_7,
  TerritoryColors.LIME_8,
  TerritoryColors.ORANGE_5,
  TerritoryColors.INDIGO_5,
  TerritoryColors.SEPIA_3,
  TerritoryColors.BLUE_4
];

export interface WorkerManagedState {
  selectedGeoIds: number[];
  selectedAccounts: number[];
  selectedRuleIds: number[];
  sourceGroups: SourceGroup[];
  polygonNameMap: ReadonlyMap<string, string>;
  geographyNameMap: ReadonlyMap<number, string>;

  lockedRuleIds: Set<number>;
  ignoredRuleIds: Set<number>;

  isUndoAvailable: boolean;
  isLoadingGeoHierarchy: boolean;
  isLoadingSelection: boolean;
  isLoadingRules: boolean;
  isLoadingCustomerPins: boolean;
  rules: RuleForMap[];
  // Null when hierarchy not present
  bounds: GeoBounds | null;
  // Null when hierarchy selection is empty
  hierarchySelectionDetails: MapHierarchySelectionDetails | null;
  customers: ReadonlyArray<CustomerForMap>;
  customHierarchies: NamedHierarchy[];
  customHierarchyFilter: CustomHierarchyFilter;
}

export interface CustomerForMap {
  center: [number, number];
  accountId: number;
  geographyId: number;
  accountName: string;
  ruleId: number | null;
  ruleColor: string | null;
  territoryName: string | null;
  territoryId: string | null;
  measureValue: number | null;
  isModifierAccount: boolean;
  isGeoOverassigned: boolean;
  geoOverassignmentCount: number;
  accountOverassignmentCount: number;
}

export interface CustomerInsight {
  accountName: string;
  accountId: number;
  ruleId: number | null;
  geographyId: number | null;
  measureValue: number | null;
  isModifierAccount: boolean;
}

export interface RuleIncExcGroup {
  inclusions: InclusionExclusion[];
  exclusions: InclusionExclusion[];
}

export interface RuleDefinitionResponseFilter {
  id: string;
  field: string;
  operator: OperatorType;
  fieldIdsDetails?: Array<{
    fieldId: number;
    fieldName: string;
  }> | null;
  label?: string | null;
  rootHierarchyId: number;
  rootHierarchyName: string;
}

export interface RuleDefinitionResponseRuleGroup {
  id: string;
  combinator: CombinatorType;
  filters: RuleDefinitionResponseFilter[] | null;
  ruleGroups?: RuleDefinitionResponseRuleGroup[] | null;
}

export type RebalancingMetric = {
  totalAccountsCount: number;
  totalAccountsMean: number;
  measureValueMean: number;
  measureValueStandardDeviation: number;
};

export enum SelectedPlanningType {
  TERRITORY_PLANNING = 'territory',
  QUOTA_PLANNING = 'quota',
  NONE = 'none'
}

export enum DeploymentModelPhase {
  plan = 'plan',
  manage = 'manage'
}

export enum PlanningPath {
  quota = 'quota',
  territory = 'territory'
}

export interface SelectedTenant {
  id: number | null;
  globalId: string | null;
  slug: string | null;
}

export interface Column {
  measureName: string;
  editable: boolean;
  expandable?: boolean;
  value?: number;
  type?: string;
  measureFieldType?: MeasureFieldType | string;
}

export interface QuotaGridMeasureColumn {
  measureName: string;
  measureDate?: string;
  measureQuarter?: string;
  measureHalf?: string;
  measureYear?: string;
}

export interface HierarchyItem {
  ruleId?: number;
  hierarchyId?: number;
  key?: string;
  territoryId?: string;
  name: string;
}
export interface HierarchyItemDelta {
  hierarchyItem: HierarchyItem;
  isSelected: boolean;
  parent?: string;
}

export type HierarchyItemDeltaMap = Record<number, HierarchyItemDelta>;
export type SelectedMap = Record<string, boolean>;

export interface SelectedTerritory {
  territoryId: string;
  ruleId: number;
  territoryGroupId: number;
}

export enum MapActions {
  Create = 'Create',
  Delete = 'Delete',
  FixConflict = 'FixConflict',
  Reassign = 'Reassign',
  Unassign = 'Unassign',
  Undo = 'Undo',
  Isolate = 'Isolate'
}

export type PossibleMapActions = Record<MapActions, boolean>;

export const RuleChangeCauses = {
  ...MapActions,
  Recolor: 'Recolor',
  InitialLoad: 'InitialLoad',
  LimitationChange: 'LimitationChange',
  FinishUndo: 'FinishUndo',
  FailedUndo: 'FailedUndo',
  MeasureChange: 'MeasureChange',
  Coinsort: 'Coinsort'
} as const;

export type RuleChangeCause = ValueOf<typeof RuleChangeCauses>;

export interface MapRuleFields {
  ruleId: number;
  territoryId: string;
  territoryName: string;
  territoryGroupId: number;
  effectiveDate: string;
  endDate: string;
  color: string | null;
  numberOfAccounts: number;
  measureValue: number;
}

export interface SimplifiedGeoRule {
  ruleId: number;
  territoryId: string;
  territoryName: string;
  territoryGroupId: number;
  effectiveDate: string;
  endDate: string;
  color: string | null;
  isMappable: boolean;
  isPassingFilter: boolean;
  isEmpty: boolean;
  numberOfAccounts: number;
  measureValue: number;
}

export interface RuleForMap extends SimplifiedGeoRule {
  isOverAssigned: boolean;
  accountModifierCount: number; // Todo: Move into Base
}

export interface SelectedGeography {
  geographyName: string;
  measureValue: number;
}

export interface NamedHierarchy {
  hierarchyId: number;
  name: string;
}

export interface SimplifiedRuleWithDefinition {
  rule: SimplifiedGeoRule;
  geoHierarchyIds: Set<number>;
  reservedAccountHierarchyIds: Set<number>;
  accountHierarchyIds: Set<number>;
  customHierarchies: Map<number, NamedHierarchy>;
  excludedCustomHierarchies: Set<number>;
}

export interface RuleWithAttachedDecompressedDefinition {
  rule: GetTerritoryRulesForMap_getTerritoryRules_territoryRules;
  definition: NewRuleDefinitionResponse;
  compressedDefinition: Compressed<CompressedRuleDefinitionResponseEnum.NewRuleDefinitionResponse>;
}

export type SimpleUpsertRule = Omit<
  UpsertTerritoryRuleVariables,
  'ruleDefinition' | 'completeRuleDefinition' | 'inheritsFrom' | 'definition'
>;

export type ClusterId = number;

export type ClusterBreakdown = {
  clusterId: ClusterId;
  flyTo: mapboxgl.FlyToOptions;
};

export type ClusterFeature = GeoJSON.Feature<GeoJSON.Point, { cluster_id: ClusterId }> & { source?: string };

export interface FeatureStateIds {
  id: string; // Id of the feature
  source: string;
  sourceLayer?: string;
}

export interface SourceGroup {
  sourceKey: string;
  sourceUrl: string;
  sourceId: string;
  countries: string[];
}

export interface ExhaustivePolygonFeatureState {
  isSelected: boolean;
  isOverAssignedGeo: boolean;
  overAssignmentCount: number;

  ruleId: number | null;
  territoryId: string | null;
  territoryName: string | null;
  ruleColor: string | null;
  isOverAssignedRule: boolean;
  isRuleIgnored: boolean;

  groupColor: string | null;
  groupName: string | null;
  groupOwner: string | null;
}

export type PolygonFeatureState = Partial<ExhaustivePolygonFeatureState>;

export interface MapRuleUpsert extends UpsertTerritoryRuleVariables {
  territoryGroupId: number;
  definition?: NewRuleDefinitionResponse;
  territoryId: string;
  territoryName: string;
  ruleId?: number;
  color?: string;
}

export interface FixConflictDetails {
  hierarchyIdsBeingRemoved: Array<number>;
  territoryId: string;
  ruleId: number;
  hierarchyNamesBeingRemoved: string[];
}

export type WrappedRuleDefinition = Pick<UpsertTerritoryRuleVariables, 'definition'>;

export interface MapHierarchySelectionDetails {
  isOnlyUnassigned: boolean;
  atLeastOneModifier: boolean;
}

export interface FlattenedFilter {
  field: string;
  rootHierarchyId: number;
  rootHierarchyName: string;
  fieldIdsDetails: Record<string, FieldIdsDetails[]>;
  inclusionTotal?: number;
  exclusionTotal?: number;
}

export interface GeoBounds {
  minLat: number;
  minLon: number;
  maxLat: number;
  maxLon: number;
}
export interface PinSet {
  pinSetId: number;
  battlecardId: number;
  locationGroupId: number;
  pinSetName: string;
  icon: string;
  color: string;
}

export interface LocationGroup {
  locationGroupId: number;
  locationGroupName: string;
}

export enum RulePartType {
  INCLUSION,
  EXCLUSION,
  BASE,
  OVERRIDE
}

export interface PinFeatureProperties {
  pinSetId: number;
  name: string;
  icon: string;
  color: string;
}

export type PinFeature = GeoJSON.Feature<GeoJSON.Point, PinFeatureProperties>;

export type PinFeatureCollection = GeoJSON.FeatureCollection<GeoJSON.Point, PinFeatureProperties>;

export type CustomerFeatureCollection = GeoJSON.FeatureCollection<GeoJSON.Point>;

export enum CustomerVisualization {
  HIDE = 'HIDE',
  HEAT = 'HEAT',
  CLUSTER = 'CLUSTER'
}
export type LassoAbleGeometry = GeoJSON.Point | GeoJSON.Polygon | GeoJSON.MultiPolygon;

export interface TerritoryWorkflowAddCommentFormValues {
  threadId?: number | null;
  content: string;
  version: number;
}

export enum InsightsCardState {
  None = 'None',
  Summary = 'Summary',
  Table = 'Table'
}

export enum TerritorySheetGridColumnName {
  TERRITORY_ID = 'Territory ID',
  TERRITORY_NAME = 'Territory name',
  TERRITORY_EFFECTIVE_DATES = 'Territory effective dates',
  SEASONALITY = 'Seasonality',
  PLANNED_QUOTA = 'Planned Quota',
  QUOTA_ADJUSTMENT = 'Quota Adjustment',
  ACTUAL_TERRITORY_QUOTA = 'Actual Territory Quota',
  REVISED_TERRITORY_QUOTA = 'Revised territory quota',
  ADJUSTMENT_EFFECTIVE_DATE = 'Adjustment Effective Date',
  TERRITORY_QUOTA_ADJUSTMENT = 'Territory Quota Adjustment'
}

export enum SellerSheetGridColumnName {
  TERRITORY_ID = 'Territory ID',
  TERRITORY_NAME = 'Territory name',
  ACTUAL_TERRITORY_QUOTA = 'Actual Territory Quota',
  REVISED_TERRITORY_QUOTA = 'Revised territory quota',
  SELLERS = 'Sellers',
  ASSIGNMENT_PERCENTAGE = 'Assignment %',
  SELLER_EFFECTIVE_DATES = 'Seller Effective Dates',
  RAMP = 'Ramp',
  SELLER_QUOTA_ADJUSTMENT = 'Seller Quota Adjustment',
  ACTUAL_SELLER_QUOTA = 'Actual Seller Quota',
  ASSIGNED_SELLER_QUOTA = 'Assigned seller quota'
}

//Add sheet defintions order look up map to match with design order
export enum TerritorySheetDefinitionsOrderLookUpMap {
  'Seasonality',
  'Planned Quota',
  'Territory Quota Adjustment',
  'Adjustment Effective Date',
  'Actual Territory Quota'
}

export const SellerSheetDefinitionsOrderLookupMap = {
  'Actual Territory Quota': 0,
  Sellers: 1,
  'Assignment %': 2,
  'Seller Effective Dates': 3,
  Ramp: 4,
  'Seller Quota Adjustment': 5,
  'Actual Seller Quota': 6
};

export const MonthMap = {
  '1': 'January',
  '2': 'February',
  '3': 'March',
  '4': 'April',
  '5': 'May',
  '6': 'June',
  '7': 'July',
  '8': 'August',
  '9': 'September',
  '10': 'October',
  '11': 'November',
  '12': 'December'
};

export type AnyJson = boolean | number | string | Date | null | Array<AnyJson> | JsonObject;

export interface JsonObject {
  [key: string]: AnyJson;
}

export interface Lookup {
  fileId: string | null;
  lookupId: string;
  lookupType: LookupTypeEnum;
  metadata: LookupMetadata;
}

export interface LookupMetadata {
  tableData: JsonObject;
  tableSchema: JsonObject;
}

export interface FieldIdsLookUpTable {
  [id: number]: string;
}

// Until redirecting to arbitrary paths is supported: these details are provided during a redirect
export interface EmbeddedAuthSavedAppState {
  tenantSlug: string;
  planningCycleSlug: string;
  deploymentModelPhase: string | null;
}

export enum UpsertTerritoryDateStatusType {
  SKIP = 1,
  SUCCESS = 2,
  FAIL = 3
}

export interface UpsertTerritoryFields extends GetTerritoryRulesForFieldsVariables {
  totalCount: number;
  fieldIdsLookUpTable: FieldIdsLookUpTable;
}

export interface EditableFieldCellRendererProps extends ICellRendererParams {
  shouldShowAddButton?: boolean;
  shouldDisableAddButton?: boolean;
  measureName?: string;
  fieldId?: number;
  isAddition?: boolean;
  isDeletion?: boolean;
  isTooltipDisplayed?: boolean;
  isLink?: boolean;
  isEffectiveDateOutOfRange?: boolean;
  tooltipMessage?: string;
}

export interface OwnersUpsert {
  ownersToDelete: Owner[];
  allOwnersInTerritory: Owner[];
  territoryRuleId: number;
}

export type ICellRendererParamsWithContext<ContextType> = Omit<ICellRendererParams, 'context'> & {
  context: ContextType;
};

export interface AccountRedirect {
  __typename: 'AccountRuleBindingRedirect';
  startDate: string | null;
  endDate: string | null;
  redirectId: number;
  targetRule: RedirectTargetRule | null;
  fields: GetAccountRuleBindings_getAccountRuleBindings_bindings_redirects_fields[];
}

export interface RedirectTargetRule {
  __typename: 'TerritoryRuleSummary';
  ruleId: number;
  territoryId: string;
  territoryName: string;
  territoryGroupId: number;
  effectiveDate: string | null;
  endDate: string | null;
}

export type GetCatalogItem = Omit<
  GetGeoCatalogAndSelection_getPlanningCycleSpec_availableCountrySourceCatalogItems,
  '__typename'
>;

export interface CommandCenterHierarchyPanelContentFormValues {
  hierarchyName: string;
  hierarchyKey: string;
  address1: string;
  address2: string;
  city: string;
  country: string;
  industry: string;
  zipPostal: string;
  stateProvince: string;
  effectiveDate: string;
  endDate: string;
  customProperties: React.ReactNode[];
}

export interface TerritoryGroupForMap {
  territoryGroupParentId: number | null;
  territoryGroupId: number;
  name: string;
  owner: { firstName: string; lastName: string };
  color: string;
}

export type MapContextBattleCard = GetBattlecardsForEmbeddedMap_getDeploymentModelSpec_battlecards & {
  battlecardType: BattlecardType | string;
  battlecardInfoLevel: BCInfoLevelEnum | null;
  territoryGroupTypes: Array<
    GetBattlecardsForEmbeddedMap_getDeploymentModelSpec_battlecards_territoryGroupTypes & {
      hierarchyType: HierarchyType | string;
    }
  >;
};

export enum OptimizationScenario {
  CURRENT_STATE = 'Current state',
  MOST_BALANCED = 'balanced',
  MOST_CONTIGUOUS = 'most_compact',
  LEAST_DISRUPTION = 'Least disruption'
}

export const GEOGRAPHIES = 'Geographies';
export const EXCLUDE_COLUMN = 'Exclude column';
export const SELECT = 'Select';
export const INVALID_DATE = 'Invalid Date';
export const ADMIN = 'Admin';
export const TOTAL = 'Total';
export const AVERAGE = 'Average';

export enum AllocationItemTypes {
  PLANNED_TERRITORY_QUOTA,
  TOP_DOWN_TARGET,
  QUOTA_ADJUSTMENTS,
  BUSINESS_TARGET,
  REVISED_TERRITORY_QUOTA,
  ASSIGNED_SELLER_QUOTA,
  GAP
}

export enum RebalanceHierarchyInputKey {
  GEOGRAPHIES = 'Geographies'
}

export interface PlanningCycleJob {
  planningCycleId: number;
  planningCycleName: string;
  jobType: PCJobType;
  isScenarioCreation?: boolean;
}

export interface TerritoryRuleJob {
  jobKey: string;
  deleteRuleCount?: number;
}

export interface AllocateTopDownJob {
  battlecardId: number;
  quotaComponentId: number;
  selectedPillId?: number;
}

export interface ExportChangeHistoryJob {
  jobName: JobCatalogObjectType.GET_CHANGE_HISTORY_EXPORT;
}

export type LongRunningJob = PlanningCycleJob | AllocateTopDownJob | TerritoryRuleJob | ExportChangeHistoryJob;

export interface TokenState {
  token: string | null;
  isLoading: boolean;
}

export enum LocationColumnHeaderKeys {
  COUNTRY_COLUMN = 'Country',
  ZIP_POSTAL = 'ZipPostal',
  NAME_COLUMN = 'Name',
  ADDRESS_COLUMN_1 = 'Address1',
  ADDRESS_COLUMN_2 = 'Address2',
  CITY = 'City',
  STATE_PROVINCE = 'StateProvince',
  LATITUDE = 'Latitude',
  LONGITUDE = 'Longitude'
}

export const MathJSErrorMap = {
  UNEXPECTED_END_OF_EXPRESSION: 'Unexpected end of expression',
  VALUE_EXPECTED: 'Value expected',
  RIGHT_PARENTHESIS_EXPECTED: 'Parenthesis ) expected',
  END_OF_STRING_DOUBLE_QUOTES_EXPECTED: 'End of string " expected',
  UNEXPECTED_PART: 'Unexpected part',
  UNEXPECTED_OPERATOR: 'Unexpected operator'
};

export enum MapLevelType {
  ZIP_OR_POSTAL = 'Zip or postal code',
  STATE_OR_PROVINCE = 'State or province',
  UNDECIDED = 'Undecided'
}

export enum BusinessTargetMeasure {
  NEW_BUSINESS_TARGET = 'New business target',
  ALLOCATED_BUSINESS_TARGET = 'Allocated Business Target',
  UNALLOCATED_BUSINESS_TARGET = 'Unallocated Business Target'
}

export const ColumnHeadersListedMap = {
  CUSTOMER_ACCOUNT_HIERARCHY:
    'Name*, Key*, ParentKey*, City, StateProvince, Address1, Address2, Country, ZipPostal, Industry',
  ACTIVITY: 'Customer Account ID*, Geography ID*, Date*, Sales*, Units Sold, Quota Component*, Currency Code',
  LOCATION: 'Country*, ZipPostal*, Name, Address1, Address2, City, StateProvince',
  GENERIC: 'Name*, Key*, ParentKey*',
  EFFECTIVE_DATE_COLUMNS: ', EffectiveDate, EndDate'
};

export enum PlanDurations {
  YEARLY = '12',
  SEMI_ANNUAL = '6',
  QUARTERLY = '3',
  MONTHLY = '1'
}

export type BulkHierarchyPolygons = {
  inUseCatalogItems: HierarchyPolygonId['getPlanningCycleSpec']['inUseCountrySourceCatalogItems'];
  compressedHierarchyPolygonPages: HierarchyPolygonId['getHierarchyPolygonId'][];
};
export type MessageKey = keyof typeof enUS;

export interface CheckboxListItem {
  label: string;
  value: string;
  checked: boolean;
}

export enum AccountMovesDiff {
  ADDITION = '+',
  REMOVAL = '-',
  EXISTING = ''
}

export interface AggregatePinCenter {
  lat: number;
  lon: number;
}

export interface AggregatePin {
  accountName: string;
  accountId: number;
  geographyId: number;
  measureValue: number | null;
  center: AggregatePinCenter;
}

export const Multiple = 'multiple';

export enum CollectionFilterKind {
  CONTAINS_ANY = 'CONTAINS_ANY',
  NOT_CONTAINS_ANY = 'NOT_CONTAINS_ANY',
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS'
}

export interface Clause {
  rootHierarchyId: number;
  operator: CollectionFilterKind;
  ids: number[];
}

export interface Group {
  clauses: Clause[];
}

export interface DefinitionFilters {
  groups: Group[];
}

export interface CollectionFilter<T> {
  ids: ReadonlyArray<T>;
  kind: CollectionFilterKind;
}

export type CustomHierarchyFilter = CollectionFilter<number>;
export const defaultCustomHierarchyFilter: CollectionFilter<number> = {
  kind: CollectionFilterKind.EQUALS,
  ids: []
};

// Todo: Rename
export const MchQuantity = {
  NONE: 'NONE',
  ALL: 'ALL',
  SINGULAR: 'SINGULAR'
} as const;

export type MchQuantityType = (typeof MchQuantity)[keyof typeof MchQuantity];

export type MapCustomHierarchySetting =
  | {
      quantity: typeof MchQuantity.NONE;
    }
  | { quantity: typeof MchQuantity.ALL }
  | {
      quantity: typeof MchQuantity.SINGULAR;
      details: HierarchyRoot;
    };

export interface PermissionPerType {
  view: boolean;
  edit: boolean;
}

export interface Permission {
  actionName: string;
  deploymentModelId: number;
  roleName: string;
}

export interface BattleCardDesignerRootHierarchy {
  hierarchyTreeData: {
    id: number;
    label: string;
    key: string;
    hierarchyType: HierarchyType;
    childNodes?: HierarchyTreeNode[];
    hasChildren: boolean;
    isExpanded?: boolean;
    icon?: React.ReactNode;
    firstLevelMemberCount?: number;
  };
}

export interface AccountRuleRow {
  [GridFields.RULE_ID]: number;
  [GridFields.ACCOUNT_ID]: number;
  [GridFields.ACCOUNT_KEY]: string;
  [GridFields.ACCOUNT_NAME]: string;
  [GridFields.TERRITORY_ID]: string;
  [GridFields.TERRITORY_NAME]: string;
  [GridFields.TERRITORY_GROUP]: string;
  dynamic: Record<string, string | number>;
}

export interface FilterChangeInput {
  filterType: string;
  type: string;
  filter: string | number;
  colId: string;
}

export interface HierarchyBasedTargetItem {
  hierarchyId: number;
  hierarchyKey: string;
  hierarchyName: string;
  value: number;
}

export interface onAllocateParams {
  amount?: number;
  measureToProrateBy: { key: string; value: number };
  clearQuotaAdjustment: boolean;
  hierarchyTarget?: HierarchyTarget[];
  preserveSeasonality: boolean;
}

export enum QuotaComponentEditableType {
  TRUE = 'Y',
  FALSE = 'N'
}
export enum JobCatalogObjectType {
  COINSORT = 'COINSORT',
  PLANNING_CYCLE_CLONE = 'PLANNING_CYCLE_CLONE',
  DEPLOYMENT_CLONE = 'DEPLOYMENT_CLONE',
  POST_COINSORT = 'POST_COINSORT',
  GEOGRAPHY_AGGREGATION = 'GEOGRAPHY_AGGREGATION',
  TERRITORY_ACCOUNT_AGGREGATION = 'TERRITORY_ACCOUNT_AGGREGATION',
  TERRITORY_AGGREGATION = 'TERRITORY_AGGREGATION',
  DELETE_TERRITORY_RULE = 'DELETE_TERRITORY_RULE',
  TERRITORY_MEASURE_VALUE_AGGREGATION = 'TerritoryMeasureValueAggregation',
  ALLOCATE_TOP_DOWN = 'ALLOCATE_TOP_DOWN',
  GET_CHANGE_HISTORY_EXPORT = 'GET_CHANGE_HISTORY_EXPORT'
}

export interface AccountMoveOverlapRanges
  extends GetAccountRuleBindingsForQuotaMove_getAccountRuleBindings_bindings_measures_measureValueBreakdown {
  overlapStartDate: string;
  overlapEndDate: string;
}

export interface QuotaReallocationPreviewItems {
  quotaComponentName: string;
  fullAccountQuotaValue: number;
  reallocatingValue: number;
}

export enum QuotaReallocationOptions {
  DONT_MOVE_QUOTA,
  MOVE_PRORATED_QUOTA
}

export enum HierarchyAttributeType {
  TEXT = 'string',
  NUMERIC = 'number',
  DATE = 'date'
}

export enum AccountQuotaAdjustmentType {
  ORIGINAL = 'Original',
  ACCOUNT_MOVE = 'Account move'
}

export enum AccountQuotaRedirectDirection {
  TO = 'To',
  FROM = 'From'
}

export interface QuotaRedirect {
  moveDirection: AccountQuotaRedirectDirection;
  territory: TerritoryRuleHierarchyWithInfo;
}

export interface DatePeriod {
  periodStartDate: string;
  periodEndDate: string;
}

export interface MeasureValueBreakdown {
  measureValue: number;
  periodEndDate: string;
  periodStartDate: string;
}

export interface MeasureBreakdown {
  measureId: number;
  measureName: string;
  measureValue: number;
  measureValueBreakdown: MeasureValueBreakdown[];
  editable: boolean;
}

export interface AccountRedirectQuotaDistribution {
  accountKey: string;
  accountName: string;
  adjustmentType: AccountQuotaAdjustmentType;
  quotaRedirect: QuotaRedirect;
  alignmentEffectiveDate: DatePeriod[];
  measures: MeasureBreakdown[];
}

export enum ExportTableName {
  TERRITORY_QUOTA_FILE_UPLOAD_TEMPLATE = 'Territory Quota File Upload Template',
  TERRITORY_RULES = 'Territory Rules'
}
