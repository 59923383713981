import { RowNode, ValueGetterParams, ValueSetterParams } from '@ag-grid-community/core';
import { ApolloQueryResult } from '@apollo/client';
import dayjs, { Dayjs } from 'dayjs';

import {
  AccountRedirectInput,
  AccountRedirectTarget,
  AccountRedirectReallocationField,
  GetAccountRuleBindings,
  GetAccountRuleBindingsForQuotaMove_getAccountRuleBindings_bindings_measures,
  GetAccountRuleBindingsVariables,
  GetAccountRuleBindings_getAccountRuleBindings_bindings,
  GetAccountRuleBindingsForQuotaMove_getAccountRuleBindings_bindings_measures_measureValueBreakdown,
  GetAccountRuleBindings_getAccountRuleBindings_bindings_redirects
} from 'app/graphql/generated/apolloTypes';

import {
  AccountMoveOverlapRanges,
  AccountRedirect,
  AqgTerritoryKind,
  DeleteOptions,
  INVALID_DATE,
  QuotaGridColumnName,
  QuotaReallocationPreviewItems,
  RedirectTargetRule,
  SelectedPlanningCycle,
  Toast
} from 'app/models';

import { getLocalDateString } from 'utils/helpers/dateHelpers';
import { formatMessage } from 'utils/messages/utils';

import { AqgBindingRow, AqgRow } from './AccountQuotaGrid';

const DATE_FORMAT = 'YYYY-MM-DD';

export const getEffectiveDateValue = (
  params: ValueGetterParams,
  planningCycleStartDate?: string,
  planningCycleDuration?: number
): string | null => {
  if (params?.data?.redirects?.length > 0) {
    if (params?.data?.isRowExpanded) {
      return null;
    }
    return formatMessage('MULTIPLE');
  }

  const redirects = params?.node?.parent?.data?.redirects;

  const sortedRedirects = redirects && sortRedirectsByStartDate([...redirects]);

  const sourceRule = params?.node?.parent?.data?.sourceRule || params?.data?.sourceRule;

  const planningCycleEndDate = getPlanningCycleEndDate(planningCycleStartDate, planningCycleDuration);

  const calculatedEndDate =
    params?.data?.kind === AqgTerritoryKind.EXPANDED_SOURCE && sortedRedirects
      ? getDayBeforeDate(sortedRedirects[0].startDate)
      : planningCycleEndDate;

  let startDate;

  if (params?.data?.redirectStartDate) {
    startDate = params?.data?.redirectStartDate;
  } else if (sourceRule?.effectiveDate && dayjs(sourceRule?.effectiveDate).isAfter(planningCycleStartDate)) {
    startDate = sourceRule?.effectiveDate;
  } else {
    startDate = planningCycleStartDate;
  }

  let endDate;

  if (params?.data?.redirectEndDate) {
    endDate = params?.data?.redirectEndDate;
  } else if (
    sourceRule?.endDate &&
    dayjs(sourceRule.endDate).isBefore(planningCycleEndDate) &&
    dayjs(sourceRule.endDate).isBefore(calculatedEndDate)
  ) {
    endDate = sourceRule?.endDate;
  } else {
    endDate = calculatedEndDate;
  }

  startDate = startDate ? dayjs(startDate).format('MM/DD/YYYY') : formatMessage('NO_START_DATE');
  endDate = endDate && endDate !== INVALID_DATE ? dayjs(endDate).format('MM/DD/YYYY') : formatMessage('NO_END_DATE');

  return `${startDate} - ${endDate}`;
};

export const setEffectiveDateValue = (
  params: ValueSetterParams,
  handleRedirectAccountUnit: (accountRedirectInput: AccountRedirectInput, toast: Toast) => void
): boolean => {
  const parentData = params?.node?.parent?.data;
  const newStartDate = params.newValue.effectiveStartDate;
  const accountId = parentData?.accountId;
  const accountName = parentData?.accountName;
  const sourceRuleId = parentData?.sourceRuleId;
  const redirects = parentData?.redirects;

  if (!redirects) {
    return false;
  }

  const sortedRedirects = sortRedirectsByStartDate([...redirects]);

  const { targetRedirect, index } = getTargetRedirect(sortedRedirects, params.data.redirectStartDate);

  if (!targetRedirect?.startDate || dayjs(newStartDate).format(DATE_FORMAT) === targetRedirect.startDate) {
    return false;
  }

  const newRedirects = new Array<AccountRedirectTarget>();
  const redirectIdsToRemove = new Array<number>();

  const newStartDateRedirect = generateRecreatedNewDatesTarget(
    targetRedirect,
    targetRedirect.targetRule?.ruleId,
    dayjs(newStartDate).format(DATE_FORMAT),
    targetRedirect.endDate
  );

  newRedirects.push(newStartDateRedirect);
  redirectIdsToRemove.push(targetRedirect.redirectId);

  if (index > 0) {
    const previousRedirect = sortedRedirects[index - 1];
    const newEndDateRedirect = generateRecreatedNewDatesTarget(
      previousRedirect,
      previousRedirect.targetRule?.ruleId,
      previousRedirect.startDate,
      getDayBeforeDate(newStartDate)
    );

    newRedirects.push(newEndDateRedirect);
    redirectIdsToRemove.push(previousRedirect.redirectId);
  }

  handleRedirectAccountUnit(
    { targets: newRedirects, redirectIdsToRemove, accountId, sourceRuleId },
    {
      message: formatMessage('UPDATED_EFFECTED_DATES', { accountName }),
      title: formatMessage('ACCOUNT_EFFECTIVE_DATE_UPDATED')
    }
  );

  return true;
};

export const getTargetRedirect = (
  redirects: AccountRedirect[],
  redirectStartDate: string
): { targetRedirect: AccountRedirect; index: number } => {
  const targetIndex = redirects.findIndex((redirect) => redirect.startDate === redirectStartDate);
  return { targetRedirect: redirects[targetIndex], index: targetIndex };
};

export const getPlanningCycleEndDate = (startDate: string, additionalMonths = 0): string => {
  const formattedDate = dayjs(startDate).add(additionalMonths, 'month').subtract(1, 'day');
  return dayjs(formattedDate).format(DATE_FORMAT);
};

export const formatDateYearMonthDay = (date: string | Dayjs): string => {
  return dayjs(date).format(DATE_FORMAT);
};

export const formatDateMonthDayYear = (date: string | Dayjs): string => {
  return dayjs(date).format('MM-DD-YYYY');
};

export const sortRedirectsByStartDate = (redirects: AccountRedirect[]): AccountRedirect[] => {
  return redirects.sort((redirect1, redirect2) => redirect1.startDate.localeCompare(redirect2.startDate));
};

export const getDayBeforeDate = (date: string): string => {
  return dayjs(date).subtract(1, 'day').format(DATE_FORMAT);
};

export const getDayAfterDate = (date: string): string => {
  return dayjs(date).add(1, 'day').format(DATE_FORMAT);
};

export const calculateRedirectDates = (
  startDate: string,
  redirects: AccountRedirect[],
  endDate: string,
  targetRuleId: number,
  reallocationField?: AccountRedirectReallocationField
): {
  newRedirects: AccountRedirectTarget[];
  redirectIdsToRemove: number[];
} => {
  const newRedirects = new Array<AccountRedirectTarget>();
  const redirectIdsToRemove = new Array<number>();
  const newRedirect: AccountRedirectTarget = {
    targetRuleId,
    startDate,
    endDate,
    reallocationField
  };

  if (redirects.length === 0) {
    newRedirects.push(newRedirect);
  } else {
    let newEndDate;
    const sortedRedirects = sortRedirectsByStartDate([...redirects]);
    // when there are existing redirects, and the new redirect start date has the earliest date
    // then the new redirect will be the first redirect with end date as one day before the next redirect start date
    if (dayjs(startDate).isBefore(sortedRedirects[0].startDate)) {
      newEndDate = getDayBeforeDate(sortedRedirects[0].startDate);
      newRedirect.endDate = newEndDate;
      newRedirects.push(newRedirect);
    } else {
      // when the new redirect start date is in between a existing redirect
      // then the new redirect's end date will be the end date of that existing redirect
      // also need to recreate that existing redirect to have a new end date which is one day before the new redirect start date
      for (const redirect of sortedRedirects) {
        if (startDate <= redirect.endDate && startDate >= redirect.startDate) {
          newEndDate = redirect.endDate;
          newRedirect.endDate = newEndDate;

          const recreatedTarget = generateRecreatedNewDatesTarget(
            redirect,
            redirect.targetRule?.ruleId,
            redirect.startDate,
            getDayBeforeDate(startDate)
          );

          newRedirects.push(newRedirect, recreatedTarget);
          redirectIdsToRemove.push(redirect.redirectId);
          break;
        }
      }
    }
  }

  return { newRedirects, redirectIdsToRemove };
};

export const getRedirectIndexById = (redirects: AccountRedirect[], redirectId: number): number => {
  for (let i = 0; i < redirects.length; ++i) {
    if (redirects[i].redirectId === redirectId) return i;
  }
  return null;
};

export const getFormattedTerritoryName = (territory: RedirectTargetRule) => {
  if (!territory) return formatMessage('UNASSIGNED_TERRITORY');
  return `${territory.territoryName} (${territory.territoryId})`;
};

export const calculateRedirectToDelete = (
  sortedRedirects: AccountRedirect[],
  redirectToDeleteId: number,
  sourceRuleId: number,
  deleteOption: DeleteOptions
): {
  newRedirect: AccountRedirectTarget;
  redirectIdsToRemove: number[];
  formattedTerritoryNameText: string;
} => {
  let newRedirect: AccountRedirectTarget;
  let formattedTerritoryNameText: string;
  const redirectIdsToRemove = [redirectToDeleteId];

  const redirectToDeleteIndex = sortedRedirects.findIndex((redirect) => redirect.redirectId === redirectToDeleteId);
  if (redirectToDeleteIndex < 0) throw new Error(`No redirect with ID ${redirectToDeleteId} to delete`);
  const redirectToDelete = sortedRedirects[redirectToDeleteIndex];

  switch (deleteOption) {
    case DeleteOptions.START:
      const futureRedirect = sortedRedirects[redirectToDeleteIndex - 1];
      if (futureRedirect) {
        redirectIdsToRemove.push(futureRedirect.redirectId);

        newRedirect = generateRecreatedNewDatesTarget(
          futureRedirect,
          futureRedirect.targetRule?.ruleId,
          redirectToDelete.startDate,
          futureRedirect.endDate
        );
        formattedTerritoryNameText = getFormattedTerritoryName(futureRedirect.targetRule);
      }
      break;
    case DeleteOptions.END:
      const pastRedirect = sortedRedirects[redirectToDeleteIndex + 1];
      if (pastRedirect) {
        redirectIdsToRemove.push(pastRedirect.redirectId);

        newRedirect = generateRecreatedNewDatesTarget(
          pastRedirect,
          pastRedirect.targetRule?.ruleId,
          pastRedirect.startDate,
          redirectToDelete.endDate
        );
        formattedTerritoryNameText = getFormattedTerritoryName(pastRedirect.targetRule);
      }
      break;
    case DeleteOptions.ORIGINAL:
      newRedirect = {
        targetRuleId: sourceRuleId,
        startDate: redirectToDelete.startDate,
        endDate: redirectToDelete.endDate
      };
      break;
    default:
      console.warn(`Unhandled delete option ${deleteOption}`);
  }

  return { newRedirect, redirectIdsToRemove, formattedTerritoryNameText };
};

export const getFormattedBindings = (
  bindings: GetAccountRuleBindings_getAccountRuleBindings_bindings[],
  planningCycleEndDate: string
): AqgBindingRow[] => {
  return bindings?.map((binding) => {
    const measure = binding.measures.find((measure) => {
      return measure.measureName === QuotaGridColumnName.ACCOUNT_QUOTA;
    });

    // Using the first hierarchy for now as we are not supporting multiple hierarchies yet
    // This will be added in the story TQP-14410
    const formattedBinding = {
      kind: AqgTerritoryKind.ROOT_SOURCE,
      sourceRuleId: binding.sourceRule.ruleId,
      accountId: binding.hierarchies[0].hierarchyId,
      accountKey: binding.hierarchies[0].key,
      accountName: binding.hierarchies[0].name,
      customHierarchyId: binding.hierarchies[1]?.hierarchyId,
      customHierarchyKey: binding.hierarchies[1]?.key,
      customHierarchyName: binding.hierarchies[1]?.name,
      type: binding.hierarchies[0].type,
      redirects: binding.redirects,
      effectiveStartDate: binding.sourceRule.effectiveDate,
      effectiveEndDate: binding.sourceRule.endDate,
      territoryName: binding.sourceRule.territoryName,
      territoryId: binding.sourceRule.territoryId,
      sourceRule: binding.sourceRule,
      accountQuotaMeasureValue: measure?.measureValue,
      accountQuotaMeasureId: measure?.measureId
    };
    // If redirects are present, then the source rule may not be the current territory
    if (binding.redirects.length > 0) {
      const currentTerritoryOverrides = getCurrentTerritoryOverrides(binding, planningCycleEndDate);
      if (currentTerritoryOverrides) {
        return {
          ...formattedBinding,
          ...currentTerritoryOverrides
        };
      }
    }
    return formattedBinding;
  });
};

export const getCurrentTerritoryOverrides = (
  binding: GetAccountRuleBindings_getAccountRuleBindings_bindings,
  planningCycleEndDate: string
): Record<string, string> => {
  let currentTerritory;
  const currentDate = getLocalDateString();
  currentTerritory = binding.redirects.find((redirect) => {
    const { startDate, endDate } = redirect;
    return currentDate > startDate && currentDate < endDate;
  });

  if (currentDate > planningCycleEndDate) {
    const redirects = binding.redirects.slice();
    redirects.sort((a, b) => a.endDate.localeCompare(b.endDate));
    currentTerritory = redirects[redirects.length - 1];
  }

  if (!currentTerritory) {
    return null;
  }

  if (!currentTerritory.targetRule) {
    return {
      territoryName: formatMessage('UNASSIGNED_TERRITORY'),
      territoryId: null,
      kind: AqgTerritoryKind.UNASSIGNED
    };
  }

  return {
    territoryName: currentTerritory.targetRule.territoryName,
    territoryId: currentTerritory.targetRule.territoryId,
    kind:
      currentTerritory.targetRule.ruleId === binding.sourceRule.ruleId
        ? AqgTerritoryKind.MOVE_BACK
        : AqgTerritoryKind.MOVE_AWAY
  };
};

interface HandleRefreshInput {
  rowNode: RowNode;
  accountRedirectInput: AccountRedirectInput;
  territoryGroupTypeId: number;
  selectedQuotaComponentId: number;
  selectedPlanningCycle: SelectedPlanningCycle;
  getExpandedTerritoryRows: (expandedRow: AqgBindingRow) => AqgRow[];
  fetchMore: (options: {
    variables: GetAccountRuleBindingsVariables;
  }) => Promise<ApolloQueryResult<GetAccountRuleBindings>>;
}

export const handleRedirectRowRefresh = async ({
  rowNode,
  accountRedirectInput,
  territoryGroupTypeId,
  selectedQuotaComponentId,
  selectedPlanningCycle,
  fetchMore,
  getExpandedTerritoryRows
}: HandleRefreshInput): Promise<void> => {
  const { redirectStartDate, redirectEndDate, accountId, redirectId } = rowNode.data;
  const isParentToRefresh = accountRedirectInput.accountId === accountId;
  const isRedirectToRefresh = accountRedirectInput.redirectIdsToRemove.includes(redirectId);
  if (!isParentToRefresh && !isRedirectToRefresh) return;

  const updatedBinding = await fetchMore({
    variables: {
      input: {
        territoryGroupTypeId,
        quotaComponentId: selectedQuotaComponentId,
        startRow: 1,
        endRow: 1,
        filters: JSON.stringify({
          ruleId: { filterType: 'number', type: 'equals', filter: accountRedirectInput.sourceRuleId },
          accountId: { filterType: 'number', type: 'equals', filter: accountRedirectInput.accountId }
        })
      }
    }
  });
  const [formattedBinding] = getFormattedBindings(
    updatedBinding?.data.getAccountRuleBindings.bindings,
    getPlanningCycleEndDate(selectedPlanningCycle.planningCycleStartDate, selectedPlanningCycle.planningCycleDuration)
  );

  if (isParentToRefresh) {
    rowNode.updateData(formattedBinding);
  } else {
    const formattedExpandedRows = getExpandedTerritoryRows(formattedBinding);
    const filteredExpandedRow = formattedExpandedRows.find(
      (redirect) => redirect.redirectStartDate === redirectStartDate || redirect.redirectEndDate === redirectEndDate
    );
    rowNode.updateData(filteredExpandedRow);
  }
};

export const getDeleteRedirectToastText = ({
  newRedirect,
  deleteOption,
  sortedRedirects,
  redirectId,
  accountName,
  formattedTerritoryNameText
}: {
  newRedirect: AccountRedirectTarget;
  deleteOption: DeleteOptions;
  sortedRedirects: AccountRedirect[];
  redirectId: number;
  accountName: string;
  formattedTerritoryNameText: string;
}) => {
  if (!newRedirect) {
    const removedRedirectEndDate = sortedRedirects.find((redirect) => redirect.redirectId === redirectId).startDate;
    return formatMessage('DELETE_REDIRECT_TOAST_BODY_ORIGINAL', {
      accountName,
      startDate: formatDateMonthDayYear(removedRedirectEndDate)
    });
  }
  const { startDate, endDate } = newRedirect;
  switch (deleteOption) {
    case DeleteOptions.START:
      return formatMessage('DELETE_REDIRECT_TOAST_BODY_START', {
        nextTerritoryName: formattedTerritoryNameText,
        startDate: formatDateMonthDayYear(startDate)
      });
    case DeleteOptions.END:
      return formatMessage('DELETE_REDIRECT_TOAST_BODY_END', {
        prevTerritoryName: formattedTerritoryNameText,
        endDate: formatDateMonthDayYear(endDate)
      });
    case DeleteOptions.ORIGINAL:
      return formatMessage('DELETE_REDIRECT_TOAST_BODY_ORIGINAL', {
        accountName,
        startDate: formatDateMonthDayYear(startDate)
      });
    default:
      console.warn(`Unhandled delete option ${deleteOption}`);
  }
  return '';
};

const splitDate = (dateStr) => {
  const [year, month, date] = dateStr.split('-');
  return { year, month, date };
};

export const calculateNewRedirectEndDate = (
  redirectStartDate: string,
  sourceRuleEndDate: string,
  redirects: GetAccountRuleBindings_getAccountRuleBindings_bindings_redirects[]
): string => {
  if (!redirectStartDate || !sourceRuleEndDate) {
    return null;
  }

  if (redirects.length === 0) {
    return sourceRuleEndDate;
  }

  const sortedRedirects = sortRedirectsByStartDate([...redirects]);

  // If the redirect start date is before the first redirect,
  // return the day before the first redirect start date as redirect end date
  if (dayjs(redirectStartDate).isBefore(sortedRedirects[0].startDate)) {
    return getDayBeforeDate(sortedRedirects[0].startDate);
  }

  // find the first redirect that has an end date before the redirect start date
  const firstMatchingRedirect = sortedRedirects.find((redirect) => {
    return dayjs(redirectStartDate).isBefore(redirect.endDate);
  });

  return firstMatchingRedirect?.endDate;
};

export const getRedirectDateAndTargetRuleOverlapPeriods = (
  redirectStartDate: string,
  redirectEndDate: string,
  targetRuleEffectiveStartDate: string,
  targetRuleEndDate: string
) => {
  if (!redirectStartDate || !targetRuleEffectiveStartDate || !redirectEndDate || !targetRuleEndDate) {
    return [];
  }

  // Range 1: Redirect date and redirect end date range
  const range1Start = dayjs(redirectStartDate, DATE_FORMAT);
  const range1End = dayjs(redirectEndDate, DATE_FORMAT);

  //Range 2: Target rule effective start and end date range
  const range2Start = dayjs(targetRuleEffectiveStartDate, DATE_FORMAT);
  const range2End = dayjs(targetRuleEndDate, DATE_FORMAT);

  // Calculate the overall overlap period
  const overlapStart = range1Start.isAfter(range2Start) ? range1Start : range2Start;
  const overlapEnd = range1End.isBefore(range2End) ? range1End : range2End;

  if (overlapStart.isAfter(overlapEnd)) {
    return [];
  }

  const overlapPeriods = [];
  let currentStart = overlapStart;

  // Set the overlap periods based on the overlap start and end dates
  while (currentStart.isBefore(overlapEnd) || currentStart.isSame(overlapEnd, 'day')) {
    const monthEnd = currentStart.endOf('month');
    const currentEnd = monthEnd.isBefore(overlapEnd) ? monthEnd : overlapEnd;
    overlapPeriods.push({
      overlapStartDate: currentStart.format(DATE_FORMAT),
      overlapEndDate: currentEnd.format(DATE_FORMAT)
    });
    currentStart = currentEnd.add(1, 'day');
  }
  return overlapPeriods;
};

const getMatchingBreakdownPeriodsWithOverlapPeriods = (
  breakdownArr: GetAccountRuleBindingsForQuotaMove_getAccountRuleBindings_bindings_measures_measureValueBreakdown[],
  overlapRanges: AccountMoveOverlapRanges[]
): AccountMoveOverlapRanges[] => {
  const overlapPeriods = [];
  if (overlapRanges.length === 0 || breakdownArr.length === 0) return overlapPeriods;

  // Finds matching breakdown periods that overlap between sourceRule, redirectDate and targetRule.
  overlapRanges.forEach((overlap) => {
    const overlapStartDate = splitDate(overlap.overlapStartDate);
    const overlapEndDate = splitDate(overlap.overlapEndDate);

    breakdownArr.forEach((breakdown) => {
      const breakdownStart = splitDate(breakdown.periodStartDate);
      const breakdownEnd = splitDate(breakdown.periodEndDate);

      // Check if the breakdown period in sourceRule matches the overlap period of targetRule and selected redirect date.
      if (
        breakdownStart.month === overlapStartDate.month &&
        breakdownStart.year === overlapStartDate.year &&
        breakdownEnd.month === overlapEndDate.month &&
        breakdownEnd.year === overlapEndDate.year
      ) {
        // Add the combined overlap and breakdown period to the result array for quota reallocation calculations.
        overlapPeriods.push({
          ...overlap,
          ...breakdown
        });
      }
    });
  });

  return overlapPeriods;
};

const calculateReallocationPreviewValue = (
  measure: GetAccountRuleBindingsForQuotaMove_getAccountRuleBindings_bindings_measures,
  overlapRanges: AccountMoveOverlapRanges[]
): number => {
  // Calculates the total reallocation preview value for a given measure and its overlap periods.
  const overlapPeriods = getMatchingBreakdownPeriodsWithOverlapPeriods(measure.measureValueBreakdown, overlapRanges);
  return overlapPeriods.reduce((total, period) => {
    const { measureValue, overlapEndDate, overlapStartDate, periodStartDate, periodEndDate } = period;

    const overlapStartDay = splitDate(overlapStartDate).date;
    const overlapEndDay = splitDate(overlapEndDate).date;
    const periodStartDay = splitDate(periodStartDate).date;
    const periodEndDay = splitDate(periodEndDate).date;

    // Calculate the total effective days and the number of overlap days
    const totalEffectiveDays = periodEndDay - periodStartDay + 1;
    const overlapDays = overlapEndDay - overlapStartDay + 1;

    // Calculate the period value based on the proportion of overlap days to total effective days
    const periodValue = measureValue * (overlapDays / totalEffectiveDays);
    return total + periodValue;
  }, 0);
};

export const handleCalculatingPreviewQuotaReallocation = (
  measures: GetAccountRuleBindingsForQuotaMove_getAccountRuleBindings_bindings_measures[],
  overlapRanges: AccountMoveOverlapRanges[]
): QuotaReallocationPreviewItems[] => {
  return measures.map((measure) => {
    return {
      quotaComponentName: measure.quotaComponentName,
      fullAccountQuotaValue: measure.measureValue,
      reallocatingValue: calculateReallocationPreviewValue(measure, overlapRanges)
    };
  });
};

export const generateRecreatedNewDatesTarget = (
  oldRedirect: AccountRedirect,
  targetRuleId: number,
  newStartDate: string,
  newEndDate: string
): AccountRedirectTarget => {
  const recreatedTarget: AccountRedirectTarget = {
    targetRuleId,
    startDate: newStartDate,
    endDate: newEndDate
  };
  // when the existing redirect gets recreated with a new dates
  // if it has reallocation field previously, need to add reallocation field to the recreated redirect target
  if (oldRedirect.fields.length > 0) {
    const { primaryFieldId, fieldId } = oldRedirect.fields[0];
    recreatedTarget.reallocationField = { primaryFieldId, fieldId };
  }
  return recreatedTarget;
};
