import { useMemo } from 'react';

import { PrimaryHierarchyTypeForMap } from 'app/workers/MapWorker/MapWorkerProtocol';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useScope } from 'app/contexts/scopeProvider';
import { useTerritoryOptimization } from 'app/contexts/territoryOptimizationProvider';

import { SplitFeatures } from 'app/global/features';

import {
  CustomHierarchyQuantifierEnum,
  GetMapVisualizationSettings_getDeploymentModelSpec_battlecards_mapVisualizationSettings,
  VisualizationTerritoryGroupTypeEnum
} from 'app/graphql/generated/apolloTypes';
import { useGetMapVisualizationSettings } from 'app/graphql/queries/getMapVisualizationSettings';

import useTreatment from 'app/hooks/useTreatment';

import { HierarchyType, MapCustomHierarchySetting } from 'app/models';

export const useMapVisualizationSettings = (): {
  savedCustomHierarchyPreference: MapCustomHierarchySetting;
  mapVisualizationSettings: GetMapVisualizationSettings_getDeploymentModelSpec_battlecards_mapVisualizationSettings;
  selectedTerritoryGroupId: number | null;
  selectedTerritoryGroupTypeId: number | null;
  primaryHierarchy: PrimaryHierarchyTypeForMap;
} => {
  const { selectedBattleCardId, battleCardLookupMap } = useBattleCard();
  const { optimizationTargetTgId } = useTerritoryOptimization();

  const selectedBattleCard = battleCardLookupMap[selectedBattleCardId] ?? null;
  const { selectedDeploymentModelId, selectedPlanningCycle } = useScope();
  const [isAccountFirstMapOn] = useTreatment(SplitFeatures.ACCOUNT_FIRST_MAP);

  const geoTgtId = selectedBattleCard?.territoryGroupTypes?.find(
    (tgt) => tgt.hierarchyType === HierarchyType.GeographicTerritoryHierarchy
  )?.territoryGroupId;

  const accountTgtId = selectedBattleCard?.territoryGroupTypes?.find(
    (tgt) => tgt.hierarchyType === HierarchyType.CustomerAccountHierarchy
  )?.territoryGroupId;

  const { data } = useGetMapVisualizationSettings({
    fetchPolicy: 'network-only',
    skip: !isAccountFirstMapOn || !selectedBattleCardId || !selectedDeploymentModelId,
    variables: {
      input: {
        battlecardId: +selectedBattleCardId,
        deploymentModelId: selectedDeploymentModelId
      },
      planningCycleId: selectedPlanningCycle?.id
    }
  });

  const mapVisualizationSettings = data?.getDeploymentModelSpec?.battlecards?.[0]?.mapVisualizationSettings;
  const rootHierarchies = data?.getRootHierarchies;
  const isCustomerAccountPrimaryHierarchy =
    isAccountFirstMapOn &&
    accountTgtId &&
    (mapVisualizationSettings?.territoryGroupType === VisualizationTerritoryGroupTypeEnum.CustomerAccountHierarchy ||
      !geoTgtId) &&
    !optimizationTargetTgId;

  const savedCustomHierarchyPreference = useMemo(() => {
    if (!mapVisualizationSettings || !rootHierarchies) {
      return null;
    }
    switch (mapVisualizationSettings.customHierarchyQuantifier) {
      case CustomHierarchyQuantifierEnum.SINGULAR: {
        const { rootHierarchyId, rootKey, rootName } = rootHierarchies.find(
          (hierarchy) => hierarchy.rootHierarchyId === mapVisualizationSettings.customHierarchyId
        );
        return {
          quantity: CustomHierarchyQuantifierEnum.SINGULAR,
          details: {
            rootHierarchyId,
            rootKey,
            rootName
          }
        };
      }
      case CustomHierarchyQuantifierEnum.ALL:
      case CustomHierarchyQuantifierEnum.NONE:
        return {
          quantity: mapVisualizationSettings.customHierarchyQuantifier
        };
    }
  }, [mapVisualizationSettings, rootHierarchies]);

  const {
    selectedTerritoryGroupId,
    selectedTerritoryGroupTypeId,
    primaryHierarchy
  }: {
    selectedTerritoryGroupId: number | null;
    selectedTerritoryGroupTypeId: number | null;
    primaryHierarchy: PrimaryHierarchyTypeForMap;
  } = useMemo(() => {
    if (isCustomerAccountPrimaryHierarchy) {
      return {
        selectedTerritoryGroupId: accountTgtId,
        selectedTerritoryGroupTypeId: accountTgtId,
        primaryHierarchy: HierarchyType.CustomerAccountHierarchy
      };
    }

    return {
      selectedTerritoryGroupId: +optimizationTargetTgId || geoTgtId || null,
      selectedTerritoryGroupTypeId: geoTgtId || null,
      primaryHierarchy: HierarchyType.GeographicTerritoryHierarchy
    };
  }, [selectedBattleCard, optimizationTargetTgId, isCustomerAccountPrimaryHierarchy, accountTgtId, geoTgtId]);

  return {
    savedCustomHierarchyPreference,
    mapVisualizationSettings,
    selectedTerritoryGroupId,
    selectedTerritoryGroupTypeId,
    primaryHierarchy
  };
};
