import React, { FC, useMemo } from 'react';

import { useMapVisualizationSettings } from 'app/components/TerritoryMap/hooks/useMapVisualizationSettings';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useCoinsort } from 'app/contexts/coinsortProvider';
import { useCommandCenter } from 'app/contexts/commandCenterProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { CommandCenterDrawerState } from 'app/models';

import {
  MapContextRedistributorContextValues,
  MapContextRedistributorProvider
} from './mapContextRedistributorProvider';

type NativeMcrLoaderProps = {
  selectedBattleCardId?: string | null;
  selectedQuotaComponentId?: number | null;
};

export const NativeMcrLoader: FC<NativeMcrLoaderProps> = ({
  selectedBattleCardId: selectedBattleCardIdOverride,
  selectedQuotaComponentId: selectedQuotaComponentIdOverride,
  children
}) => {
  const {
    selectedBattleCardId: defaultSelectedBattleCardId,
    battleCardLookupMap,
    selectedQuotaComponentId: defaultSelectedQuotaComponentId
  } = useBattleCard();
  const { coinsortProgressLookupMap } = useCoinsort();
  const { commandCenterDrawerState } = useCommandCenter();
  const { selectedDeploymentModelId } = useScope();

  const selectedBattleCardId = selectedBattleCardIdOverride ?? defaultSelectedBattleCardId;
  const selectedQuotaComponentId = selectedQuotaComponentIdOverride ?? defaultSelectedQuotaComponentId;

  // Transformed values
  const isMapPointerDisabled = commandCenterDrawerState === CommandCenterDrawerState.HALF;
  const coinsortStatus = coinsortProgressLookupMap[selectedBattleCardId]?.coinsortStatus ?? null;
  const selectedBattleCard = battleCardLookupMap[selectedBattleCardId] ?? null;

  const {
    savedCustomHierarchyPreference,
    mapVisualizationSettings,
    selectedTerritoryGroupTypeId,
    selectedTerritoryGroupId,
    primaryHierarchy
  } = useMapVisualizationSettings();

  const contextValue = useMemo(
    (): MapContextRedistributorContextValues => ({
      // From useBattleCard
      selectedBattleCardId,
      selectedQuotaComponentId,
      selectedTerritoryGroupTypeId,
      selectedTerritoryGroupId,
      // Transformed
      coinsortStatus,
      isMapPointerDisabled,
      selectedBattleCard,
      isEmbedded: false,
      selectedDeploymentModelId,
      isBattlecardDataLoading: false,
      externalControlSuffix: '',
      primaryHierarchy,
      savedCustomHierarchyPreference,
      mapVisualizationSettings
    }),
    [
      selectedDeploymentModelId,
      selectedBattleCardId,
      selectedQuotaComponentId,
      selectedTerritoryGroupTypeId,
      selectedTerritoryGroupId,
      coinsortStatus,
      isMapPointerDisabled,
      selectedBattleCard,
      primaryHierarchy,
      savedCustomHierarchyPreference,
      mapVisualizationSettings
    ]
  );

  return <MapContextRedistributorProvider value={contextValue}> {children}</MapContextRedistributorProvider>;
};
